import { useTranslation } from "@hireroo/i18n";
import { Divider } from "@mui/material";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import * as React from "react";

import FollowUpQuestion, { FollowUpQuestionProps } from "../../../../../../modules/FollowUpQuestion/FollowUpQuestion";

type CategorySection = {
  title: string;
  questions: FollowUpQuestionProps[];
};

export type QuestionDetailCollapseProps = {
  title: string;
  InheritedSection: React.ReactNode;
  categorySections: CategorySection[];
};

const QuestionDetailCollapse: React.FC<QuestionDetailCollapseProps> = props => {
  const { t } = useTranslation();
  return (
    <Stack direction="column" spacing={1.5}>
      <Typography color="textSecondary" fontWeight={700}>
        {props.title}
      </Typography>
      {props.InheritedSection && <Box height="250px">{props.InheritedSection}</Box>}
      <Stack spacing={1}>
        {props.categorySections.map((section, index) => (
          <Stack key={`section-${index}`} spacing={1}>
            <Stack direction="row" alignItems="center" width="100%" spacing={2}>
              <Typography
                whiteSpace="nowrap"
                fontWeight={500}
                fontSize={14}
                color="textSecondary"
              >{`${t("カテゴリ")}: ${section.title}`}</Typography>
              <Box width="100%">
                <Divider textAlign="center" variant="fullWidth" />
              </Box>
            </Stack>
            <Stack pl={5}>
              {section.questions.map((question, index) => (
                <FollowUpQuestion key={`question-${index}`} {...question} />
              ))}
            </Stack>
          </Stack>
        ))}
      </Stack>
    </Stack>
  );
};

QuestionDetailCollapse.displayName = "QuestionDetailCollapse";

export default QuestionDetailCollapse;
