import { useTranslationWithVariable } from "@hireroo/i18n";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import Slider, { SliderProps } from "@mui/material/Slider";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { useController } from "react-hook-form";

import { useSearchFormContext } from "../../PrivateContext";
import SplitButton, { SplitButtonProps } from "../SplitButton/SplitButton";

type FieldValue = {
  /** 0 ~ 100 */
  min: number;
  /** 0 ~ 100 */
  max: number;
};

export type RangeFieldProps = {
  name: string;
  title: string;
  label: string;
  onChange?: (fields: FieldValue) => void;
  disabled?: boolean;
  variant?: SplitButtonProps["variant"];
  slider?: Pick<SliderProps, "max" | "min" | "step" | "valueLabelFormat">;
  customCurrentLabelFormat?: (value: FieldValue) => string;
};

const INITIAL_SLIDER_VALUE: FieldValue = {
  min: 0,
  max: 100,
};

const RangeField: React.FC<RangeFieldProps> = props => {
  const { t: t2 } = useTranslationWithVariable();
  const { onChange } = props;
  const { subscribeClearAllFields: onClearFields } = useSearchFormContext();
  const { field } = useController<Record<string, FieldValue>>({
    name: props.name,
  });
  const [sliderValue, setSliderValue] = React.useState<FieldValue>(field.value);

  const initialSliderValue = React.useMemo(() => {
    if (props.slider && props.slider.max !== undefined && props.slider.min !== undefined) {
      return {
        min: props.slider.min,
        max: props.slider.max,
      };
    }
    return INITIAL_SLIDER_VALUE;
  }, [props.slider]);
  const resetField = React.useCallback(() => {
    setSliderValue(initialSliderValue);
    onChange?.(initialSliderValue);
    field.onChange(initialSliderValue);
  }, [field, initialSliderValue, onChange]);

  React.useEffect(() => {
    const stop = onClearFields(() => {
      setSliderValue(initialSliderValue);
      onChange?.(initialSliderValue);
    });
    return () => {
      stop();
    };
  }, [initialSliderValue, onChange, onClearFields]);

  const range = React.useMemo(() => {
    return [sliderValue.min, sliderValue.max];
  }, [sliderValue]);

  const handleChangeRange = React.useCallback((_event: Event, newValue: number | number[]) => {
    const [min, max] = newValue as number[];
    setSliderValue({ min, max });
  }, []);

  const isInitialValue = sliderValue.min === initialSliderValue.min && sliderValue.max === initialSliderValue.max;
  const currentRangeLabel =
    props.customCurrentLabelFormat !== undefined
      ? props.customCurrentLabelFormat(sliderValue)
      : `${Math.round(sliderValue.min)}% ~ ${Math.round(sliderValue.max)}%`;
  const title = isInitialValue ? props.title : [props.title, currentRangeLabel].join(": ");
  const splitButtonProps: SplitButtonProps = {
    title: title,
    disabled: props.disabled,
    status: isInitialValue ? "INACTIVE" : "ACTIVE",
    label: {
      children: title,
    },
    onApply: () => {
      onChange?.(sliderValue);
      field.onChange(sliderValue);
    },
    onReset: () => {
      resetField();
    },
    variant: props.variant,
  };
  return (
    <FormControl>
      <SplitButton {...splitButtonProps}>
        <Box mx={2} mt={1}>
          <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
            <Typography variant={"body1"}>{props.label}</Typography>
          </Stack>
          <Typography variant={"body2"} sx={{ mt: 1 }}>
            {isInitialValue ? `${t2("AllLabel", { label: props.label })}` : currentRangeLabel}
          </Typography>
        </Box>
        <Box mx={4} mt={4} mb={1}>
          <Box sx={{ width: 300 }}>
            <Slider
              getAriaLabel={() => "Temperature range"}
              valueLabelDisplay={"auto"}
              {...props.slider}
              value={range}
              onChange={handleChangeRange}
              getAriaValueText={v => String(v)}
              color="secondary"
            />
          </Box>
        </Box>
      </SplitButton>
    </FormControl>
  );
};

RangeField.displayName = "RangeField";

export default RangeField;
