import { DEFAULT_SEARCH_FILTER } from "./constants";
import { state } from "./State";
import type * as Types from "./types";

export const initialize = (res: Types.Res, requestOffset: number): void => {
  state.res = res;

  // if requestOffset is 0, it means the request is a new search filter or sort params
  if (requestOffset === 0) {
    state.currentCompetitionIdSet.clear();
  }

  res.competitions.forEach(competition => {
    state.competitionMap.set(competition.competitionId, competition);
    state.currentCompetitionIdSet.add(competition.competitionId);
  });
};

export const initializeFilters = (filter: Types.SearchFilter, sortParams: Types.SortParams) => {
  state.currentSearchFilter = filter;
  state.sortParams = sortParams;
  state.initializedSearchFilter = true;
};

export const clear = (): void => {
  state.res = null;
  state.currentSearchFilter = DEFAULT_SEARCH_FILTER;
  state.initializedSearchFilter = false;
  resetOffset();
};

export const resetOffset = (): void => {
  state.offset = 0;
};

export const updateCurrentSearchFilter = (filter: Types.SearchFilter) => {
  state.currentSearchFilter = filter;
  /**
   * Reset offset when search filter is updated
   */
  resetOffset();
};

export const updateSortParams = (sortParams: Types.SortParams) => {
  state.sortParams = sortParams;
  /**
   * Reset offset when sortParams is updated
   */
  resetOffset();
};

export const updateOffset = (offset: number): void => {
  state.offset = offset;
};
