import { useSnapshot } from "valtio";

import { state } from "./State";
import type * as Types from "./types";

const useSnapshotState = () => {
  return useSnapshot(state);
};

export const useAuthToken = () => {
  const snapshot = useSnapshotState();
  return snapshot.authToken;
};

export const useCurrentProviderIdFromFirebaseIdToken = () => {
  const snapshot = useSnapshotState();
  return snapshot.providerId;
};

export const useUserType = (): Types.UserType => {
  const snapshot = useSnapshotState();
  if (snapshot.userType === null) {
    throw new Error("Please initialize user type!");
  }
  return snapshot.userType;
};

export const useSignedIn = () => {
  const snapshot = useSnapshotState();
  return snapshot.signedIn;
};

export const useUid = () => {
  const snapshot = useSnapshotState();
  return snapshot.uid;
};

/**
 * value which is custom claim
 */
export const useCompanyId = () => {
  const snapshot = useSnapshotState();
  return snapshot.customClaim?.company_id;
};

export const useHasAuthToken = () => {
  const snapshot = useSnapshotState();
  return !!snapshot.authToken;
};

export const useCurrentUserMailAddress = (): string | null => {
  const snapshot = useSnapshotState();
  return snapshot.mailAddress;
};

export const useIsSystemAdmin = () => {
  const snapshot = useSnapshotState();
  return snapshot.isSystemAdmin;
};
