import * as React from "react";
import { useSnapshot } from "valtio";

import { Area, CommentElement, Coordinate, EdgeElement, ELEMENT_TYPE, FlowElement, NetworkElement, NodeElement } from "../features/types";
import { edgeCoordinatesFromNodes, generateHashId, geometryFromElement } from "../helpers/flowChart";
import { timeInSeconds } from "../helpers/time";
import * as Types from "./types";

export const createHooks = (privateState: Types.State) => {
  const useSnapshotState = () => {
    return useSnapshot(privateState);
  };

  const useComponentType = () => {
    const state = useSnapshotState();
    return state.componentType;
  };

  const useSelectableComponentTypes = () => {
    const state = useSnapshotState();
    return state.selectableComponentTypes;
  };

  const useStartSystemDesign = () => {
    const state = useSnapshotState();
    return {
      isStartSystemDesignLoading: state.isStartSystemDesignLoading,
      startSystemDesignError: state.startSystemDesignError,
    };
  };
  const useGetSubmission = () => {
    const state = useSnapshotState();
    return {
      isGetSubmissionLoading: state.isGetSubmissionLoading,
      getSubmissionError: state.getSubmissionError,
    };
  };
  const useGetQuestion = () => {
    const state = useSnapshotState();
    return {
      isGetQuestionLoading: state.isGetQuestionLoading,
      getQuestionError: state.getQuestionError,
    };
  };
  const useSubmitSystemDesign = () => {
    const state = useSnapshotState();
    return {
      isSubmitSystemDesignLoading: state.isSubmitSystemDesignLoading,
      submitSystemDesignError: state.submitSystemDesignError,
    };
  };
  const useReevaluateSystemDesign = () => {
    const state = useSnapshotState();
    return {
      isReevaluateSystemDesignLoading: state.isReevaluateSystemDesignLoading,
      reevaluateSystemDesignError: state.reevaluateSystemDesignError,
    };
  };
  const useRunSystemDesign = () => {
    const state = useSnapshotState();
    return {
      isRunSystemDesignLoading: state.isRunSystemDesignLoading,
      runSystemDesignError: state.runSystemDesignError,
    };
  };
  const useSystemDesign = () => {
    const state = useSnapshotState();
    return (id: number) => state.systemDesigns[id];
  };
  // const useQuestions = () => {
  //   const state = useSnapshotState();
  // }
  // const useQuestion = () => {
  //   const state = useSnapshotState();
  // }
  // const useAnswer = () => {
  //   const state = useSnapshotState();
  // }
  // const useScoringItem = () => {
  //   const state = useSnapshotState();
  // }
  // const useHint = () => {
  //   const state = useSnapshotState();
  // }
  const useComponentTypes = (systemDesignId: number) => {
    const state = useSnapshotState();
    const systemDesign = state.systemDesigns[systemDesignId];
    if (!systemDesign) return [];

    return systemDesign.componentTypesList ?? [];
  };
  const useElement = () => {
    const state = useSnapshotState();
    return (id: string) => {
      return id in state.elements ? state.elements[id] : undefined;
    };
  };
  const useElements = () => {
    const state = useSnapshotState();
    return state.elements;
  };
  const useElementsList = () => {
    const state = useSnapshotState();
    return state.elementIds.map(id => state.elements[id]);
  };
  const useElementIds = () => {
    const state = useSnapshotState();
    return state.elementIds;
  };
  const useSelectedElementIds = () => {
    const state = useSnapshotState();
    return React.useMemo(() => {
      return state.selectedElementIds;
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(state.selectedElementIds)]);
  };
  const useSelectedElementId = () => {
    const selectedElementIds = useSelectedElementIds();
    return selectedElementIds.length === 1 ? selectedElementIds[0] : undefined;
  };
  const useNetworkIds = () => {
    const state = useSnapshotState();
    return state.elementIds.filter(elementId => state.elements[elementId].type === ELEMENT_TYPE.network);
  };
  const useViewbox = () => {
    const state = useSnapshotState();
    return state.viewbox;
  };
  const useCachedViewbox = () => {
    const state = useSnapshotState();
    return state.cachedViewbox;
  };
  const useScale = () => {
    const state = useSnapshotState();
    return state.scale;
  };
  const useMinimumArea = () => {
    const state = useSnapshotState();
    return (elementIds: string[]) => {
      const minimumArea: Area = {
        minX: Number.MAX_SAFE_INTEGER,
        minY: Number.MAX_SAFE_INTEGER,
        maxX: Number.MIN_SAFE_INTEGER,
        maxY: Number.MIN_SAFE_INTEGER,
      };

      elementIds.forEach(elementId => {
        // Basically the element exists in the map state, but on the remote interview,
        // some action could come in from the peer after resetting of the flowchart,
        // hence confirm its existence just in case.
        if (!(elementId in state.elements)) return;

        const element = state.elements[elementId];

        if (element.type === ELEMENT_TYPE.edge) {
          const { from, to } = edgeCoordinatesFromNodes(
            state.elements[element.source] as NodeElement,
            state.elements[element.target] as NodeElement,
          );

          const minX = Math.min(from.x, to.x);
          const maxX = Math.max(from.x, to.x);
          const minY = Math.min(from.y, to.y);
          const maxY = Math.max(from.y, to.y);

          if (minX < minimumArea.minX) {
            minimumArea.minX = minX;
          }
          if (minY < minimumArea.minY) {
            minimumArea.minY = minY;
          }
          if (maxX > minimumArea.maxX) {
            minimumArea.maxX = maxX;
          }
          if (maxY > minimumArea.maxY) {
            minimumArea.maxY = maxY;
          }
        } else {
          const geometry = geometryFromElement(element);

          if (geometry.minX < minimumArea.minX) {
            minimumArea.minX = geometry.minX;
          }
          if (geometry.minY < minimumArea.minY) {
            minimumArea.minY = geometry.minY;
          }
          if (geometry.maxX > minimumArea.maxX) {
            minimumArea.maxX = geometry.maxX;
          }
          if (geometry.maxY > minimumArea.maxY) {
            minimumArea.maxY = geometry.maxY;
          }
        }
      });

      return minimumArea;
    };
  };
  // const useSubmissions = () => {
  //   const state = useSnapshotState();
  //   return state.submissions;
  // }
  // const useSubmission = () => {
  //   const state = useSnapshotState();
  // }
  // const useSubmissionByKeys = () => {
  //   const state = useSnapshotState();
  // }
  const useIsDoubleEdge = () => {
    const state = useSnapshotState();

    return (node1: string, node2: string) => {
      let isDoubleEdge = false;

      state.elementIds.forEach(elementId => {
        // Basically the element exists in the map state, but on the remote interview,
        // some action could come in from the peer after resetting of the flowchart,
        // hence confirm its existence just in case.
        if (!(elementId in state.elements)) return;

        const element = state.elements[elementId];
        if (element.type === ELEMENT_TYPE.edge) {
          if ([element.source, element.target].includes(node1) && [element.source, element.target].includes(node2)) {
            isDoubleEdge = true;
          }
        }
      });

      return isDoubleEdge;
    };
  };
  const useWithNeighborEdge = () => {
    const state = useSnapshotState();
    return (ids: string[]) => {
      const elementIds = state.elementIds;
      const elements = state.elements;
      return elementIds.filter(elementId => {
        const element = elements[elementId];
        const isSelected = ids.includes(elementId);
        const isNeighborEdge = element.type === ELEMENT_TYPE.edge && (ids.includes(element.source) || ids.includes(element.target));
        return isSelected || isNeighborEdge;
      });
    };
  };
  const usePasteDestElements = () => {
    return (srcElements: FlowElement[], position: Coordinate) => {
      const destElements: FlowElement[] = [];
      const idMap: Record<string, string> = {};
      const timestamp = timeInSeconds();

      // 最初の要素のgeometryを取得してoffsetを計算
      let minX = Infinity;
      let minY = Infinity;

      // 座標の最小値を取得（エッジ以外の要素から）
      srcElements.forEach(element => {
        if (element.type !== ELEMENT_TYPE.edge) {
          const geometry = geometryFromElement(element);
          minX = Math.min(minX, geometry.minX);
          minY = Math.min(minY, geometry.minY);
        }
      });

      // 全要素に新しいIDを生成
      srcElements.forEach(element => {
        idMap[element.id] = generateHashId();
      });

      srcElements.forEach(element => {
        if (element.type !== ELEMENT_TYPE.edge) {
          const geometry = geometryFromElement(element);

          // 元の要素からの相対位置を計算
          const offsetX = geometry.minX - minX;
          const offsetY = geometry.minY - minY;

          // 新しい要素を作成
          const newElement: NodeElement | NetworkElement | CommentElement = {
            ...element,
            id: idMap[element.id],
            geometry: {
              minX: position.x + offsetX,
              minY: position.y + offsetY,
              maxX: position.x + offsetX + (geometry.maxX - geometry.minX),
              maxY: position.y + offsetY + (geometry.maxY - geometry.minY),
            },
            updatedAt: timestamp,
          } as NodeElement | NetworkElement | CommentElement;

          destElements.push(newElement);
        } else {
          if (idMap[element.source] && idMap[element.target]) {
            const newEdge = {
              ...element,
              id: idMap[element.id],
              source: idMap[element.source],
              target: idMap[element.target],
              updatedAt: timestamp,
            } as EdgeElement;

            destElements.push(newEdge);
          }
        }
      });

      return destElements;
    };
  };
  const useOpenMiniMap = () => {
    const state = useSnapshotState();
    return state.openMiniMap;
  };
  const useOpenResetDialog = () => {
    const state = useSnapshotState();
    return state.openResetConfirmDialog;
  };
  return {
    useComponentType,
    useSelectableComponentTypes,
    useSnapshotState,
    useStartSystemDesign,
    useGetSubmission,
    useGetQuestion,
    useSubmitSystemDesign,
    useReevaluateSystemDesign,
    useRunSystemDesign,
    useSystemDesign,
    useOpenMiniMap,
    // useQuestions,
    // useQuestion,
    // useAnswer,
    // useScoringItem,
    // useHint,
    useComponentTypes,
    useElement,
    useElementsList,
    useElementIds,
    useSelectedElementIds,
    useSelectedElementId,
    useNetworkIds,
    useViewbox,
    useCachedViewbox,
    useScale,
    useMinimumArea,
    // useSubmissions,
    // useSubmission,
    // useSubmissionByKeys,
    useIsDoubleEdge,
    useWithNeighborEdge,
    usePasteDestElements,
    useOpenResetDialog,
    useElements,
  };
};
