import { Candidates } from "@hireroo/app-store/page/e/candidates";
import * as Graphql from "@hireroo/graphql/client/urql";

export const SortMethodReverseMap: Record<string, Graphql.CandidateSortMethod> = {
  [Graphql.CandidateSortMethod.AverageRank]: Graphql.CandidateSortMethod.AverageRank,
  [Graphql.CandidateSortMethod.AverageScore]: Graphql.CandidateSortMethod.AverageScore,
  [Graphql.CandidateSortMethod.ExpectedSalary]: Graphql.CandidateSortMethod.ExpectedSalary,
};

export const convertStringToSortParams = (value: string | null): Candidates.SortParams => {
  if (value === null) return Candidates.DEFAULT_SORT_PARAMS;
  try {
    // "sortMethod+isDescending" (e.g.) "CREATED_AT-true"
    const [field, isDesc] = value.split("-");
    const sortField = SortMethodReverseMap[field] ?? Candidates.DEFAULT_SORT_PARAMS.sortMethod;
    return {
      sortMethod: sortField,
      isDescending: isDesc === "true",
    };
  } catch (_) {
    return Candidates.DEFAULT_SORT_PARAMS;
  }
};

export const convertStringsToPage = (value: string | null): number => {
  if (value === null) return 0;
  const page = Number(value);
  // Set it to 0 if it is invalid (NaN or negative). Note that page > 0 is false if page is NaN.
  // Actual (zero-index) page number = (page number from the query param) - 1
  if (page < 1) {
    return 0;
  }
  return page - 1;
};

const SIZES = [20, 30, 50];
export const convertStringToSize = (value: string | null) => {
  if (value === null) return Candidates.DEFAULT_PAGER.size;
  const size = Number(value);
  // Set it to the default value if it is invalid.
  if (!SIZES.includes(size)) {
    return Candidates.DEFAULT_PAGER.size;
  }
  return size;
};

export const convertStringToJobChangeStatuses = (values: string[] | null): Candidates.SearchFilter["jobChangeStatuses"] => {
  if (!values) {
    return [];
  }

  const statusMap: Record<string, Candidates.SearchFilter["jobChangeStatuses"][0]> = {
    HIGH: "HIGH",
    MEDIUM: "MEDIUM",
    LOW: "LOW",
  };
  const newStatuses: Candidates.SearchFilter["jobChangeStatuses"] = [];

  for (const value of values) {
    value.split("%").forEach(v => {
      // Check if the string value can be converted to a valid status.
      if (statusMap[v]) {
        newStatuses.push(statusMap[v]);
      }
    });
  }
  return newStatuses;
};

export const convertStringToExpectedSalaryMin = (value: string | null): Candidates.SearchFilter["expectedSalary"]["min"] => {
  if (!value) return Candidates.EXPECTED_SALARY.MIN;

  const min = Number(value);
  if (Number.isNaN(min)) return Candidates.EXPECTED_SALARY.MIN;
  if (Candidates.EXPECTED_SALARY.MIN <= min && min <= Candidates.EXPECTED_SALARY.MAX) {
    return min;
  }

  return Candidates.EXPECTED_SALARY.MIN;
};

export const convertStringToExpectedSalaryMax = (value: string | null): Candidates.SearchFilter["expectedSalary"]["max"] => {
  if (!value) return Candidates.EXPECTED_SALARY.MAX;

  const max = Number(value);
  if (Number.isNaN(max)) return Candidates.EXPECTED_SALARY.MAX;
  if (Candidates.EXPECTED_SALARY.MIN <= max && max <= Candidates.EXPECTED_SALARY.MAX) {
    return max;
  }

  return Candidates.EXPECTED_SALARY.MAX;
};

export const convertStringToAverageScoreMin = (value: string | null): Candidates.SearchFilter["averageScore"]["min"] => {
  if (!value) return Candidates.AVERAGE_SCORE.MIN;

  const min = Number(value);
  if (Number.isNaN(min)) return Candidates.AVERAGE_SCORE.MIN;
  if (Candidates.AVERAGE_SCORE.MIN <= min && min <= Candidates.AVERAGE_SCORE.MAX) {
    return min;
  }

  return Candidates.AVERAGE_SCORE.MIN;
};

export const convertStringToAverageScoreMax = (value: string | null): Candidates.SearchFilter["averageScore"]["max"] => {
  if (!value) return Candidates.AVERAGE_SCORE.MAX;

  const max = Number(value);
  if (Number.isNaN(max)) return Candidates.AVERAGE_SCORE.MAX;
  if (Candidates.AVERAGE_SCORE.MIN <= max && max <= Candidates.AVERAGE_SCORE.MAX) {
    return max;
  }

  return Candidates.AVERAGE_SCORE.MAX;
};

export const convertStringToAverageRank = (values: string[] | null): Candidates.SearchFilter["averageRank"] => {
  if (!values) {
    return [];
  }

  const rankMap: Record<string, Candidates.SearchFilter["averageRank"][0]> = {
    S: "S",
    A: "A",
    B: "B",
    C: "C",
    D: "D",
  };
  const newRanks: Candidates.SearchFilter["averageRank"] = [];

  for (const value of values) {
    value.split("%").forEach(value => {
      // Check if the string value can be converted to a valid status.
      if (rankMap[value]) {
        newRanks.push(rankMap[value]);
      }
    });
  }
  return newRanks;
};
