import * as z from "zod";

export const useMetricItem = () => {
  return z.object({
    itemId: z.string(),
    selected: z.boolean(),
  });
};

export type MetricItem = z.infer<ReturnType<typeof useMetricItem>>;

export const useMetricItemListForm = () => {
  const metricItem = useMetricItem();
  return z.object({
    items: metricItem.array(),
  });
};

export type MetricItemListForm = z.infer<ReturnType<typeof useMetricItemListForm>>;
