import { Switch } from "@mui/material";
import FormControlLabel, { FormControlLabelProps } from "@mui/material/FormControlLabel";
import { SwitchProps } from "@mui/material/Switch/Switch";
import * as React from "react";
import { useController } from "react-hook-form";

import { useSearchFormContext } from "../../PrivateContext";

type FieldValue = boolean;

export type SwitchFieldProps = {
  name: string;
  label: string;
  onChange?: (fields: FieldValue) => void;
  disabled?: boolean;
};

const SwitchField: React.FC<SwitchFieldProps> = props => {
  const { onChange } = props;
  const { subscribeClearAllFields: onClearFields } = useSearchFormContext();
  const { field } = useController<Record<string, FieldValue>>({
    name: props.name,
  });
  const [resetKey, setResetKey] = React.useState("");

  React.useEffect(() => {
    const stop = onClearFields(() => {
      onChange?.(false);
      field.onChange(false);
      setResetKey(Date.now().toString());
    });
    return () => {
      stop();
    };
  }, [field, onChange, onClearFields]);

  const switchProps: SwitchProps = {
    value: field.value,
    onChange: (_event, checked) => {
      field.onChange(checked);
    },
    size: "small",
    color: "secondary",
    disabled: props.disabled,
  };
  const formControlLabelProps: FormControlLabelProps = {
    componentsProps: {
      typography: {
        fontSize: 14,
      },
    },
    label: props.label,
    value: field.value,
    name: props.name,
    control: <Switch key={`${resetKey}`} {...switchProps} />,
  };
  return <FormControlLabel {...formControlLabelProps} />;
};

SwitchField.displayName = "RangeField";

export default SwitchField;
