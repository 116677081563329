import * as Graphql from "@hireroo/graphql/client/urql";

import type * as Types from "./types";

export const SORT_FIELDS = {
  SCORE_DESCENDING: "SCORE_DESCENDING",
  SCORE_ASCENDING: "SCORE_ASCENDING",
  COMPLETED_AT_DESCENDING: "COMPLETED_AT_DESCENDING",
  COMPLETED_AT_ASCENDING: "COMPLETED_AT_ASCENDING",
} as const;

export type SortFieldValue = (typeof SORT_FIELDS)[keyof typeof SORT_FIELDS];

export const SORT_PARAMS = {
  [SORT_FIELDS.SCORE_DESCENDING]: { sortMethod: Graphql.ApplicantSortMethod.TotalScore, isDescending: true },
  [SORT_FIELDS.SCORE_ASCENDING]: { sortMethod: Graphql.ApplicantSortMethod.TotalScore, isDescending: false },
  [SORT_FIELDS.COMPLETED_AT_DESCENDING]: { sortMethod: Graphql.ApplicantSortMethod.CompletedAt, isDescending: true },
  [SORT_FIELDS.COMPLETED_AT_ASCENDING]: { sortMethod: Graphql.ApplicantSortMethod.CompletedAt, isDescending: false },
} satisfies Record<SortFieldValue, { isDescending: boolean; sortMethod: Graphql.ApplicantSortMethod }>;

export const DEFAULT_SORT_PARAMS: Types.SortParams = {
  sortMethod: Graphql.ApplicantSortMethod.CompletedAt,
  isDescending: true,
};
export const DEFAULT_PAGER: Types.Pager = {
  page: 0,
  size: 20,
  offset: 0,
  sortParams: DEFAULT_SORT_PARAMS,
};

export const QueryKeys = {
  PAGE: "page",
  SIZE: "size",
  SORT: "sorting",
} as const;
