import { translateSalary } from "@hireroo/app-helper/competition";
import * as ErrorHandlingHelper from "@hireroo/app-helper/error-handling";
import { convertFileToBase64, getCroppedImage } from "@hireroo/app-helper/files";
import { Auth } from "@hireroo/app-store/essential/candidate";
import { CandidateProfile } from "@hireroo/app-store/widget/c/CandidateProfile";
import { Snackbar } from "@hireroo/app-store/widget/shared/Snackbar";
import { getGraphqlClient } from "@hireroo/graphql/client/request";
import * as Graphql from "@hireroo/graphql/client/urql";
import { useLanguageCode, useTranslation, useTranslationWithVariable } from "@hireroo/i18n";
import type { Widget } from "@hireroo/presentation";
import { CandidateProfileForm } from "@hireroo/validator";
import * as Sentry from "@sentry/react";
import * as React from "react";
import { Area } from "react-easy-crop";
import { SubmitHandler } from "react-hook-form";

import { jobChangeInterestMap, jobChangeInterestMapReverse } from "./privateHelper";

const EXPECTED_SALARY_YEN = [4_000_000, 5_000_000, 6_000_000, 7_000_000, 8_000_000, 9_000_000, 10_000_000];
const ONE_MB = 1024 * 1024;

export type GenerateCandidateProfilePropsArgs = {};

export const useGenerateProps = (_args: GenerateCandidateProfilePropsArgs): Widget.CandidateProfileProps => {
  const { t } = useTranslation();
  const { t: t2 } = useTranslationWithVariable();
  const lang = useLanguageCode();
  const currentUser = CandidateProfile.useCurrentUser();
  const essentialCandidate = Auth.useCurrentCandidate();
  const client = getGraphqlClient();
  const [status, setStatus] = React.useState<"READY" | "PENDING">("READY");

  const [croppedAreaPixels, setCroppedAreaPixels] = React.useState<Area>();
  const [originalImage, setOriginalImage] = React.useState<string>();

  const updateCandidateProfile = React.useCallback(
    async (args: Graphql.UpdateCandidateForCandidateProfileMutationVariables["updateCandidateInput"]) => {
      await client
        .UpdateCandidateForCandidateProfile({
          updateCandidateInput: {
            candidateId: args.candidateId,
            displayName: args.displayName,
            profileText: args.profileText,
            jobChangeInterest: args.jobChangeInterest,
            expectedSalaryYen: args.expectedSalaryYen,
          },
        })
        .then(res => {
          Snackbar.notify({
            severity: "success",
            message: t("ユーザ情報を更新しました。"),
          });
          CandidateProfile.setCurrentUser(res.updateCandidate);
          Auth.setCandidate({
            ...essentialCandidate,
            displayName: res.updateCandidate.displayName,
          });
        })
        .catch(error => {
          Sentry.captureException(error);
          const errorNotification = ErrorHandlingHelper.generateErrorNotification(
            error,
            t("ユーザ情報の更新に失敗しました。しばらくしてから再度お試し頂くか、ヘルプボタンよりお問い合わせください。"),
          );
          Snackbar.notify({
            severity: "error",
            message: errorNotification.message,
          });
        });
    },
    [client, essentialCandidate, t],
  );

  const updatePhotoUrl = React.useCallback(
    async (args: { candidateId: string; photoUrl: string }) => {
      await client
        .UpdateCandidateForCandidateProfile({
          updateCandidateInput: {
            candidateId: args.candidateId,
            photoUrl: args.photoUrl,
          },
        })
        .then(res => {
          Snackbar.notify({
            severity: "success",
            message: t("アイコンを更新しました。"),
          });
          CandidateProfile.setCurrentUser(res.updateCandidate);
          Auth.setCandidate({
            ...essentialCandidate,
            photoUrl: res.updateCandidate.photoUrl,
          });
        })
        .catch(error => {
          Sentry.captureException(error);
          const errorNotification = ErrorHandlingHelper.generateErrorNotification(
            error,
            t("ユーザ情報の更新に失敗しました。しばらくしてから再度お試し頂くか、ヘルプボタンよりお問い合わせください。"),
          );
          Snackbar.notify({
            severity: "error",
            message: errorNotification.message,
          });
        });
    },
    [client, essentialCandidate, t],
  );

  const onCropImage = React.useCallback(() => {
    if (!originalImage || !croppedAreaPixels) return;

    // Crop the selected image
    const croppedImage = getCroppedImage(originalImage, croppedAreaPixels);
    setStatus("PENDING");
    // Upload it to the GCS
    client
      .UploadUserImageForUserProfile({ uid: currentUser.candidateId, image: croppedImage })
      .then(res => {
        setOriginalImage(undefined);
        updatePhotoUrl({
          candidateId: currentUser.candidateId,
          photoUrl: res.uploadUserImage,
        });
      })
      .catch(error => {
        Sentry.captureException(error);
      })
      .finally(() => {
        setStatus("READY");
      });
  }, [originalImage, croppedAreaPixels, client, currentUser.candidateId, updatePhotoUrl]);

  const onImageCropDialogCancel = React.useCallback(() => {
    setOriginalImage(undefined);
  }, []);

  const onCropComplete = React.useCallback((croppedAreaPixels: Area) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const onClickFileUpload = React.useCallback(
    async (file: File) => {
      // 4MB is the Upper limit of the request body for the envoy gateway
      if (file.size > ONE_MB * 4) {
        Snackbar.notify({
          severity: "error",
          message: t("アップロード可能な画像のデータサイズの上限は4MBまでです。"),
        });
        return;
      }

      const base64Img = await convertFileToBase64(file);
      setOriginalImage(base64Img);
    },
    [t],
  );

  const onSubmit: SubmitHandler<CandidateProfileForm.CandidateProfileFormSchema> = React.useCallback(
    field => {
      setStatus("PENDING");
      updateCandidateProfile({
        candidateId: currentUser.candidateId,
        displayName: field.displayName.trim(),
        profileText: field.profileText.trim(),
        expectedSalaryYen: field.expectedSalary,
        jobChangeInterest: jobChangeInterestMapReverse[field.jobChangeStatus],
      })
        .catch(error => {
          Sentry.captureException(error);
        })
        .finally(() => {
          setStatus("READY");
        });
    },
    [currentUser, updateCandidateProfile],
  );

  const jobChangeStatusItems = React.useMemo(() => {
    return [
      // 一度設定すると未設定に戻せない
      currentUser.jobChangeInterest === "UNKNOWN" && {
        text: t("未設定"),
        value: "UNSET",
      },
      {
        text: t("転職意欲高い"),
        value: "HIGH",
      },
      {
        text: t("転職意欲普通"),
        value: "MIDDLE",
      },
      {
        text: t("転職意欲低い"),
        value: "LOW",
      },
      {
        text: t("転職意欲なし"),
        value: "NONE",
      },
    ].flatMap(x => (x ? [x] : []));
  }, [t, currentUser.jobChangeInterest]);

  const expectedSalaryItems = React.useMemo(() => {
    const items = [
      {
        text: t("未設定"),
        value: 0,
      },
    ];

    for (const salary of EXPECTED_SALARY_YEN) {
      items.push({
        text: t2("ExpectedSalary", { salary: translateSalary({ salaryInTenK: salary / 10000, lang: lang, withCurrency: true }) }),
        value: salary,
      });
    }

    return items;
  }, [t, t2, lang]);

  // EXPECTED_SALARY_YEN に含まれていなければ「未設定」扱い
  const expectedSalary = React.useMemo(() => {
    return currentUser.expectedSalaryYen && EXPECTED_SALARY_YEN.includes(currentUser.expectedSalaryYen) ? currentUser.expectedSalaryYen : 0;
  }, [currentUser.expectedSalaryYen]);

  return {
    onSubmit: onSubmit,
    photoURLField: {
      photoUrl: currentUser.photoUrl,
      disabled: status === "PENDING",
      onClickFileUpload: onClickFileUpload,
    },
    imageCropDialog: {
      originalImage: originalImage,
      yesButton: {
        onClick: onCropImage,
      },
      noButton: {
        onClick: onImageCropDialogCancel,
      },
      onCropComplete: onCropComplete,
    },
    jobChangeStatusField: {
      items: jobChangeStatusItems,
    },
    expectedSalaryField: {
      items: expectedSalaryItems,
    },
    saveButton: {
      loading: status === "PENDING",
    },
    defaultValues: {
      displayName: currentUser.displayName,
      profileText: currentUser.profileText,
      jobChangeStatus: jobChangeInterestMap[currentUser.jobChangeInterest],
      expectedSalary: expectedSalary,
    },
  };
};
