import { useTranslation } from "@hireroo/i18n";
import { CompetitionSearchForm as CompetitionSearchFormValidator } from "@hireroo/validator";

import { SearchFormProps } from "../../modules/SearchForm/SearchForm";

type FieldProps = SearchFormProps["fields"][0];

type FieldKeyName = keyof CompetitionSearchFormValidator.CompetitionSearchSchema;
export type MultiChoiceFieldProps = Extract<
  FieldProps,
  {
    kind: "MULTI_CHOICE";
  }
>;
export type RangeFieldProps = Extract<
  FieldProps,
  {
    kind: "RANGE";
  }
>;
export type SwitchFieldProps = Extract<
  FieldProps,
  {
    kind: "SWITCH";
  }
>;

const FieldName = {
  TEXT_FILTER: "textFilter",
  STATUSES: "statuses",
  MIN_SALARY: "minSalary",
  SELF_HOSTED_ONLY: "selfHostedOnly",
} satisfies Record<string, FieldKeyName>;

export const useTextFilterField = (): SearchFormProps["textFilter"] => {
  const { t } = useTranslation();
  return {
    name: FieldName.TEXT_FILTER,
    placeholder: t("コンペを検索"),
    sx: {
      "& .MuiInputBase-root": {
        height: "48px",
        fontSize: "14px",
      },
    },
  };
};

export type MinSalaryFieldArgs = Pick<RangeFieldProps, "customCurrentLabelFormat"> & {
  slider: {
    max: number;
    min: number;
    step: number;
    valueLabelFormat: Exclude<RangeFieldProps["slider"], undefined>["valueLabelFormat"];
  };
};

export const useMinSalaryField = (args: MinSalaryFieldArgs): RangeFieldProps => {
  const { t } = useTranslation();
  return {
    ...args,
    kind: "RANGE",
    name: FieldName.MIN_SALARY,
    title: t("最低提示年収"),
    label: t("最低提示年収"),
  };
};

type StatusesOption = {
  displayName: string;
  value: CompetitionSearchFormValidator.CompetitionSearchSchema["statuses"][0];
};

export const useStatusesField = (): MultiChoiceFieldProps => {
  const { t } = useTranslation();
  const options: StatusesOption[] = [
    { value: "CREATED", displayName: t("開催前") },
    { value: "STARTED", displayName: t("開催中") },
    { value: "ENDED", displayName: t("採点中") },
    { value: "CONFIRMED", displayName: t("採点済み") },
  ];
  return {
    kind: "MULTI_CHOICE",
    title: t("ステータス"),
    name: FieldName.STATUSES,
    options: options,
  };
};
export const useSelfHostedOnlyField = (): SwitchFieldProps => {
  const { t } = useTranslation();
  return {
    kind: "SWITCH",
    label: t("自社開催のみ"),
    name: FieldName.SELF_HOSTED_ONLY,
  };
};
