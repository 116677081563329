import { Competitions } from "@hireroo/app-store/page/c/competitions";
import { updateQueryParamsByObject } from "@hireroo/router/api";

export const startSubscribeListParams = () => {
  return Competitions.subscribeAllState(state => {
    if (!state.currentSearchFilter) {
      return;
    }
    const { currentSearchFilter, sortParams } = state;

    const isDefaultSortParams =
      sortParams.sortMethod === Competitions.DEFAULT_SORT_PARAMS.sortMethod &&
      sortParams.isDescending === Competitions.DEFAULT_SORT_PARAMS.isDescending;
    /**
     * Do not update query parameters if they are different from the Default Value when the page is visited
     * (i.e., the user has not manipulated them).
     */
    updateQueryParamsByObject({
      [Competitions.QueryKeys.NAME]:
        currentSearchFilter.textFilter && currentSearchFilter.textFilter.length > 0 ? [currentSearchFilter.textFilter] : [],
      [Competitions.QueryKeys.STATUS]: currentSearchFilter.statuses.length > 0 ? currentSearchFilter.statuses : [],
      [Competitions.QueryKeys.MIN_SALARY_MAX]:
        currentSearchFilter.minSalary.max !== Competitions.MIN_SALARY.MAX ? [currentSearchFilter.minSalary.max.toFixed()] : [],
      [Competitions.QueryKeys.MIN_SALARY_MIN]:
        currentSearchFilter.minSalary.min !== Competitions.MIN_SALARY.MIN ? [currentSearchFilter.minSalary.min.toFixed()] : [],
      [Competitions.QueryKeys.SORT]: !isDefaultSortParams ? [`${sortParams.sortMethod}-${sortParams.isDescending}`] : [],
    });
  });
};
