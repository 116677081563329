import * as z from "zod";

const useStatus = () => {
  return z.array(z.union([z.literal("CREATED"), z.literal("STARTED"), z.literal("ENDED"), z.literal("CONFIRMED")]));
};

const useSalaryField = () => {
  return z.object({
    min: z.number(),
    max: z.number(),
  });
};

export const useCompetitionSearch = () => {
  const statuses = useStatus();
  const minSalary = useSalaryField();
  return z.object({
    textFilter: z.string(),
    statuses,
    minSalary,
    selfHostedOnly: z.boolean(),
  });
};

export type CompetitionSearchSchema = z.infer<ReturnType<typeof useCompetitionSearch>>;
