import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { startSubscribeListParams } from "./Subscriber";
import { useGenerateProps } from "./useGenerateProps";

export type CompetitionApplicantsContainerProps = {};

const CompetitionApplicantsContainer: React.FC<CompetitionApplicantsContainerProps> = () => {
  const competitionApplicantsProps = useGenerateProps({});
  React.useEffect(() => {
    const stop = startSubscribeListParams();

    return () => {
      stop();
    };
  }, []);
  return (
    <ErrorBoundary>
      <Widget.CompetitionApplicants {...competitionApplicantsProps} />
    </ErrorBoundary>
  );
};

CompetitionApplicantsContainer.displayName = "CompetitionApplicantsContainer";

export default withErrorBoundary(CompetitionApplicantsContainer, {});
