import { EvaluationMetricListForRemote } from "@hireroo/app-store/widget/e/EvaluationMetricListForRemote";
import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { GenerateEvaluationMetricListForRemotePropsArgs, useGenerateProps } from "./useGenerateProps";

export type EvaluationMetricListForRemoteContainerProps = GenerateEvaluationMetricListForRemotePropsArgs;

const EvaluationMetricListForRemoteContainer: React.FC<EvaluationMetricListForRemoteContainerProps> = props => {
  const refreshKey = EvaluationMetricListForRemote.useRefreshKey();
  const evaluationMetricListForRemoteProps = useGenerateProps(props);
  return (
    <ErrorBoundary>
      <Widget.EvaluationMetricListForRemote key={refreshKey} {...evaluationMetricListForRemoteProps} />
    </ErrorBoundary>
  );
};

EvaluationMetricListForRemoteContainer.displayName = "EvaluationMetricListForRemoteContainer";

export default withErrorBoundary(EvaluationMetricListForRemoteContainer, {});
