import { RemoteInterviewResourceEditorStore } from "@hireroo/app-store/view-domain/RemoteInterviewResourceEditor";
import { EvaluationMetricListForRemote } from "@hireroo/app-store/widget/e/EvaluationMetricListForRemote";
import { useTranslation } from "@hireroo/i18n";
import type { Widget } from "@hireroo/presentation";
import { useTransitionNavigate } from "@hireroo/router/hooks";
import * as React from "react";

import EvaluationMetricListForRemoteFetchContainer from "../../../EvaluationMetricListForRemote/FetchContainer";

export type GenerateRemoteCreateDialogPropsArgs = {
  dialog: Widget.RemoteCreateDialogProps["dialog"];
  selectedSpotId: string;
};

export const useGenerateProps = (args: GenerateRemoteCreateDialogPropsArgs): Widget.RemoteCreateDialogProps => {
  const navigate = useTransitionNavigate();
  const { selectedSpotId } = args;
  const { t } = useTranslation();
  const selectedMetricIds = EvaluationMetricListForRemote.useSelectedMetricIds();
  const metricsMap = EvaluationMetricListForRemote.useMetricMap();

  return {
    dialog: args.dialog,
    createOption: {
      createNewButton: {
        // Not necessary because the step is fixed
        onClick: () => {},
      },
      selectFromTestButton: {
        // Not necessary because the step is fixed
        onClick: () => {},
      },
    },
    // The step is fixed
    step: "EVALUATION_METRICS",
    TestList: null,
    EvaluationMetrics: (
      <EvaluationMetricListForRemoteFetchContainer
        onChange={fields => {
          const selectedIds: number[] = fields.items.filter(item => item?.selected).map(item => Number(item.itemId));
          EvaluationMetricListForRemote.setSelectedMetricIds(selectedIds);
        }}
      />
    ),
    actionButtons: {
      yesButton: {
        onClick: () => {
          const selectedMetrics = selectedMetricIds.map(id => metricsMap.get(id)!);
          RemoteInterviewResourceEditorStore.setInitialEvaluationMetricIds(selectedMetrics);
          navigate("/e/remotes/create", { queryParams: { spotId: selectedSpotId } });
        },
        children: t("テストを引き継いで作成"),
        disabled: false,
      },
      // Not necessary because the step is fixed
      noButton: undefined,
    },
  };
};
