import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import * as React from "react";

import TablePagination, { TablePaginationProps } from "../../modules/TablePagination/TablePagination";
import CompetitionApplicantTable, { CompetitionApplicantTableProps } from "./parts/CompetitionApplicantTable/CompetitionApplicantTable";
import SearchResultBar, { SearchResultBarProps } from "./parts/SearchResultBar/SearchResultBar";

export type CompetitionApplicantListProps = {
  searchResultBar: SearchResultBarProps;
  table: CompetitionApplicantTableProps;
  tablePagination: TablePaginationProps;
};

const CompetitionApplicantList: React.FC<CompetitionApplicantListProps> = props => {
  return (
    <Paper sx={{ boxShadow: "none", borderRadius: "16px" }} elevation={2}>
      <SearchResultBar {...props.searchResultBar} />
      <CompetitionApplicantTable {...props.table} />
      <Box display="flex" justifyContent="flex-end">
        <TablePagination {...props.tablePagination} />
      </Box>
    </Paper>
  );
};

CompetitionApplicantList.displayName = "CompetitionApplicant" + "List";

export default CompetitionApplicantList;
