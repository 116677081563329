import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { GenerateCompetitionResultReportPropsArgs, useGenerateProps } from "./useGenerateProps";

export type CompetitionResultReportContainerProps = GenerateCompetitionResultReportPropsArgs;

const CompetitionResultReportContainer: React.FC<CompetitionResultReportContainerProps> = props => {
  const competitionResultReportProps = useGenerateProps(props);
  return (
    <ErrorBoundary>
      <Widget.CompetitionResultReport {...competitionResultReportProps} />
    </ErrorBoundary>
  );
};

CompetitionResultReportContainer.displayName = "CompetitionResultReportContainer";

export default withErrorBoundary(CompetitionResultReportContainer, {});
