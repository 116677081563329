import { App } from "@hireroo/app-store/essential/candidate";
import { CompetitionApplicantLeaderboard } from "@hireroo/app-store/widget/c/CompetitionApplicantLeaderboard";
import * as Graphql from "@hireroo/graphql/client/urql";
import { Widget } from "@hireroo/presentation";
import { withErrorBoundary } from "@sentry/react";
import React from "react";

import CompetitionApplicantLeaderboardContainer, { CompetitionApplicantLeaderboardContainerProps } from "./Container";

export type CompetitionApplicantLeaderboardFetchContainerProps = {
  competitionId: string;
};

const CompetitionApplicantLeaderboardFetchContainer: React.FC<CompetitionApplicantLeaderboardFetchContainerProps> = props => {
  const appStatus = App.useStatus();
  const initialized = CompetitionApplicantLeaderboard.useInitialized();
  const [result, refresh] = Graphql.useListApplicantsAllForCompetitionApplicantLeaderboardQuery({
    variables: {
      filters: {
        competitionId: props.competitionId,
        statuses: ["COMPLETED", "CONFIRMED"],
      },
      isDescending: false,
      sortMethod: "RANK",
    },
    requestPolicy: "cache-and-network",
    pause: appStatus !== "INITIALIZED",
  });

  React.useEffect(() => {
    if (result.data?.applicantsAll) {
      CompetitionApplicantLeaderboard.initialize(result.data.applicantsAll);
    }
  }, [result.data]);

  React.useEffect(() => {
    return () => {
      CompetitionApplicantLeaderboard.clear();
    };
  }, []);

  if (result.error) {
    return <Widget.RefreshPanel refresh={refresh} />;
  }
  if (result.fetching || appStatus !== "INITIALIZED" || !initialized) {
    return <Widget.Loading kind="CENTER_%" />;
  }

  const containerProps: CompetitionApplicantLeaderboardContainerProps = {};

  return <CompetitionApplicantLeaderboardContainer {...containerProps} />;
};

CompetitionApplicantLeaderboardFetchContainer.displayName = "CompetitionApplicantLeaderboardFetchContainer";

export default withErrorBoundary(CompetitionApplicantLeaderboardFetchContainer, {});
