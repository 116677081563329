import type * as Types from "./types";

export const defaultFilters: Readonly<Types.Filters> = {
  name: "",
  /**
   * If this value is `undefined`, it means that the user has not specified it explicitly.
   */
  result: undefined,
  createdByList: [],
  statuses: [],
  tagNames: [],
  rankEvaluations: [],
  screeningIds: [],
  /**
   * If this value is `undefined`, it means that the user has not specified it explicitly.
   */
  minTotalScore: undefined,
  /**
   * If this value is `undefined`, it means that the user has not specified it explicitly.
   */
  maxTotalScore: undefined,
  /**
   * If this value is `undefined`, it means that the user has not specified it explicitly.
   */
  minCustomScore: undefined,
  /**
   * If this value is `undefined`, it means that the user has not specified it explicitly.
   */
  maxCustomScore: undefined,
  suspiciousDegrees: [],
};
