import * as Graphql from "@hireroo/graphql/client/urql";
import { useTranslation } from "@hireroo/i18n";

export const useFollowUpQuestionPriorityMap = (): Record<Graphql.FollowUpQuestionPriority, string> => {
  const { t } = useTranslation();

  return {
    [Graphql.FollowUpQuestionPriority.Unknown]: t("不明"),
    [Graphql.FollowUpQuestionPriority.Low]: t("優先度低"),
    [Graphql.FollowUpQuestionPriority.Medium]: t("優先度中"),
    [Graphql.FollowUpQuestionPriority.High]: t("優先度高"),
  };
};
