import { useTranslation } from "@hireroo/i18n";
import * as z from "zod";

export const useJobChangeStatus = () => {
  const { t } = useTranslation();
  return z.enum(["UNSET", "HIGH", "MIDDLE", "LOW", "NONE"]).superRefine((value, ctx) => {
    if (value === "UNSET") {
      ctx.addIssue({
        code: z.ZodIssueCode.invalid_enum_value,
        options: ["HIGH", "MIDDLE", "LOW", "NONE"],
        received: value,
        message: t("転職意欲を選択してください。"),
      });
    }
  });
};

export type JobChangeStatus = z.infer<ReturnType<typeof useJobChangeStatus>>;

export const useCandidateProfileForm = () => {
  const { t } = useTranslation();
  const jobChangeStatus = useJobChangeStatus();
  return z.object({
    displayName: z
      .string()
      .min(1, { message: t("氏名は1文字以上で入力してください。") })
      .max(100, { message: t("氏名は100文字以内で入力してください。") })
      .refine(value => value.trim().length > 0, { message: t("氏名は1文字以上で入力してください。") }),
    profileText: z.string().max(2000, { message: t("自己紹介は2000文字以内で入力してください。") }),
    jobChangeStatus,
    expectedSalary: z.number(),
  });
};

export type CandidateProfileFormSchema = z.infer<ReturnType<typeof useCandidateProfileForm>>;
