import { useTranslation } from "@hireroo/i18n";
import StaticFlowChart, { StaticFlowChartProps } from "@hireroo/system-design/react/v2/StaticFlowChart/StaticFlowChart";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { useResizeDetector } from "react-resize-detector";

import DisplayJudgmentCondition, { DisplayJudgmentConditionProps } from "./DisplayJudgmentCondition";

type Status = "MATCH" | "NOT_MATCHED";

const STATIC_FLOW_CHART_HEIGHT = 400;

export type JudgmentContentProps = {
  status: Status;
  reason?: string;
  displayJudgmentCondition: Omit<DisplayJudgmentConditionProps, "isRoot">;
  staticFlowChart: StaticFlowChartProps | undefined;
};

const JudgmentContent: React.FC<JudgmentContentProps> = props => {
  const { t } = useTranslation();
  const detector = useResizeDetector({
    handleHeight: false,
  });

  const aspectRatio = STATIC_FLOW_CHART_HEIGHT / (detector.width ?? 1);

  const titleMap: Record<Status, string> = {
    MATCH: `${t("予測判定")}: ${t("正解")}`,
    NOT_MATCHED: `${t("予測判定")}: ${t("不正解")}`,
  };

  const contentsMap: Record<Status, React.ReactNode> = {
    MATCH: (
      <>
        <Box display={"flex"} alignItems={"center"} sx={{ mb: 2 }}>
          <Typography sx={{ mr: 2 }}>{t("条件を満たすグラフ構造")}</Typography>
          <Typography variant={"caption"}>※ {t("該当箇所は緑色でハイライトされます。")}</Typography>
        </Box>

        {props.staticFlowChart && (
          <Box ref={detector.ref} height={STATIC_FLOW_CHART_HEIGHT} overflow="hidden">
            <React.Suspense>
              <StaticFlowChart {...props.staticFlowChart} aspectRatio={aspectRatio} padding={10} />
            </React.Suspense>
          </Box>
        )}

        {!props.staticFlowChart && (
          <Typography>{t("フローチャートの復元に失敗しました。お手数ですが運営までお問い合わせください。")}</Typography>
        )}
      </>
    ),
    NOT_MATCHED: <Typography>{t("条件を満たすグラフ構造が存在しません。")}</Typography>,
  };

  return (
    <Box>
      <Box display={"flex"} alignItems={"center"} mb={2}>
        <Typography flexGrow={1}>{titleMap[props.status]}</Typography>
      </Box>
      <Typography sx={{ my: 2 }}>{t("解答例")}</Typography>
      <Box mb={2}>
        <DisplayJudgmentCondition {...props.displayJudgmentCondition} isRoot />
      </Box>
      {props.reason && (
        <Box display={"flex"} alignItems={"center"} mb={2}>
          <Typography flexGrow={1}>
            {t("判定理由")}: {props.reason}
          </Typography>
        </Box>
      )}
      {contentsMap[props.status]}
    </Box>
  );
};

JudgmentContent.displayName = "JudgmentContent";

export default JudgmentContent;
