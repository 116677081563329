import { useCompetitionStatusMap } from "@hireroo/app-definition/competition";
import { translateSalary } from "@hireroo/app-helper/competition";
import * as ErrorHandlingHelper from "@hireroo/app-helper/error-handling";
import { Auth } from "@hireroo/app-store/essential/candidate";
import { CompetitionsIdDetail } from "@hireroo/app-store/page/c/competitions_id_detail";
import { Snackbar } from "@hireroo/app-store/widget/shared/Snackbar";
import * as Time from "@hireroo/formatter/time";
import * as TimeFormatter from "@hireroo/formatter/time";
import { getGraphqlClient } from "@hireroo/graphql/client/request";
import { useLanguageCode, useTranslation, useTranslationWithVariable } from "@hireroo/i18n";
import type { Widget } from "@hireroo/presentation";
import { generateCurrentOriginUrl } from "@hireroo/router/api";
import { useTransitionNavigate } from "@hireroo/router/hooks";
import * as Sentry from "@sentry/react";
import * as React from "react";

type Header = Widget.CompetitionIdDetailForCandidateProps["header"];

export type GenerateHeaderPropsArgs = {
  refresh: () => void;
};

export const useGenerateHeaderProps = (args: GenerateHeaderPropsArgs): Widget.CompetitionIdDetailForCandidateProps["header"] => {
  const { refresh } = args;
  const { t } = useTranslation();
  const { t: t2 } = useTranslationWithVariable();
  const competition = CompetitionsIdDetail.useCompetition();
  const lang = useLanguageCode();
  const statusMap = useCompetitionStatusMap();
  const dialogStatus = CompetitionsIdDetail.useDialogStatus();
  const navigate = useTransitionNavigate();
  const client = getGraphqlClient();
  const candidate = Auth.useCurrentCandidate();
  const loadingStatus = CompetitionsIdDetail.useLoadingStatus();
  const applicant = CompetitionsIdDetail.useApplicant();

  const registerButton = React.useMemo((): Header["registerButton"] => {
    const isRegistered = new Set(competition.applicantIds).has(candidate.candidateId);
    if (competition.status === "CREATED") {
      if (isRegistered) {
        return undefined;
      }
      return {
        children: t("コンペに事前登録する"),
        variant: "contained",
        onClick: () => {
          if (!candidate.displayName) {
            CompetitionsIdDetail.updateDialogStatus("OPEN_PROFILE");
          } else {
            CompetitionsIdDetail.updateDialogStatus("OPEN_REGISTER");
          }
        },
      };
    }
    if (competition.status === "ENDED" || competition.status === "CONFIRMED") {
      return {
        children: t("コンペは終了しました"),
        variant: "text",
        disabled: true,
      };
    }
    if (competition.status === "STARTED") {
      if (!applicant?.result) {
        return {
          children: t("コンペに参加する"),
          variant: "contained",
          onClick: () => {
            if (!candidate.displayName) {
              CompetitionsIdDetail.updateDialogStatus("OPEN_PROFILE");
            } else {
              CompetitionsIdDetail.updateDialogStatus("OPEN_GUIDELINE");
            }
          },
        };
      }
      if (applicant.result.status === "STARTED") {
        const resultId = applicant.result.resultId;
        return {
          children: t("コンペを再開する"),
          variant: "contained",
          onClick: () => {
            navigate("/c/competitions/results/:id", { pathParams: { id: resultId } });
          },
        };
      }
      return {
        children: t("参加済み"),
        variant: "text",
        disabled: true,
      };
    }
    return undefined;
  }, [competition.applicantIds, competition.status, candidate.candidateId, candidate.displayName, t, applicant?.result, navigate]);

  const handleGuidelineClick = React.useCallback(async () => {
    const isRegistered = new Set(competition.applicantIds).has(candidate.candidateId);
    CompetitionsIdDetail.updateLoadingStatus("LOADING");
    try {
      if (!isRegistered) {
        await client.ApplyCompetitionForCandidateCompetitionsIdDetail({
          input: {
            candidateId: candidate.candidateId,
            competitionId: competition.competitionId,
          },
        });
      }
      const res = await client.JoinCompetitionForCandidateCompetitionsIdDetail({
        input: {
          candidateId: candidate.candidateId,
          competitionId: competition.competitionId,
        },
      });
      Snackbar.notify({
        severity: "success",
        message: t("コンペに参加しました。"),
      });
      navigate("/c/competitions/results/:id", { pathParams: { id: res.joinCompetition.resultId } });
    } catch (error) {
      Sentry.captureException(error);
      const errorNotification = ErrorHandlingHelper.generateErrorNotification(
        error as Error,
        t("コンペの参加に失敗しました。") + t("しばらくしてから再度お試し頂くか、ヘルプボタンよりお問い合わせください。"),
      );
      Snackbar.notify({
        severity: "error",
        message: errorNotification.message,
      });
    } finally {
      CompetitionsIdDetail.updateLoadingStatus("READY");
    }
  }, [candidate.candidateId, client, competition.applicantIds, competition.competitionId, navigate, t]);

  const willStartAt = React.useMemo((): Header["willStartAt"] => {
    const isRegistered = new Set(competition.applicantIds).has(candidate.candidateId);
    if (isRegistered && competition.status === "CREATED") {
      return `${t2("CompetitionWillStartShort", { date: Time.unixToDatetimeFormat(competition.startsAtSeconds) })}`;
    }

    return undefined;
  }, [candidate.candidateId, competition.applicantIds, competition.startsAtSeconds, competition.status, t2]);

  return {
    company: {
      name: competition.hostCompany?.name || "",
      src: competition.hostCompany?.logo || undefined,
    },
    title: competition.title,
    duration: `${TimeFormatter.unixToDatetimeFormat(competition.startsAtSeconds)} ~ ${TimeFormatter.unixToDatetimeFormat(competition.endsAtSeconds)}`,
    salary: `${translateSalary({ salaryInTenK: competition.minSalary, lang: lang })}+`,
    label: statusMap[competition.status],
    scale: `${competition.applicantIds.length} ${t("人")}`,
    thumbnailImage: competition.thumbnailUrl
      ? {
          src: competition.thumbnailUrl,
          alt: competition.title,
        }
      : undefined,
    registerButton: registerButton,
    willStartAt: willStartAt,
    registerDialog: {
      dialog: {
        open: dialogStatus === "OPEN_REGISTER",
        onClose: () => {
          CompetitionsIdDetail.updateDialogStatus("CLOSED");
        },
      },
      yesButton: {
        onClick: () => {
          CompetitionsIdDetail.updateLoadingStatus("LOADING");
          client
            .ApplyCompetitionForCandidateCompetitionsIdDetail({
              input: {
                candidateId: candidate.candidateId,
                competitionId: competition.competitionId,
              },
            })
            .then(() => {
              CompetitionsIdDetail.updateDialogStatus("CLOSED");
              Snackbar.notify({
                severity: "success",
                message: t("事前登録しました。"),
              });
              refresh();
            })
            .catch(error => {
              Sentry.captureException(error);
              const errorNotification = ErrorHandlingHelper.generateErrorNotification(
                error,
                t("事前登録に失敗しました。しばらくしてから再度お試し頂くか、ヘルプボタンよりお問い合わせください。"),
              );
              Snackbar.notify({
                severity: "error",
                message: errorNotification.message,
              });
            })
            .finally(() => {
              CompetitionsIdDetail.updateLoadingStatus("READY");
            });
        },
      },
      startAt: `${Time.unixToDatetimeFormat(competition.startsAtSeconds)}`,
    },
    guidelineDialog: {
      dialog: {
        open: dialogStatus === "OPEN_GUIDELINE",
        onClose: () => {
          CompetitionsIdDetail.updateDialogStatus("CLOSED");
        },
      },
      yesButton: {
        onClick: handleGuidelineClick,
        disabled: loadingStatus === "LOADING",
      },
    },
    profileDialog: {
      dialog: {
        open: dialogStatus === "OPEN_PROFILE",
        onClose: () => {
          CompetitionsIdDetail.updateDialogStatus("CLOSED");
        },
      },
      href: generateCurrentOriginUrl("/c/settings/user"),
    },
  };
};
