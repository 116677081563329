import styled from "@emotion/styled";
import { useTranslation } from "@hireroo/i18n";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import * as React from "react";

import CandidateLayout, { CandidateLayoutProps } from "../../layouts/CandidateLayout/CandidateLayout";

const StyledHeaderStack = styled(Stack)(() => ({
  height: 36,
  alignItems: "center",
  justifyContent: "space-between",
}));

export type UserSettingsForCandidateProps = {
  layout: CandidateLayoutProps;
  children: React.ReactNode;
};

const UserSettingsForCandidate: React.FC<UserSettingsForCandidateProps> = props => {
  const { t } = useTranslation();

  return (
    <CandidateLayout {...props.layout}>
      <Box p={3}>
        <StyledHeaderStack direction="row" mb={2}>
          <Typography variant="h5" fontWeight={700} noWrap>
            {t("プロフィール編集")}
          </Typography>
        </StyledHeaderStack>
        {props.children}
      </Box>
    </CandidateLayout>
  );
};

UserSettingsForCandidate.displayName = "UserSettingsForCandidate";

export default UserSettingsForCandidate;
