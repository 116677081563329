import { EntityType } from "@hireroo/app-helper/normalizer";
import type { DeepReadonly } from "@hireroo/app-helper/types";
import type * as Graphql from "@hireroo/graphql/client/urql";

import { state } from "./State";

type Entity = Graphql.ResultForCompetitionsResultsIdFragment["entities"][0];

export type NormalizedEntity = {
  entityType: EntityType;
  entityId: number;
  questionId: number;
  questionVersion: string;
};

export const generateNormalizedEntity = (entity: DeepReadonly<Entity>): NormalizedEntity => {
  const type = entity.__typename;
  switch (type) {
    case "ChallengeEntity":
      return {
        entityType: "challenge",
        entityId: entity.entityId,
        questionId: entity.challengeQuestion?.questionId ?? 0,
        questionVersion: entity.questionVersion,
      };
    default:
      throw new Error(`Unsupported entity type ${type satisfies never}`);
  }
};

export const extractQuestionTitle = (entity: Entity): { titleJa: string | undefined; titleEn: string | undefined } => {
  switch (entity.__typename) {
    case "ChallengeEntity":
      return {
        titleEn: entity.challengeQuestion?.titleEn ?? "",
        titleJa: entity.challengeQuestion?.titleJa ?? "",
      };
    default:
      return {
        titleEn: "",
        titleJa: "",
      };
  }
};

export const generateCompletedEntities = (entities: DeepReadonly<Entity[]> = []) => {
  return entities.reduce<DeepReadonly<Entity>[]>((all, entity) => {
    switch (entity.__typename) {
      case "ChallengeEntity": {
        if (entity.submissions.length) {
          all.push(entity);
        }
        break;
      }
    }
    return all;
  }, []);
};

export const generateEntityKey = (entity: DeepReadonly<Entity>): string => {
  switch (entity.__typename) {
    case "ChallengeEntity":
      return `challenge-${entity.entityId}-${entity.challengeQuestion?.questionId}-${entity.questionVersion}`;
    default:
      return "";
  }
};

export const getEntities = () => {
  return state.result?.entities || [];
};

export const getCompletedEntities = () => {
  return generateCompletedEntities(state.result?.entities);
};
