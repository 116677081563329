import { Pages } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { useGenerateProps } from "./useGenerateProps";

export type CandidateDetailForEmployeeContainerProps = {};

const CandidateDetailForEmployeeContainer: React.FC<CandidateDetailForEmployeeContainerProps> = () => {
  const candidateDetailForEmployeeProps = useGenerateProps({});
  return (
    <ErrorBoundary>
      <Pages.CandidateDetailForEmployee {...candidateDetailForEmployeeProps} />
    </ErrorBoundary>
  );
};

CandidateDetailForEmployeeContainer.displayName = "CandidateDetailForEmployeeContainer";

export default withErrorBoundary(CandidateDetailForEmployeeContainer, {});
