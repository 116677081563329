import Box from "@mui/material/Box";
import * as React from "react";

import TablePagination, { TablePaginationProps } from "../../modules/TablePagination/TablePagination";
import MetricItemTable, { MetricItemTableProps } from "../../ms-components/Evaluation/MetricItemTable/MetricItemTable";

export type EvaluationMetricListForRemoteProps = Omit<MetricItemTableProps, "enableCheckbox"> & {
  pagination: TablePaginationProps;
};

const EvaluationMetricListForRemote: React.FC<EvaluationMetricListForRemoteProps> = props => {
  const metricItemTable: MetricItemTableProps = {
    ...props,
    enableCheckbox: true,
  };
  return (
    <Box>
      <MetricItemTable {...metricItemTable} />
      <TablePagination {...props.pagination} />
    </Box>
  );
};

EvaluationMetricListForRemote.displayName = "EvaluationMetricListForRemote";

export default EvaluationMetricListForRemote;
