import { extractUniqueEntity } from "@hireroo/app-helper/entity";
import { App } from "@hireroo/app-store/essential/employee";
import { CompetitionResultReport } from "@hireroo/app-store/widget/e/CompetitionResultReport";
import * as Graphql from "@hireroo/graphql/client/urql";
import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import React from "react";

import CompetitionResultReportContainer, { CompetitionResultReportContainerProps } from "./Container";

export type CompetitionResultReportFetchContainerProps = {
  resultId: string;
};

const CompetitionResultReportFetchContainer: React.FC<CompetitionResultReportFetchContainerProps> = props => {
  const appStatus = App.useStatus();
  const initialized = CompetitionResultReport.useInitialized();
  const [result, refresh] = Graphql.useGetResultForCompetitionResultReportQuery({
    variables: {
      id: props.resultId,
      withAnswers: true,
    },
    pause: appStatus !== "INITIALIZED",
    requestPolicy: "cache-and-network",
  });
  React.useEffect(() => {
    return () => {
      CompetitionResultReport.clear();
    };
  }, []);
  React.useEffect(() => {
    if (result.data?.result) {
      const uniqueEntity = extractUniqueEntity(window.location.hash);
      const isValid = result.data.result.entities.some(entity => entity.entityId === uniqueEntity?.id);
      const firstUniqueEntityId = CompetitionResultReport.generateUniqueEntityId(result.data.result.entities[0]);
      const entityId: CompetitionResultReport.UniqueEntityId =
        uniqueEntity && isValid ? `${uniqueEntity.type}-${uniqueEntity.id}` : firstUniqueEntityId;
      CompetitionResultReport.initialize(result.data.result, entityId);
    }
  }, [result.data]);

  if (result.error) {
    return <Widget.RefreshPanel refresh={refresh} />;
  }
  if (result.fetching || appStatus !== "INITIALIZED" || !initialized) {
    return <Widget.Loading kind="TOP" />;
  }

  const containerProps: CompetitionResultReportContainerProps = {};
  return (
    <ErrorBoundary>
      <CompetitionResultReportContainer {...containerProps} />
    </ErrorBoundary>
  );
};

CompetitionResultReportFetchContainer.displayName = "CompetitionResultReportFetchContainer";

export default withErrorBoundary(CompetitionResultReportFetchContainer, {});
