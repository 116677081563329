import { useTranslation } from "@hireroo/i18n";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";

import EmployeeLayout, { EmployeeLayoutProps } from "../../layouts/EmployeeLayout/EmployeeLayout";
import ButtonWithTooltip, { ButtonWithTooltipProps } from "../../primitive/Button/ButtonWithTooltip/ButtonWithTooltip";

const HeadingTypography = styled(Typography)(() => ({
  fontWeight: "bold",
  fontSize: 24,
  whiteSpace: "nowrap",
  wordBreak: "keep-all",
}));

export type CompetitionListForEmployeeProps = {
  layout: EmployeeLayoutProps;
  children: React.ReactNode;
  SearchForm: React.ReactNode;
  createButton?: Pick<ButtonWithTooltipProps, "onClick" | "disabled" | "title">;
};

const CompetitionListForEmployee: React.FC<CompetitionListForEmployeeProps> = props => {
  const { t } = useTranslation();

  const layoutProps: EmployeeLayoutProps = {
    ...props.layout,
  };
  const createButton: ButtonWithTooltipProps | undefined = props.createButton
    ? {
        ...props.createButton,
        variant: "contained",
        startIcon: "ADD",
        children: t("コンペ作成"),
      }
    : undefined;
  return (
    <EmployeeLayout {...layoutProps}>
      <Box p={3}>
        <Stack direction="row" justifyContent="space-between" alignItems="center" mb={2}>
          <HeadingTypography>{t("コンペ一覧")}</HeadingTypography>
          {createButton && <ButtonWithTooltip {...createButton} />}
        </Stack>
        <Stack>{props.SearchForm}</Stack>
        {props.children}
      </Box>
    </EmployeeLayout>
  );
};

CompetitionListForEmployee.displayName = "CompetitionListForEmployee";

export default CompetitionListForEmployee;
