import { CompetitionsIdDetail } from "@hireroo/app-store/page/e/competitions_id_detail";
import { Pages } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { useGenerateProps } from "./useGenerateProps";

export type CompetitionIdDetailForEmployeeContainerProps = {};

const CompetitionIdDetailForEmployeeContainer: React.FC<CompetitionIdDetailForEmployeeContainerProps> = () => {
  const competitionIdDetailForEmployeeProps = useGenerateProps({});
  React.useEffect(() => {
    return () => {
      CompetitionsIdDetail.clear();
    };
  }, []);
  return (
    <ErrorBoundary>
      <Pages.CompetitionIdDetailForEmployee {...competitionIdDetailForEmployeeProps} />
    </ErrorBoundary>
  );
};

CompetitionIdDetailForEmployeeContainer.displayName = "CompetitionIdDetailForEmployeeContainer";

export default withErrorBoundary(CompetitionIdDetailForEmployeeContainer, {});
