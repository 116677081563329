import { RANK_MAP } from "@hireroo/app-definition/interview";
import { ENTITY_REPORT_TOP } from "@hireroo/app-helper/entity";
import { App, Company, Payment } from "@hireroo/app-store/essential/employee";
import { AssessmentReport } from "@hireroo/app-store/widget/e/AssessmentReport";
import { Snackbar } from "@hireroo/app-store/widget/shared/Snackbar";
import { formatScore } from "@hireroo/formatter/score";
import { useCurrentLanguage, useTranslation, useTranslationWithVariable } from "@hireroo/i18n";
import type { Widget } from "@hireroo/presentation";
import { updateHashParam } from "@hireroo/router/api";
import { useHelpCenterNavigate } from "@hireroo/router/hooks";
import * as React from "react";

import ExamRankVisualizerInitialContainer from "../../shared/ExamRankVisualizer/InitalContainer";
import ChallengeTestReportFetchContainer from "../ChallengeTestReport/FetchContainer";
import ProjectTestReportFetchContainer from "../ProjectTestReport/FetchContainer";
import QuizTestReportFetchContainer from "../QuizTestReport/FetchContainer";
import SystemDesignTestReportFetchContainer from "../SystemDesignTestReport/FetchContainer";
import { generateSmoothTargetId } from "./privateHelper";
import { useGenerateEntityScoreSelector } from "./useGenerateEntityScoreSelector";
import { useGenerateFooterProps } from "./useGenerateFooterProps";

export type GenerateTestReportPropsArgs = {};

type DisplayScoreArea = Exclude<Widget.AssessmentReportProps["summary"]["scorePieChart"], undefined>["displayScoreArea"];

type Report = Widget.AssessmentReportProps["Reports"][0];

export const useGenerateProps = (_args: GenerateTestReportPropsArgs): Widget.AssessmentReportProps => {
  const appStatus = App.useStatus();
  const { t } = useTranslation();
  const { t: t2 } = useTranslationWithVariable();
  const activeCompanyId = Company.useStrictActiveCompanyId();
  const exam = AssessmentReport.useExam();
  const lang = useCurrentLanguage();
  const helpCenterNavigate = useHelpCenterNavigate(lang);
  const isAvailableFeature = Payment.useIsAvailableFeature();
  const entityScoreSelector = useGenerateEntityScoreSelector();
  const selectedEntityId = AssessmentReport.useSelectedEntityId();
  const footer = useGenerateFooterProps();

  const Reports = React.useMemo(() => {
    /**
     * Release date 2023/5/1 0:00
     */
    const challengeTabAndPasteStatisticsReleaseDateSeconds = new Date(2023, 4, 1, 0, 0).getTime() / 1000;
    /**
     * Release date 2023/7/6 16:00
     */
    const challengeCheatDetectionSectionReleaseDateSeconds = new Date(2023, 6, 5, 16, 0).getTime() / 1000;
    /**
     * Release date 2024/1/4 0:00
     */
    const cheatDetectionReleaseDateSeconds = new Date(2024, 0, 3, 0, 0).getTime() / 1000;

    /**
     * For Quiz, Project, SystemDesign
     */
    const canShowCheatDetectionSection =
      isAvailableFeature("exam.copy-and-paste.read") && (exam.didStartAtSeconds ?? 0) >= cheatDetectionReleaseDateSeconds;

    return exam.entities.map((entity): Report => {
      const smoothScrollTargetId = generateSmoothTargetId(entity);

      if (entity.__typename === "ChallengeEntity") {
        const canShowPasteAndTabStatisticsChallenge =
          isAvailableFeature("exam.copy-and-paste.read") && (exam.didStartAtSeconds ?? 0) >= challengeTabAndPasteStatisticsReleaseDateSeconds;
        const canShowCheatDetectSectionChallenge =
          isAvailableFeature("exam.copy-and-paste.read") && (exam.didStartAtSeconds ?? 0) >= challengeCheatDetectionSectionReleaseDateSeconds;
        const canShowPlayback = isAvailableFeature("exam.playback.read");
        const canShowStatistics = isAvailableFeature("exam.statics.read");
        return {
          id: AssessmentReport.generateUniqueEntityId(entity),
          smoothScrollTargetId,
          Content: activeCompanyId && (
            <ChallengeTestReportFetchContainer
              canShowPlayback={canShowPlayback}
              canShowStatistics={canShowStatistics}
              canShowFollowUpQuestions={false}
              canShowCheatDetectionSection={canShowCheatDetectSectionChallenge}
              canShowPasteAndTabStatistics={canShowPasteAndTabStatisticsChallenge}
              canShowFeedbackSuspiciousDegree={false}
              companyId={activeCompanyId}
              challengeId={entity.challengeEntityId}
              showUsedHintSection={true}
            />
          ),
        };
      } else if (entity.__typename === "QuizEntity") {
        return {
          id: AssessmentReport.generateUniqueEntityId(entity),
          smoothScrollTargetId,
          Content: activeCompanyId && (
            <QuizTestReportFetchContainer
              key={entity.quizEntityId}
              quizId={entity.quizEntityId}
              companyId={activeCompanyId}
              featureKind="exam"
              canShowCheatDetectionSection={canShowCheatDetectionSection}
            />
          ),
        };
      } else if (entity.__typename === "ProjectEntity") {
        return {
          id: AssessmentReport.generateUniqueEntityId(entity),
          smoothScrollTargetId,
          Content: activeCompanyId && (
            <ProjectTestReportFetchContainer
              featureKind="exam"
              projectId={entity.projectEntityId}
              companyId={activeCompanyId}
              testId={exam.id}
              kindOfReview="EMPLOYEE_REVIEW_EXAM"
              canShowCheatDetectionSection={canShowCheatDetectionSection}
            />
          ),
        };
      } else if (entity.__typename === "SystemDesignEntity") {
        return {
          id: AssessmentReport.generateUniqueEntityId(entity),
          smoothScrollTargetId,
          Content: activeCompanyId && (
            <SystemDesignTestReportFetchContainer
              featureKind="exam"
              systemDesignId={entity.systemDesignEntityId}
              companyId={activeCompanyId}
              canShowCheatDetectionSection={canShowCheatDetectionSection}
              showUsedHintSection={true}
            />
          ),
        };
      } else {
        throw new Error("unknown entity type");
      }
    });
  }, [activeCompanyId, exam.didStartAtSeconds, exam.entities, exam.id, isAvailableFeature]);

  const displayScoreAreaProps = React.useMemo<DisplayScoreArea>(() => {
    if (exam.status !== "FINALIZED") {
      return { kind: "SCORING" };
    } else if (!exam.isReliableRank) {
      return {
        kind: "WITH_RANK",
        rankLabel: {
          rank: "UNKNOWN",
          reason: t("提出時点のランクを表示するためのデータ数が足りないため表示できません。"),
        },
      };
    } else if (exam.rankEvaluation === "UNKNOWN") {
      return {
        kind: "SCORE_ONLY",
      };
    } else {
      return {
        kind: "WITH_RANK",
        rankLabel: {
          rank: RANK_MAP[exam.rankEvaluation],
          reason: t2("RelativeScoreLabel", { score: formatScore(exam.relativeScore) }),
        },
      };
    }
  }, [exam.status, exam.rankEvaluation, exam.relativeScore, exam.isReliableRank, t, t2]);

  return {
    summary: {
      scorePieChart: {
        score: formatScore(exam.totalScore),
        displayScoreArea: displayScoreAreaProps,
      },
      status: exam.status !== "FINALIZED" ? "NOT_READY" : "READY",
      entityScoreSelector: entityScoreSelector,
      enableStaticContent: isAvailableFeature("exam.statics.read"),
      StatisticsContent: activeCompanyId && (
        <ExamRankVisualizerInitialContainer
          companyId={activeCompanyId}
          examId={exam.id}
          finalizedAtSeconds={exam.finalizedAtSeconds}
          pause={appStatus !== "INITIALIZED"}
          enableTagField={false}
          enableRank
          isReliableExamRank={exam.isReliableRank}
          rankEvaluation={exam.rankEvaluation}
        />
      ),
      helpLink: {
        onClick: () => {
          helpCenterNavigate("CHECK_REPORT", { _blank: true });
        },
      },
      linkButton: {
        onClick: () => {
          Snackbar.notify({
            severity: "info",
            message: t("クリップボードにコピーされました"),
          });
          updateHashParam("summary");
        },
        url: window.location.href,
      },
      showSuspiciousBehaviorText: false,
    },
    footer: footer,
    Reports: Reports,
    targetElementId: ENTITY_REPORT_TOP,
    selectedEntityId: selectedEntityId || exam.entities[0].id,
  };
};
