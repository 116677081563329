import { useTranslation } from "@hireroo/i18n";
import { CandidateProfileForm } from "@hireroo/validator";
import Box from "@mui/material/Box";
import FormHelperText from "@mui/material/FormHelperText";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { useFormContext } from "react-hook-form";

import SelectField, { SelectFieldProps } from "../../../../primitive/InputControl/SelectField/SelectField";

export type JobChangeStatusFieldProps = Pick<SelectFieldProps, "items" | "onChange">;

const JobChangeStatusField: React.FC<JobChangeStatusFieldProps> = props => {
  const { t } = useTranslation();
  const {
    formState: { errors },
  } = useFormContext<CandidateProfileForm.CandidateProfileFormSchema>();
  const errorMessage = errors.jobChangeStatus?.message;

  return (
    <Stack direction="column" spacing={1.5}>
      <Typography fontSize={14} fontWeight={700}>
        {t("現在の転職意欲")}
      </Typography>
      <Box width={"50%"}>
        <SelectField name="jobChangeStatus" color="secondary" {...props} />
        {errorMessage && <FormHelperText error>{errorMessage}</FormHelperText>}
      </Box>
    </Stack>
  );
};

JobChangeStatusField.displayName = "JobChangeStatusField";

export default JobChangeStatusField;
