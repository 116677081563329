import { useEnabledSinSClassPhase2 } from "@hireroo/app-helper/feature";
import { RemoteInterviewResourceEditorStore } from "@hireroo/app-store/view-domain/RemoteInterviewResourceEditor";
import { EvaluationMetricListForRemote } from "@hireroo/app-store/widget/e/EvaluationMetricListForRemote";
import { RemoteCreateDialog } from "@hireroo/app-store/widget/e/RemoteCreateDialog";
import { useTranslation } from "@hireroo/i18n";
import type { Widget } from "@hireroo/presentation";
import { useTransitionNavigate } from "@hireroo/router/hooks";
import * as React from "react";

import EvaluationMetricListForRemoteFetchContainer from "../../../../../../widget/v2/e/EvaluationMetricListForRemote/FetchContainer";
import ScreeningTestListForRemoteContentContainer from "./widgets/ScreeningTestListForRemoteContent/Container";

export type GenerateRemoteCreateDialogPropsArgs = {};

export const useGenerateProps = (_args: GenerateRemoteCreateDialogPropsArgs): Widget.RemoteCreateDialogProps => {
  const navigate = useTransitionNavigate();
  const open = RemoteCreateDialog.useOpen();
  const step = RemoteCreateDialog.useStep();
  const selectedSpotId = RemoteCreateDialog.useSelectedSpotId();
  const initialized = RemoteCreateDialog.useInitialized();
  const enabledSinSClassPhase2 = useEnabledSinSClassPhase2();
  const { t } = useTranslation();
  const yesButtonMap: Record<Exclude<RemoteCreateDialog.Step, "CHOOSE_OPTION">, string> = {
    TEST_LIST: enabledSinSClassPhase2 ? t("次へ") : t("テストを引き継いで作成"),
    EVALUATION_METRICS: t("テストを引き継いで作成"),
  };
  const [selectedMetricIds, setSelectedMetricIds] = React.useState<number[]>([]);
  const metricsMap = EvaluationMetricListForRemote.useMetricMap();

  return {
    dialog: {
      open: open,
      onClose: () => {
        RemoteCreateDialog.setOpen(false);
      },
    },
    createOption: {
      createNewButton: {
        onClick: () => {
          navigate("/e/remotes/create");
        },
      },
      selectFromTestButton: {
        onClick: () => {
          RemoteCreateDialog.updateStep("TEST_LIST");
        },
      },
    },
    step: step,
    TestList: <ScreeningTestListForRemoteContentContainer />,
    EvaluationMetrics: enabledSinSClassPhase2 ? (
      <EvaluationMetricListForRemoteFetchContainer
        onChange={fields => {
          const selectedIds: number[] = fields.items.filter(item => item.selected).map(item => Number(item.itemId));
          setSelectedMetricIds(selectedIds);
        }}
      />
    ) : null,
    actionButtons: {
      yesButton:
        step === "CHOOSE_OPTION"
          ? undefined
          : {
              onClick: () => {
                if (!selectedSpotId) return;
                if (enabledSinSClassPhase2) {
                  if (step === "TEST_LIST") {
                    RemoteCreateDialog.updateStep("EVALUATION_METRICS");
                  }
                  if (step === "EVALUATION_METRICS") {
                    const selectedMetrics = selectedMetricIds.map(id => metricsMap.get(id)!);
                    RemoteInterviewResourceEditorStore.setInitialEvaluationMetricIds(selectedMetrics);
                    navigate("/e/remotes/create", { queryParams: { spotId: selectedSpotId } });
                  }
                  return;
                }
                navigate("/e/remotes/create", { queryParams: { spotId: selectedSpotId } });
              },
              children: yesButtonMap[step],
              disabled: selectedSpotId === null || !initialized,
            },
      noButton:
        step === "CHOOSE_OPTION"
          ? undefined
          : {
              onClick: () => {
                if (enabledSinSClassPhase2) {
                  if (step === "EVALUATION_METRICS") {
                    RemoteCreateDialog.updateStep("TEST_LIST");
                  }
                  if (step === "TEST_LIST") {
                    RemoteCreateDialog.updateStep("CHOOSE_OPTION");
                    RemoteCreateDialog.clearSelectedSpotId();
                  }
                  return;
                }
                RemoteCreateDialog.updateStep("CHOOSE_OPTION");
                RemoteCreateDialog.clearSelectedSpotId();
              },
            },
    },
  };
};
