import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowRight from "@mui/icons-material/ArrowRight";
import { useTheme } from "@mui/material";
import Collapse from "@mui/material/Collapse";
import * as React from "react";

import type * as Types from "../../../../types";
import NavigationMenuItem, { NavigationMenuItemProps } from "../NavigationMenuItem/NavigationMenuItem";

export type ChildNavigationMenuFactoryProps = Types.MenuItemWithChildren & {
  NavigationMenu: React.FC<{ items: Types.MenuItem[] }>;
};

const ChildNavigationMenuFactory: React.FC<ChildNavigationMenuFactoryProps> = props => {
  const [open, setOpen] = React.useState(true);
  const { id, children, NavigationMenu } = props;
  const theme = useTheme();

  const EndIconMap: Record<`${boolean}`, React.ReactElement> = {
    true: <ArrowDropDownIcon htmlColor={theme.palette["Gray/Shades"].p56} fontSize="small" />,
    false: <ArrowRight htmlColor={theme.palette["Gray/Shades"].p56} fontSize="small" />,
  };

  const navigationMenuItemProps: NavigationMenuItemProps = {
    root: true,
    id: id,
    href: props.href,
    startIcon: props.startIcon,
    children: props.title,
    onClick: () => {
      setOpen(prev => !prev);
    },
    ExpandIcon: EndIconMap[`${open}`],
  };
  return (
    <>
      <NavigationMenuItem {...navigationMenuItemProps} />
      <Collapse in={open}>
        <NavigationMenu items={children} />
      </Collapse>
    </>
  );
};

ChildNavigationMenuFactory.displayName = "ChildNavigationMenuFactory";

export default ChildNavigationMenuFactory;
