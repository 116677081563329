import { useTranslation } from "@hireroo/i18n";
import type { TagForm } from "@hireroo/validator";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import * as React from "react";

import DeleteDialog, { DeleteDialogProps } from "../../../../../../../../modules/DeleteDialog/DeleteDialog";
import TagDialog, { TagDialogProps } from "../../../../../../../../modules/TagDialog/TagDialog";
import MoreButton, { MoreButtonProps } from "../../../../../../../../primitive/Button/MoreButton/MoreButton";

type DialogController = {
  setLoading: (loading: boolean) => void;
  close: () => void;
};

type DialogStatus = "OPEN_TAG" | "OPEN_DELETE" | "CLOSED";

export type ActionMenuProps = {
  disabled?: boolean;
  tooltipText?: string;
  tagDialog: Pick<TagDialogProps, "defaultValues" | "tagInputField"> & {
    onSubmit: (fields: TagForm.TagsSchema, controller: DialogController) => void;
  };
  createInterviewButton?: Pick<MoreButtonProps["options"][0], "onClick" | "disabled" | "disabledText">;
  deleteDialog: {
    name: DeleteDialogProps["name"];
    onSubmit: (controller: DialogController) => void;
  };
  deleteButton: Pick<MoreButtonProps["options"][0], "disabled" | "disabledText">;
};

const ActionMenu: React.FC<ActionMenuProps> = props => {
  const { t } = useTranslation();
  const [dialogStatus, setDialogStatus] = React.useState<DialogStatus>("CLOSED");
  const [loading, setLoading] = React.useState(false);
  const tagDialogProps: TagDialogProps = {
    ...props.tagDialog,
    open: dialogStatus === "OPEN_TAG",
    disabled: loading,
    onSubmit: fields => {
      const controller: DialogController = {
        close: () => {
          setDialogStatus("CLOSED");
          setLoading(false);
        },
        setLoading: (newLoading: boolean) => {
          setLoading(newLoading);
        },
      };
      props.tagDialog.onSubmit(fields, controller);
    },
    onOpen: () => {
      setDialogStatus("OPEN_TAG");
    },
    onClose: () => {
      setDialogStatus("CLOSED");
    },
  };
  const deleteDialogProps: DeleteDialogProps = {
    dialog: {
      open: dialogStatus === "OPEN_DELETE",
      noButton: {
        onClick: () => {
          setDialogStatus("CLOSED");
        },
        disabled: loading,
      },
      yesButton: {
        onClick: () => {
          const controller: DialogController = {
            close: () => {
              setDialogStatus("CLOSED");
              setLoading(false);
            },
            setLoading: (newLoading: boolean) => {
              setLoading(newLoading);
            },
          };
          props.deleteDialog.onSubmit(controller);
        },
        disabled: loading,
      },
    },
    name: props.deleteDialog.name,
    description: t("この操作は復旧不可能なためお気をつけください。"),
  };
  const moreButtonProps: MoreButtonProps = {
    iconButton: {
      disabled: props.disabled,
    },
    variant: "vertical",
    options: [
      props.createInterviewButton && {
        startIcon: "VIDEO_ADD" as const,
        value: "create-interview",
        displayName: t("続きからインタビュー作成"),
        ...props.createInterviewButton,
      },
      {
        startIcon: "EDIT" as const,
        value: "tag-setting",
        displayName: t("タグ設定"),
        onClick: () => {
          setDialogStatus("OPEN_TAG");
        },
      },
      {
        startIcon: "DELETE" as const,
        value: "delete",
        color: "error",
        displayName: t("削除"),
        onClick: () => {
          setDialogStatus("OPEN_DELETE");
        },
        ...props.deleteButton,
      },
    ].flatMap(v => (v ? [v] : [])),
  };
  const tooltipText = props.disabled ? props.tooltipText ?? "" : "";
  return (
    <Box>
      <Tooltip title={tooltipText}>
        <span>
          <MoreButton {...moreButtonProps}></MoreButton>
        </span>
      </Tooltip>
      <TagDialog key={`open-tag-${dialogStatus}`} {...tagDialogProps} />
      <DeleteDialog key={`open-delete-${dialogStatus}`} {...deleteDialogProps} />
    </Box>
  );
};

ActionMenu.displayName = "ActionMenu";

export default ActionMenu;
