import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import { styled } from "@mui/material/styles";
import * as React from "react";

import CompetitionCard, { CompetitionCardProps } from "./parts/CompetitionCard/CompetitionCard";
import SearchResultBar, { SearchResultBarProps } from "./parts/SearchResultBar/SearchResultBar";

const CardListWrapper = styled(Box)(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "repeat(auto-fill, minmax(280px, 1fr))",
  gap: theme.spacing(3),
  overflow: "auto",
}));

export type CompetitionCardListProps = {
  searchResultBar: SearchResultBarProps;
  items: CompetitionCardProps[];
  onEndReached?: () => void;
  loading?: boolean;
};

const CompetitionCardList: React.FC<CompetitionCardListProps> = props => {
  const { onEndReached } = props;
  const itemLength = props.items.length;
  const scrollRootRef = React.useRef<HTMLUListElement>(null);
  const targetRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    /**
     * skip intersectionObserver if onEndReached is not provided.
     */
    if (!onEndReached) return;
    if (itemLength === 0) return;
    const observer = new IntersectionObserver(
      entries => {
        entries.forEach(entry => entry.isIntersecting && onEndReached());
      },
      {
        root: scrollRootRef.current,
        rootMargin: "20px",
        threshold: 1.0,
      },
    );
    const targetElement = targetRef.current;

    if (targetElement) {
      observer.observe(targetElement);
    }

    return () => {
      if (targetElement) {
        observer.disconnect();
      }
    };
  }, [itemLength, onEndReached]);

  return (
    <Box>
      <SearchResultBar {...props.searchResultBar} />
      <CardListWrapper ref={scrollRootRef} maxHeight="70vh" overflow="auto">
        {props.items.map(item => (
          <CompetitionCard key={item.id} {...item} />
        ))}
        {props.loading && <CircularProgress size={32} />}
        <div ref={targetRef} />
      </CardListWrapper>
    </Box>
  );
};

CompetitionCardList.displayName = "CompetitionCardList";

export default CompetitionCardList;
