import { useTitle } from "@hireroo/app-helper/react-use";
import { App } from "@hireroo/app-store/essential/employee";
import { Competitions } from "@hireroo/app-store/page/e/competitions";
import { EmployeeSideBarContent } from "@hireroo/app-store/widget/e/EmployeeSideBarContent";
import { useTranslation } from "@hireroo/i18n";
import type { Pages } from "@hireroo/presentation";
import { generatePath } from "@hireroo/router/api";
import { useTransitionNavigate } from "@hireroo/router/hooks";
import React from "react";
import { useSearchParams } from "react-router-dom";

import EmployeeHeaderRightContentContainer from "../../../../widget/v2/e/EmployeeHeaderRightContent/Container";
import EmployeeSideBarContentContainer from "../../../../widget/v2/e/EmployeeSideBarContent/Container";
import FooterContainer from "../../../../widget/v2/e/Footer/Container";
import { useNotificationBanner } from "../../../../widget/v2/e/NotificationBanner/Container";
import SnackbarContainer from "../../../../widget/v2/shared/Snackbar/Container";
import * as PrivateHelper from "./privateHelper";
import CompetitionCardListFetchContainer from "./widgets/CompetitionCardList/FetchContainer";
import CompetitionSearchAreaContainer from "./widgets/CompetitionSearchArea/Container";

export type GenerateCompetitionListForEmployeePropsArgs = {};

export const useGenerateProps = (_args: GenerateCompetitionListForEmployeePropsArgs): Pages.CompetitionListForEmployeeProps => {
  const { t } = useTranslation();
  const NotificationBannerContainer = useNotificationBanner();
  const navigate = useTransitionNavigate();
  const appStatus = App.useStatus();
  const [searchParams] = useSearchParams();
  const initializedSearchFilter = Competitions.useInitializedSearchFilter();
  useTitle(t("コンペ一覧"));

  React.useEffect(() => {
    EmployeeSideBarContent.setOpenSidebar(true);
  }, []);

  React.useEffect(() => {
    Competitions.initializeFilters(
      {
        textFilter: searchParams.get(Competitions.QueryKeys.NAME) ?? "",
        statuses: PrivateHelper.convertStringToStatuses(searchParams.getAll(Competitions.QueryKeys.STATUS)),
        minSalary: {
          max: PrivateHelper.convertStringToMinSalaryMax(searchParams.get(Competitions.QueryKeys.MIN_SALARY_MAX)),
          min: PrivateHelper.convertStringToMinSalaryMin(searchParams.get(Competitions.QueryKeys.MIN_SALARY_MIN)),
        },
        selfHostedOnly: PrivateHelper.convertStringToSelfHostedOnly(searchParams.get(Competitions.QueryKeys.SELF_HOSTED_ONLY)),
      },
      PrivateHelper.convertStringToSortFieldValue(searchParams.get(Competitions.QueryKeys.SORT)),
    );
  }, [searchParams]);

  return {
    layout: {
      openSidebar: EmployeeSideBarContent.useOpenSidebar(),
      onChangeOpenSidebar: open => {
        EmployeeSideBarContent.setOpenSidebar(open);
      },
      loading: appStatus === "INITIALIZING",
      NotificationBanner: NotificationBannerContainer,
      HeaderRightContent: <EmployeeHeaderRightContentContainer />,
      SideBarContent: <EmployeeSideBarContentContainer />,
      Footer: <FooterContainer />,
      Snackbar: <SnackbarContainer />,
      sideBarHeader: {
        logo: {
          href: generatePath("/e/home"),
          onClick: () => {
            navigate("/e/home");
          },
        },
      },
      navigationBreadcrumbs: {
        parentLinks: [
          {
            href: generatePath("/e/home"),
            children: t("ホーム"),
            onClick: () => {
              navigate("/e/home");
            },
          },
        ],
        current: t("コンペ一覧"),
      },
    },
    children: <CompetitionCardListFetchContainer />,
    SearchForm: initializedSearchFilter && <CompetitionSearchAreaContainer />,
  };
};
