import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { GenerateCompetitionResultsIdHeaderPropsArgs, useGenerateProps } from "./useGenerateProps";

export type CompetitionResultsIdHeaderContainerProps = GenerateCompetitionResultsIdHeaderPropsArgs;

const CompetitionResultsIdHeaderContainer: React.FC<CompetitionResultsIdHeaderContainerProps> = props => {
  const competitionResultsIdHeaderProps = useGenerateProps(props);
  return (
    <ErrorBoundary>
      <Widget.ScreeningTestHeader {...competitionResultsIdHeaderProps} />
    </ErrorBoundary>
  );
};

CompetitionResultsIdHeaderContainer.displayName = "CompetitionResultsIdHeaderContainer";

export default withErrorBoundary(CompetitionResultsIdHeaderContainer, {});
