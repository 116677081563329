import { Auth } from "@hireroo/app-store/essential/candidate";
import { CompetitionApplicantLeaderboard } from "@hireroo/app-store/widget/c/CompetitionApplicantLeaderboard";
import { formatScore } from "@hireroo/formatter/score";
import * as TimeFormatter from "@hireroo/formatter/time";
import { useLanguageCode, useTranslation } from "@hireroo/i18n";
import type { Widget } from "@hireroo/presentation";
import * as React from "react";

type Item = Widget.CompetitionApplicantLeaderboardProps["leaderboard"][number];

export type GenerateCompetitionApplicantLeaderboardPropsArgs = {};

export const useGenerateProps = (_args: GenerateCompetitionApplicantLeaderboardPropsArgs): Widget.CompetitionApplicantLeaderboardProps => {
  const currentApplicants = CompetitionApplicantLeaderboard.useCurrentApplicants();
  const { t } = useTranslation();
  const lang = useLanguageCode();
  const currentCandidate = Auth.useCurrentCandidate();
  const leaderboard = React.useMemo((): Widget.CompetitionApplicantLeaderboardProps["leaderboard"] => {
    return currentApplicants.map(
      (applicant): Item => ({
        id: applicant.id,
        name: t("参加者"),
        isCurrentUser: applicant.applicantId === currentCandidate.candidateId,
        elapsedTime: applicant.result?.elapsedTimeSeconds
          ? `${TimeFormatter.formatSecondsToHumanTimeWithTranslation(applicant.result.elapsedTimeSeconds, lang)}`
          : "-",
        score: applicant.result ? `${formatScore(applicant.result.totalScore)}` : "",
        ranking: applicant.result && applicant.result.rank > 0 ? applicant.result.rank : undefined,
      }),
    );
  }, [currentApplicants, currentCandidate.candidateId, lang, t]);
  return {
    leaderboard: leaderboard,
  };
};
