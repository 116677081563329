import HireRooLogoCircle from "@hireroo/ui-assets/images/Logo/HireRooLogoCircle.svg";
import Logo from "@hireroo/ui-assets/images/Logo/Logo.svg";
import MenuIcon from "@mui/icons-material/Menu";
import MenuOpenOutlined from "@mui/icons-material/MenuOpenOutlined";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import * as React from "react";

import IconButton, { IconButtonProps } from "../../../../primitive/Button/IconButton/IconButton";
import Link, { LinkProps } from "../../../../primitive/Link/Link";

const StyledStack = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.palette["Background/Paper"].p2,
}));

const StyledLink = styled(Link)(() => ({
  display: "inline-flex",
  alignItems: "stretch",
}));

const CircleLogoImg = styled("img")(() => ({
  width: "32px",
  height: "32px",
}));

const ClosedStack = styled(Stack)(() => ({
  maxWidth: "32px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
}));

const OpenIconButton = styled(IconButton)(() => ({
  width: "32px",
  height: "32px",
}));

export type SideBarHeaderProps = {
  open: boolean;
  width: number;
  height: number;
  logo: LinkProps;
  closeButton: Pick<IconButtonProps, "onClick">;
  onOpen: () => void;
};

const SideBarHeader: React.FC<SideBarHeaderProps> = props => {
  const openButtonProps: IconButtonProps = {
    onClick: () => {
      props.onOpen();
    },
    children: <MenuIcon />,
  };
  if (props.open) {
    return (
      <StyledStack py={2} px={3} direction="row" alignItems="center" justifyContent="space-between" height={props.height} width={props.width}>
        <StyledLink {...props.logo}>
          <img src={Logo} width={100} alt="hireroo" />
        </StyledLink>
        <IconButton {...props.closeButton}>
          <MenuOpenOutlined />
        </IconButton>
      </StyledStack>
    );
  }
  return (
    <ClosedStack direction="column" spacing={2}>
      <StyledLink {...props.logo}>
        <CircleLogoImg src={HireRooLogoCircle} alt="hireroo" />
      </StyledLink>
      <OpenIconButton {...openButtonProps} />
    </ClosedStack>
  );
};

SideBarHeader.displayName = "SideBarHeader";

export default SideBarHeader;
