import { Competitions } from "@hireroo/app-store/page/c/competitions";
import * as Graphql from "@hireroo/graphql/client/urql";

export const SortMethodReverseMap: Record<string, Graphql.CompetitionSortMethod> = {
  [Graphql.CompetitionSortMethod.CreatedAt]: Graphql.CompetitionSortMethod.CreatedAt,
  [Graphql.CompetitionSortMethod.MinSalary]: Graphql.CompetitionSortMethod.MinSalary,
  [Graphql.CompetitionSortMethod.StartsAt]: Graphql.CompetitionSortMethod.StartsAt,
};

export const convertStringToSortFieldValue = (value: string | null): Competitions.SortParams => {
  if (value === null) return Competitions.DEFAULT_SORT_PARAMS;
  try {
    // "sortField+isDesc" (e.g.) "CREATED_AT-true"
    const [field, isDesc] = value.split("-");
    const sortField = SortMethodReverseMap[field] ?? Competitions.DEFAULT_SORT_PARAMS.sortMethod;
    return {
      sortMethod: sortField,
      isDescending: isDesc === "true",
    };
  } catch (_) {
    return Competitions.DEFAULT_SORT_PARAMS;
  }
};

export const convertStringToStatuses = (values: string[] | null): Competitions.SearchFilter["statuses"] => {
  if (!values) {
    return [];
  }

  const statusMap: Record<string, Competitions.SearchFilter["statuses"][0]> = {
    CREATED: "CREATED",
    STARTED: "STARTED",
    ENDED: "ENDED",
    CONFIRMED: "CONFIRMED",
  };
  const newStatuses: Competitions.SearchFilter["statuses"] = [];

  for (const value of values) {
    value.split("%").forEach(v => {
      // Check if the string value can be converted to a valid status.
      if (statusMap[v]) {
        newStatuses.push(statusMap[v]);
      }
    });
  }
  return newStatuses;
};

export const convertStringToMinSalaryMin = (value: string | null): Competitions.SearchFilter["minSalary"]["min"] => {
  if (!value) return Competitions.MIN_SALARY.MIN;
  const min = Number(value);
  if (Number.isNaN(min)) return Competitions.MIN_SALARY.MIN;
  if (Competitions.MIN_SALARY.MIN <= min && min <= Competitions.MIN_SALARY.MAX) {
    return min;
  }

  return Competitions.MIN_SALARY.MIN;
};

export const convertStringToMinSalaryMax = (value: string | null): Competitions.SearchFilter["minSalary"]["max"] => {
  if (!value) return Competitions.MIN_SALARY.MAX;
  const min = Number(value);
  if (Number.isNaN(min)) return Competitions.MIN_SALARY.MAX;
  if (Competitions.MIN_SALARY.MIN <= min && min <= Competitions.MIN_SALARY.MAX) {
    return min;
  }

  return Competitions.MIN_SALARY.MAX;
};

export const convertStringToSelfHostedOnly = (value: string | null) => {
  return value === "true";
};
