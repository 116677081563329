import { RemoteInterviewResourceEditorStore } from "@hireroo/app-store/view-domain/RemoteInterviewResourceEditor";
import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import RemoteInterviewResourceEditorContainer, { RemoteInterviewResourceEditorContainerProps } from "./Container";

export type RemoteInterviewResourceEditorMiddleContainerProps = RemoteInterviewResourceEditorContainerProps & {
  initialStepName?: Widget.RemoteInterviewResourceEditorProviderProps["initialStepName"];
  isSubmitting?: boolean;
};

const RemoteInterviewResourceEditorMiddleContainer: React.FC<RemoteInterviewResourceEditorMiddleContainerProps> = props => {
  const { isSubmitting } = props;

  React.useEffect(() => {
    return () => {
      RemoteInterviewResourceEditorStore.clear();
    };
  }, []);

  const submitDefaultValues = React.useMemo(() => {
    if (props.initialStepName === "CONFIRM" && props.defaultValues && !isSubmitting) {
      return {
        REMOTE_QUESTION_SETUP: props.defaultValues.remoteQuestionSetup,
        REPORT_SETUP: props.defaultValues.remoteReportSetup,
      };
    }
    return undefined;
  }, [props.defaultValues, props.initialStepName, isSubmitting]);
  return (
    <ErrorBoundary>
      <Widget.RemoteInterviewResourceEditorProvider initialStepName={props.initialStepName} submitDefaultValues={submitDefaultValues}>
        <RemoteInterviewResourceEditorContainer {...props} />
      </Widget.RemoteInterviewResourceEditorProvider>
    </ErrorBoundary>
  );
};

RemoteInterviewResourceEditorMiddleContainer.displayName = "RemoteInterviewResourceEditorMiddleContainer";

export default withErrorBoundary(RemoteInterviewResourceEditorMiddleContainer, {});
