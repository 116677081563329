import Box from "@mui/material/Box";
import CircularProgress, { CircularProgressProps } from "@mui/material/CircularProgress";
import { styled } from "@mui/material/styles";
import * as React from "react";

const CircularProgressWrapper = styled(Box)`
  position: relative;
  width: 120px;
  height: 120px;
`;

const StyledCircularProgress = styled(CircularProgress)(() => ({
  color: "#FFFFFF",
}));

const BackgroundCircularProgress = styled(CircularProgress)(() => ({
  color: "#000000",
  opacity: "0.16",
  position: "absolute",
}));

const CenteredContent = styled(Box)(() => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  display: "flex",
  justifyContent: "center",
}));

export type PieChartProps = {
  className?: string;
  /** 0 - 100 */
  value: number;
  children?: React.ReactNode;
};

const PieChart: React.FC<PieChartProps> = props => {
  const circularProgressProps: CircularProgressProps = {
    variant: "determinate",
    value: props.value,
    size: 120,
    thickness: 2.5,
  };
  return (
    <CircularProgressWrapper className={props.className}>
      <BackgroundCircularProgress
        variant="determinate"
        size={circularProgressProps.size}
        thickness={circularProgressProps.thickness}
        value={100}
      />
      <StyledCircularProgress {...circularProgressProps} />
      <CenteredContent>{props.children}</CenteredContent>
    </CircularProgressWrapper>
  );
};

PieChart.displayName = "PieChart";

export default PieChart;
