import KeyboardArrowRightOutlinedIcon from "@mui/icons-material/KeyboardArrowRightOutlined";
import Avatar from "@mui/material/Avatar";
import Button, { ButtonProps } from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";

const TextButton = styled(Button)(({ theme }) => ({
  lineHeight: "22px",
  minHeight: "26px",
  textAlign: "left",
  textTransform: "none",
  textOverflow: "ellipsis",
  color: theme.palette.text.secondary,
  "& .MuiButton-root": {
    textAlign: "left",
    flexShrink: 0,
    flexGrow: 0,
  },
  "& .MuiButton-endIcon": {
    marginLeft: theme.spacing(0.5),
  },
  paddingLeft: theme.spacing(0),
  marginBottom: theme.spacing(0.5),
  alignItems: "center",
  justifyContent: "flex-start",
}));

const WrapperStack = styled(Stack)(({ theme }) => ({
  padding: theme.spacing(3),
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
}));

const LeftSideStack = styled(Stack)(() => ({
  display: "flex",
  justifyContent: "center",
}));

const RightSideStack = styled(Stack)(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

export type CompetitionResultDetailHeaderProps = {
  candidateName: string;
  candidateAvatarUrl: string;
  competitionButton: Pick<ButtonProps, "onClick" | "children">;
};

const CompetitionResultDetailHeader: React.FC<CompetitionResultDetailHeaderProps> = props => {
  const competitionButton: ButtonProps = {
    ...props.competitionButton,
    color: "outline-only",
    variant: "text",
    size: "small",
    endIcon: <KeyboardArrowRightOutlinedIcon fontSize="small" />,
  };

  return (
    <WrapperStack direction="row">
      <LeftSideStack direction="column">
        <TextButton {...competitionButton} />
        <Stack direction="row" spacing={1} alignItems="center">
          <Avatar alt={props.candidateName} sx={{ width: "32px", height: "32px" }} src={props.candidateAvatarUrl} />
          <Typography fontSize={20} fontWeight={700} ml={1.5}>
            {props.candidateName}
          </Typography>
        </Stack>
      </LeftSideStack>
      <RightSideStack direction="row" spacing={1}></RightSideStack>
    </WrapperStack>
  );
};

CompetitionResultDetailHeader.displayName = "CompetitionResultDetailHeader";

export default CompetitionResultDetailHeader;
