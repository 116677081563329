import { proxy } from "valtio";

import type * as Types from "./types";

export const state = proxy<Types.State>({
  competition: null,
  applicant: null,
  dialogStatus: "CLOSED",
  loadingStatus: "READY",
});
