import { useChallengeFollowUpQuestionCategoryMap } from "@hireroo/app-definition/challenge";
import { useFollowUpQuestionPriorityMap } from "@hireroo/app-definition/livecoding";
import { useSystemDesignFollowUpQuestionCategoryMap } from "@hireroo/app-definition/systemDesign";
import { useTwilioConversationsContext } from "@hireroo/app-helper/hooks";
import { RemotesId } from "@hireroo/app-store/page/e/remotes_id";
import { useLanguageCode } from "@hireroo/i18n";
import { resolveLanguage } from "@hireroo/i18n/utils";
import type { Pages } from "@hireroo/presentation";
import * as React from "react";

import LiveCodingChatWindowFetchContainer from "./widget/LiveCodingChatWindow/FetchContainer";
import RemoteInterviewEvaluationFetchContainer from "./widget/RemoteInterviewEvaluation/FetchContainer";

type FollowUpSection = Exclude<Pages.RemoteInterviewForEmployeeProps["rightSidePanel"]["followUpQuestions"], undefined>["categorySections"][0];
type FollowUpQuestion = FollowUpSection["questions"][0];

type GenerateRightSidePanelPropsArgs = {
  remoteId: string;
};

export const useGenerateRightSidePanelProps = (
  args: GenerateRightSidePanelPropsArgs,
): Pages.RemoteInterviewForEmployeeProps["rightSidePanel"] => {
  const { hasUnreadMessages } = useTwilioConversationsContext();
  const currentSession = RemotesId.useActiveSession();
  const lang = useLanguageCode();
  const followUpQuestionPriorityMap = useFollowUpQuestionPriorityMap();
  const challengeFollowUpQuestionCategoryMap = useChallengeFollowUpQuestionCategoryMap();
  const systemDesignFollowUpQuestionCategoryMap = useSystemDesignFollowUpQuestionCategoryMap();

  const sections = React.useMemo((): FollowUpSection[] => {
    if (!currentSession) return [];
    const categoryQuestionMap = new Map<string, FollowUpQuestion[]>();
    currentSession.followUpQuestions.forEach(followUpQuestion => {
      const priority: FollowUpQuestion["priority"] =
        followUpQuestion.priority === "UNKNOWN"
          ? {
              kind: "LOW",
              label: followUpQuestionPriorityMap[followUpQuestion.priority],
            }
          : {
              kind: followUpQuestion.priority,
              label: followUpQuestionPriorityMap[followUpQuestion.priority],
            };
      const question: FollowUpQuestion = {
        question: resolveLanguage(followUpQuestion, lang, "question"),
        answer: resolveLanguage(followUpQuestion, lang, "modelAnswer"),
        priority: priority,
      };

      let categoryText = "";
      const categoryTypename = followUpQuestion.category.__typename;
      switch (categoryTypename) {
        case "LiveCodingChallengeFollowUpQuestionCategory":
          categoryText = challengeFollowUpQuestionCategoryMap[followUpQuestion.category.challenge];
          break;
        case "LiveCodingSystemDesignFollowUpQuestionCategory":
          categoryText = systemDesignFollowUpQuestionCategoryMap[followUpQuestion.category.systemDesign];
          break;
        default:
          throw new Error(`Invalid ${categoryTypename satisfies never}`);
      }
      if (!categoryQuestionMap.has(categoryText)) {
        categoryQuestionMap.set(categoryText, [question]);
      } else {
        categoryQuestionMap.get(categoryText)?.push(question);
      }
    });

    return Array.from(categoryQuestionMap).map(([category, questions]) => ({
      title: category,
      questions: questions,
    }));
  }, [currentSession, followUpQuestionPriorityMap, lang, challengeFollowUpQuestionCategoryMap, systemDesignFollowUpQuestionCategoryMap]);

  return {
    Evaluation: <RemoteInterviewEvaluationFetchContainer uniqueKey={args.remoteId} />,
    Chat: <LiveCodingChatWindowFetchContainer />,
    hasChatBadge: hasUnreadMessages,
    defaultTab: React.useMemo(() => {
      if (currentSession && currentSession.isInherited && currentSession.followUpQuestions.length > 0) {
        return "FOLLOW_UP_QUESTIONS";
      }
      return undefined;
    }, [currentSession]),
    followUpQuestions:
      currentSession && currentSession.followUpQuestions.length > 0
        ? {
            categorySections: sections,
          }
        : undefined,
  };
};
