import { proxySet } from "valtio/utils";

import type * as Types from "./types";

export const createDefaultState = (state?: Partial<Types.State>): Types.State => {
  return {
    componentType: state?.componentType ?? "default",
    selectableComponentTypes: proxySet(state?.selectableComponentTypes ?? ["default", "aws", "gcp", "azure"]),
    cachedViewbox: { minX: 0, minY: 0, maxX: 0, maxY: 0 },
    viewbox: { minX: 0, minY: 0, maxX: 2000, maxY: 2000 },
    aspectRatio: 1,
    scale: 1,
    elementIds: [],
    duplicateEdgeIds: [],
    selectedElementIds: [],
    elements: {},

    systemDesigns: {},
    answers: {},
    submissions: {},
    scoringItems: {},
    hints: {},

    isStartSystemDesignLoading: false,
    isGetSubmissionLoading: false,
    isGetQuestionLoading: false,
    isSubmitSystemDesignLoading: false,
    isReevaluateSystemDesignLoading: false,
    isRunSystemDesignLoading: false,
    openMiniMap: false,
    openResetConfirmDialog: false,
    ...state,
  };
};
