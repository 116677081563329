import { proxy } from "valtio";
import { proxyMap } from "valtio/utils";

import { DEFAULT_PAGER, DEFAULT_SEARCH_FILTER } from "./constants";
import type * as Types from "./types";

export const state = proxy<Types.State>({
  initializedSearchFilter: false,
  currentSearchFilter: DEFAULT_SEARCH_FILTER,
  pager: DEFAULT_PAGER,
  res: null,
  candidateMap: proxyMap(),
  candidateIds: [],
});
