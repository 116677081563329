import { useFetchIpAddress } from "@hireroo/app-helper/hooks";
import { generateEntityKey } from "@hireroo/app-helper/normalizer";
import { useTitle } from "@hireroo/app-helper/react-use";
import { Auth } from "@hireroo/app-store/essential/candidate";
import { CompetitionsResultsId } from "@hireroo/app-store/page/c/competitions_results_id";
import { InterviewNavigation } from "@hireroo/app-store/widget/c/InterviewNavigation";
import { ChallengeCodingEditor } from "@hireroo/app-store/widget/shared/ChallengeCodingEditor";
import { Snackbar } from "@hireroo/app-store/widget/shared/Snackbar";
import { getGraphqlClient } from "@hireroo/graphql/client/request";
import { useTranslation } from "@hireroo/i18n";
import type { Pages } from "@hireroo/presentation";
import * as React from "react";

import CandidateFooterContainer from "../../../../widget/v2/c/CandidateFooter/Container";
import ChallengeCodingEditorInitialContainer, {
  ChallengeCodingEditorInitialContainerProps,
} from "../../../../widget/v2/shared/ChallengeCodingEditor/InitializeContainer";
import ScreeningTestTutorialContainer from "../../../../widget/v2/shared/ScreeningTestTutorial/Container";
import SnackbarContainer from "../../../../widget/v2/shared/Snackbar/Container";
import TestAnnouncementContainer, { TestAnnouncementContainerProps } from "../../../../widget/v2/shared/TestAnnouncement/Container";
import { generateCandidateFeedbackLink } from "./privateHelper";
import CompetitionResultsIdHeaderContainer, { CompetitionResultsIdHeaderContainerProps } from "./widgets/CompetitionResultIdHeader/Container";

export type GenerateCompetitionsResultsIdPropsArgs = {
  resultId: string;
  canConnect: boolean;
};

type EntityComponentMap = Record<string, React.ReactNode>;

export const useGenerateProps = (args: GenerateCompetitionsResultsIdPropsArgs): Pages.ScreeningTestProps => {
  const { t } = useTranslation();
  const uid = Auth.useCurrentUid();
  const status = CompetitionsResultsId.useResultStatus();
  const candidateName = CompetitionsResultsId.useCandidateName();
  const entities = CompetitionsResultsId.useEntities();
  const endInterviewStatus = CompetitionsResultsId.useEndStatus();
  const endInterviewLoadingStatus = CompetitionsResultsId.useEndLoadingStatus();
  const currentEntityKey = InterviewNavigation.useCurrentSelectedEntityKey();
  const loadingStatus = InterviewNavigation.useLoadingStatus();
  const client = getGraphqlClient();
  const allQuestionsHaveBeenSubmitted = InterviewNavigation.useAllQuestionsHaveBeenSubmitted();
  const ipInfo = useFetchIpAddress();
  const metaTitle = React.useMemo((): string => {
    switch (status) {
      case "STARTED":
        return t("コンペ");
      default:
        return t("コンペ終了");
    }
  }, [status, t]);

  useTitle(metaTitle);

  const headerContainerProps: Omit<CompetitionResultsIdHeaderContainerProps, "showingMode"> = {
    onEndInterview: () => {
      CompetitionsResultsId.updateEndLoadingStatus("PENDING");
      client
        .EndResultForCompetitionsResultsId({
          input: {
            resultId: args.resultId,
          },
        })
        .then(res => {
          CompetitionsResultsId.setResult(res.endResult);
        })
        .catch(() => {
          Snackbar.notify({
            severity: "error",
            message: t("コンペの終了に失敗しました。お手数ですがヘルプセンターより運営にお問い合わせください。"),
          });
        })
        .finally(() => {
          CompetitionsResultsId.updateEndLoadingStatus("READY");
        });
    },
    willBlockEndInterview: !allQuestionsHaveBeenSubmitted,
    disabledFinishButton: endInterviewLoadingStatus === "PENDING",
    hideMessageForCandidate: true,
  };

  const ContentMap: EntityComponentMap = React.useMemo<EntityComponentMap>(() => {
    return entities.reduce<EntityComponentMap>((all, entity) => {
      const key = generateEntityKey(entity);
      switch (entity.__typename) {
        case "ChallengeEntity": {
          if (ipInfo?.ipAddress && ipInfo?.geolocation) {
            ChallengeCodingEditor.setIpAddress(ipInfo.ipAddress);
            ChallengeCodingEditor.setGeolocation(ipInfo.geolocation);
          }
          const challengeCodingEditor: ChallengeCodingEditorInitialContainerProps = {
            uid: uid ?? "",
            entityId: entity.challengeEntityId,
            editorKind: "CANDIDATE",
            interviewKind: "INTERVIEW",
            displayName: candidateName,
            enableBrowserEventDetector: true,
            enabledWebSearch: false,
            enabledChatGPT: false,
          };
          return {
            ...all,
            [key]: <ChallengeCodingEditorInitialContainer key={key} {...challengeCodingEditor} />,
          };
        }
        default: {
          return all;
        }
      }
    }, {});
  }, [entities, ipInfo, uid, candidateName]);

  const candidateFeedbackLink: TestAnnouncementContainerProps["formLink"] = {
    href: generateCandidateFeedbackLink(args.resultId),
    children: t("アンケートに回答"),
  };

  if (endInterviewStatus) {
    return {
      layout: {
        loading: loadingStatus === "LOADING",
        NotificationBanner: null,
        Header: <CompetitionResultsIdHeaderContainer {...headerContainerProps} showingMode="READONLY" />,
        Footer: <CandidateFooterContainer />,
        Snackbar: <SnackbarContainer />,
        Tutorial: null,
      },
      Content: <TestAnnouncementContainer kind={endInterviewStatus} formLink={candidateFeedbackLink} />,
    };
  }

  switch (status) {
    case "STARTED": {
      return {
        layout: {
          loading: loadingStatus === "LOADING",
          NotificationBanner: null,
          Header: <CompetitionResultsIdHeaderContainer {...headerContainerProps} showingMode="RUNNING" />,
          Footer: <CandidateFooterContainer />,
          Snackbar: <SnackbarContainer />,
          Tutorial: <ScreeningTestTutorialContainer />,
        },
        Content: args.canConnect && currentEntityKey ? ContentMap[currentEntityKey] : undefined,
      };
    }
    case "COMPLETED": {
      return {
        layout: {
          loading: loadingStatus === "LOADING",
          NotificationBanner: null,
          Header: <CompetitionResultsIdHeaderContainer {...headerContainerProps} showingMode="READONLY" />,
          Footer: <CandidateFooterContainer />,
          Snackbar: <SnackbarContainer />,
          Tutorial: null,
        },
        Content: <TestAnnouncementContainer kind="FINISH_COMPETITION" formLink={candidateFeedbackLink} />,
      };
    }
    default:
      return {
        layout: {
          loading: loadingStatus === "LOADING",
          NotificationBanner: null,
          Header: <CompetitionResultsIdHeaderContainer {...headerContainerProps} showingMode="READONLY" />,
          Footer: <CandidateFooterContainer />,
          Snackbar: <SnackbarContainer />,
          Tutorial: <ScreeningTestTutorialContainer />,
        },
        Content: <TestAnnouncementContainer kind="COMPETITION_HAS_ALREADY_BEEN_COMPLETED" formLink={candidateFeedbackLink} />,
      };
  }
};
