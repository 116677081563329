import { TestSearchForm as TestSearchFormValidator } from "@hireroo/validator";
import { zodResolver } from "@hookform/resolvers/zod";
import * as React from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";

import SearchForm, { SearchFormProps } from "../../../../modules/SearchForm/SearchForm";
import * as PrivateHelper from "./privateHelper";

export type TestSearchFormProps = {
  disabled: boolean;
  screeningField: Pick<PrivateHelper.MultiChoiceFieldProps, "options">;
  tagsField: Pick<PrivateHelper.TagFieldProps, "selectableTags">;
  defaultValues: TestSearchFormValidator.TestSearchQuerySchema;
  onChange: SubmitHandler<TestSearchFormValidator.TestSearchQuerySchema>;
  onReset?: () => void;
  AdditionalFilter?: React.ReactNode;
};

const TestSearchForm: React.FC<TestSearchFormProps> = props => {
  const { onChange } = props;
  const validateSchema = TestSearchFormValidator.useTestSearchQuery();
  const methods = useForm<TestSearchFormValidator.TestSearchQuerySchema>({
    resolver: zodResolver(validateSchema),
    defaultValues: {
      ...props.defaultValues,
    },
  });
  const textFilterField = PrivateHelper.useTextFilterField({ enabledScreeningFeature: true });
  const screeningField = PrivateHelper.useScreeningField(props.screeningField);
  const tagsField = PrivateHelper.useTagsField(props.tagsField);
  const [reset, setReset] = React.useState(false);

  React.useEffect(() => {
    const subscriber = methods.watch(() => {
      methods.handleSubmit(onChange, console.warn)();
    });
    return () => {
      subscriber.unsubscribe();
    };
  }, [methods, onChange]);

  const searchFormProps: SearchFormProps = {
    kind: "MULTI_LINE",
    variant: "INHERIT",
    disabled: props.disabled,
    textFilter: textFilterField,
    fields: React.useMemo(() => [tagsField, screeningField].flatMap(v => (v ? [v] : [])), [screeningField, tagsField]),
    AdditionalFilter: <React.Fragment key={`reset-${reset}`}>{props.AdditionalFilter}</React.Fragment>,
    onReset: () => {
      const resetValues: TestSearchFormValidator.TestSearchQuerySchema = {
        tags: [],
        score: {
          min: 0,
          max: 100,
        },
        questionVariants: [],
        statuses: [],
        createdBy: [],
        result: null,
        rank: [],
        screeningIds: [],
        textFilter: "",
        suspiciousDegrees: [],
      };
      methods.reset(resetValues);
      props.onReset?.();
      setReset(prev => !prev);
    },
  };
  return (
    <FormProvider {...methods}>
      <SearchForm {...searchFormProps} />
    </FormProvider>
  );
};

TestSearchForm.displayName = "TestSearchForm";

export default TestSearchForm;
