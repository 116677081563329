import { ENTITY_REPORT_TOP } from "@hireroo/app-helper/entity";
import { Company } from "@hireroo/app-store/essential/employee";
import { CompetitionResultReport } from "@hireroo/app-store/widget/e/CompetitionResultReport";
import { formatScore } from "@hireroo/formatter/score";
import * as Graphql from "@hireroo/graphql/client/urql";
import { useCurrentLanguage } from "@hireroo/i18n";
import { Widget } from "@hireroo/presentation";
import { useHelpCenterNavigate } from "@hireroo/router/hooks";
import * as React from "react";

import ChallengeTestReportFetchContainer from "../ChallengeTestReport/FetchContainer";
import { generateSmoothTargetId } from "./privateHelper";
import { useGenerateEntityScoreSelector } from "./useGenerateEntityScoreSelector";
import { useGenerateFooterProps } from "./useGenerateFooterProps";

export type GenerateCompetitionResultReportPropsArgs = {};

type DisplayScoreArea = Exclude<Widget.CompetitionResultReportProps["summary"]["scorePieChart"], undefined>["displayScoreArea"];

type Report = Widget.CompetitionResultReportProps["Reports"][0];

export const useGenerateProps = (_args: GenerateCompetitionResultReportPropsArgs): Widget.CompetitionResultReportProps => {
  const activeCompanyId = Company.useStrictActiveCompanyId();
  const lang = useCurrentLanguage();
  const helpCenterNavigate = useHelpCenterNavigate(lang);
  const footer = useGenerateFooterProps();
  const entityScoreSelector = useGenerateEntityScoreSelector();
  const selectedEntityId = CompetitionResultReport.useSelectedEntityId();
  const result = CompetitionResultReport.useResult();
  const isHireRooCompany = activeCompanyId === Number(import.meta.env.VITE_HIREROO_COMPANY_ID);

  const Reports = React.useMemo(() => {
    return result.entities.map((entity): Report => {
      const smoothScrollTargetId = generateSmoothTargetId(entity);
      if (entity.__typename === "ChallengeEntity") {
        return {
          id: CompetitionResultReport.generateUniqueEntityId(entity),
          smoothScrollTargetId,
          Content: activeCompanyId && (
            <ChallengeTestReportFetchContainer
              canShowPlayback={true}
              canShowStatistics={true}
              canShowFollowUpQuestions={isHireRooCompany}
              canShowCheatDetectionSection={true}
              canShowPasteAndTabStatistics={true}
              canShowFeedbackSuspiciousDegree={true}
              showUsedHintSection={false} // コンペではヒントを使用しない
              companyId={activeCompanyId}
              challengeId={entity.challengeEntityId}
            />
          ),
        };
      } else {
        throw new Error("unknown entity type");
      }
    });
  }, [activeCompanyId, result.entities, isHireRooCompany]);

  const displayScoreAreaProps = React.useMemo<DisplayScoreArea>((): DisplayScoreArea => {
    if (result.status !== "CONFIRMED") {
      return { kind: "SCORING" };
    }

    return {
      kind: "WITH_RANKING",
      ranking: result.rank,
      participants: result.numSubset,
    };
  }, [result.status, result.rank, result.numSubset]);

  return {
    summary: {
      scorePieChart: {
        score: formatScore(result.totalScore),
        displayScoreArea: displayScoreAreaProps,
      },
      status: result.status !== "CONFIRMED" ? "NOT_READY" : "READY",
      entityScoreSelector: entityScoreSelector,
      helpLink: {
        onClick: () => {
          helpCenterNavigate("CHECK_REPORT", { _blank: true });
        },
      },
      showSuspiciousBehaviorText: result.suspiciousDegree === Graphql.SpotSuspiciousDegree.Suspicious,
    },
    footer: footer,
    Reports: Reports,
    selectedEntityId: selectedEntityId || "",
    targetElementId: ENTITY_REPORT_TOP,
  };
};
