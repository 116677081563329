import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import * as React from "react";

import CandidateDetailHeader, { CandidateDetailHeaderProps } from "../../ms-components/Auth/CandidateDetailHeader/CandidateDetailHeader";
import SideSection, { SideSectionProps } from "./parts/SideSection/SideSection";

export type CandidateDetailProps = {
  header: CandidateDetailHeaderProps;
  sideSection: SideSectionProps;
  CompetitionHistory: React.ReactNode;
};

const CandidateDetail: React.FC<CandidateDetailProps> = props => {
  return (
    <Box sx={{ width: "100%" }}>
      <CandidateDetailHeader {...props.header} />
      <Grid container>
        <Grid item xs={9}>
          {props.CompetitionHistory}
        </Grid>
        <Grid item xs={3}>
          <SideSection {...props.sideSection} />
        </Grid>
      </Grid>
    </Box>
  );
};

CandidateDetail.displayName = "CandidateDetail";

export default CandidateDetail;
