import { useTranslation } from "@hireroo/i18n";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Box from "@mui/material/Box";
import Button, { ButtonProps } from "@mui/material/Button";
import Collapse from "@mui/material/Collapse";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import * as React from "react";

import DifficultyStars, { DifficultyStarsProps } from "../../../../../../modules/DifficultyStars/DifficultyStars";
import QuestionArchivedBadge from "../../../../../../ms-components/Question/QuestionArchivedBadge/QuestionArchivedBadge";
import Link, { LinkProps } from "../../../../../../primitive/Link/Link";
import QuestionDetailCollapse, { QuestionDetailCollapseProps } from "../QuestionDetailCollapse/QuestionDetailCollapse";

const StyledLink = styled(Link)(({ theme }) => ({
  fontSize: 14,
  fontWeight: 700,
  color: theme.palette.text.primary,
  marginRight: theme.spacing(2),
}));

const TITLE_CELL_CLASS_NAME = "__title_cell__";

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:last-child tr": {
    borderBottom: "none",
  },
  borderTop: "1px solid",
  borderBottom: "1px solid",
  borderColor: theme.palette.Other.Divider,
  [`.${TITLE_CELL_CLASS_NAME}`]: {
    paddingLeft: "32px",
  },
}));

const StyledTableCell = styled(TableCell)(() => ({
  border: "none",
}));

const StyledButton = styled(Button)(() => ({
  textTransform: "none",
}));

export type QuestionOverviewTableRowProps = {
  title: Pick<LinkProps, "href" | "children">;
  variant: string;
  selectedContents?: string[];
  difficultyStars: DifficultyStarsProps;
  isChild?: boolean;
  isArchived: boolean;
  details?: QuestionDetailCollapseProps;
};

const QuestionOverviewTableRow: React.FC<QuestionOverviewTableRowProps> = props => {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const detailButton: ButtonProps | undefined = props.details
    ? {
        children: t("深堀り質問"),
        onClick: () => {
          setOpen(prev => !prev);
        },
        endIcon: open ? <ExpandLess /> : <ExpandMore />,
        size: "small",
        variant: "text",
      }
    : undefined;
  return (
    <>
      <StyledTableRow sx={{ width: "100%" }}>
        <StyledTableCell className={props.isChild ? TITLE_CELL_CLASS_NAME : undefined} sx={{ width: "70%" }}>
          <Stack direction="column">
            <Box display="flex" alignItems="center">
              <StyledLink {...props.title} underline="always" target="_blank" color="textPrimary" />
              {props.isArchived && <QuestionArchivedBadge />}
            </Box>
            <Typography fontSize={14} color="textSecondary">
              {props.variant}
            </Typography>
          </Stack>
        </StyledTableCell>
        <StyledTableCell sx={{ width: "15%" }}>
          <DifficultyStars {...props.difficultyStars} />
        </StyledTableCell>
        <StyledTableCell sx={{ width: "15%" }}>{detailButton && <StyledButton {...detailButton} color="outline-only" />}</StyledTableCell>
      </StyledTableRow>
      {props.details && (
        <StyledTableRow sx={{ border: "none" }}>
          <StyledTableCell colSpan={4} sx={{ padding: 0 }}>
            <Collapse in={open} sx={{ display: "block" }} unmountOnExit>
              <Box p={2} width="100%">
                <QuestionDetailCollapse {...props.details} />
              </Box>
            </Collapse>
          </StyledTableCell>
        </StyledTableRow>
      )}
    </>
  );
};

QuestionOverviewTableRow.displayName = "QuestionOverviewTableRow";

export default QuestionOverviewTableRow;
