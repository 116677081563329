import { App, Company } from "@hireroo/app-store/essential/employee";
import { RemoteCreateDialog } from "@hireroo/app-store/widget/e/RemoteCreateDialog";
import * as Graphql from "@hireroo/graphql/client/urql";
import type { Widget } from "@hireroo/presentation";
import * as React from "react";

import ScreeningTestListSingleChoiceFetchContainer from "../ScreeningTestListSingleChoice/FetchContainer";

export type GenerateScreeningTestListForRemoteContentPropsArgs = {};

export const useGenerateProps = (_args: GenerateScreeningTestListForRemoteContentPropsArgs): Widget.ScreeningTestListForRemoteContentProps => {
  const initialized = RemoteCreateDialog.useInitialized();
  const appStatus = App.useStatus();
  const activeCompanyId = Company.useStrictActiveCompanyId();
  const [result] = Graphql.useGetFilterFieldsForRemoteCreateDialogQuery({
    variables: {
      spotTagsInput: {
        companyId: activeCompanyId,
        size: 100,
        withCount: false,
      },
      fieldsForFilter: {
        companyId: activeCompanyId,
        ignoreError: true,
        screening: true,
        creator: false,
        viewer: false,
      },
    },
    pause: appStatus !== "INITIALIZED",
  });

  return {
    active: initialized,
    search: {
      disabled: !initialized || result.fetching,
      screeningField: {
        options: React.useMemo(() => {
          return (result.data?.fieldsForFilter?.screenings ?? []).map(screening => ({
            value: screening.screeningId,
            displayName: screening.name,
          }));
        }, [result.data?.fieldsForFilter]),
      },
      tagsField: {
        selectableTags: React.useMemo(() => {
          return (result.data?.spotTagsByCompanyId?.tags ?? []).map(tag => ({ displayName: tag.name }));
        }, [result.data?.spotTagsByCompanyId?.tags]),
      },
      defaultValues: {
        tags: [],
        score: {
          min: 0,
          max: 100,
        },
        questionVariants: [],
        statuses: [],
        createdBy: [],
        result: null,
        rank: [],
        textFilter: "",
        screeningIds: [],
        suspiciousDegrees: [],
      },
      onChange: fields => {
        RemoteCreateDialog.updateFilters({
          name: fields.textFilter,
          candidateEmail: fields.textFilter,
          candidateName: fields.textFilter,
          screeningIds: fields.screeningIds,
          tagNames: fields.tags.map(tag => tag.displayName),
        });
      },
    },
    List: <ScreeningTestListSingleChoiceFetchContainer />,
  };
};
