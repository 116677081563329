import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import * as React from "react";

import TestSearchForm, { TestSearchFormProps } from "./parts/TestSearchForm/TestSearchForm";

export type ScreeningTestListForRemoteContentProps = {
  active: boolean;
  search: TestSearchFormProps;
  List: React.ReactNode;
};

const ScreeningTestListForRemoteContent: React.FC<ScreeningTestListForRemoteContentProps> = props => {
  return (
    <Box>
      <Stack spacing={2}>
        <TestSearchForm key={props.active ? "ACTIVE" : "INACTIVE"} {...props.search} />
        {props.List}
      </Stack>
    </Box>
  );
};

ScreeningTestListForRemoteContent.displayName = "ScreeningTestListForRemoteContent";

export default ScreeningTestListForRemoteContent;
