import { useTranslation } from "@hireroo/i18n";
import { Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import React from "react";

import LeaderboardTableRow, { LeaderboardTableRowProps } from "../LeaderboardTableRow/LeaderboardTableRow";

const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  backgroundColor: theme.palette["Background/Paper"].p1,
  borderRadius: "16px",
  position: "relative",
  maxHeight: "600px",
  overflow: "auto",
}));

const HeaderCell = styled(TableCell)(({ theme }) => ({
  color: "rgba(255, 255, 255, 0.7)",
  borderBottom: "1px solid rgba(255, 255, 255, 0.12)",
  padding: "8px 16px",
  fontSize: "12px",
  letterSpacing: "0.1em",
  textTransform: "uppercase",
  fontFamily: "Noto Sans JP",
  backgroundColor: theme.palette["Background/Paper"].p1,
}));

const RankHeaderCell = styled(HeaderCell)({
  width: "80px",
  textAlign: "center",
});

const NameHeaderCell = styled(HeaderCell)({
  flex: 1,
});

const TimeHeaderCell = styled(HeaderCell)({
  width: "100px",
  textAlign: "center",
});

const ScoreHeaderCell = styled(HeaderCell)({
  width: "80px",
  textAlign: "center",
});

export type LeaderboardTableProps = {
  items: LeaderboardTableRowProps[];
};

const LeaderboardTable: React.FC<LeaderboardTableProps> = ({ items }) => {
  const { t } = useTranslation();

  if (items.length === 0) {
    return (
      <Box sx={{ width: "100%" }} p={2}>
        <Typography variant="body2" textAlign="center" color="textSecondary">
          {t("参加者がいませんでした。")}
        </Typography>
      </Box>
    );
  }

  return (
    <StyledTableContainer>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <RankHeaderCell>{t("順位")}</RankHeaderCell>
            <NameHeaderCell>{t("参加者")}</NameHeaderCell>
            <TimeHeaderCell>{t("経過時間")}</TimeHeaderCell>
            <ScoreHeaderCell>{t("スコア")}</ScoreHeaderCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {items.map(item => (
            <LeaderboardTableRow key={item.id} {...item} />
          ))}
        </TableBody>
      </Table>
    </StyledTableContainer>
  );
};

LeaderboardTable.displayName = "LeaderboardTable";

export default LeaderboardTable;
