import { EvaluationMetricListForRemote } from "@hireroo/app-store/widget/e/EvaluationMetricListForRemote";
import type { Widget } from "@hireroo/presentation";
import * as React from "react";

type Item = Widget.EvaluationMetricListForRemoteProps["items"][0];

export type GenerateEvaluationMetricListForRemotePropsArgs = {
  onChange?: Widget.EvaluationMetricListForRemoteProps["onChange"];
  filterMetricIds?: number[];
};

export const useGenerateProps = (args: GenerateEvaluationMetricListForRemotePropsArgs): Widget.EvaluationMetricListForRemoteProps => {
  const metrics = EvaluationMetricListForRemote.useMetrics();
  const pagination = EvaluationMetricListForRemote.usePagination();

  const items = React.useMemo((): Item[] => {
    return metrics
      .filter(metric => !args.filterMetricIds || !args.filterMetricIds.includes(metric.metricId))
      .map((metric): Item => {
        return {
          id: metric.id,
          title: metric.title,
          description: metric.description,
        };
      });
  }, [args.filterMetricIds, metrics]);

  return {
    items: items,
    onChange: args.onChange,
    pagination: {
      count: pagination.count,
      rowsPerPage: pagination.size,
      onRowsPerPageChange: event => {
        const newSize = parseInt(event.target.value, 10);
        EvaluationMetricListForRemote.updateSize(newSize);
      },
      page: pagination.page,
      onPageChange: (_, newPage) => {
        EvaluationMetricListForRemote.updatePage(newPage);
        EvaluationMetricListForRemote.resetSelectedMetricIds();
      },
    },
  };
};
