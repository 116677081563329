import { useTranslation } from "@hireroo/i18n";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import ScreenSearchDesktopIcon from "@mui/icons-material/ScreenSearchDesktop";
import { useTheme } from "@mui/material";
import Button, { type ButtonProps } from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";

const StyledButton = styled(Button)(({ theme }) => ({
  borderRadius: "8px",
  border: "2px dashed",
  borderColor: theme.palette["Other"]["Divider"],
  "&:hover": {
    backgroundColor: theme.palette["Gray/Shades"].p8,
  },
  "&:active": {
    backgroundColor: theme.palette["Gray/Shades"].p8,
  },
  padding: theme.spacing(4),
  width: "284px",
  height: "128px",
}));

export type CreateOptionContentProps = {
  createNewButton: ButtonProps;
  selectFromTestButton: ButtonProps;
};

const CreateOptionContent: React.FC<CreateOptionContentProps> = props => {
  const { t } = useTranslation();
  const theme = useTheme();
  const createNewButtonProps: ButtonProps = {
    ...props.createNewButton,
    children: (
      <Stack spacing={1} justifyContent="center" alignItems="center">
        <AddCircleOutlineIcon htmlColor={theme.palette.text.primary} />
        <Typography color="textPrimary">{t("新規作成")}</Typography>
      </Stack>
    ),
  };
  const selectExistingButton: ButtonProps = {
    ...props.selectFromTestButton,
    children: (
      <Stack spacing={1} justifyContent="center" alignItems="center">
        <ScreenSearchDesktopIcon htmlColor={theme.palette.text.primary} />
        <Typography color="textPrimary">{t("完了済みテストから作成")}</Typography>
      </Stack>
    ),
  };
  return (
    <Stack direction="row" spacing={3} width="100%" minHeight="400px" justifyContent="center" alignItems="center">
      <StyledButton {...createNewButtonProps} />
      <StyledButton {...selectExistingButton} />
    </Stack>
  );
};

CreateOptionContent.displayName = "CreateOptionContent";

export default CreateOptionContent;
