import { App } from "@hireroo/app-store/essential/candidate";
import { Competitions } from "@hireroo/app-store/page/c/competitions";
import * as Graphql from "@hireroo/graphql/client/urql";
import { Widget } from "@hireroo/presentation";
import { withErrorBoundary } from "@sentry/react";
import React from "react";

import CompetitionCardListContainer, { CompetitionCardListContainerProps } from "./Container";

export type CompetitionCardListFetchContainerProps = {};

const CompetitionCardListFetchContainer: React.FC<CompetitionCardListFetchContainerProps> = () => {
  const appStatus = App.useStatus();
  const initialized = Competitions.useInitialized();
  const currentSearchFilter = Competitions.useCurrentSearchFilter();
  const sortParams = Competitions.useSortParams();
  const offset = Competitions.useOffset();
  const [result, refresh] = Graphql.useListCompetitionsForCandidateCompetitionsQuery({
    variables: {
      filters: {
        title: currentSearchFilter.textFilter.length > 0 ? currentSearchFilter.textFilter : undefined,
        statuses: currentSearchFilter.statuses.slice(),
        maxMinSalary: currentSearchFilter.minSalary.max !== Competitions.MIN_SALARY.MAX ? currentSearchFilter.minSalary.max : undefined,
        minMinSalary: currentSearchFilter.minSalary.min !== Competitions.MIN_SALARY.MIN ? currentSearchFilter.minSalary.min : undefined,
        onlyCurrentCompany: currentSearchFilter.selfHostedOnly,
      },
      sortMethod: sortParams.sortMethod,
      isDescending: sortParams.isDescending,
      size: 30,
      offset: offset,
    },
    pause: appStatus !== "INITIALIZED",
    requestPolicy: "cache-and-network",
  });

  React.useEffect(() => {
    if (result.data) {
      Competitions.initialize(result.data.competitions, offset);
    }
  }, [result.data, offset]);

  if (result.error) {
    return <Widget.RefreshPanel refresh={refresh} />;
  }
  if (appStatus !== "INITIALIZED" || !initialized) {
    return <Widget.Loading kind="CENTER_%" />;
  }

  const containerProps: CompetitionCardListContainerProps = {
    loading: result.fetching,
  };

  return <CompetitionCardListContainer {...containerProps} />;
};

CompetitionCardListFetchContainer.displayName = "CompetitionCardListFetchContainer";

export default withErrorBoundary(CompetitionCardListFetchContainer, {});
