import { useTranslation } from "@hireroo/i18n";
import { useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import * as React from "react";

import CandidateTableRow, { CandidateTableRowProps } from "../CandidateTableRow/CandidateTableRow";

const StyledTableHeaderCell = styled(TableCell)(({ theme }) => ({
  color: theme.palette.text.secondary,
  fontWeight: "normal",
  fontSize: "12px",
  border: "none",
  paddingTop: theme.spacing(0.5),
  paddingBottom: theme.spacing(0.5),
  height: 40,
  wordBreak: "keep-all",
  whiteSpace: "nowrap",
}));

export type CandidateTableProps = {
  items: CandidateTableRowProps[];
};

const CandidateTable: React.FC<CandidateTableProps> = props => {
  const theme = useTheme();
  const { t } = useTranslation();

  if (props.items.length === 0) {
    return (
      <Box sx={{ width: "100%" }} p={2}>
        <Typography variant="body2" textAlign="center" color="textSecondary">
          {t("候補者が見つかりませんでした。")}
        </Typography>
      </Box>
    );
  }

  return (
    <TableContainer component="div">
      <Table component="div" sx={{ display: "table" }}>
        <TableHead component="div" sx={{ display: "table-header-group" }}>
          <TableRow sx={{ borderTop: "1px solid", borderBottom: "1px solid", borderColor: theme.palette["Gray/Shades"].p8 }} component="div">
            <StyledTableHeaderCell align="left" component="div">
              {t("候補者")}
            </StyledTableHeaderCell>
            <StyledTableHeaderCell align="center" component="div" width="100px">
              {t("希望年収")}
            </StyledTableHeaderCell>
            <StyledTableHeaderCell align="center" component="div" width="100px">
              {t("転職意欲")}
            </StyledTableHeaderCell>
            <StyledTableHeaderCell align="center" component="div" width="100px">
              {t("平均スコア")}
            </StyledTableHeaderCell>
            <StyledTableHeaderCell align="center" component="div" width="100px">
              {t("平均ランク")}
            </StyledTableHeaderCell>
            <StyledTableHeaderCell align="right" component="div">
              {/* for MoreButton */}
            </StyledTableHeaderCell>
          </TableRow>
        </TableHead>
        <TableBody component="div">
          {props.items.map(item => (
            <CandidateTableRow key={item.id} {...item} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

CandidateTable.displayName = "CandidateTable";

export default CandidateTable;
