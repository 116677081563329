import BusinessIcon from "@mui/icons-material/Business";
import PersonIcon from "@mui/icons-material/Person";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import * as React from "react";

type Kind = "COMPANY" | "USER";

export type SideSectionItemProps = {
  title: string;
  text: string;
  imgUrl?: string;
  kind: Kind;
};

const SideSectionItem: React.FC<SideSectionItemProps> = props => {
  const DefaultIcon: Record<Kind, React.ReactElement> = {
    COMPANY: <BusinessIcon fontSize="small" />,
    USER: <PersonIcon fontSize="small" />,
  };
  return (
    <Stack>
      <Typography fontSize={12} color="textSecondary" fontWeight={700} px={1}>
        {props.title}
      </Typography>
      <Stack direction="row" spacing={1} alignItems="center" p={2}>
        <Avatar sx={{ width: 24, height: 24 }} alt={props.text} src={props.imgUrl}>
          {DefaultIcon[props.kind]}
        </Avatar>
        <Typography sx={{ wordBreak: "keep-all" }}>{props.text}</Typography>
      </Stack>
    </Stack>
  );
};

SideSectionItem.displayName = "SideSectionItem";

export default SideSectionItem;
