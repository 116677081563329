import { SignInOrSignUp } from "@hireroo/app-store/essential/shared";
import type { Pages } from "@hireroo/presentation";

import SnackbarContainer from "../../../../widget/v2/shared/Snackbar/Container";
import SignInContainer from "./widgets/CandidateSignIn/Container";

export type GenerateSignInPropsArgs = {};

export const useGenerateProps = (_args: GenerateSignInPropsArgs): Pages.SignInSignUpProps => {
  const signInStatus = SignInOrSignUp.useSignInStatus();

  return {
    layout: {
      loading: signInStatus === "VERIFYING",
      Bottom: <SnackbarContainer />,
    },
    children: <SignInContainer />,
  };
};
