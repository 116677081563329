import { useTranslation } from "@hireroo/i18n";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";

import CandidateLayout, { CandidateLayoutProps } from "../../layouts/CandidateLayout/CandidateLayout";

const HeadingTypography = styled(Typography)(() => ({
  fontWeight: "bold",
  fontSize: 24,
  whiteSpace: "nowrap",
  wordBreak: "keep-all",
}));

export type CompetitionListForCandidateProps = {
  layout: CandidateLayoutProps;
  children: React.ReactNode;
  SearchForm: React.ReactNode;
};

const CompetitionListForCandidate: React.FC<CompetitionListForCandidateProps> = props => {
  const { t } = useTranslation();

  const layoutProps: CandidateLayoutProps = {
    ...props.layout,
  };
  return (
    <CandidateLayout {...layoutProps}>
      <Box p={3}>
        <Stack direction="row" justifyContent="space-between" alignItems="center" mb={2}>
          <HeadingTypography>{t("コンペ一覧")}</HeadingTypography>
        </Stack>
        <Stack>{props.SearchForm}</Stack>
        {props.children}
      </Box>
    </CandidateLayout>
  );
};

CompetitionListForCandidate.displayName = "CompetitionListForCandidate";

export default CompetitionListForCandidate;
