import { App, Auth } from "@hireroo/app-store/essential/candidate";
import { CandidateProfile } from "@hireroo/app-store/widget/c/CandidateProfile";
import * as Graphql from "@hireroo/graphql/client/urql";
import { Widget } from "@hireroo/presentation";
import { withErrorBoundary } from "@sentry/react";
import React from "react";

import CandidateProfileContainer, { CandidateProfileContainerProps } from "./Container";

export type CandidateProfileFetchContainerProps = {};

const CandidateProfileFetchContainer: React.FC<CandidateProfileFetchContainerProps> = () => {
  const appStatus = App.useStatus();
  const uid = Auth.useCurrentUid();
  const initialized = CandidateProfile.useInitialized();

  const [result, refresh] = Graphql.useGetCandidateForCandidateProfileQuery({
    variables: {
      candidateId: uid,
    },
    pause: appStatus !== "INITIALIZED",
    requestPolicy: "cache-and-network",
  });

  React.useEffect(() => {
    if (result.data) {
      CandidateProfile.setCurrentUser(result.data.candidate);
    }

    return () => {
      CandidateProfile.clear();
    };
  }, [result.data]);

  if (result.error) {
    return <Widget.RefreshPanel refresh={refresh} />;
  }
  if (result.fetching || appStatus !== "INITIALIZED" || !initialized) {
    return <Widget.Loading kind="CENTER_%" />;
  }

  const containerProps: CandidateProfileContainerProps = {};

  return <CandidateProfileContainer {...containerProps} />;
};

CandidateProfileFetchContainer.displayName = "CandidateProfileFetchContainer";

export default withErrorBoundary(CandidateProfileFetchContainer, {});
