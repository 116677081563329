import { useSnapshot } from "valtio";

import { state } from "./State";

export const useSnapshotState = () => {
  return useSnapshot(state);
};

export const useInitializeError = () => {
  const snapshot = useSnapshotState();
  return snapshot.initializeError;
};

export const useCurrentUid = () => {
  const snapshot = useSnapshotState();
  if (!snapshot.user) {
    throw new Error("User is not initialized");
  }

  return snapshot.user.uid;
};

export const useCurrentUser = () => {
  const snapshot = useSnapshotState();
  if (!snapshot.user) {
    throw new Error("User is not initialized");
  }
  return snapshot.user;
};

export const useCurrentCandidate = () => {
  const snapshot = useSnapshotState();
  if (!snapshot.candidate) {
    throw new Error("Candidate is not initialized");
  }
  return snapshot.candidate;
};
