import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import Toolbar from "@mui/material/Toolbar";
import * as React from "react";

import AppBar from "./parts/AppBar/AppBar";
import NavigationBreadcrumbs, { NavigationBreadcrumbsProps } from "./parts/NavigationBreadcrumbs/NavigationBreadcrumbs";

const HEADER_HEIGHT = 48;

const StyledAppBar = styled(AppBar)(() => ({
  minHeight: HEADER_HEIGHT,
  height: HEADER_HEIGHT,
  borderTop: "none",
}));

const StyledToolbar = styled(Toolbar)(() => ({
  height: HEADER_HEIGHT,
  minHeight: HEADER_HEIGHT,
  "@media (min-width:600px)": { minHeight: `${HEADER_HEIGHT}px` },
}));

export type AppBarLayoutProps = {
  navigationBreadcrumbs?: NavigationBreadcrumbsProps;
  HeaderRightContent: React.ReactNode;
  openSidebar: boolean;
  /**
   * default fixed
   */
  headerPosition?: "fixed" | "static";
  topMargin: string;
};

const AppBarLayout: React.FC<AppBarLayoutProps> = props => {
  const { openSidebar, headerPosition = "fixed" } = props;
  return (
    <StyledAppBar
      sx={{ position: headerPosition === "static" ? "static" : "fixed", marginTop: props.topMargin }}
      open={openSidebar}
      elevation={2}
    >
      <StyledToolbar>
        <Box>{props.navigationBreadcrumbs && <NavigationBreadcrumbs {...props.navigationBreadcrumbs} />}</Box>
        <Box sx={{ flexGrow: 1 }} />
        <Box>{props.HeaderRightContent}</Box>
      </StyledToolbar>
    </StyledAppBar>
  );
};

AppBarLayout.displayName = "AppBarLayout";

export default AppBarLayout;
