import { CompetitionHistory } from "@hireroo/app-store/widget/e/CompetitionHistory";
import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";
import { useSearchParams } from "react-router-dom";

import CompetitionHistoryContainer, { CompetitionHistoryContainerProps } from "./Container";
import * as PrivateHelper from "./privateHelper";

export type CompetitionHistoryInitialContainerProps = {
  candidateId: string;
};

const CompetitionHistoryInitialContainer: React.FC<CompetitionHistoryInitialContainerProps> = props => {
  const [searchParams] = useSearchParams();
  const initializedFilter = CompetitionHistory.useInitializedFilter();

  React.useEffect(() => {
    CompetitionHistory.initializeFilter({
      page: PrivateHelper.convertStringToPage(searchParams.get(CompetitionHistory.QueryKeys.PAGE)),
      size: PrivateHelper.convertStringToSize(searchParams.get(CompetitionHistory.QueryKeys.SIZE)),
      sortParams: PrivateHelper.convertStringToSortFieldValue(searchParams.get(CompetitionHistory.QueryKeys.SORT)),
    });
  }, [searchParams]);

  if (!initializedFilter) {
    return <Widget.Loading kind="CENTER_%" />;
  }

  const generatePropsArgs: CompetitionHistoryContainerProps = {
    candidateId: props.candidateId,
  };

  return (
    <ErrorBoundary>
      <CompetitionHistoryContainer {...generatePropsArgs} />
    </ErrorBoundary>
  );
};

CompetitionHistoryInitialContainer.displayName = "CompetitionHistoryInitialContainer";

export default withErrorBoundary(CompetitionHistoryInitialContainer, {});
