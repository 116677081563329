import { useCompetitionStatusMap } from "@hireroo/app-definition/competition";
import { translateSalary } from "@hireroo/app-helper/competition";
import { CompetitionsIdDetail } from "@hireroo/app-store/page/e/competitions_id_detail";
import * as TimeFormatter from "@hireroo/formatter/time";
import { useLanguageCode, useTranslation } from "@hireroo/i18n";
import type { Widget } from "@hireroo/presentation";

export const useGenerateHeaderProps = (): Widget.CompetitionIdDetailProps["header"] => {
  const { t } = useTranslation();
  const competition = CompetitionsIdDetail.useCompetition();
  const lang = useLanguageCode();
  const statusMap = useCompetitionStatusMap();

  return {
    company: {
      name: competition.hostCompany?.name || "",
      src: competition.hostCompany?.logo || undefined,
    },
    title: competition.title,
    duration: `${TimeFormatter.unixToDatetimeFormat(competition.startsAtSeconds)} ~ ${TimeFormatter.unixToDatetimeFormat(competition.endsAtSeconds)}`,
    salary: `${translateSalary({ salaryInTenK: competition.minSalary, lang: lang })}+`,
    label: statusMap[competition.status],
    scale: `${competition.applicantIds.length} ${t("人")}`,
    thumbnailImage: competition.thumbnailUrl
      ? {
          src: competition.thumbnailUrl,
          alt: competition.title,
        }
      : undefined,
  };
};
