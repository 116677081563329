import { useTranslation } from "@hireroo/i18n";
import { Divider } from "@mui/material";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import * as React from "react";

import FollowUpQuestion, { FollowUpQuestionProps } from "../FollowUpQuestion/FollowUpQuestion";

type CategorySection = {
  title: string;
  questions: FollowUpQuestionProps[];
};

export type EntityFollowUpQuestionsSectionProps = {
  categorySections: CategorySection[];
};

const EntityFollowUpQuestionsSection: React.FC<EntityFollowUpQuestionsSectionProps> = props => {
  const { t } = useTranslation();
  return (
    <Box>
      <Typography variant="subtitle1" sx={theme => ({ fontWeight: "bold", color: theme.palette.text.secondary })} mb={3}>
        {t("インタビューで深堀る観点")}
      </Typography>

      <Stack mt={2}>
        <Stack spacing={1}>
          {props.categorySections.map((section, index) => (
            <Stack key={`section-${index}`} spacing={1}>
              <Stack direction="row" alignItems="center" width="100%" spacing={2}>
                <Typography
                  whiteSpace="nowrap"
                  fontSize={14}
                  fontWeight={500}
                  color="textSecondary"
                >{`${t("カテゴリ")}: ${section.title}`}</Typography>
                <Box width="100%">
                  <Divider textAlign="center" variant="fullWidth" />
                </Box>
              </Stack>
              <Stack pl={5}>
                {section.questions.map((question, index) => (
                  <FollowUpQuestion key={`question-${index}`} {...question} />
                ))}
              </Stack>
            </Stack>
          ))}
        </Stack>
      </Stack>
    </Box>
  );
};

EntityFollowUpQuestionsSection.displayName = "EntityFollowUpQuestionsSection";

export default EntityFollowUpQuestionsSection;
