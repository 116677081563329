import * as React from "react";

import EmployeeLayout, { EmployeeLayoutProps } from "../../layouts/EmployeeLayout/EmployeeLayout";

export type CandidateDetailForEmployeeProps = {
  layout: EmployeeLayoutProps;
  children: React.ReactNode;
};

const CandidateDetailForEmployee: React.FC<CandidateDetailForEmployeeProps> = props => {
  return <EmployeeLayout {...props.layout}>{props.children}</EmployeeLayout>;
};

CandidateDetailForEmployee.displayName = "CandidateDetailForEmployee";

export default CandidateDetailForEmployee;
