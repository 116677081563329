import { proxy } from "valtio";
import { proxyMap, proxySet } from "valtio/utils";

import { DEFAULT_SEARCH_FILTER, DEFAULT_SORT_PARAMS } from "./constants";
import type * as Types from "./types";

export const state = proxy<Types.State>({
  res: null,
  competitionMap: proxyMap(),
  currentSearchFilter: DEFAULT_SEARCH_FILTER,
  currentCompetitionIdSet: proxySet(),
  sortParams: DEFAULT_SORT_PARAMS,
  initializedSearchFilter: false,
  offset: 0,
});
