import { CompetitionsResultsId } from "@hireroo/app-store/page/c/competitions_results_id";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import CompetitionsResultsIdFetchContainer from "./FetchContainer";
import * as Subscriber from "./Subscriber";

const CompetitionsResultsIdInitialContainer: React.FC = () => {
  React.useEffect(() => {
    const stopSubscribeOnChangeResult = Subscriber.startSubscribeOnChangeResult();
    const stopSubscribeTimeoutStatus = Subscriber.startSubscribeTimeoutStatus();
    return () => {
      stopSubscribeOnChangeResult();
      stopSubscribeTimeoutStatus();
      CompetitionsResultsId.clear();
    };
  }, []);
  return (
    <ErrorBoundary>
      <CompetitionsResultsIdFetchContainer />
    </ErrorBoundary>
  );
};

CompetitionsResultsIdInitialContainer.displayName = "CompetitionsResultsIdInitialContainer";

export default withErrorBoundary(CompetitionsResultsIdInitialContainer, {});
