import { useTranslation } from "@hireroo/i18n";
import Markdown from "@hireroo/markdown-v2/react";
import Box from "@mui/material/Box";
import Button, { type ButtonProps } from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import Dialog, { DialogProps } from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FormControlLabel from "@mui/material/FormControlLabel";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";
const StyledList = styled("ol")(({ theme }) => ({
  paddingTop: 0,
  margin: 0,
  paddingLeft: theme.spacing(2),
  "& li": {
    marginTop: 0,
    marginBottom: "0.75em",
    lineHeight: "24px",
    "&:last-child": {
      marginBottom: 0,
    },
  },
}));

const StyledListItem = styled("li")(({ theme }) => ({
  marginTop: theme.spacing(2),
}));
const FormControlLabelWrapper = styled(Box)(({ theme }) => ({
  borderRadius: "4px",
  paddingX: theme.spacing(1),
}));

export type GuidelinesDialogProps = {
  dialog: Pick<DialogProps, "open" | "onClose">;
  yesButton: Pick<ButtonProps, "onClick" | "disabled">;
};

const GuidelinesDialog: React.FC<GuidelinesDialogProps> = props => {
  const { t } = useTranslation();
  const [checked, setChecked] = React.useState(false);

  const handleChange = (_event: React.SyntheticEvent, checked: boolean) => {
    setChecked(checked);
  };

  const noButton: ButtonProps = {
    color: "outline-only",
    variant: "text",
    children: t("キャンセル"),
    onClick: event => {
      props.dialog.onClose?.(event, "backdropClick");
    },
  };

  const yesButton: ButtonProps = {
    children: t("参加する"),
    variant: "contained",
    color: "primary",
    disabled: !checked || props.yesButton.disabled,
    onClick: event => {
      props.yesButton.onClick?.(event);
    },
  };

  const items: string[] = [
    t("コンペには制限時間があります。一度コンペを始めると中断することはできませんので予め余裕を持って始めるようにしてください。"),
    t("問題が複数出題されている場合、問題は順不同で解答することができます"),
    t("制限時間はすべての問題で共通です。時間配分に気をつけて解答するようにしてください"),
    t("制限時間内であれば何度でも提出が可能です。"),
    t(
      "解答の途中でブラウザが閉じてしまった場合は、メールに送信されたリンクからアクセスしてください。その場合でも時間は経過しますのでご注意ください。",
    ),
    t("SNS等に問題文や企業情報等を公開しないでください。"),
  ];

  return (
    <Dialog {...props.dialog}>
      <DialogTitle>{t("注意事項")}</DialogTitle>
      <DialogContent>
        <Stack spacing={2.5}>
          <Typography variant="body2">{t("コンペを開始する前に以下の注意事項をよく読んでから開始してください。")}</Typography>
          <StyledList>
            {items.map((item, index) => (
              <StyledListItem key={index}>
                <React.Suspense>
                  <Markdown size="middle" children={item} />
                </React.Suspense>
              </StyledListItem>
            ))}
          </StyledList>
          <FormControlLabelWrapper>
            <FormControlLabel
              sx={{ ml: 0 }}
              control={<Checkbox size="small" />}
              onChange={handleChange}
              label={<Typography fontSize={16}>{t("上記の注意事項を確認しました")}</Typography>}
            />
          </FormControlLabelWrapper>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Stack direction="row" spacing={1}>
          <Button {...noButton} />
          <Button {...yesButton} />
        </Stack>
      </DialogActions>
    </Dialog>
  );
};

GuidelinesDialog.displayName = "GuidelinesDialog";

export default GuidelinesDialog;
