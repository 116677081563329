import { App } from "@hireroo/app-store/essential/candidate";
import { CandidateSideBarContent } from "@hireroo/app-store/widget/c/CandidateSideBarContent";
import { useTranslation } from "@hireroo/i18n";
import { Pages } from "@hireroo/presentation";
import { generatePath } from "@hireroo/router/api";
import { useTransitionNavigate } from "@hireroo/router/hooks";

import CandidateHeaderRightContentContainer from "../../../../widget/v2/c/CandidateHeaderRightContent/Container";
import CandidateSideBarContentContainer from "../../../../widget/v2/c/CandidateSideBarContent/Container";
import SnackbarContainer from "../../../../widget/v2/shared/Snackbar/Container";
import CandidateDetailFetchContainer from "./widgets/CandidateDetail/FetchContainer";

export type GenerateHomePropsArgs = {};

export const useGenerateProps = (_args: GenerateHomePropsArgs): Pages.CandidateHomeProps => {
  const { t } = useTranslation();
  const navigate = useTransitionNavigate();
  const loading = App.useStatus() === "INITIALIZING";

  return {
    layout: {
      openSidebar: CandidateSideBarContent.useOpenSidebar(),
      onChangeOpenSidebar: open => {
        CandidateSideBarContent.setOpenSidebar(open);
      },
      loading: loading,
      NotificationBanner: null,
      HeaderRightContent: <CandidateHeaderRightContentContainer />,
      SideBarContent: <CandidateSideBarContentContainer />,
      Footer: null,
      Snackbar: <SnackbarContainer />,
      sideBarHeader: {
        logo: {
          href: generatePath("/c/home"),
          onClick: () => {
            navigate("/c/home");
          },
        },
      },
      navigationBreadcrumbs: {
        parentLinks: [],
        current: t("ホーム"),
      },
    },
    children: <CandidateDetailFetchContainer />,
  };
};
