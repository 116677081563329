import { Credential } from "@hireroo/app-store/essential/shared";
import * as Graphql from "@hireroo/graphql/client/request";
import * as RestClient from "@hireroo/rest-api/client";
import { RoutingType } from "@hireroo/router/api";

export const CANDIDATE_TENANT_ID = import.meta.env.VITE_CANDIDATE_TENANT_ID;

export const initializeGraphqlClientSdkAndRestApiClient = (authToken: string): void => {
  Graphql.initialize(import.meta.env.VITE_GRAPHQL_SERVER_ADDRESS, {
    authToken,
    getOrRefreshAuthTokenExpire: Credential.getOrRefreshAuthTokenExpire,
    appVersion: APP_VERSION,
  });
  RestClient.initialize(import.meta.env.VITE_REST_API_SERVER_ADDRESS, authToken);
};

export const shouldUseCandidateTenant = (routingType: RoutingType): boolean => {
  const searchParams = new URLSearchParams(window.location.search);
  const tenantId = searchParams.get("tenantId");

  // メアド検証のリンクの URL は routingType が AUTH で、クエリパラメータに tenantId が含まれるので
  // 候補者がアクセスしたかどうかをチェックする
  if (routingType === "AUTH" && tenantId === CANDIDATE_TENANT_ID) {
    return true;
  }

  return (
    routingType === "CANDIDATE" ||
    routingType === "CANDIDATE_OR_ANONYMOUS" ||
    routingType === "SIGN_IN_CANDIDATE" ||
    routingType === "SIGN_UP_CANDIDATE" ||
    routingType === "RESET_PASSWORD_CANDIDATE"
  );
};
