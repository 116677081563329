import { useTranslation } from "@hireroo/i18n";
import { MetricItemListForm } from "@hireroo/validator";
import { zodResolver } from "@hookform/resolvers/zod/dist/zod";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { FormProvider, useForm } from "react-hook-form";

import MetricItemTableRow, { MetricItemTableRowProps } from "./parts/MetricItemTableRow/MetricItemTableRow";

const ColumnTitle = styled(Typography)(() => ({
  fontSize: "12px",
  wordBreak: "keep-all",
}));

const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  "& .MuiTableCell-root": {
    borderColor: theme.palette["Other"]["Divider"],
    padding: theme.spacing(1.5),
  },
  width: "100%",
}));

const StyledTableHead = styled(TableHead)(({ theme }) => ({
  borderTop: "1px solid",
  borderColor: theme.palette["Other"]["Divider"],
}));

export type MetricItemTableProps = {
  items: Omit<MetricItemTableRowProps, "name" | "isSelectable">[];
  onChange?: (fields: MetricItemListForm.MetricItemListForm) => void;
  enableCheckbox?: boolean;
  enableActionButton?: boolean;
  loading?: boolean;
  enableTopBorder?: boolean;
};

const MetricItemTable: React.FC<MetricItemTableProps> = props => {
  const { onChange, items } = props;
  const { t } = useTranslation();
  const validateSchema = MetricItemListForm.useMetricItemListForm();
  const defaultValues = React.useMemo((): MetricItemListForm.MetricItemListForm => {
    return {
      items: items.map((item): MetricItemListForm.MetricItemListForm["items"][0] => {
        return {
          itemId: item.id,
          selected: false,
        };
      }),
    };
  }, [items]);
  const methods = useForm<MetricItemListForm.MetricItemListForm>({
    resolver: zodResolver(validateSchema),
    defaultValues: defaultValues,
  });

  const scrollRootRef = React.useRef<HTMLUListElement>(null);
  const targetRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    const subscription = methods.watch(() => {
      const fields = methods.getValues();
      onChange?.(fields);
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [methods, onChange]);

  return (
    <FormProvider {...methods}>
      <Box ref={scrollRootRef} p={0}>
        <StyledTableContainer sx={{ maxHeight: "600px" }}>
          <Table sx={{ width: "100%" }}>
            <StyledTableHead sx={{ borderTop: props.enableTopBorder ? undefined : "none" }}>
              <TableRow>
                {props.enableCheckbox && <TableCell padding="checkbox">{/* spacer */}</TableCell>}
                <TableCell>
                  <ColumnTitle>{t("評価項目名")}</ColumnTitle>
                </TableCell>
                <TableCell>
                  <ColumnTitle>{t("説明")}</ColumnTitle>
                </TableCell>
                {props.enableActionButton && (
                  <TableCell align="right" width="5%">
                    {/* spacer */}
                  </TableCell>
                )}
              </TableRow>
            </StyledTableHead>
            <TableBody sx={{ width: "100%" }}>
              {props.items.map((item, index) => (
                <MetricItemTableRow key={item.id} {...item} isSelectable={props.enableCheckbox} name={`items.${index}`} />
              ))}
              {props.items.length === 0 && (
                <TableRow>
                  <TableCell colSpan={3}>
                    <Typography fontSize="12px" color="textSecondary" textAlign="center">
                      {t("評価項目がありません。")}
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
              {props.loading && (
                <TableRow>
                  <TableCell colSpan={3}>
                    <Box display="flex" alignItems="center" justifyContent="center">
                      <CircularProgress size={23} color="secondary" />
                    </Box>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
          <div ref={targetRef} />
        </StyledTableContainer>
      </Box>
    </FormProvider>
  );
};

MetricItemTable.displayName = "MetricItemTable";

export default MetricItemTable;
