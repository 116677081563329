import { useQuestionDifficultyTypeMap } from "@hireroo/app-definition/question";
import { CompetitionsIdDetail } from "@hireroo/app-store/page/e/competitions_id_detail";
import { languageMapForDisplay } from "@hireroo/challenge/definition";
import { useLanguageCode, useTranslation } from "@hireroo/i18n";
import { resolveLanguage } from "@hireroo/i18n/utils";
import type { Widget } from "@hireroo/presentation";
import { generatePath } from "@hireroo/router/api";

type Row = Exclude<Widget.ScreeningTestOverviewProps["questionOverview"], undefined>["rows"][number];

export const useGenerateQuestionTableProps = (): Widget.CompetitionOverviewProps["questionTable"] => {
  const { t } = useTranslation();
  const lang = useLanguageCode();
  const competition = CompetitionsIdDetail.useCompetition();
  const difficultyTypeMap = useQuestionDifficultyTypeMap();

  return {
    rows: competition.questions.map((questionSource, index): Row => {
      const qNumber = `Q${index + 1}`;
      if (questionSource.question.__typename === "AlgorithmQuestion") {
        const question = questionSource.question;
        return {
          title: {
            children: `${qNumber} ${resolveLanguage(question, lang, "title")}`,
            href: generatePath("/e/questions/challenge/:id", {
              pathParams: {
                id: question.questionId.toString(),
              },
              queryParams: {
                version: question.questionId,
              },
            }),
          },
          variant: t("コーディング形式"),
          difficultyStars: {
            difficulty: difficultyTypeMap[question.difficulty],
          },
          selectedContents: questionSource.enabledLanguages.map(lang => languageMapForDisplay[lang] ?? lang),
          weight: 0,
          isArchived: question.status === "ARCHIVED",
        };
      } else {
        throw new Error("Unexpected entity type");
      }
    }),
  };
};
