import { useTranslation } from "@hireroo/i18n";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import * as React from "react";

import InputControlTextField, {
  InputControlTextFieldProps,
} from "../../../../primitive/InputControl/InputControlTextField/InputControlTextField";

export type ProfileTextFieldProps = Pick<InputControlTextFieldProps, "sx">;

const ProfileTextField: React.FC<ProfileTextFieldProps> = props => {
  const { t } = useTranslation();

  const placeholder = t("「あなたが持つスキルと、これからの目標やチャレンジしたい分野」を中心に、簡単にまとめてみましょう。");

  return (
    <Stack direction="column" spacing={1.5}>
      <Typography fontSize={14} fontWeight={700}>
        {t("自己紹介")}
      </Typography>
      <InputControlTextField name="profileText" placeholder={placeholder} {...props} multiline rows={10} />
    </Stack>
  );
};

ProfileTextField.displayName = "ProfileTextField";

export default ProfileTextField;
