import { useSnapshot } from "valtio";

import { state } from "./State";

const useSnapshotState = () => {
  return useSnapshot(state);
};

export const useInitializedFilter = () => {
  const snapshot = useSnapshotState();
  return snapshot.initializedFilter;
};

export const useInitialized = () => {
  const snapshot = useSnapshotState();
  return !!snapshot.res;
};

export const usePager = () => {
  const snapshot = useSnapshotState();
  return snapshot.pager;
};

export const useSortParams = () => {
  const snapshot = useSnapshotState();
  return snapshot.pager.sortParams;
};

export const useResponse = () => {
  const snapshot = useSnapshotState();
  if (!snapshot.res) {
    throw new Error("Response not initialized");
  }
  return snapshot.res;
};

export const useCompetitionIds = () => {
  const snapshot = useSnapshotState();
  return snapshot.competitionIds;
};

export const useApplicants = () => {
  const snapshot = useSnapshotState();
  const competitionIds = useCompetitionIds();

  return competitionIds.map(id => {
    const applicant = snapshot.applicantMap.get(id);
    if (!applicant) {
      throw new Error(`Applicant not found: ${id}`);
    }
    return applicant;
  });
};
