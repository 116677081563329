import { useTranslation } from "@hireroo/i18n";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import * as React from "react";

import CompetitionSearchForm, { type CompetitionSearchFormProps } from "./parts/CompetitionSearchForm/CompetitionSearchForm";

export type CompetitionHistoryProps = {
  searchForm?: CompetitionSearchFormProps;
  List: React.ReactNode;
};

const CompetitionHistory: React.FC<CompetitionHistoryProps> = props => {
  const { t } = useTranslation();
  return (
    <Box p={3}>
      <Typography variant="h6" fontWeight={700} fontSize={20} mb={3}>
        {t("参加コンペ")}
      </Typography>
      <Stack spacing={2}>
        {props.searchForm && <CompetitionSearchForm {...props.searchForm} />}
        {props.List}
      </Stack>
    </Box>
  );
};

CompetitionHistory.displayName = "CompetitionHistory";

export default CompetitionHistory;
