import { translateSalary } from "@hireroo/app-helper/competition";
import { Company } from "@hireroo/app-store/essential/employee";
import { CompetitionsIdDetail } from "@hireroo/app-store/page/e/competitions_id_detail";
import * as TimeFormatter from "@hireroo/formatter/time";
import { useLanguageCode, useTranslation } from "@hireroo/i18n";
import type { Widget } from "@hireroo/presentation";
import * as React from "react";

import { useGenerateQuestionTableProps } from "./useGenerateQuestionTableProps";

export type GenerateCompetitionOverviewPropsArgs = {};

export const useGenerateProps = (_args: GenerateCompetitionOverviewPropsArgs): Widget.CompetitionOverviewProps => {
  const { t } = useTranslation();
  const competition = CompetitionsIdDetail.useCompetition();
  const lang = useLanguageCode();
  const questionTableProps = useGenerateQuestionTableProps();
  const currentCompanyId = Company.useStrictActiveCompanyId();

  const createdBy = React.useMemo(() => {
    if (competition.hostCompany?.companyId !== currentCompanyId) return undefined;

    return {
      text: competition.creator?.displayName || competition.creator?.email || t("不明"),
      imgUrl: competition.creator?.photoUrl,
    };
  }, [competition.hostCompany?.companyId, currentCompanyId, competition.creator, t]);

  return {
    description: competition.description,
    salary: `${translateSalary({ salaryInTenK: competition.minSalary, lang: lang })}+`,
    timeLimit: TimeFormatter.formatMinutes(competition.timeLimitSeconds / 60, lang),
    questionTable: questionTableProps,
    hostCompany: {
      text: competition.hostCompany?.name || "",
      imgUrl: competition.hostCompany?.logo || "",
    },
    createdBy: createdBy,
  };
};
