import { Pages } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { useGenerateProps } from "./useGenerateProps";

export type ResultDetailContainerProps = {};

const ResultDetailContainer: React.FC<ResultDetailContainerProps> = () => {
  const props = useGenerateProps({});

  return (
    <ErrorBoundary>
      <Pages.CompetitionResultDetail {...props} />
    </ErrorBoundary>
  );
};

ResultDetailContainer.displayName = "ResultDetailContainer";

export default withErrorBoundary(ResultDetailContainer, {});
