import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { startSubscribeListParams } from "./Subscriber";
import { useGenerateProps } from "./useGenerateProps";

export type CompetitionHistoryContainerProps = {
  candidateId: string;
};

const CompetitionHistoryContainer: React.FC<CompetitionHistoryContainerProps> = props => {
  const competitionHistoryProps = useGenerateProps(props);

  React.useEffect(() => {
    const stop = startSubscribeListParams();
    return () => {
      stop();
    };
  }, []);

  return (
    <ErrorBoundary>
      <Widget.CompetitionHistory {...competitionHistoryProps} />
    </ErrorBoundary>
  );
};

CompetitionHistoryContainer.displayName = "CompetitionHistoryContainer";

export default withErrorBoundary(CompetitionHistoryContainer, {});
