import { useSnapshot } from "valtio";

import { state } from "./State";
import type * as Types from "./types";

const useSnapshotState = () => {
  return useSnapshot(state);
};

export const useInitialized = (): boolean => {
  const snapshot = useSnapshotState();
  return snapshot.initialized;
};

export const useApplicantMap = () => {
  const snapshot = useSnapshotState();
  return snapshot.applicantMap;
};

export const useCurrentApplicants = (): Types.Applicant[] => {
  const snapshot = useSnapshotState();
  return Array.from(snapshot.applicantMap.values());
};
