import { state } from "./State";
import type * as Types from "./types";

export const setResult = (result: Types.Result): void => {
  state.result = result;
};

export const clear = (): void => {
  state.result = null;
  state.endStatus = null;
  state.endLoadingStatus = "READY";
};

export const updateEndStatus = (status: Types.EndCompetitionResultStatus): void => {
  state.endStatus = status;
};

export const updateEndLoadingStatus = (status: Types.EndCompetitionResultLoadingStatus): void => {
  state.endLoadingStatus = status;
};
