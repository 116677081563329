import { Pages } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { useGenerateProps } from "./useGenerateProps";
export type CandidateSignInContainerProps = {};

const CandidateSignInContainer: React.FC<CandidateSignInContainerProps> = () => {
  const signInSignUpProps = useGenerateProps({});

  return (
    <ErrorBoundary>
      <Pages.SignInSignUp {...signInSignUpProps} />
    </ErrorBoundary>
  );
};

CandidateSignInContainer.displayName = "CandidateSignInContainer";

export default withErrorBoundary(CandidateSignInContainer, {});
