import { useTranslation } from "@hireroo/i18n";
import Box from "@mui/material/Box";
import { styled, useTheme } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import * as React from "react";

import ScreeningTestListTableRow, { ScreeningTestListTableRowProps } from "./parts/ScreeningTestListTableRow/ScreeningTestListTableRow";

const StyledTableHeaderCell = styled(TableCell)(({ theme }) => ({
  color: theme.palette.text.secondary,
  fontWeight: "normal",
  border: "none",
  paddingTop: theme.spacing(0.5),
  paddingBottom: theme.spacing(0.5),
  fontSize: "12px",
  height: 40,
  wordBreak: "keep-all",
  whiteSpace: "nowrap",
}));

export type ScreeningTestListTableProps = {
  items: ScreeningTestListTableRowProps[];
};

const ScreeningTestListTable: React.FC<ScreeningTestListTableProps> = props => {
  const { items } = props;
  const { t } = useTranslation();
  const theme = useTheme();

  if (items.length === 0) {
    return (
      <Box sx={{ width: "100%" }} p={2}>
        <Typography variant="body2" textAlign="center" color="textSecondary">
          {t("該当の候補者はありません")}
        </Typography>
      </Box>
    );
  }

  return (
    <TableContainer sx={{ overflow: "auto" }}>
      <Table component="div" sx={{ display: "table" }}>
        <TableHead component="div" sx={{ display: "table-header-group" }}>
          <TableRow sx={{ borderTop: "1px solid", borderBottom: "1px solid", borderColor: theme.palette["Gray/Shades"].p8 }} component="div">
            <StyledTableHeaderCell align="left" component="div">
              {t("候補者名")}
            </StyledTableHeaderCell>
            <StyledTableHeaderCell align="left" component="div">
              {t("コーディングテスト")}
            </StyledTableHeaderCell>
            <StyledTableHeaderCell align="right" component="div">
              {/* for MoreButton */}
            </StyledTableHeaderCell>
          </TableRow>
        </TableHead>
        <TableBody component="div" sx={{ display: "table-row-group" }}>
          {items.map(itemProps => (
            <ScreeningTestListTableRow key={itemProps.id} {...itemProps} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

ScreeningTestListTable.displayName = "TestListTable";

export default ScreeningTestListTable;
