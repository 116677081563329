import { useTranslation } from "@hireroo/i18n";
import type { Widget } from "@hireroo/presentation";
import * as React from "react";
import { useSearchParams } from "react-router-dom";

import CompetitionApplicantsInitialContainer from "../CompetitionApplicants/InitialContainer";
import CompetitionOverviewContainer from "../CompetitionOverview/Container";
import { useGenerateHeaderProps } from "./useGenerateHeaderProps";

export type GenerateCompetitionIdDetailPropsArgs = {};

export const useGenerateProps = (_args: GenerateCompetitionIdDetailPropsArgs): Widget.CompetitionIdDetailProps => {
  const { t } = useTranslation();
  const headerProps = useGenerateHeaderProps();
  const [params, setParams] = useSearchParams();
  const defaultTab = React.useMemo((): Widget.CompetitionIdDetailProps["defaultTabId"] => {
    const tab = params.get("tab");
    if (tab === "OVERVIEW" || tab === "APPLICANTS") {
      return tab;
    }
    return "OVERVIEW";
  }, [params]);

  return {
    defaultTabId: defaultTab,
    header: headerProps,
    items: [
      {
        id: "OVERVIEW",
        Component: <CompetitionOverviewContainer />,
        label: {
          name: t("概要"),
          onClick: () => {
            setParams({ tab: "OVERVIEW" });
          },
        },
      },
      {
        id: "APPLICANTS",
        Component: <CompetitionApplicantsInitialContainer />,
        label: {
          name: t("参加者一覧"),
          onClick: () => {
            setParams({ tab: "APPLICANTS" });
          },
        },
      },
    ],
  };
};
