import { useTranslation } from "@hireroo/i18n";
import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import { type ButtonProps } from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import * as React from "react";

import DeleteDialog, { DeleteDialogProps } from "../../../../../modules/DeleteDialog/DeleteDialog";

type DialogController = {
  setLoading: (loading: boolean) => void;
  onClose: () => void;
};

export type TabLabelProps = {
  title: string;
  deleteDialog?: {
    name: DeleteDialogProps["name"];
    onDelete: (controller: DialogController) => void;
  };
};

const TabLabel: React.FC<TabLabelProps> = props => {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);
  const handleClick = React.useCallback<Exclude<ButtonProps["onClick"], undefined>>(event => {
    event.stopPropagation();
    setOpen(true);
  }, []);
  const deleteDialog: DeleteDialogProps | undefined = props.deleteDialog
    ? {
        dialog: {
          open: open,
          yesButton: {
            onClick: () => {
              const dialogController: DialogController = {
                setLoading: setLoading,
                onClose: () => {
                  setOpen(false);
                },
              };
              props.deleteDialog?.onDelete?.(dialogController);
            },
            disabled: loading,
          },
          noButton: {
            onClick: () => {
              setOpen(false);
            },
            disabled: loading,
          },
        },
        name: props.deleteDialog?.name || "",
        description: t("一度削除するとコードを元に戻せません。"),
      }
    : undefined;
  return (
    <Box justifyContent="space-between" display="flex" alignItems="center">
      <Typography variant="body2" textTransform="none">
        {props.title}
      </Typography>
      {deleteDialog && (
        <>
          <Box flexDirection="column" justifyContent="center" display="flex" component="span">
            {/* This className is used in the tutorial. */}
            <IconButton className={"close-tab"} size="small" style={{ marginLeft: 8 }} onClick={handleClick} component="span">
              <CloseIcon fontSize="small" />
            </IconButton>
          </Box>
          <DeleteDialog {...deleteDialog} />
        </>
      )}
    </Box>
  );
};

TabLabel.displayName = "TabLabel";

export default TabLabel;
