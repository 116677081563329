import { useJobChangeInterestMap } from "@hireroo/app-definition/auth";
import { translateSalary } from "@hireroo/app-helper/competition";
import { CompetitionsIdDetail } from "@hireroo/app-store/page/e/competitions_id_detail";
import { formatScore } from "@hireroo/formatter/score";
import * as TimeFormatter from "@hireroo/formatter/time";
import * as Graphql from "@hireroo/graphql/client/urql";
import { useLanguageCode, useTranslation, useTranslationWithVariable } from "@hireroo/i18n";
import type { Widget } from "@hireroo/presentation";
import { generatePath } from "@hireroo/router/api";
import { useTransitionNavigate } from "@hireroo/router/hooks";
import * as React from "react";

import * as PrivateHelper from "./privateHelper";

type Item = Widget.CompetitionApplicantListProps["table"]["items"][0];
export type GenerateCompetitionApplicantListPropsArgs = {};

export const useGenerateProps = (_args: GenerateCompetitionApplicantListPropsArgs): Widget.CompetitionApplicantListProps => {
  const { t } = useTranslation();
  const { t: t2 } = useTranslationWithVariable();
  const navigate = useTransitionNavigate();
  const lang = useLanguageCode();
  const pager = CompetitionsIdDetail.useApplicantPager();
  const res = CompetitionsIdDetail.useApplicantsRes();
  const jobChangeInterestMap = useJobChangeInterestMap();

  const items = React.useMemo((): Item[] => {
    return res.applicants.map((applicant): Item => {
      const name = applicant.candidate?.displayName || t("未登録");
      const suspiciousDegreeMap: Record<Graphql.ResultSuspiciousDegree, Item["suspiciousDegree"] | undefined> = {
        [Graphql.ResultSuspiciousDegree.Clean]: { degree: "CLEAN" },
        [Graphql.ResultSuspiciousDegree.Suspicious]: { degree: "SUSPICIOUS" },
        [Graphql.ResultSuspiciousDegree.Uncalculated]: { degree: "UNCALCULATED" },
      };
      const statusMap: Record<Graphql.ApplicantStatus, Item["status"]> = {
        [Graphql.ApplicantStatus.Created]: { text: t("未実施"), color: "DEFAULT" },
        [Graphql.ApplicantStatus.Started]: { text: t("実施中"), color: "DEFAULT" },
        [Graphql.ApplicantStatus.Completed]: { text: t("実施済み"), color: "DEFAULT" },
        [Graphql.ApplicantStatus.Confirmed]: { text: t("採点済み"), color: "PRIMARY" },
      };
      return {
        id: applicant.id,
        user: {
          name: name,
          imgUrl: applicant.candidate?.photoUrl,
        },
        disabled: !applicant.result,
        href: applicant.result
          ? generatePath("/e/competitions/results/:id/detail", {
              pathParams: {
                id: applicant.result.resultId,
              },
            })
          : "",
        onClick: () => {
          if (!applicant.result) return;
          navigate("/e/competitions/results/:id/detail", {
            pathParams: {
              id: applicant.result.resultId,
            },
          });
        },
        elapsedTime: applicant.result?.elapsedTimeSeconds
          ? `${TimeFormatter.formatSecondsToHumanTimeWithTranslation(applicant.result.elapsedTimeSeconds, lang)}`
          : "-",
        ranking: applicant.result && applicant.result.rank > 0 ? applicant.result.rank : undefined,
        score: applicant.result ? `${formatScore(applicant.result.totalScore)}` : "",
        status: statusMap[applicant.status],
        suspiciousDegree: applicant.result ? suspiciousDegreeMap[applicant.result.suspiciousDegree] : undefined,
        applicantPreview: {
          name: name,
          imageUrl: applicant.candidate?.photoUrl,
          status: jobChangeInterestMap[applicant?.candidate?.jobChangeInterest ?? "UNKNOWN"],
          onClick: () => {
            if (!applicant.candidate) return;
            navigate("/e/candidates/:id/detail", {
              pathParams: {
                id: applicant.candidate.candidateId,
              },
            });
          },
          salary:
            applicant.candidate && applicant.candidate?.expectedSalaryYen !== null && applicant.candidate.expectedSalaryYen > 0
              ? t2("ExpectedSalary", {
                  salary: translateSalary({
                    salaryInTenK: applicant.candidate.expectedSalaryYen / 10000,
                    lang,
                    withCurrency: true,
                  }),
                })
              : t("未設定"),
        },
      };
    });
  }, [jobChangeInterestMap, lang, navigate, res.applicants, t, t2]);

  return {
    searchResultBar: {
      defaultValue: PrivateHelper.generateSortMethod({
        sortMethod: pager.sortParams.sortMethod,
        isDescending: pager.sortParams.isDescending,
      }),
      resultText: t2("SearchResultCount", { count: res.count }),
      onChangeSortMethod: value => {
        const target = PrivateHelper.sortMethodMap[value];
        CompetitionsIdDetail.updateApplicantListParams({
          sortParams: {
            sortMethod: target.sortMethod,
            isDescending: target.isDescending,
          },
        });
      },
    },
    table: {
      items: items,
    },
    tablePagination: {
      count: res.count,
      rowsPerPage: pager.size,
      onRowsPerPageChange: event => {
        const newSize = Number(event.target.value);
        CompetitionsIdDetail.updatePageSize(newSize);
      },
      page: pager.page,
      onPageChange: (_, page) => {
        CompetitionsIdDetail.updatePage(page);
      },
    },
  };
};
