import CodeEditor, { CodeEditorProps } from "@hireroo/code-editor/react/v2/CodeEditor";
import Box from "@mui/material/Box";
import * as React from "react";

export type ChallengeReadonlyCodeEditorProps = {
  value: string;
  path: string;
};

/**
 * This component cannot be checked by storybook
 * because code-editor v2 CodeEditor is not supported
 */

const ChallengeReadonlyCodeEditor: React.FC<ChallengeReadonlyCodeEditorProps> = props => {
  const codeEditorProps: CodeEditorProps = {
    path: props.path,
    value: props.value,
    readOnly: true,
    style: {
      height: "100%",
    },
  };
  return (
    <Box height="100%">
      <React.Suspense>
        <CodeEditor {...codeEditorProps} />
      </React.Suspense>
    </Box>
  );
};

ChallengeReadonlyCodeEditor.displayName = "ChallengeReadonlyCodeEditor";

export default ChallengeReadonlyCodeEditor;
