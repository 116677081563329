import { useTranslation } from "@hireroo/i18n";
import RankingFirst from "@hireroo/ui-assets/images/Ranking/RankingFirst.svg";
import RankingSecond from "@hireroo/ui-assets/images/Ranking/RankingSecond.svg";
import RankingThird from "@hireroo/ui-assets/images/Ranking/RankingThird.svg";
import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import { styled, useTheme } from "@mui/material/styles";
import TableCell from "@mui/material/TableCell";
import TableRow, { TableRowProps } from "@mui/material/TableRow";
import React from "react";
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette["Other"]["Divider"]}`,
  padding: "8px 16px",
}));

const RankCell = styled(StyledTableCell)({
  width: "40px",
  height: "53px",
  textAlign: "center",
});

const NameCell = styled(StyledTableCell)(({ theme }) => ({
  flex: 1,
  color: theme.palette.text.primary,
}));

const TimeCell = styled(StyledTableCell)({
  width: "120px",
  textAlign: "center",
  fontSize: "12px",
});

const ScoreCell = styled(StyledTableCell)(({ theme }) => ({
  width: "56px",
  textAlign: "center",
  color: theme.palette.text.primary,
}));

const RankDisplay = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "24px",
  height: "24px",
  margin: "0 auto",
  fontSize: "12px",
  fontWeight: 400,
  color: theme.palette.text.primary,
}));

const RankIcon = styled("img")({
  width: "32px",
  height: "32px",
});

export type LeaderboardTableRowProps = {
  id: string;
  ranking?: number;
  name: string;
  elapsedTime: string;
  score: string;
  isCurrentUser?: boolean;
};

const LeaderboardTableRow: React.FC<LeaderboardTableRowProps> = props => {
  const { ranking, name, elapsedTime, score, isCurrentUser } = props;

  const { t } = useTranslation();
  const theme = useTheme();

  const renderRank = () => {
    switch (ranking) {
      case 1:
        return <RankIcon src={RankingFirst} alt="1st place" />;
      case 2:
        return <RankIcon src={RankingSecond} alt="2nd place" />;
      case 3:
        return <RankIcon src={RankingThird} alt="3rd place" />;
      default:
        return <RankDisplay>{ranking ?? "-"}</RankDisplay>;
    }
  };

  const tableRowProps: TableRowProps = {
    sx: {
      ...(isCurrentUser
        ? {
            backgroundColor: theme.palette["Background/Paper"].p4,
            position: "sticky",
            left: 0,
            top: 40,
            bottom: 0,
            zIndex: 1,
          }
        : {}),
    },
  };

  return (
    <TableRow {...tableRowProps}>
      <RankCell>{renderRank()}</RankCell>
      <NameCell>{isCurrentUser ? <Typography fontWeight={700}>{t("あなた")}</Typography> : name}</NameCell>
      <TimeCell>{elapsedTime}</TimeCell>
      <ScoreCell>{score}</ScoreCell>
    </TableRow>
  );
};

LeaderboardTableRow.displayName = "LeaderboardTableRow";

export default LeaderboardTableRow;
