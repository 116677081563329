import { ENTITY_TYPE_MAP } from "@hireroo/app-helper/entity";
import { DeepReadonly } from "@hireroo/app-helper/types";

import type * as Types from "./types";

export const generateUniqueEntityId = (entity: DeepReadonly<Types.Entity>): Types.UniqueEntityId => {
  switch (entity.__typename) {
    case "ChallengeEntity": {
      return `${ENTITY_TYPE_MAP.CHALLENGE}-${entity.entityId}`;
    }
    default: {
      throw new Error("unknown type");
    }
  }
};
