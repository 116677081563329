import { App } from "@hireroo/app-store/essential/employee";
import { CompetitionHistory } from "@hireroo/app-store/widget/e/CompetitionHistory";
import * as Graphql from "@hireroo/graphql/client/urql";
import { Widget } from "@hireroo/presentation";
import { withErrorBoundary } from "@sentry/react";
import React from "react";

import CompetitionHistoryListContainer, { CompetitionHistoryListContainerProps } from "./Container";

export type CompetitionHistoryListFetchContainerProps = {
  candidateId: string;
};

const CompetitionHistoryListFetchContainer: React.FC<CompetitionHistoryListFetchContainerProps> = props => {
  const appStatus = App.useStatus();
  const initialized = CompetitionHistory.useInitialized();
  const pager = CompetitionHistory.usePager();

  const [result, refresh] = Graphql.useListApplicantsForEmployerCompetitionHistoryQuery({
    variables: {
      filters: {
        candidateId: props.candidateId,
        // 登録のみの状態は対象外
        statuses: [Graphql.ApplicantStatus.Created, Graphql.ApplicantStatus.Completed, Graphql.ApplicantStatus.Confirmed],
        suspiciousDegrees: [],
      },
      sortMethod: pager.sortParams.sortMethod,
      isDescending: pager.sortParams.isDescending,
      size: pager.size,
      offset: pager.offset,
    },
    pause: appStatus !== "INITIALIZED",
    requestPolicy: "cache-and-network",
  });

  React.useEffect(() => {
    if (result.data?.applicants) {
      CompetitionHistory.setResponse(result.data.applicants);
    }
  }, [result.data]);

  if (result.error) {
    return <Widget.RefreshPanel refresh={refresh} />;
  }
  if (result.fetching || appStatus !== "INITIALIZED" || !initialized) {
    return <Widget.Loading kind="CENTER_%" />;
  }

  const containerProps: CompetitionHistoryListContainerProps = {};

  return <CompetitionHistoryListContainer {...containerProps} />;
};

CompetitionHistoryListFetchContainer.displayName = "CompetitionHistoryListFetchContainer";

export default withErrorBoundary(CompetitionHistoryListFetchContainer, {});
