import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { useGenerateProps } from "./useGenerateProps";

export type CompetitionApplicantListContainerProps = {};

const CompetitionApplicantListContainer: React.FC<CompetitionApplicantListContainerProps> = () => {
  const competitionApplicantListProps = useGenerateProps({});
  return (
    <ErrorBoundary>
      <Widget.CompetitionApplicantList {...competitionApplicantListProps} />
    </ErrorBoundary>
  );
};

CompetitionApplicantListContainer.displayName = "CompetitionApplicantListContainer";

export default withErrorBoundary(CompetitionApplicantListContainer, {});
