export type TranslateSalaryArgs = {
  /**
   * Salary to be translated
   * @example 600 meaning 600万
   */
  salaryInTenK: number;
  /**
   * Language to translate to
   */
  lang: "ja" | "en";
  withCurrency?: boolean;
};

export const translateSalary = (args: TranslateSalaryArgs): string => {
  const { salaryInTenK, lang } = args;
  if (lang === "en") {
    const salary = salaryInTenK / 100;
    return `${args.withCurrency ? "¥ " : ""}${salary} million`;
  }
  return `${salaryInTenK}万円`;
};
