import { Candidates } from "@hireroo/app-store/page/e/candidates";
import { updateQueryParamsByObject } from "@hireroo/router/api";

export const startSubscribeListParams = () => {
  return Candidates.subscribeAllState(state => {
    if (!state.currentSearchFilter) {
      return;
    }
    const { currentSearchFilter, pager } = state;

    const isDefaultSortParams =
      pager.sortParams.sortMethod === Candidates.DEFAULT_SORT_PARAMS.sortMethod &&
      pager.sortParams.isDescending === Candidates.DEFAULT_SORT_PARAMS.isDescending;
    /**
     * Do not update query parameters if they are different from the Default Value when the page is visited
     * (i.e., the user has not manipulated them).
     */
    updateQueryParamsByObject({
      [Candidates.QueryKeys.DISPLAY_NAME]:
        currentSearchFilter.textFilter && currentSearchFilter.textFilter.length > 0 ? [currentSearchFilter.textFilter] : [],
      [Candidates.QueryKeys.JOB_CHANGE_STATUS]: currentSearchFilter.jobChangeStatuses.length > 0 ? currentSearchFilter.jobChangeStatuses : [],
      [Candidates.QueryKeys.EXPECTED_SALARY_MAX]:
        currentSearchFilter.expectedSalary.max !== Candidates.EXPECTED_SALARY.MAX ? [currentSearchFilter.expectedSalary.max.toFixed()] : [],
      [Candidates.QueryKeys.EXPECTED_SALARY_MIN]:
        currentSearchFilter.expectedSalary.min !== Candidates.EXPECTED_SALARY.MIN ? [currentSearchFilter.expectedSalary.min.toFixed()] : [],
      [Candidates.QueryKeys.AVERAGE_SCORE_MAX]:
        currentSearchFilter.averageScore.max !== Candidates.AVERAGE_SCORE.MAX ? [currentSearchFilter.averageScore.max.toFixed()] : [],
      [Candidates.QueryKeys.AVERAGE_SCORE_MIN]:
        currentSearchFilter.averageScore.min !== Candidates.AVERAGE_SCORE.MIN ? [currentSearchFilter.averageScore.min.toFixed()] : [],
      [Candidates.QueryKeys.AVERAGE_RANK]: currentSearchFilter.averageRank.length > 0 ? currentSearchFilter.averageRank : [],
      [Candidates.QueryKeys.SORT]: !isDefaultSortParams ? [`${pager.sortParams.sortMethod}-${pager.sortParams.isDescending}`] : [],
      /**
       * Set page number to "1" if it is not a page change
       */
      [Candidates.QueryKeys.PAGE]: pager.page > 0 ? [(pager.page + 1).toString()] : [],
      [Candidates.QueryKeys.SIZE]: pager.size !== Candidates.DEFAULT_PAGER.size ? [pager.size.toString()] : [],
    });
  });
};
