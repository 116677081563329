import { useTranslation } from "@hireroo/i18n";
import OpenInNew from "@mui/icons-material/OpenInNew";
import Button, { type ButtonProps } from "@mui/material/Button";
import Dialog, { DialogProps } from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Stack from "@mui/material/Stack";
import * as React from "react";

import Link, { LinkProps } from "../../../../../primitive/Link/Link";

export type ProfileDialogProps = {
  dialog: Pick<DialogProps, "open" | "onClose">;
  href: LinkProps["href"];
};

const ProfileDialog: React.FC<ProfileDialogProps> = props => {
  const { t } = useTranslation();
  const noButton: ButtonProps = {
    color: "outline-only",
    variant: "text",
    children: t("キャンセル"),
    onClick: event => {
      props.dialog.onClose?.(event, "backdropClick");
    },
  };

  const yesButton: ButtonProps = {
    children: t("プロフィール編集"),
    variant: "contained",
    color: "primary",
    endIcon: <OpenInNew fontSize="small" />,
  };
  return (
    <Dialog {...props.dialog}>
      <DialogTitle>{t("プロフィールを入力してください")}</DialogTitle>
      <DialogContent>{t("コンペに参加するには、事前にプロフィールの入力が必要です。")}</DialogContent>
      <DialogActions>
        <Stack direction="row" spacing={1}>
          <Button {...noButton} />
          <Link href={props.href} target="_blank">
            <Button {...yesButton} />
          </Link>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};

ProfileDialog.displayName = "ProfileDialog";

export default ProfileDialog;
