import * as Graphql from "@hireroo/graphql/client/urql";
import type { Widget } from "@hireroo/presentation";

type SortMethod = Parameters<Widget.CompetitionApplicantListProps["searchResultBar"]["onChangeSortMethod"]>[0];

export const sortMethodMap: Record<
  SortMethod,
  {
    sortMethod: Exclude<
      Graphql.ApplicantSortMethod,
      typeof Graphql.ApplicantSortMethod.CompletedAt | typeof Graphql.ApplicantSortMethod.AppliedAt
    >;
    isDescending: boolean;
  }
> = {
  TOTAL_SCORE_ASCENDING: { sortMethod: Graphql.ApplicantSortMethod.TotalScore, isDescending: false },
  TOTAL_SCORE_DESCENDING: { sortMethod: Graphql.ApplicantSortMethod.TotalScore, isDescending: true },
  RANK_ASCENDING: { sortMethod: Graphql.ApplicantSortMethod.Rank, isDescending: false },
  RANK_DESCENDING: { sortMethod: Graphql.ApplicantSortMethod.Rank, isDescending: true },
  ELAPSED_TIME_ASCENDING: { sortMethod: Graphql.ApplicantSortMethod.ElapsedTime, isDescending: false },
  ELAPSED_TIME_DESCENDING: { sortMethod: Graphql.ApplicantSortMethod.ElapsedTime, isDescending: true },
};

export const generateSortMethod = (args: {
  sortMethod: Exclude<
    Graphql.ApplicantSortMethod,
    typeof Graphql.ApplicantSortMethod.CompletedAt | typeof Graphql.ApplicantSortMethod.AppliedAt
  >;
  isDescending: boolean;
}): SortMethod => {
  const SUFFIX = args.isDescending ? ("DESCENDING" as const) : ("ASCENDING" as const);
  const prefixMap: Record<
    Exclude<Graphql.ApplicantSortMethod, typeof Graphql.ApplicantSortMethod.CompletedAt | typeof Graphql.ApplicantSortMethod.AppliedAt>,
    "TOTAL_SCORE" | "RANK" | "ELAPSED_TIME"
  > = {
    [Graphql.ApplicantSortMethod.TotalScore]: "TOTAL_SCORE",
    [Graphql.ApplicantSortMethod.Rank]: "RANK",
    [Graphql.ApplicantSortMethod.ElapsedTime]: "ELAPSED_TIME",
  };
  const PREFIX = prefixMap[args.sortMethod];
  return `${PREFIX}_${SUFFIX}` as const;
};
