import { useSnapshot } from "valtio";

import { state } from "./State";
import * as Types from "./types";

const useSnapshotState = () => {
  return useSnapshot(state);
};

export const useRefreshKey = () => {
  const snapshot = useSnapshotState();
  return snapshot.refreshKey;
};

export const usePagination = () => {
  const snapshot = useSnapshotState();
  return snapshot.pagination;
};

export const useMetricsIdsForPagination = () => {
  const snapshot = useSnapshotState();
  const { page, size } = snapshot.pagination;
  const metricsIds = Array.from(snapshot.metricMap.keys());
  return metricsIds.slice(page * size, (page + 1) * size);
};

export const useMetrics = (): Types.Metric[] => {
  const snapshot = useSnapshotState();
  const ids = useMetricsIdsForPagination();

  return ids.reduce<Types.Metric[]>((all, id) => {
    const metric = snapshot.metricMap.get(id);
    if (!metric) return all;
    return all.concat(metric);
  }, []);
};

export const useMetricMap = () => {
  const snapshot = useSnapshotState();
  return snapshot.metricMap;
};

export const useSelectedMetricIds = () => {
  const snapshot = useSnapshotState();
  return snapshot.selectedMetricIds;
};
