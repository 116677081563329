import { SupportLanguageValue, useLanguageCode } from "@hireroo/i18n";
import Logo from "@hireroo/ui-assets/images/Logo/Logo.svg";
import LogoDarkEn from "@hireroo/ui-assets/images/Logo/LogoDarkEn.svg";
import LogoDarkJa from "@hireroo/ui-assets/images/Logo/LogoDarkJa.svg";
import LogoLightEn from "@hireroo/ui-assets/images/Logo/LogoLightEn.svg";
import LogoLightJa from "@hireroo/ui-assets/images/Logo/LogoLightJa.svg";
import { PaletteMode } from "@mui/material";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import * as React from "react";

type LogoKey = `${PaletteMode}_${SupportLanguageValue}`;

export type SignInHeaderProps = {
  withoutLogoText?: boolean;
};

const SignInHeader: React.FC<SignInHeaderProps> = props => {
  const theme = useTheme();
  const lang = useLanguageCode();
  const LogoMap: Record<LogoKey, string> = {
    dark_ja: LogoDarkJa,
    dark_en: LogoDarkEn,
    light_ja: LogoLightJa,
    light_en: LogoLightEn,
  };
  const logo: string = props.withoutLogoText ? Logo : LogoMap[`${theme.palette.mode}_${lang}`];

  return (
    <Box display={"flex"} alignSelf={"center"} mb={2}>
      <img src={logo} alt="HireRoo, Inc." width={200} />
    </Box>
  );
};

SignInHeader.displayName = "SignInHeader";

export default SignInHeader;
