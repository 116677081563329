import { proxy } from "valtio";
import { proxyMap } from "valtio/utils";

import { DEFAULT_PAGER } from "./constants";
import type * as Types from "./types";

export const state = proxy<Types.State>({
  initializedFilter: false,
  pager: DEFAULT_PAGER,
  res: null,
  applicantMap: proxyMap(),
  competitionIds: [],
});
