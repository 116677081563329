import { DeepReadonly } from "@hireroo/app-helper/types";
import { SystemDesignTestReport } from "@hireroo/app-store/view-domain/SystemDesignTestReport";
import type * as Graphql from "@hireroo/graphql/client/urql";
import { useTranslation } from "@hireroo/i18n";
import { Language, resolveLanguage } from "@hireroo/i18n/utils";
import type { Widget } from "@hireroo/presentation";

export const useStatusMessageMap = (): Record<Graphql.SystemDesignStatus, string> => {
  const { t } = useTranslation();
  return {
    CREATED: t("問題を開始していないため提出結果がありません。"),
    STARTED: t("問題は開始されましたが、まだ提出されておりません。"),
    COMPLETED: "",
    EVALUATED: "",
    UNKNOWN: "",
  };
};

type Section = Exclude<Widget.SystemDesignTestReportProps["followUpQuestionsSection"], undefined>["categorySections"][0];
type FollowUpQuestion = Section["questions"][number];

export const generateCategorySectionsFromFollowUpQuestions = (
  followUpQuestions: DeepReadonly<SystemDesignTestReport.Submission["followUpQuestions"]>,
  lang: Language,
  followUpQuestionPriorityMap: Record<Graphql.FollowUpQuestionPriority, string>,
  systemDesignCategoryMap: Record<Graphql.SystemDesignFollowUpQuestionCategory, string>,
): Section[] => {
  const categoryQuestionMap = new Map<string, FollowUpQuestion[]>();
  followUpQuestions.forEach(followUpQuestion => {
    const priority: FollowUpQuestion["priority"] =
      followUpQuestion.priority === "UNKNOWN"
        ? {
            kind: "LOW",
            label: followUpQuestionPriorityMap[followUpQuestion.priority],
          }
        : {
            kind: followUpQuestion.priority,
            label: followUpQuestionPriorityMap[followUpQuestion.priority],
          };

    const question: FollowUpQuestion = {
      question: resolveLanguage(followUpQuestion, lang, "question"),
      answer: resolveLanguage(followUpQuestion, lang, "modelAnswer"),
      purpose: resolveLanguage(followUpQuestion, lang, "purpose"),
      priority: priority,
    };

    const categoryText = systemDesignCategoryMap[followUpQuestion.systemDesignCategory];
    if (!categoryQuestionMap.has(categoryText)) {
      categoryQuestionMap.set(categoryText, [question]);
    } else {
      categoryQuestionMap.get(categoryText)?.push(question);
    }
  });

  return Array.from(categoryQuestionMap.entries()).map(([category, questions]) => ({
    title: category,
    questions: questions,
  }));
};
