import * as Graphql from "@hireroo/graphql/client/urql";
import { CandidateProfileForm } from "@hireroo/validator";

export const jobChangeInterestMap: Record<Graphql.JobChangeInterest, CandidateProfileForm.JobChangeStatus> = {
  [Graphql.JobChangeInterest.High]: "HIGH",
  [Graphql.JobChangeInterest.Medium]: "MIDDLE",
  [Graphql.JobChangeInterest.Low]: "LOW",
  [Graphql.JobChangeInterest.None]: "NONE",
  [Graphql.JobChangeInterest.Unknown]: "UNSET",
};

export const jobChangeInterestMapReverse: Record<CandidateProfileForm.JobChangeStatus, Graphql.JobChangeInterest> = {
  HIGH: Graphql.JobChangeInterest.High,
  MIDDLE: Graphql.JobChangeInterest.Medium,
  LOW: Graphql.JobChangeInterest.Low,
  NONE: Graphql.JobChangeInterest.None,
  UNSET: Graphql.JobChangeInterest.Unknown,
};
