import { useTranslation } from "@hireroo/i18n";
import Badge, { BadgeProps } from "@mui/material/Badge";
import Box from "@mui/material/Box";
import { styled, useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { useResizeDetector } from "react-resize-detector";

import CustomLabelTab, { CustomLabelTabProps } from "./parts/CustomLabelTab/CustomLabelTab";
import FollowUpQuestions, { FollowUpQuestionsProps } from "./parts/FollowUpQuestions/FollowUpQuestions";

const SidebarWrapper = styled(Box)`
  position: relative;
  overflow: hidden;
  height: 100%;
  width: 100%;
`;

const StyledBadge = styled(Badge)<BadgeProps>(() => ({
  "& .MuiBadge-badge": {
    top: 0,
    right: -8,
  },
}));

type TabId = "EVALUATION" | "CHAT" | "FOLLOW_UP_QUESTIONS";

const TAB_ID_MAP: Record<TabId, string> = {
  EVALUATION: "EVALUATION",
  CHAT: "CHAT",
  FOLLOW_UP_QUESTIONS: "FOLLOW_UP_QUESTIONS",
} as const;

export type RightSidePanelProps = {
  Evaluation?: React.ReactNode;
  Chat: React.ReactNode;
  followUpQuestions?: FollowUpQuestionsProps;
  hasChatBadge: boolean;
  defaultTab?: TabId;
};

const RightSidePanel: React.FC<RightSidePanelProps> = props => {
  const theme = useTheme();
  const { t } = useTranslation();
  const wrapperDetector = useResizeDetector();
  const customLabelTabProps: CustomLabelTabProps = {
    items: React.useMemo(() => {
      return [
        props.Evaluation && {
          id: TAB_ID_MAP.EVALUATION,
          Component: props.Evaluation,
          name: t("評価"),
          icon: "COMMENT" as const,
        },
        {
          id: TAB_ID_MAP.CHAT,
          Component: props.Chat,
          label: props.hasChatBadge && (
            <StyledBadge color="primary" variant={"dot"}>
              <Typography textTransform="none" variant="body2" fontSize={14}>
                {t("チャット")}
              </Typography>
            </StyledBadge>
          ),
          icon: "CHAT" as const,
          name: t("チャット"),
          // This is for tutorial
          className: "chat-tab",
        },
        props.followUpQuestions && {
          id: TAB_ID_MAP.FOLLOW_UP_QUESTIONS,
          Component: <FollowUpQuestions {...props.followUpQuestions} />,
          name: t("質問"),
          icon: "QUESTION" as const,
        },
      ].flatMap(v => (v ? [v] : []));
    }, [props.Chat, props.Evaluation, props.followUpQuestions, props.hasChatBadge, t]),
    tabBox: {
      sx: { padding: 0 },
    },
    tabPanelBox: {
      sx: {
        marginTop: 0,
        overflowY: "auto",
        padding: 0,
        bgcolor: theme.palette["Secondary/Shades"].p8,
      },
    },
    defaultTab: props.defaultTab,
  };
  return (
    <SidebarWrapper ref={wrapperDetector.ref}>
      <CustomLabelTab {...customLabelTabProps} />
    </SidebarWrapper>
  );
};

RightSidePanel.displayName = "RightSidePanel";

export default RightSidePanel;
