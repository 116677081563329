import { useTranslation } from "@hireroo/i18n";
import { CandidateSearchForm as CandidateSearchFormValidator } from "@hireroo/validator";

import type { SearchFormProps } from "../../modules/SearchForm/SearchForm";

type FieldProps = SearchFormProps["fields"][0];
export type MultiChoiceFieldProps = Extract<
  FieldProps,
  {
    kind: "MULTI_CHOICE";
  }
>;
export type RangeFieldProps = Extract<
  FieldProps,
  {
    kind: "RANGE";
  }
>;

type FieldKeyName = keyof CandidateSearchFormValidator.CandidateSearchQuerySchema;

const FieldName = {
  TEXT_FILTER: "textFilter",
  EXPECTED_SALARY: "expectedSalary",
  JOB_CHANGE_STATUSES: "jobChangeStatuses",
  AVERAGE_SCORE: "averageScore",
  AVERAGE_RANK: "averageRank",
} satisfies Record<string, FieldKeyName>;

export const useTextFilterField = (): SearchFormProps["textFilter"] => {
  const { t } = useTranslation();
  return {
    name: FieldName.TEXT_FILTER,
    placeholder: t("候補者検索"),
  };
};

export type ExpectedSalaryFieldArgs = Pick<RangeFieldProps, "customCurrentLabelFormat"> & {
  slider: {
    max: number;
    min: number;
    step: number;
    valueLabelFormat: Exclude<RangeFieldProps["slider"], undefined>["valueLabelFormat"];
  };
};

export const useExpectedSalaryField = (args: ExpectedSalaryFieldArgs): RangeFieldProps => {
  const { t } = useTranslation();
  return {
    ...args,
    kind: "RANGE",
    name: FieldName.EXPECTED_SALARY,
    title: t("希望年収"),
    label: t("希望年収"),
  };
};

type JobChangeStatuses = {
  displayName: string;
  value: CandidateSearchFormValidator.CandidateSearchQuerySchema["jobChangeStatuses"][0];
};

export const useJobChangeStatusesField = (): MultiChoiceFieldProps => {
  const { t } = useTranslation();
  const options: JobChangeStatuses[] = [
    { value: "HIGH", displayName: t("転職意欲高い") },
    { value: "MEDIUM", displayName: t("転職意欲普通") },
    { value: "LOW", displayName: t("転職意欲低い") },
    { value: "NONE", displayName: t("転職意欲なし") },
  ];
  return {
    kind: "MULTI_CHOICE",
    title: t("転職意欲"),
    name: FieldName.JOB_CHANGE_STATUSES,
    options: options,
  };
};

export const useAverageScoreField = (): RangeFieldProps => {
  const { t } = useTranslation();
  return {
    kind: "RANGE",
    name: FieldName.AVERAGE_SCORE,
    title: t("平均スコア"),
    label: t("平均スコア"),
  };
};

type AverageRankOption = {
  displayName: string;
  value: CandidateSearchFormValidator.CandidateSearchQuerySchema["averageRank"][0];
};

export const useAverageRankField = (): MultiChoiceFieldProps => {
  const { t } = useTranslation();
  const options: AverageRankOption[] = [
    { value: "S", displayName: `${t("ランク")} S` },
    { value: "A", displayName: `${t("ランク")} A` },
    { value: "B", displayName: `${t("ランク")} B` },
    { value: "C", displayName: `${t("ランク")} C` },
    { value: "D", displayName: `${t("ランク")} D` },
  ];
  return {
    kind: "MULTI_CHOICE",
    title: t("平均ランク"),
    name: FieldName.AVERAGE_RANK,
    options: options,
  };
};
