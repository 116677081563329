import { Pages } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { useGenerateProps } from "./useGenerateProps";

export type CandidateNeedEmailVerificationContainerProps = {};

const CandidateNeedEmailVerificationContainer: React.FC<CandidateNeedEmailVerificationContainerProps> = _props => {
  const needEmailVerification = useGenerateProps({});
  return (
    <ErrorBoundary>
      <Pages.NeedEmailVerification {...needEmailVerification} />
    </ErrorBoundary>
  );
};

CandidateNeedEmailVerificationContainer.displayName = "CandidateNeedEmailVerificationContainer";

export default withErrorBoundary(CandidateNeedEmailVerificationContainer, {});
