import { Pages } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { startSubscribeListParams } from "./Subscriber";
import { useGenerateProps } from "./useGenerateProps";

export type CompetitionListForCandidateContainerProps = {};

const CompetitionListForCandidateContainer: React.FC<CompetitionListForCandidateContainerProps> = () => {
  const competitionListForCandidateProps = useGenerateProps({});
  React.useEffect(() => {
    const stop = startSubscribeListParams();

    return () => {
      stop();
    };
  }, []);
  return (
    <ErrorBoundary>
      <Pages.CompetitionListForCandidate {...competitionListForCandidateProps} />
    </ErrorBoundary>
  );
};

CompetitionListForCandidateContainer.displayName = "CompetitionListForCandidateContainer";

export default withErrorBoundary(CompetitionListForCandidateContainer, {});
