import { languageMap } from "@hireroo/app-definition";
import { initializeSentry, Mixpanel } from "@hireroo/app-monitoring";
import * as RouterStore from "@hireroo/app-store/essential/router";
import { Snackbar } from "@hireroo/app-store/widget/shared/Snackbar";
import { getCurrentUser, initialize as initializeFirebase, listenAuthStateChanged, signOut } from "@hireroo/firebase";
import { createTemporallyGraphqlClient } from "@hireroo/graphql/client/request";
import type * as Graphql from "@hireroo/graphql/client/urql";
import { getLanguage, getTranslation, initialize as initializeI18n } from "@hireroo/i18n";
import { currentWindowMatchPath, getCurrentRoutingType, LastVisitedRoute, redirectWithRawUrl, safeRedirect } from "@hireroo/router/api";
import { RouteKey } from "@hireroo/router/config";
import * as Sentry from "@sentry/browser";

import { Auth } from "./auth";
import { Candidate } from "./candidate";
import { Employee } from "./employee";
import { CANDIDATE_TENANT_ID, initializeGraphqlClientSdkAndRestApiClient, shouldUseCandidateTenant } from "./helper";
import { InviteEmployee } from "./invite-employee";
import { InviteTalent } from "./invite-talent";
import { Talent } from "./talent";
import { Unknown } from "./unknown";

const initializeMonitoringTools = async () => {
  initializeSentry({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    environment: import.meta.env.VITE_SENTRY_ENVIRONMENT,
    release: SENTRY_RELEASE,
    enabled: import.meta.env.VITE_ENABLED_SENTRY === "true",
  });
  const mixpanelParamsMap: Record<string, Mixpanel.MixpanelInitializeParams> = {
    production: {
      token: import.meta.env.VITE_MIXPANEL_TOKEN,
      config: {
        track_pageview: true,
        debug: false,
      },
    },
    development: {
      token: import.meta.env.VITE_MIXPANEL_TOKEN,
      config: {
        track_pageview: false,
        debug: true,
      },
    },
  };
  Mixpanel.initialize(
    mixpanelParamsMap[import.meta.env.MODE] || {
      token: "TEST_TOKEN",
      config: {
        track_pageview: false,
        debug: false,
      },
    },
  );
};

const initializeServices = async () => {
  initializeI18n();
  const routingType = getCurrentRoutingType();
  // auth でも使う必要がある。テナントID次第
  const tenantId: string | undefined = shouldUseCandidateTenant(routingType) ? CANDIDATE_TENANT_ID : undefined;

  await initializeFirebase(
    {
      apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
      authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
      databaseURL: import.meta.env.VITE_FIREBASE_DATABASE_URL,
      projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
      storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET,
      messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID,
      appId: import.meta.env.VITE_FIREBASE_APP_ID,
      databaseQuizUrl: import.meta.env.VITE_FIREBASE_DATABASE_QUIZ_URL,
      databaseProjectUrl: import.meta.env.VITE_FIREBASE_DATABASE_PROJECT_URL,
      databaseSystemDesignUrl: import.meta.env.VITE_FIREBASE_DATABASE_SYSTEM_DESIGN_URL,
      databaseInterviewUrl: import.meta.env.VITE_FIREBASE_DATABASE_INTERVIEW_URL,
      databaseLiveCodingUrl: import.meta.env.VITE_FIREBASE_DATABASE_LIVE_CODING_URL,
    },
    tenantId,
  );
  const redirectUrl: RouteKey = shouldUseCandidateTenant(routingType) ? "/c/signin" : "/signin";
  listenAuthStateChanged({
    onAfterSignOut: () => {
      /** SPA transitions are not executed to volatilize the cache of States stored in the Store on sign-out. */
      safeRedirect(redirectUrl);
    },
  });
};

const execAction = async (
  action: Graphql.ClientSideInitializeAction,
  context?: {
    candidate?: Graphql.EssentialCandidateFragment;
  },
): Promise<void> => {
  const { t } = getTranslation();
  switch (action) {
    case "GO_SIGN_IN": {
      LastVisitedRoute.save();
      await safeRedirect("/signin");
      await Unknown.initialize({}).catch(error => {
        Sentry.captureException(error);
        RouterStore.updateErrorStatus("INITIALIZE_ERROR");
      });
      break;
    }
    case "GO_SIGN_UP": {
      LastVisitedRoute.save();
      await safeRedirect("/signup");
      await Unknown.initialize({}).catch(error => {
        Sentry.captureException(error);
        RouterStore.updateErrorStatus("INITIALIZE_ERROR");
      });
      break;
    }
    case "GO_CANDIDATE_SIGN_IN": {
      LastVisitedRoute.save();
      await safeRedirect("/c/signin");
      await Unknown.initialize({}).catch(error => {
        Sentry.captureException(error);
        RouterStore.updateErrorStatus("INITIALIZE_ERROR");
      });
      break;
    }
    case "GO_CANDIDATE_SIGN_UP": {
      LastVisitedRoute.save();
      await safeRedirect("/c/signup");
      await Unknown.initialize({}).catch(error => {
        Sentry.captureException(error);
        RouterStore.updateErrorStatus("INITIALIZE_ERROR");
      });
      break;
    }
    case "GO_CANDIDATE_HOME": {
      await safeRedirect("/c/home");
      break;
    }
    case "GO_CANDIDATE_VERIFY_EMAIL": {
      LastVisitedRoute.save();
      await safeRedirect("/c/verify_email");
      await Candidate.initialize({
        candidate: context?.candidate ?? null,
      }).catch(error => {
        Sentry.captureException(error);
        RouterStore.updateErrorStatus("INITIALIZE_ERROR");
      });
      break;
    }
    case "GO_RESET_PASSWORD": {
      await safeRedirect("/reset/password");
      await Unknown.initialize({}).catch(error => {
        Sentry.captureException(error);
        RouterStore.updateErrorStatus("INITIALIZE_ERROR");
      });
      break;
    }
    case "GO_CANDIDATE_RESET_PASSWORD": {
      await safeRedirect("/c/reset/password");
      await Unknown.initialize({}).catch(error => {
        Sentry.captureException(error);
        RouterStore.updateErrorStatus("INITIALIZE_ERROR");
      });
      break;
    }
    case "GO_EMPLOYEE_HOME": {
      await safeRedirect("/e/home");
      break;
    }
    case "STAY_CANDIDATE": {
      await Candidate.initialize({
        candidate: context?.candidate ?? null,
      }).catch(error => {
        Sentry.captureException(error);
        RouterStore.updateErrorStatus("INITIALIZE_ERROR");
      });
      break;
    }
    case "REFRESH_AUTH_TOKEN":
      await initializeUser();
      return;
    case "STAY_UNKNOWN": {
      const pathname = LastVisitedRoute.getAndClear();
      if (pathname && window.location.pathname !== pathname) {
        await redirectWithRawUrl(pathname);
      } else {
        await safeRedirect("/");
      }
      await Unknown.initialize({});
      break;
    }
    case "WAIT_UNTIL_INTERNAL_SERVER_ERROR": {
      Snackbar.notify({
        message: t("エラーが発生しました。しばらくしてからもう一度お試しください。"),
        severity: "error",
      });
      break;
    }
    case "GO_TALENT_HOME": {
      await safeRedirect("/t/assessments");
      break;
    }
    case "SHOULD_SIGN_OUT": {
      await signOut();
      await safeRedirect("/signin");
      break;
    }
    default:
      throw new Error(`Invalid action: ${action satisfies never}`);
  }
};

export const initializeUser = async () => {
  const routingType = getCurrentRoutingType();
  const currentUser = await getCurrentUser();

  const authToken = await currentUser?.getIdToken(true);
  const client = createTemporallyGraphqlClient(import.meta.env.VITE_GRAPHQL_SERVER_ADDRESS, {
    authToken,
    appVersion: APP_VERSION,
  });
  const currentBrowserLanguage = getLanguage() ?? "ja";
  const res = await client
    .InitializeApp({
      input: {
        appVersion: APP_VERSION,
        routeType: routingType,
        clientLanguage: languageMap[currentBrowserLanguage],
        tenantId: currentUser?.tenantId,
      },
    })
    .catch(error => {
      Sentry.captureException(error);
      throw error;
    });

  switch (res.initializeApp.__typename) {
    case "InitializedEmployeeUserResponse": {
      if (!authToken || !currentUser) {
        await safeRedirect("/signin");
        await Unknown.initialize({});
        return;
      }
      // Since client does not get refresh auth token, we need to refresh here to update claim
      const refreshedAuthToken = await currentUser.getIdToken(true);
      initializeGraphqlClientSdkAndRestApiClient(refreshedAuthToken);
      await Employee.initialize({
        currentUser,
        authToken: refreshedAuthToken,
        res: res.initializeApp,
      }).catch(error => {
        Sentry.captureException(error);
        RouterStore.updateErrorStatus("INITIALIZE_ERROR");
      });
      if (res.initializeApp.shouldAction) {
        await execAction(res.initializeApp.shouldAction, {});
      }
      break;
    }
    case "InitializedCandidateUserResponse": {
      await Candidate.initialize({
        candidate: res.initializeApp.candidate,
      }).catch(error => {
        Sentry.captureException(error);
        RouterStore.updateErrorStatus("INITIALIZE_ERROR");
      });
      if (res.initializeApp.shouldAction) {
        await execAction(res.initializeApp.shouldAction, {
          candidate: res.initializeApp.candidate ?? undefined,
        });
      }
      return;
    }
    case "InitializedTalentUserResponse": {
      if (!authToken || !currentUser) {
        await safeRedirect("/signin");
        await Unknown.initialize({});
        return;
      }
      await Talent.initialize({
        currentUser,
        authToken,
        res: res.initializeApp,
      }).catch(error => {
        Sentry.captureException(error);
        RouterStore.updateErrorStatus("INITIALIZE_ERROR");
      });
      if (res.initializeApp.shouldAction) {
        await execAction(res.initializeApp.shouldAction, {});
      }
      return;
    }
    case "InitializedInvitationEmployeeResponse": {
      await InviteEmployee.initialize({
        res: res.initializeApp,
      });
      break;
    }
    case "InitializedInvitationTalentResponse": {
      await InviteTalent.initialize({
        res: res.initializeApp,
      });
      break;
    }
    case "InitializedAuthResponse": {
      await Auth.initialize({
        res: res.initializeApp,
      }).catch(error => {
        Sentry.captureException(error);
        RouterStore.updateErrorStatus("INITIALIZE_ERROR");
      });
      break;
    }
    case "NeedClientSideActionResponse": {
      await execAction(res.initializeApp.action, {});
      break;
    }
    default: {
      throw new Error(`Unimplemented: ${res.initializeApp satisfies never}`);
    }
  }
};

const reportDeprecatedRouting = () => {
  if (currentWindowMatchPath("/c/interview-groups/:id")) {
    Sentry.captureMessage(`User visited "${window.location.pathname}".`, "warning");
  }
};

export const initializeApplication = async (): Promise<void> => {
  await initializeMonitoringTools();
  reportDeprecatedRouting();
  await initializeServices();
  await initializeUser();
};
