import { SystemDesignTestReport } from "@hireroo/app-store/view-domain/SystemDesignTestReport";
import { useLanguageCode, useTranslation } from "@hireroo/i18n";
import { resolveLanguage } from "@hireroo/i18n/utils";
import type { Widget } from "@hireroo/presentation";
import { parseGraphlets } from "@hireroo/system-design/helpers/flowChart";
import { getBool, getChild } from "@hireroo/system-design/helpers/judgmentCondition";

type SystemDesignScoringItemProps = Exclude<Widget.SystemDesignTestReportProps["editableScoreSection"], undefined>["availability"]["items"][0];

export type GenerateSystemDesignScoringItemPropsFactoryArgs = {
  entityId: number;
  showAnswer?: boolean;
};

export const useGenerateSystemDesignScoringItemPropsFactory = (args: GenerateSystemDesignScoringItemPropsFactoryArgs) => {
  const { entityId } = args;
  const { t } = useTranslation();
  const lang = useLanguageCode();
  const { useCurrentSelectedSubmissionSnapshot: useParsedFlowchartSnapshot } = SystemDesignTestReport.useCreateSystemDesignHooks(entityId);
  const parsedFlowChartResult = useParsedFlowchartSnapshot();
  return (item: SystemDesignTestReport.SoreTargetItem): SystemDesignScoringItemProps => {
    const parsedResult = parseGraphlets(item.graphlets);
    const isMatch: boolean = !!item.testResult.matched_ids && item.testResult.matched_ids.length > 0;

    return {
      name: `scoringItems.${item.index}`,
      mode: "READ",
      title: resolveLanguage(item, lang, "title", args.showAnswer ? undefined : t("閲覧制限されています")),
      isPassed: item.isPassed,
      description: resolveLanguage(item, lang, "description", args.showAnswer ? undefined : t("閲覧制限されています")),
      judgmentContent: {
        status: isMatch ? "MATCH" : "NOT_MATCHED",
        reason:
          item.testResult.reason_ja &&
          resolveLanguage(item.testResult, lang, "reason", args.showAnswer ? undefined : t("閲覧制限されています")),
        displayJudgmentCondition: {
          componentType: parsedFlowChartResult.result.componentType,
          patterns: getChild(parsedResult.result),
          parentBool: getBool(parsedResult.result),
        },
        staticFlowChart: parsedFlowChartResult.ok
          ? {
              ...parsedFlowChartResult.result,
              matchedIds: item.testResult.matched_ids,
            }
          : undefined,
      },
    };
  };
};
