import { useJobChangeInterestMap } from "@hireroo/app-definition/auth";
import { translateSalary } from "@hireroo/app-helper/competition";
import { Candidates } from "@hireroo/app-store/page/e/candidates";
import { formatScore } from "@hireroo/formatter/score";
import { useLanguageCode, useTranslation, useTranslationWithVariable } from "@hireroo/i18n";
import type { Widget } from "@hireroo/presentation";
import { generatePath } from "@hireroo/router/api";
import { useTransitionNavigate } from "@hireroo/router/hooks";
import React from "react";

import * as PrivateHelper from "./privateHelper";

type Item = Widget.CandidateListProps["table"]["items"][number];

export type GenerateCandidateListPropsArgs = {};

export const useGenerateProps = (_args: GenerateCandidateListPropsArgs): Widget.CandidateListProps => {
  const lang = useLanguageCode();
  const { t } = useTranslation();
  const { t: t2 } = useTranslationWithVariable();
  const navigate = useTransitionNavigate();

  const candidates = Candidates.useCandidates();
  const res = Candidates.useResponse();
  const pager = Candidates.usePager();

  const jobChangeInterestMap = useJobChangeInterestMap();

  const items = React.useMemo((): Item[] => {
    return candidates.map((candidate): Item => {
      return {
        id: candidate.id,
        user: {
          name: candidate.displayName,
          imgUrl: candidate.photoUrl.length > 0 ? candidate.photoUrl : undefined,
        },
        href: generatePath("/e/candidates/:id/detail", {
          pathParams: {
            id: candidate.candidateId,
          },
        }),
        onClick: () => {
          navigate("/e/candidates/:id/detail", {
            pathParams: {
              id: candidate.candidateId,
            },
          });
        },
        expectedSalary:
          candidate.expectedSalaryYen !== null && candidate.expectedSalaryYen > 0
            ? t2("ExpectedSalary", { salary: translateSalary({ salaryInTenK: candidate.expectedSalaryYen / 10000, lang, withCurrency: true }) })
            : t("未設定"),
        jobChangeStatus: jobChangeInterestMap[candidate.jobChangeInterest],
        averageScore: candidate.averageScore !== null ? formatScore(candidate.averageScore) : undefined,
        averageRank: candidate.averageRankEvaluation,
      };
    });
  }, [candidates, t2, lang, t, jobChangeInterestMap, navigate]);

  return {
    searchResultBar: {
      defaultValue: PrivateHelper.convertSortParamsToSortMethod(pager.sortParams),
      resultText: t2("SearchResultCount", { count: res.count }),
      onChangeSortMethod: value => {
        const target = PrivateHelper.sortMethodMap[value];
        Candidates.updateSortParams({
          sortMethod: target.sortMethod,
          isDescending: target.isDescending,
        });
      },
    },
    table: {
      items: items,
    },
    tablePagination: {
      count: res.count,
      rowsPerPage: pager.size,
      onRowsPerPageChange: event => {
        const newSize = Number(event.target.value);
        Candidates.updatePageSize(newSize);
      },
      page: pager.page,
      onPageChange: (_, newPage) => {
        Candidates.updatePage(newPage);
      },
    },
  };
};
