import type { Widget } from "@hireroo/presentation";

import CompetitionHistoryListFetchContainer from "../CompetitionHistoryList/FetchContainer";
export type GenerateCompetitionHistoryPropsArgs = {
  candidateId: string;
};

export const useGenerateProps = (args: GenerateCompetitionHistoryPropsArgs): Widget.CompetitionHistoryProps => {
  return {
    List: <CompetitionHistoryListFetchContainer candidateId={args.candidateId} />,
  };
};
