import { RANK_MAP } from "@hireroo/app-definition/interview";
import { ENTITY_REPORT_TOP } from "@hireroo/app-helper/entity";
import { useEnabledScoringFeedback } from "@hireroo/app-helper/feature";
import { Mixpanel } from "@hireroo/app-monitoring";
import { App, Auth, Company, Payment } from "@hireroo/app-store/essential/employee";
import { TestReport } from "@hireroo/app-store/widget/e/TestReport";
import { Snackbar } from "@hireroo/app-store/widget/shared/Snackbar";
import { formatScore } from "@hireroo/formatter/score";
import { getGraphqlClient } from "@hireroo/graphql/client/request";
import * as Graphql from "@hireroo/graphql/client/urql";
import { useCurrentLanguage, useTranslation, useTranslationWithVariable } from "@hireroo/i18n";
import { Widget } from "@hireroo/presentation";
import { useHelpCenterNavigate } from "@hireroo/router/hooks";
import * as Sentry from "@sentry/react";
import * as React from "react";

import TestRankVisualizerFetchContainer from "../../shared/TestRankVisualizer/FetchContainer";
import ChallengeTestReportFetchContainer from "../ChallengeTestReport/FetchContainer";
import ProjectTestReportFetchContainer from "../ProjectTestReport/FetchContainer";
import QuizTestReportFetchContainer from "../QuizTestReport/FetchContainer";
import SystemDesignTestReportFetchContainer from "../SystemDesignTestReport/FetchContainer";
import TestReportContentNotAvailableContainer from "../TestReportContentNotAvailable/FetchContainer";
import { generateSmoothTargetId } from "./privateHelper";
import { useGenerateEntityScoreSelector } from "./useGenerateEntityScoreSelector";
import { useGenerateFooterProps } from "./useGenerateFooterProps";

export type GenerateTestReportPropsArgs = {};

type DisplayScoreArea = Exclude<Widget.ScreeningTestReportProps["summary"]["scorePieChart"], undefined>["displayScoreArea"];

type Report = Widget.ScreeningTestReportProps["Reports"][0];

export const useGenerateProps = (_args: GenerateTestReportPropsArgs): Widget.ScreeningTestReportProps => {
  const appStatus = App.useStatus();
  const { t } = useTranslation();
  const { t: t2 } = useTranslationWithVariable();
  const activeCompanyId = Company.useStrictActiveCompanyId();
  const test = TestReport.useTest();
  const lang = useCurrentLanguage();
  const helpCenterNavigate = useHelpCenterNavigate(lang);
  const isAvailableFeature = Payment.useIsAvailableFeature();
  const footer = useGenerateFooterProps();
  const entityScoreSelector = useGenerateEntityScoreSelector();
  const selectedEntityId = TestReport.useSelectedEntityId();
  const enabledScoringFeedback = useEnabledScoringFeedback();
  const dialogStatus = TestReport.useDialogStatus();
  const currentUid = Auth.useCurrentUid();
  const client = getGraphqlClient();
  const refresh = TestReport.useRefresh();

  const Reports = React.useMemo(() => {
    /**
     * Release date 2023/5/1 0:00
     */
    const challengeTabAndPasteStatisticsReleaseDateSeconds = new Date(2023, 4, 1, 0, 0).getTime() / 1000;
    /**
     * Release date 2023/7/6 16:00
     */
    const challengeCheatDetectionSectionReleaseDateSeconds = new Date(2023, 6, 5, 16, 0).getTime() / 1000;
    /**
     * Release date 2024/1/4 0:00
     */
    const cheatDetectionReleaseDateSeconds = new Date(2024, 0, 3, 0, 0).getTime() / 1000;

    /**
     * For Quiz, Project, SystemDesign
     */
    const canShowCheatDetectionSection =
      isAvailableFeature("test.copy-and-paste.read") && (test.didStartAtSeconds ?? 0) >= cheatDetectionReleaseDateSeconds;

    return test.entities.map((entity): Report => {
      const smoothScrollTargetId = generateSmoothTargetId(entity);
      if (entity.__typename === "ChallengeEntity") {
        const canShowPasteAndTabStatisticsChallenge =
          isAvailableFeature("test.copy-and-paste.read") && (test.didStartAtSeconds ?? 0) >= challengeTabAndPasteStatisticsReleaseDateSeconds;
        const canShowCheatDetectSectionChallenge =
          isAvailableFeature("test.copy-and-paste.read") && (test.didStartAtSeconds ?? 0) >= challengeCheatDetectionSectionReleaseDateSeconds;
        const canShowFeedbackSuspiciousDegree = isAvailableFeature("test.suspicious-degree.edit");
        const canShowPlayback = isAvailableFeature("test.playback.read");
        const canShowStatistics = isAvailableFeature("test.statics.read");
        const canShowFollowUpQuestions = isAvailableFeature("interview.create");
        return {
          id: TestReport.generateUniqueEntityId(entity),
          smoothScrollTargetId,
          Content: activeCompanyId && (
            <ChallengeTestReportFetchContainer
              canShowPlayback={canShowPlayback}
              canShowStatistics={canShowStatistics}
              canShowCheatDetectionSection={canShowPasteAndTabStatisticsChallenge}
              canShowPasteAndTabStatistics={canShowCheatDetectSectionChallenge}
              canShowFeedbackSuspiciousDegree={canShowFeedbackSuspiciousDegree}
              canShowFollowUpQuestions={canShowFollowUpQuestions}
              showUsedHintSection={test.allowHint}
              companyId={activeCompanyId}
              challengeId={entity.challengeEntityId}
            />
          ),
        };
      } else if (entity.__typename === "QuizEntity") {
        return {
          id: TestReport.generateUniqueEntityId(entity),
          smoothScrollTargetId,
          Content: activeCompanyId && (
            <QuizTestReportFetchContainer
              key={entity.quizEntityId}
              quizId={entity.quizEntityId}
              companyId={activeCompanyId}
              featureKind="test"
              canShowCheatDetectionSection={canShowCheatDetectionSection}
            />
          ),
        };
      } else if (entity.__typename === "ProjectEntity") {
        const content =
          activeCompanyId &&
          (isAvailableFeature("test.project.read") ? (
            <ProjectTestReportFetchContainer
              featureKind="test"
              projectId={entity.projectEntityId}
              companyId={activeCompanyId}
              testId={test.id}
              kindOfReview="EMPLOYEE_REVIEW_INTERVIEW"
              canShowCheatDetectionSection={canShowCheatDetectionSection}
            />
          ) : (
            <TestReportContentNotAvailableContainer kind="PROJECT" />
          ));
        return {
          id: TestReport.generateUniqueEntityId(entity),
          smoothScrollTargetId,
          Content: content,
        };
      } else if (entity.__typename === "SystemDesignEntity") {
        const content =
          activeCompanyId &&
          (isAvailableFeature("test.systemDesign.read") ? (
            <SystemDesignTestReportFetchContainer
              featureKind="test"
              systemDesignId={entity.systemDesignEntityId}
              companyId={activeCompanyId}
              canShowCheatDetectionSection={canShowCheatDetectionSection}
              showUsedHintSection={test.allowHint}
            />
          ) : (
            <TestReportContentNotAvailableContainer kind="SYSTEM_DESIGN" />
          ));

        return {
          id: TestReport.generateUniqueEntityId(entity),
          smoothScrollTargetId,
          Content: content,
        };
      } else {
        throw new Error("unknown entity type");
      }
    });
  }, [activeCompanyId, isAvailableFeature, test.didStartAtSeconds, test.entities, test.id, test.allowHint]);

  const displayScoreAreaProps = React.useMemo((): DisplayScoreArea => {
    if (test.status !== "FINALIZED" && test.status !== "EVALUATED") {
      return { kind: "SCORING" };
    } else if (test.rankEvaluation === "UNKNOWN" || !isAvailableFeature("test.rank.read")) {
      return {
        kind: "SCORE_ONLY",
      };
    } else if (!test.isReliableRank) {
      return {
        kind: "WITH_RANK",
        rankLabel: {
          rank: "UNKNOWN",
          reason: t("提出時点のランクを表示するためのデータ数が足りないため表示できません。"),
        },
      };
    } else {
      return {
        kind: "WITH_RANK",
        rankLabel: {
          rank: RANK_MAP[test.rankEvaluation],
          reason: t2("RelativeScoreLabel", { score: formatScore(test.relativeScore) }),
        },
      };
    }
  }, [isAvailableFeature, test.rankEvaluation, test.relativeScore, test.status, test.isReliableRank, t, t2]);

  const isFinalizedOrEvaluated = test.status === "FINALIZED" || test.status === "EVALUATED";

  return {
    summary: {
      scorePieChart: {
        score: formatScore(test.customScore),
        displayScoreArea: displayScoreAreaProps,
      },
      scoringFeedbackButton:
        enabledScoringFeedback && !test.hasScoringPrecisionFeedback && isFinalizedOrEvaluated
          ? {
              onClick: () => {
                TestReport.updateDialogStatus("OPEN_SCORING_FEEDBACK");
                Mixpanel.track({
                  name: "TestReport-ScoringFeedbackDialog",
                  properties: {
                    openedBy: "ButtonClicked",
                  },
                });
              },
              disabled: false,
            }
          : undefined,
      status: test.status !== "FINALIZED" && test.status !== "EVALUATED" ? "NOT_READY" : "READY",
      entityScoreSelector: entityScoreSelector,
      enableStaticContent: isAvailableFeature("test.statics.read"),
      StatisticsContent: activeCompanyId && (
        <TestRankVisualizerFetchContainer
          companyId={activeCompanyId}
          spotId={test.id}
          finalizedAtSeconds={test.finalizedAtSeconds}
          pause={appStatus !== "INITIALIZED"}
          enableTagField
          enableRank={test.isReliableRank}
          rankEvaluation={test.rankEvaluation}
        />
      ),
      helpLink: {
        onClick: () => {
          helpCenterNavigate("CHECK_REPORT", { _blank: true });
        },
      },
      showSuspiciousBehaviorText:
        isAvailableFeature("test.suspicious-degree.read") && test.suspiciousDegree === Graphql.SpotSuspiciousDegree.Suspicious,
    },
    scoringFeedbackDialog: enabledScoringFeedback
      ? {
          dialog: {
            open: dialogStatus === "OPEN_SCORING_FEEDBACK",
            onClose: () => {
              TestReport.updateDialogStatus("CLOSED");
            },
          },
          onSubmit: (fields, controller) => {
            controller.setLoading(true);
            client
              .SendScoringPrecisionFeedbackForScreeningTestReport({
                input: {
                  spotId: test.spotId,
                  employeeId: currentUid,
                  answer: fields.answer,
                  comment: fields.comment,
                },
              })
              .then(() => {
                Snackbar.notify({
                  severity: "success",
                  message: t("フィードバックを送信しました。"),
                });
                refresh();
                controller.close();
              })
              .catch(error => {
                Sentry.captureException(error);
                Snackbar.notify({
                  severity: "error",
                  message: t("フィードバックの送信に失敗しました。"),
                });
              })
              .finally(() => {
                controller.setLoading(false);
              });
          },
        }
      : undefined,

    footer: footer,
    Reports: Reports,
    selectedEntityId: selectedEntityId || "",
    targetElementId: ENTITY_REPORT_TOP,
  };
};
