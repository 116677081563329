import { useSnapshot } from "valtio";

import { state } from "./State";

const useSnapshotState = () => {
  return useSnapshot(state);
};

export const useInitialized = (): boolean => {
  const snapshot = useSnapshotState();
  return !!snapshot.candidate;
};

export const useCandidate = () => {
  const snapshot = useSnapshotState();
  if (!snapshot.candidate) {
    throw new Error("Candidate is not initialized");
  }
  return snapshot.candidate;
};
