import { CompetitionsIdDetail } from "@hireroo/app-store/page/e/competitions_id_detail";
import { useTranslation } from "@hireroo/i18n";
import type { Widget } from "@hireroo/presentation";
import * as React from "react";

import CompetitionApplicantListFetchContainer from "../CompetitionApplicantList/FetchContainer";

export type GenerateCompetitionApplicantsPropsArgs = {};

export const useGenerateProps = (_args: GenerateCompetitionApplicantsPropsArgs): Widget.CompetitionApplicantsProps => {
  const { t } = useTranslation();
  const initializedApplicantFilter = CompetitionsIdDetail.useInitializedApplicantFilter();
  const filter = CompetitionsIdDetail.useApplicantFilter();
  const defaultValues = React.useMemo((): Widget.CompetitionApplicantsProps["searchForm"]["defaultValues"] => {
    return {
      textFilter: filter.textFilter,
      score: {
        min: filter.score.min,
        max: filter.score.max,
      },
      statuses: filter.statuses.slice(),
      suspiciousDegrees: filter.suspiciousDegrees.slice(),
    };
  }, [filter.score.max, filter.score.min, filter.statuses, filter.suspiciousDegrees, filter.textFilter]);
  return {
    searchForm: {
      disabled: !initializedApplicantFilter,
      defaultValues: defaultValues,
      onChange: fields => {
        CompetitionsIdDetail.updateApplicantFilter(fields);
      },
      placeholder: t("参加者検索"),
    },
    List: <CompetitionApplicantListFetchContainer />,
  };
};
