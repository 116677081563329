import { useSnapshot } from "valtio";

import { state } from "./State";

const useSnapshotState = () => {
  return useSnapshot(state);
};

export const useInitialized = (): boolean => {
  const snapshot = useSnapshotState();
  return !!snapshot.competition;
};

export const useCompetition = () => {
  const snapshot = useSnapshotState();
  if (!snapshot.competition) {
    throw new Error("Competition is not initialized");
  }
  return snapshot.competition;
};

export const useDialogStatus = () => {
  const snapshot = useSnapshotState();

  return snapshot.dialogStatus;
};

export const useLoadingStatus = () => {
  const snapshot = useSnapshotState();

  return snapshot.loadingStatus;
};

export const useApplicant = () => {
  const snapshot = useSnapshotState();
  return snapshot.applicant;
};
