import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { GenerateCandidateNeedEmailVerificationPropsArgs, useGenerateProps } from "./useGenerateProps";

export type CandidateNeedEmailVerificationContainerProps = GenerateCandidateNeedEmailVerificationPropsArgs;

const CandidateNeedEmailVerificationContainer: React.FC<CandidateNeedEmailVerificationContainerProps> = props => {
  const emailVerificationScreenProps = useGenerateProps(props);
  return (
    <ErrorBoundary>
      <Widget.EmailVerificationScreen {...emailVerificationScreenProps} />
    </ErrorBoundary>
  );
};

CandidateNeedEmailVerificationContainer.displayName = "CandidateNeedEmailVerificationContainer";

export default withErrorBoundary(CandidateNeedEmailVerificationContainer, {});
