import { state } from "./State";
import type * as Types from "./types";

export const setCurrentUser = (user: Types.User): void => {
  state.currentUser = user;
};

export const clear = (): void => {
  state.currentUser = null;
};
