import "firebase/compat/auth";

import firebase from "firebase/compat/app";

import * as App from "./app";

export type InitializeParameter = {
  apiKey: string;
  authDomain: string;
  databaseURL: string;
  projectId: string;
  storageBucket: string;
  messagingSenderId: string;
  appId: string;
  databaseQuizUrl: string;
  databaseProjectUrl: string;
  databaseSystemDesignUrl: string;
  databaseInterviewUrl: string;
  databaseLiveCodingUrl: string;
};

export const initialize = (parameter: InitializeParameter, tenantId?: string): Promise<void> => {
  const app = firebase.initializeApp(parameter);
  App.setupApp({
    challenge: {
      ...parameter,
      databaseURL: parameter.databaseURL,
    },
    systemDesign: {
      ...parameter,
      databaseURL: parameter.databaseSystemDesignUrl,
    },
    project: {
      ...parameter,
      databaseURL: parameter.databaseProjectUrl,
    },
    quiz: {
      ...parameter,
      databaseURL: parameter.databaseQuizUrl,
    },
    interview: {
      ...parameter,
      databaseURL: parameter.databaseInterviewUrl,
    },
    liveCoding: {
      ...parameter,
      databaseURL: parameter.databaseLiveCodingUrl,
    },
  });
  return new Promise(resolve => {
    const auth = app.auth();
    /**
     * 一度 auth.tenantId を指定したあとに `firebase.auth()` を実行すると
     * その後のリクエストは指定した tenant に対するリクエストとなる。
     * tenantId を上書きしない限り、 tenant は切り替わらないので注意。
     * @see https://cloud.google.com/identity-platform/docs/multi-tenancy-authentication?hl=ja#sign_in_with_tenants
     */
    auth.tenantId = tenantId ?? null;
    auth.onAuthStateChanged(() => {
      resolve();
    });
  });
};
