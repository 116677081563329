import { useEnabledSinSClassPhase2 } from "@hireroo/app-helper/feature";
import { Auth } from "@hireroo/app-store/essential/employee";
import { RemoteInterviewResourceEditorStore } from "@hireroo/app-store/view-domain/RemoteInterviewResourceEditor";
import { FillRemoteTemplateList } from "@hireroo/app-store/widget/e/FillRemoteTemplateList";
import { getGraphqlClient } from "@hireroo/graphql/client/request";
import { useLanguageCode } from "@hireroo/i18n";
import type { Widget } from "@hireroo/presentation";
import { generateHelpCenterUrl } from "@hireroo/router/api";
import { RemoteInterviewResourceEditor } from "@hireroo/validator";
import * as Sentry from "@sentry/react";
import * as React from "react";
import { useSearchParams } from "react-router-dom";

import QuestionSelectFieldV2InitialContainer from "../QuestionSelectFieldV2/InitialContainer";
import SpotTagFieldContainer from "../SpotTagField/Container";
import ViewerAssignFieldContainer from "../ViewerAssignField/Container";
import { useGenerateConfirmSectionProps } from "./useGenerateConfirmSectionProps";
import EvaluationMetricsSectionContainer from "./widgets/EvaluationMetricsSection/Container";
import FillTemplateDialogContainer from "./widgets/FillTemplateDialog/Container";

const TEMPLATE_ID = "templateId";

export type GenerateRemoteInterviewResourceEditorPropsArgs = {
  mode: Widget.RemoteInterviewResourceEditorProps["mode"];
  defaultValues?: RemoteInterviewResourceEditor.CreateRemoteInterviewSchema;
  loading: boolean;
  onSubmit: (fields: RemoteInterviewResourceEditor.CreateRemoteInterviewSchema) => void;
};

export const useGenerateProps = (args: GenerateRemoteInterviewResourceEditorPropsArgs): Widget.RemoteInterviewResourceEditorProps => {
  const confirmSection = useGenerateConfirmSectionProps();
  const lang = useLanguageCode();
  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const [searchParams, setSearchParams] = useSearchParams();
  const [templateId, setTemplateId] = React.useState(searchParams.get(TEMPLATE_ID) ? Number(searchParams.get(TEMPLATE_ID)) : null);
  const currentUid = Auth.useCurrentUid();
  const client = getGraphqlClient();
  const questionKeyInheritedEntitySourceMap = RemoteInterviewResourceEditorStore.useQuestionKeyInheritedEntitySourceMap();
  const enabledSinSClassPhase2 = useEnabledSinSClassPhase2();

  React.useEffect(() => {
    if (templateId && templateId > 0) {
      client
        .GetTemplateForRemoteInterviewResourceEditor({
          templateId: templateId,
        })
        .then(res => {
          FillRemoteTemplateList.addTemplate(res.remoteInterviewTemplate);
          setOpen(true);
          FillRemoteTemplateList.initializeSelectedTemplate(templateId);
          FillRemoteTemplateList.updateStep("CONFIRM");
        })
        .catch(error => {
          Sentry.captureException(error);
        })
        .finally(() => {
          setSearchParams(prev => {
            prev.delete(TEMPLATE_ID);
            return prev;
          });
          setTemplateId(null);
        });
    }
  }, [client, setSearchParams, templateId]);

  const defaultValues: RemoteInterviewResourceEditor.CreateRemoteInterviewSchema = args.defaultValues || {
    remoteQuestionSetup: {
      name: "",
      candidateName: "",
      entityTracks: [],
    },
    remoteReportSetup: {
      memo: undefined,
      isPublic: true,
      editorUid: currentUid,
      viewers: [],
      tags: [],
      evaluationMetricIds: [],
    },
  };
  const inheritedQuestionKeySet = React.useMemo(() => {
    return new Set(questionKeyInheritedEntitySourceMap.keys());
  }, [questionKeyInheritedEntitySourceMap]);

  return {
    mode: args.mode,
    questionSetupSection: {
      mode: args.mode,
      QuestionSelectField: (
        <QuestionSelectFieldV2InitialContainer
          name="entityTracks"
          target="remote"
          enabledRestoreQuestionFromQueryParams={args.mode === "CREATE"}
          enableMultiSelectQuestion={false}
          inheritedQuestionKeySet={inheritedQuestionKeySet}
        />
      ),
      defaultValues: defaultValues.remoteQuestionSetup,
      onSubmit: fields => {
        const selectedQuestionKeys = fields.entityTracks.reduce<Set<string>>((questionKeys, track) => {
          if (track.type === "FIXED") {
            questionKeys.add(track.entitySource.uniqueKey);
          }
          return questionKeys;
        }, new Set());
        Array.from(questionKeyInheritedEntitySourceMap.keys()).forEach(key => {
          if (!selectedQuestionKeys.has(key)) {
            RemoteInterviewResourceEditorStore.deleteQuestionKeyInheritedEntitySource(key);
          }
        });
        RemoteInterviewResourceEditorStore.setSubmitValue("REMOTE_QUESTION_SETUP", fields);
      },
    },
    reportSection: {
      ViewerField: <ViewerAssignFieldContainer name="viewers" variant="OUTLINED" />,
      TagField: <SpotTagFieldContainer name="tags" variant="OUTLINED" />,
      defaultValues: defaultValues.remoteReportSetup,
      onSubmit: fields => {
        RemoteInterviewResourceEditorStore.setSubmitValue("REPORT_SETUP", fields);
      },
      MetricsSection: enabledSinSClassPhase2 ? <EvaluationMetricsSectionContainer /> : undefined,
    },
    confirmSection: confirmSection,
    layout: {
      loading: args.loading,
      helpLink: {
        href: generateHelpCenterUrl(lang, "REMOTE_CREATE_PAGE"),
      },
    },
    onSubmit: args.onSubmit,
    templateButton:
      args.mode === "CREATE"
        ? {
            onClick: () => {
              setOpen(true);
            },
          }
        : undefined,
    Dialog: <FillTemplateDialogContainer open={open} onClose={handleClose} />,
  };
};
