import * as React from "react";

import EmptyLayout, { EmptyLayoutProps } from "../../layouts/EmptyLayout/EmptyLayout";

export type NeedEmailVerificationProps = {
  layout: EmptyLayoutProps;
  children: React.ReactNode;
};

const NeedEmailVerification: React.FC<NeedEmailVerificationProps> = props => {
  return <EmptyLayout {...props.layout}>{props.children}</EmptyLayout>;
};

NeedEmailVerification.displayName = "NeedEmailVerification";

export default NeedEmailVerification;
