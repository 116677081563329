import { useTranslation } from "@hireroo/i18n";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";

import QuestionOverviewTable, { QuestionOverviewTableProps } from "../../ms-components/Question/QuestionOverviewTable/QuestionOverviewTable";
import Section, { SectionProps } from "./parts/Section/Section";
import SideSectionItem, { SideSectionItemProps } from "./parts/SideSectionItem/SideSectionItem";

const StyledBox = styled(Box)(({ theme }) => ({
  background: theme.palette["Background/Paper"].p1,
  padding: theme.spacing(2),
  borderRadius: theme.spacing(2),
}));

export type CompetitionOverviewProps = {
  description: string;
  salary: string;
  timeLimit: string;
  questionTable?: QuestionOverviewTableProps;
  questionCount?: string;
  hostCompany: Pick<SideSectionItemProps, "text" | "imgUrl">;
  createdBy?: Pick<SideSectionItemProps, "text" | "imgUrl">;
};

const CompetitionOverview: React.FC<CompetitionOverviewProps> = props => {
  const { t } = useTranslation();
  const sections: SectionProps[] = [
    {
      title: t("概要"),
      children: <Typography>{props.description}</Typography>,
    },
    {
      title: t("想定年収"),
      children: <Typography>{props.salary}</Typography>,
    },
    {
      title: t("制限時間"),
      children: <Typography>{props.timeLimit}</Typography>,
    },
    props.questionCount && {
      title: t("問題数"),
      children: <Typography>{props.questionCount}</Typography>,
    },
    props.questionTable && {
      title: t("出題問題"),
      children: (
        <StyledBox>
          <QuestionOverviewTable {...props.questionTable} />
        </StyledBox>
      ),
    },
  ].flatMap(v => (v ? [v] : []));

  const sideSections: SideSectionItemProps[] = [
    {
      title: t("主催"),
      kind: "COMPANY" as const,
      ...props.hostCompany,
    },
    props.createdBy && {
      title: t("作成者"),
      kind: "USER" as const,
      ...props.createdBy,
    },
  ].flatMap(v => (v ? [v] : []));

  return (
    <Box p={3} display="flex" flexDirection="row" gap={7} width="100%" justifyContent="space-between">
      <Stack spacing={5} minWidth="70%">
        {sections.map(section => (
          <Section key={section.title} {...section} />
        ))}
      </Stack>
      <Stack>
        {sideSections.map(sideSection => (
          <SideSectionItem key={sideSection.title} {...sideSection} />
        ))}
      </Stack>
    </Box>
  );
};

CompetitionOverview.displayName = "CompetitionOverview";

export default CompetitionOverview;
