import { LanguageMap, languageMap } from "@hireroo/app-definition";
import * as ErrorHandlingHelper from "@hireroo/app-helper/error-handling";
import { Auth } from "@hireroo/app-store/essential/candidate";
import { Snackbar } from "@hireroo/app-store/widget/shared/Snackbar";
import { getGraphqlClient } from "@hireroo/graphql/client/request";
import * as Graphql from "@hireroo/graphql/server/types";
import { useChangeLanguage, useLanguageCode, useTranslation } from "@hireroo/i18n";
import type { Widget } from "@hireroo/presentation";
import { generateHelpCenterUrl } from "@hireroo/router/api";
import * as Sentry from "@sentry/browser";
import * as React from "react";

import CandidateMenuContainer from "../CandidateMenu/Container";

export type GenerateCandidateHeaderRightContentPropsArgs = {};

export const useGenerateProps = (_args: GenerateCandidateHeaderRightContentPropsArgs): Widget.CandidateHeaderRightContentProps => {
  const { t } = useTranslation();
  const currentCandidate = Auth.useCurrentCandidate();
  const client = getGraphqlClient();
  const lang = useLanguageCode();
  const changeLanguage = useChangeLanguage();

  const updateLanguage = React.useCallback(
    async (lang: Graphql.Language): Promise<void> => {
      await client
        .UpdateCandidateLanguageForCandidateHeaderRightContent({
          input: {
            candidateId: currentCandidate.candidateId,
            language: lang,
          },
        })
        .then(res => {
          changeLanguage(LanguageMap[res.updateCandidate.language]);
          Snackbar.notify({
            severity: "success",
            message: t("言語設定を更新しました。"),
          });
          Auth.setCandidate(res.updateCandidate);
        })
        .catch(error => {
          Sentry.captureException(error);
          const errorNotification = ErrorHandlingHelper.generateErrorNotification(
            error,
            t("言語設定の更新に失敗しました。しばらくしてから再度お試し頂くか、ヘルプボタンよりお問い合わせください。"),
          );
          Snackbar.notify({
            severity: "error",
            message: errorNotification.message,
          });
        });
    },
    [changeLanguage, client, currentCandidate.candidateId, t],
  );

  const helpPageUrl = React.useMemo(() => {
    return generateHelpCenterUrl(lang, "ROOT");
  }, [lang]);

  return {
    CandidateMenu: <CandidateMenuContainer />,
    NotificationButton: undefined, // TODO: implement candidate notification
    helpButton: {
      href: helpPageUrl,
      target: "_blank",
    },
    preferences: {
      languageDropDownMenu: {
        onChangeLanguage: language => {
          updateLanguage(languageMap[language]);
        },
      },
    },
  };
};
