import * as React from "react";

import CandidateLayout, { CandidateLayoutProps } from "../../layouts/CandidateLayout/CandidateLayout";

export type CompetitionIdDetailForCandidateProps = {
  layout: CandidateLayoutProps;
  children: React.ReactNode;
};

const CompetitionIdDetailForCandidate: React.FC<CompetitionIdDetailForCandidateProps> = props => {
  return <CandidateLayout {...props.layout}>{props.children}</CandidateLayout>;
};

CompetitionIdDetailForCandidate.displayName = "CompetitionIdDetailForCandidate";

export default CompetitionIdDetailForCandidate;
