import { Candidates } from "@hireroo/app-store/page/e/candidates";
import * as Graphql from "@hireroo/graphql/client/urql";
import type { Widget } from "@hireroo/presentation";

type SortMethod = Parameters<Widget.CandidateListProps["searchResultBar"]["onChangeSortMethod"]>[0];

export const sortMethodMap: Record<SortMethod, Candidates.SortParams> = {
  AVERAGE_RANK_ASCENDING: { sortMethod: Graphql.CandidateSortMethod.AverageRank, isDescending: false },
  AVERAGE_RANK_DESCENDING: { sortMethod: Graphql.CandidateSortMethod.AverageRank, isDescending: true },
  AVERAGE_SCORE_ASCENDING: { sortMethod: Graphql.CandidateSortMethod.AverageScore, isDescending: false },
  AVERAGE_SCORE_DESCENDING: { sortMethod: Graphql.CandidateSortMethod.AverageScore, isDescending: true },
  EXPECTED_SALARY_ASCENDING: { sortMethod: Graphql.CandidateSortMethod.ExpectedSalary, isDescending: false },
  EXPECTED_SALARY_DESCENDING: { sortMethod: Graphql.CandidateSortMethod.ExpectedSalary, isDescending: true },
};

const isValidSortMethod = (value: string): value is SortMethod => {
  return Object.keys(sortMethodMap).includes(value);
};

export const convertSortParamsToSortMethod = (sortParams: Candidates.SortParams): SortMethod => {
  for (const [key, value] of Object.entries(sortMethodMap)) {
    if (value.sortMethod === sortParams.sortMethod && value.isDescending === sortParams.isDescending) {
      if (isValidSortMethod(key)) {
        return key;
      }
    }
  }
  return "AVERAGE_SCORE_DESCENDING";
};
