import { useTranslation } from "@hireroo/i18n";
import Notify from "@hireroo/ui-assets/images/Error/Notify.svg";
import { Stack } from "@mui/material";
import Box from "@mui/material/Box";
import Button, { ButtonProps } from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import * as React from "react";

export type EmailVerificationScreenProps = {
  resendButton: Pick<ButtonProps, "onClick" | "disabled">;
  signOutButton: Pick<ButtonProps, "onClick" | "disabled">;
};

const EmailVerificationScreen: React.FC<EmailVerificationScreenProps> = props => {
  const { t } = useTranslation();
  const title = t("メールアドレスの検証を行なってください");
  const descriptionFirstLine = t("登録いただいたメールアドレス宛に、検証用のリンクを含んだメールが送付されています。");
  const descriptionSecondLine = t("メール本文に記載されたリンクをクリックして、メールアドレスを検証してください。");
  const resendButton: ButtonProps = {
    color: "secondary",
    children: t("メールを再送する"),
    sx: {
      textTransform: "none",
    },
    ...props.resendButton,
  };
  const signOutButton: ButtonProps = {
    color: "secondary",
    children: t("サインアウト"),
    sx: {
      textTransform: "none",
    },
    ...props.signOutButton,
  };

  return (
    <Box width="100vw" height="100vh" bgcolor="background.default" justifyContent="center" alignItems="center" display="flex">
      <Box mx={10} justifyContent="center" alignItems="center" display="flex">
        {/* TODO: メアド検証用の image に変更する */}
        <img src={Notify} width={300} alt="finish-notify" />
        <Box ml={2} />
        <Box display="flex" flexDirection="column" mb={1} mx={2}>
          <Typography variant="h4" gutterBottom>
            {title}
          </Typography>

          <Typography variant="body1" component="p" gutterBottom sx={{ lineHeight: 1.8 }}>
            {descriptionFirstLine}
            <br />
            {descriptionSecondLine}
          </Typography>
          <Stack spacing={1} mt={1} direction="row" alignItems="center" divider={<span>/</span>}>
            <Button {...resendButton} />
            <Button {...signOutButton} />
          </Stack>
        </Box>
      </Box>
    </Box>
  );
};

EmailVerificationScreen.displayName = "EmailVerificationScreen";

export default EmailVerificationScreen;
