import { DeepReadonly } from "@hireroo/app-helper/types";
import { CompetitionResultReport } from "@hireroo/app-store/widget/e/CompetitionResultReport";

export const generateSmoothTargetId = (entity: DeepReadonly<CompetitionResultReport.Entity>): string => {
  switch (entity.__typename) {
    case "ChallengeEntity": {
      return `challenge-${entity.challengeEntityId}-${entity.challengeQuestion?.questionId}-${entity.challengeQuestion?.version}`;
    }
    default: {
      return "";
    }
  }
};
