import List from "@mui/material/List";
import { styled } from "@mui/material/styles";
import * as React from "react";

import type * as Types from "../../types";
import ChildNavigationMenuFactory, { ChildNavigationMenuFactoryProps } from "./parts/ChildNavigationMenuFactory/ChildNavigationMenuFactory";
import NavigationMenuItem from "./parts/NavigationMenuItem/NavigationMenuItem";

const StyledList = styled(List)(() => ({
  padding: 0,
}));

export type NavigationMenuV2Props = {
  root?: boolean;
  items: Types.MenuItem[];
};

const NavigationMenuV2: React.FC<NavigationMenuV2Props> = props => {
  return (
    <StyledList>
      {props.items.map(item => {
        if ("title" in item) {
          const childNavigationMenuFactoryProps: ChildNavigationMenuFactoryProps = {
            ...item,
            NavigationMenu: NavigationMenuV2,
          };
          return <ChildNavigationMenuFactory key={item.id} {...childNavigationMenuFactoryProps} />;
        }
        return <NavigationMenuItem key={item.id} {...item} root={props.root} />;
      })}
    </StyledList>
  );
};

NavigationMenuV2.displayName = "NavigationMenuV2";

export default NavigationMenuV2;
