import { useTitle } from "@hireroo/app-helper/react-use";
import { QuestionsStore } from "@hireroo/app-store/page/e/questions";
import { EmployeeSideBarContent } from "@hireroo/app-store/widget/e/EmployeeSideBarContent";
import { useCurrentLanguage, useTranslation } from "@hireroo/i18n";
import type { Pages } from "@hireroo/presentation";
import { generatePath } from "@hireroo/router/api";
import { useTransitionNavigate } from "@hireroo/router/hooks";
import React from "react";
import { useSearchParams } from "react-router-dom";

import EmployeeHeaderRightContentContainer from "../../../../widget/v2/e/EmployeeHeaderRightContent/Container";
import EmployeeSideBarContentContainer from "../../../../widget/v2/e/EmployeeSideBarContent/Container";
import FooterContainer from "../../../../widget/v2/e/Footer/Container";
import { useNotificationBanner } from "../../../../widget/v2/e/NotificationBanner/Container";
import SnackbarContainer from "../../../../widget/v2/shared/Snackbar/Container";
import * as PrivateHelper from "./privateHelper";
import QuestionSearchAreaContainer from "./widgets/QuestionSearchArea/Container";
import QuestionTableFetchContainer from "./widgets/QuestionTable/FetchContainer";

export type GenerateQuestionListPropsArgs = {};

export const useGenerateProps = (_args: GenerateQuestionListPropsArgs): Pages.QuestionListProps => {
  const { t } = useTranslation();
  const navigate = useTransitionNavigate();
  const NotificationBannerContainer = useNotificationBanner();
  const initializedCurrentSearchFilter = QuestionsStore.useInitializedCurrentSearchFilter();
  const language = useCurrentLanguage();
  const [searchParams] = useSearchParams();
  useTitle(t("問題一覧"));

  React.useEffect(() => {
    QuestionsStore.initializeFilters(
      {
        textFilter: searchParams.get(QuestionsStore.QueryKeys.TITLE) ?? "",
        statuses: PrivateHelper.convertStringsToStatuses(searchParams.getAll(QuestionsStore.QueryKeys.STATUS)),
        questionVariants: PrivateHelper.convertStringsToQuestionVariants(searchParams.getAll(QuestionsStore.QueryKeys.QUESTION_VARIANT)),
        difficulties: PrivateHelper.convertStringsToDifficulties(searchParams.getAll(QuestionsStore.QueryKeys.DIFFICULTY)),
        skillTags: PrivateHelper.convertStringsToSkillTags(searchParams.getAll(QuestionsStore.QueryKeys.SKILL_TAG)),
        leakScoreLevels: PrivateHelper.convertStringsToLeakScoreLevels(searchParams.getAll(QuestionsStore.QueryKeys.LEAK_SCORE_LEVEL)),
      },
      {
        page: PrivateHelper.convertStringsToPage(searchParams.get(QuestionsStore.QueryKeys.PAGE)),
        size: PrivateHelper.convertStringToSize(searchParams.get(QuestionsStore.QueryKeys.SIZE)),
        sortFieldValue: PrivateHelper.convertStringToSortFieldValue(searchParams.get(QuestionsStore.QueryKeys.SORT)),
      },
    );
  }, [language, searchParams]);

  return {
    layout: {
      openSidebar: EmployeeSideBarContent.useOpenSidebar(),
      onChangeOpenSidebar: open => {
        EmployeeSideBarContent.setOpenSidebar(open);
      },
      navigationBreadcrumbs: {
        parentLinks: [
          {
            href: generatePath("/e/home"),
            children: t("ホーム"),
            onClick: () => {
              navigate("/e/home");
            },
          },
        ],
        current: t("問題一覧"),
      },
      NotificationBanner: NotificationBannerContainer,
      HeaderRightContent: <EmployeeHeaderRightContentContainer />,
      SideBarContent: <EmployeeSideBarContentContainer />,
      Footer: <FooterContainer />,
      Snackbar: <SnackbarContainer />,
      sideBarHeader: {
        logo: {
          href: generatePath("/e/home"),
          onClick: () => {
            navigate("/e/home");
          },
        },
      },
    },
    SearchArea: initializedCurrentSearchFilter ? <QuestionSearchAreaContainer /> : null,
    QuestionTable: initializedCurrentSearchFilter ? <QuestionTableFetchContainer /> : null,
  };
};
