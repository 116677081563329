import LabelOutlinedIcon from "@mui/icons-material/LabelOutlined";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { styled, useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";

const StyledStack = styled(Stack)(({ theme }) => ({
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1),
  paddingRight: theme.spacing(1.5),
  paddingLeft: theme.spacing(1.5),
  backgroundColor: theme.palette.background.paper,
  borderRadius: "8px",
}));

const StyledChildStack = styled(Stack)(({ theme }) => ({
  paddingLeft: theme.spacing(2),
  backgroundColor: theme.palette.background.paper,
}));

const Heading = styled(Typography)(() => ({
  fontSize: "14px",
}));

const StyledItemLabelBox = styled(Box)(({ theme }) => ({
  display: "flex",
  borderRadius: "20px",
  alignItems: "center",
  backgroundColor: theme.palette.Action["Disabled Background"].p12,
  padding: "1px 4px",
  height: "16px",
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  fontSize: "14px",
  color: theme.palette.text.secondary,
}));

const SubItemBox = styled(Box)(({ theme }) => ({
  borderLeft: `3px solid ${theme.palette.Other.Divider}`,
}));

export type ReadonlySelectedQuestionFieldProps = {
  title: string;
  itemLabel?: string;
  variant?: string;
  description?: string;
  isChild?: boolean;
  subItems?: Omit<ReadonlySelectedQuestionFieldProps, "subItems">[];
};

const ReadonlySelectedQuestionField: React.FC<ReadonlySelectedQuestionFieldProps> = props => {
  const theme = useTheme();
  const Wrapper = props.isChild ? StyledChildStack : StyledStack;
  return (
    <Wrapper direction="row" justifyContent="space-between" width="100%">
      <Stack direction="column" spacing={0.5} width="100%">
        <Stack direction="row" justifyContent="space-between" width="100%" alignItems="center">
          <Heading>{props.title}</Heading>
          {props.itemLabel && (
            <StyledItemLabelBox>
              <Typography fontSize={10}>{props.itemLabel}</Typography>
            </StyledItemLabelBox>
          )}
        </Stack>
        {props.variant && (
          <Stack direction="row" spacing={0.5} alignItems="center" color={theme.palette.text.secondary}>
            <LabelOutlinedIcon fontSize="small" />
            <StyledTypography>{props.variant}</StyledTypography>
          </Stack>
        )}
        {props.description && <StyledTypography>{props.description}</StyledTypography>}
        {props.subItems && props.subItems.length && (
          <Box pt={1}>
            <Stack spacing={1.5} direction="column">
              {props.subItems.map((subItem, index) => {
                return (
                  <SubItemBox key={`item-${index}`}>
                    <ReadonlySelectedQuestionField {...subItem} isChild />
                  </SubItemBox>
                );
              })}
            </Stack>
          </Box>
        )}
      </Stack>
    </Wrapper>
  );
};

ReadonlySelectedQuestionField.displayName = "ReadonlySelectedQuestionField";

export default ReadonlySelectedQuestionField;
