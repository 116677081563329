import { useTranslation } from "@hireroo/i18n";
import Markdown from "@hireroo/markdown-v2/react";
import { SystemDesignScoreEditForm } from "@hireroo/validator";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { useFormContext } from "react-hook-form";

import CorrectToggleButtons, { CorrectToggleButtonsProps } from "../../../../modules/CorrectToggleButtons/CorrectToggleButtons";
import JudgmentContent, { type JudgmentContentProps } from "./JudgmentContent";

type Icon = "SUCCESS" | "ERROR";

const IconMap = {
  SUCCESS: <CheckCircleIcon color="success" fontSize="small" />,
  ERROR: <CancelIcon color="error" fontSize="small" />,
} satisfies Record<Icon, React.ReactElement>;

const StyledAccordion = styled(Accordion)(({ theme }) => ({
  borderColor: theme.palette.divider,
  "&:first-of-type": {
    borderTopLeftRadius: "8px",
    borderTopRightRadius: "8px",
  },
  "&:last-of-type": {
    borderBottomLeftRadius: "8px",
    borderBottomRightRadius: "8px",
  },
}));

type Mode = "READ" | "EDIT" | "UPDATED";

export type SystemDesignScoringItemProps = {
  /**
   * `scoringItems.${index}`
   */
  name: `scoringItems.${number}`;
  mode: Mode;
  isPassed: boolean;
  title: string;
  description: string;
  judgmentContent: JudgmentContentProps;
  forceExpanded: boolean;
};

const SystemDesignScoringItem: React.FC<SystemDesignScoringItemProps> = props => {
  const { t } = useTranslation();
  const methods = useFormContext<SystemDesignScoreEditForm.SystemDesignScoreEditForm>();

  const [expanded, setExpanded] = React.useState<boolean>(false);
  React.useEffect(() => {
    setExpanded(props.forceExpanded);
  }, [props.forceExpanded]);
  const handleClick = () => {
    setExpanded(draft => !draft);
  };

  const ResultIcon = props.isPassed ? IconMap.SUCCESS : IconMap.ERROR;

  const correctToggleButtons: CorrectToggleButtonsProps = {
    onChange: value => {
      methods.setValue(`${props.name}.isPassed`, value === "CORRECT");
    },
    defaultValue: methods.getValues(`${props.name}.isPassed`) ? "CORRECT" : "WRONG",
  };

  const ItemModeMap = {
    READ: ResultIcon,
    UPDATED: ResultIcon,
    EDIT: <CorrectToggleButtons {...correctToggleButtons} />,
  } satisfies Record<Mode, React.ReactNode>;

  return (
    <StyledAccordion TransitionProps={{ unmountOnExit: true }} expanded={expanded}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} onClick={handleClick}>
        <Box display="flex" justifyContent="start" width="100%" alignItems="center">
          {ItemModeMap[props.mode]}
          <Typography ml={1} variant="body2" fontSize={14} flexGrow={1}>
            {props.title}
          </Typography>
          {props.mode === "UPDATED" && <Typography variant={"caption"}>{t("編集済み")}</Typography>}
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <React.Suspense>
          <Box mb={2}>
            <Markdown size="middle" children={props.description} />
          </Box>
        </React.Suspense>
        <JudgmentContent {...props.judgmentContent} />
      </AccordionDetails>
    </StyledAccordion>
  );
};

SystemDesignScoringItem.displayName = "SystemDesignScoringItem";

export default SystemDesignScoringItem;
