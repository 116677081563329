import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { GenerateCompetitionCardListPropsArgs, useGenerateProps } from "./useGenerateProps";

export type CompetitionCardListContainerProps = GenerateCompetitionCardListPropsArgs;

const CompetitionCardListContainer: React.FC<CompetitionCardListContainerProps> = props => {
  const competitionCardListProps = useGenerateProps(props);
  return (
    <ErrorBoundary>
      <Widget.CompetitionCardList {...competitionCardListProps} />
    </ErrorBoundary>
  );
};

CompetitionCardListContainer.displayName = "CompetitionCardListContainer";

export default withErrorBoundary(CompetitionCardListContainer, {});
