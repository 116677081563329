import { useTranslation } from "@hireroo/i18n";
import Markdown from "@hireroo/markdown-v2/react";
import Box from "@mui/material/Box";
import Button, { ButtonProps } from "@mui/material/Button";
import * as React from "react";

export type MoreMarkdownProps = {
  maxLines: number;
  description: string;
  moreButton?: Pick<ButtonProps, "variant" | "sx">;
};

const MoreMarkdown: React.FC<MoreMarkdownProps> = props => {
  const { t } = useTranslation();
  const textLines = props.description.split("\n");
  const shouldDisplayButton = React.useMemo(() => {
    return textLines.length > props.maxLines;
  }, [props.maxLines, textLines.length]);
  const [hidden, setHidden] = React.useState<boolean>(textLines.length > props.maxLines);

  const limitedText = [...textLines].splice(0, props.maxLines).join("\n");
  const [shownText, setShownText] = React.useState(limitedText);

  const onClickMore = React.useCallback(() => {
    setShownText(props.description);
    setHidden(false);
  }, [props.description]);

  const onClickLess = React.useCallback(() => {
    setShownText(limitedText);
    setHidden(true);
  }, [limitedText]);

  const moreButtonProps: ButtonProps = {
    variant: "outlined",
    size: "small",
    ...props.moreButton,
    sx: {
      marginTop: "16px",
      ...props.moreButton?.sx,
    },
  };

  return (
    <Box>
      <React.Suspense>
        <Markdown children={shownText} size="small" />
      </React.Suspense>
      {shouldDisplayButton && (
        <Button onClick={hidden ? onClickMore : onClickLess} {...moreButtonProps}>
          {hidden ? t("もっと見る") : t("閉じる")}
        </Button>
      )}
    </Box>
  );
};

MoreMarkdown.displayName = "MoreMarkdown";

export default MoreMarkdown;
