import { Pages } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { startSubscribeListParams } from "./Subscriber";
import { useGenerateProps } from "./useGenerateProps";

export type CompetitionListForEmployeeContainerProps = {};

const CompetitionListForEmployeeContainer: React.FC<CompetitionListForEmployeeContainerProps> = () => {
  const competitionListForEmployeeProps = useGenerateProps({});
  React.useEffect(() => {
    const stop = startSubscribeListParams();

    return () => {
      stop();
    };
  }, []);
  return (
    <ErrorBoundary>
      <Pages.CompetitionListForEmployee {...competitionListForEmployeeProps} />
    </ErrorBoundary>
  );
};

CompetitionListForEmployeeContainer.displayName = "CompetitionListForEmployeeContainer";

export default withErrorBoundary(CompetitionListForEmployeeContainer, {});
