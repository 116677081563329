import { VerifyEmail } from "@hireroo/app-store/page/c/verify_email";
import type { Pages } from "@hireroo/presentation";

import SnackbarContainer from "../../../../widget/v2/shared/Snackbar/Container";
import CandidateNeedEmailVerificationContainer from "./widgets/CandidateNeedEmailVerification/Container";

export type GenerateSignInPropsArgs = {};

export const useGenerateProps = (_args: GenerateSignInPropsArgs): Pages.SignInSignUpProps => {
  const isLoading = VerifyEmail.useIsLoading();

  return {
    layout: {
      loading: isLoading,
      Bottom: <SnackbarContainer />,
    },
    children: <CandidateNeedEmailVerificationContainer />,
  };
};
