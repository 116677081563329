import { PersonRounded } from "@mui/icons-material";
import AppRegistrationOutlinedIcon from "@mui/icons-material/AppRegistrationOutlined";
import AssignmentIndOutlinedIcon from "@mui/icons-material/AssignmentIndOutlined";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import Home from "@mui/icons-material/Home";
import HomeOutlined from "@mui/icons-material/HomeOutlined";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import MonitorOutlined from "@mui/icons-material/MonitorOutlined";
import VideocamOutlined from "@mui/icons-material/VideocamOutlined";
import VideocamRounded from "@mui/icons-material/VideocamRounded";
import { useTheme } from "@mui/material";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import { styled } from "@mui/material/styles";
import * as React from "react";

import Link from "../../../../../../primitive/Link/Link";
import type * as Types from "../../../../types";

const StyledListItemIcon = styled(ListItemIcon)(() => ({
  width: "20px",
  minWidth: "20px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));

const StyledListItemButton = styled(ListItemButton)(() => ({
  padding: "6px 16px",
  width: "36px",
  height: "36px",
  display: "flex",
  justifyContent: "center",
  borderRadius: "4px",
}));

type Variant = "SECONDARY" | "GRAY";

export type NavigationMenuItemProps = Types.MenuItemWithoutChildren & {
  root?: boolean;
  variant?: Variant;
};

const NavigationMenuItem = React.forwardRef((props: NavigationMenuItemProps, ref: React.Ref<HTMLDivElement>) => {
  const theme = useTheme();
  const { variant = "SECONDARY" } = props;
  const colorMap: Record<Variant, string> = {
    SECONDARY: theme.palette.secondary.main,
    GRAY: theme.palette["Gray/Shades"].p56,
  };

  const backgroundColorMap: Record<Variant, string> = {
    SECONDARY: theme.palette["Secondary/Shades"].p16,
    GRAY: theme.palette["Gray/Shades"].p16,
  };

  const hoverColorMap: Record<Variant, string> = {
    SECONDARY: theme.palette.secondary.main,
    GRAY: theme.palette.text.primary,
  };

  const HomeMap: Record<Variant, React.ReactElement> = {
    SECONDARY: <HomeOutlined htmlColor={colorMap[variant]} fontSize="small" />,
    GRAY: <Home htmlColor={colorMap[variant]} fontSize="small" />,
  };
  const VideocamMap: Record<Variant, React.ReactElement> = {
    SECONDARY: <VideocamOutlined htmlColor={colorMap[variant]} fontSize="small" />,
    GRAY: <VideocamRounded htmlColor={colorMap[variant]} fontSize="small" />,
  };

  const StartIconMap: Record<Types.StartIcon, React.ReactNode> = {
    HOME: HomeMap[variant],
    MONITOR: <MonitorOutlined htmlColor={colorMap[variant]} fontSize="small" />,
    ASSIGNMENT: <AssignmentIndOutlinedIcon htmlColor={colorMap[variant]} fontSize="small" />,
    VIDEOCAM: VideocamMap[variant],
    REGISTRATION: <AppRegistrationOutlinedIcon htmlColor={colorMap[variant]} fontSize="small" />,
    DOCUMENT: <InsertDriveFileOutlinedIcon htmlColor={colorMap[variant]} fontSize="small" />,
    EVENT: <EmojiEventsIcon htmlColor={colorMap[variant]} fontSize="small" />,
    PERSON: <PersonRounded htmlColor={colorMap[variant]} fontSize="small" />,
  };

  return (
    <StyledListItemButton
      onClick={props.onClick}
      ref={ref}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      component={Link}
      href={props.href}
      sx={{
        "&:hover": {
          color: colorMap[variant],
          backgroundColor: backgroundColorMap[variant],
          svg: {
            color: hoverColorMap[variant],
          },
        },
        svg: {
          color: colorMap[variant],
          maxHeight: "20px",
          maxWidth: "20px",
        },
      }}
    >
      {props.startIcon && <StyledListItemIcon>{StartIconMap[props.startIcon]}</StyledListItemIcon>}
    </StyledListItemButton>
  );
});

NavigationMenuItem.displayName = "NavigationMenuItem";

export default NavigationMenuItem;
