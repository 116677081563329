import { Credential, SignInOrSignUp } from "@hireroo/app-store/essential/shared";
import * as Graphql from "@hireroo/graphql/client/urql";
import { Pages } from "@hireroo/presentation";
import { LastVisitedRoute, navigate as navigateApi, redirectWithRawUrl, safeRedirect } from "@hireroo/router/api";
import routes from "@hireroo/router/config";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { useGenerateProps } from "./useGenerateProps";

export type AuthContainerProps = {};

const AuthContainer: React.FC<AuthContainerProps> = () => {
  const signInSignUpProps = useGenerateProps({});
  const firebaseAuthStatusCode = SignInOrSignUp.useFirebaseAuthStatusCode();
  const userType = Credential.useUserType();

  const redirectAfterVerify = React.useCallback(async () => {
    switch (userType) {
      case Graphql.UserType.Employee: {
        safeRedirect("/e/home");
        break;
      }
      case Graphql.UserType.Talent: {
        safeRedirect("/t/assessments");
        break;
      }
      case Graphql.UserType.Candidate: {
        const previousPathname = LastVisitedRoute.getAndClear();
        if (previousPathname) {
          await redirectWithRawUrl(previousPathname);
          return;
        }
        safeRedirect("/c/home");
        return;
      }
      case Graphql.UserType.Unknown:
      default:
        /**
         * Use navigateApi in order to reset state and initialize
         */
        navigateApi(routes["/"]);
        break;
    }
  }, [userType]);

  React.useEffect(() => {
    if (firebaseAuthStatusCode === "EMAIL_SUCCESSFULLY_AUTHENTICATED") {
      redirectAfterVerify();
    }
  }, [firebaseAuthStatusCode, redirectAfterVerify]);

  return (
    <ErrorBoundary>
      <Pages.SignInSignUp {...signInSignUpProps} />
    </ErrorBoundary>
  );
};

AuthContainer.displayName = "AuthContainer";

export default withErrorBoundary(AuthContainer, {});
