import { useTranslation } from "@hireroo/i18n";
import Markdown from "@hireroo/markdown/react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import LightbulbRoundedIcon from "@mui/icons-material/LightbulbRounded";
import { useTheme } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";
import Typography, { TypographyProps } from "@mui/material/Typography";
import * as React from "react";

const Wrapper = styled(Stack)(({ theme }) => ({
  padding: theme.spacing(2),
  backgroundColor: theme.palette["Background/Paper"].p2,
  borderRadius: "8px",
}));

const StyledAccordion = styled(Accordion)(() => ({
  backgroundImage: "none",
  backgroundColor: "transparent",
  boxShadow: "none",
  position: "static",
}));

const StyledAccordionSummary = styled(AccordionSummary)(() => ({
  minHeight: "20px",
  padding: 0,
  "&.Mui-expanded": {
    minHeight: "20px",
  },
  "& .MuiAccordionSummary-content": {
    margin: 0,
    padding: 0,
    "&.Mui-expanded": {
      margin: 0,
    },
  },
}));

const StyledAccordionDetails = styled(AccordionDetails)(() => ({
  padding: 0,
}));

const QuestionMarkdownWrapper = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  width: "-webkit-fill-available",
  boxSizing: "border-box",
  backgroundColor: "inherit",
  // overwrite style of p tag which is rendered by markdown
  "& p": {
    margin: 0,
  },
}));

const AnswerMarkdownWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  width: "90%",
  borderRadius: "8px",
  border: "1px solid",
  boxSizing: "border-box",
  backgroundColor: "inherit",
  borderColor: theme.palette["Other"].Divider,
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
}));

const IconWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  borderRadius: "4px",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: theme.palette["Gray/Shades"].p8,
  height: "20px",
  width: "20px",
}));

type Priority = {
  kind: "HIGH" | "MEDIUM" | "LOW";
  label: string;
};
const StyledPriorityLabel = styled(Typography)(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  wordBreak: "keep-all",
  fontSize: "10px",
  fontFamily: "Noto Sans JP",
  lineHeight: "14px",
  height: "14px",
  padding: "1px 2px",
  margin: 0,
}));

export type FollowUpQuestionProps = {
  question: string;
  answer: string;
  purpose?: string;
  priority: Priority;
  className?: string;
};

const FollowUpQuestion: React.FC<FollowUpQuestionProps> = props => {
  const { t } = useTranslation();
  const theme = useTheme();
  const priorityColorMap: Record<Priority["kind"], string> = {
    HIGH: theme.palette.error.main,
    MEDIUM: theme.palette.info.main,
    LOW: theme.palette.text.primary,
  };
  const typographyMap: Record<Priority["kind"], TypographyProps> = {
    HIGH: {
      color: theme.palette.error.main,
      border: "1px solid",
      borderColor: theme.palette.error.main,
    },
    MEDIUM: {
      color: theme.palette.info.main,
      border: "1px solid",
      borderColor: theme.palette.info.main,
    },
    LOW: {
      color: theme.palette.text.primary,
      border: "1px solid",
      borderColor: theme.palette.text.primary,
    },
  };
  return (
    <Wrapper spacing={2} className={props.className}>
      <Stack direction="row" spacing={1.5} alignItems="center">
        <IconWrapper>
          <LightbulbRoundedIcon fontSize="small" htmlColor={priorityColorMap[props.priority.kind]} />
        </IconWrapper>
        <StyledPriorityLabel {...typographyMap[props.priority.kind]}>{props.priority.label}</StyledPriorityLabel>
        {props.purpose && (
          <Typography color={priorityColorMap[props.priority.kind]} fontSize="14px" fontWeight={700}>
            {props.purpose}
          </Typography>
        )}
      </Stack>
      <StyledAccordion>
        <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Stack direction="row" spacing={1.5}>
            <IconWrapper>
              <Typography fontSize={14} fontWeight={900}>
                Q
              </Typography>
            </IconWrapper>
            <React.Suspense>
              <QuestionMarkdownWrapper>
                <Markdown size="small">{props.question}</Markdown>
              </QuestionMarkdownWrapper>
            </React.Suspense>
          </Stack>
        </StyledAccordionSummary>
        <StyledAccordionDetails>
          <Stack direction="row" pt={2} spacing={1.5} width="100%">
            <IconWrapper>
              <Tooltip title={t("模範解答")}>
                <Typography fontSize={14} fontWeight={900}>
                  A
                </Typography>
              </Tooltip>
            </IconWrapper>
            <React.Suspense>
              <AnswerMarkdownWrapper>
                <Box width="100%">
                  <Markdown size="small">{props.answer}</Markdown>
                </Box>
              </AnswerMarkdownWrapper>
            </React.Suspense>
          </Stack>
        </StyledAccordionDetails>
      </StyledAccordion>
    </Wrapper>
  );
};

FollowUpQuestion.displayName = "FollowUpQuestion";

export default FollowUpQuestion;
