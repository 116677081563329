import { App } from "@hireroo/app-store/essential/candidate";
import * as CandidateEssentialStores from "@hireroo/app-store/essential/candidate";
import { Credential } from "@hireroo/app-store/essential/shared";
import {
  getCurrentUser,
  getExpireDate,
  getIdToken,
  parseCustomClaims,
  signInAnonymously as firebaseSignInAnonymously,
  signOut,
} from "@hireroo/firebase";
import { getGraphqlClient } from "@hireroo/graphql/client/request";
import * as Graphql from "@hireroo/graphql/client/urql";

import { CANDIDATE_TENANT_ID, initializeGraphqlClientSdkAndRestApiClient } from "./helper";

const setupAuthToken = async ({ refreshToken }: { refreshToken: boolean }) => {
  const authToken = (await getIdToken(refreshToken)) || null;
  if (!authToken) {
    throw new Error("Cannot get auth token");
  }
  const expireDate = await getExpireDate();
  Credential.setAuthToken({
    authToken,
    expireDate: expireDate || null,
    getRefreshAuthToken: async () => {
      const newAuthToken = await getIdToken(true);
      const expireDate = await getExpireDate();
      if (newAuthToken) {
        initializeGraphqlClientSdkAndRestApiClient(newAuthToken);
      }
      return {
        authToken: newAuthToken,
        expireDate,
      };
    },
  });
  initializeGraphqlClientSdkAndRestApiClient(authToken);
};

const initializeCandidate = async (args: InitializeCandidateArgs): Promise<void> => {
  const currentUser = await getCurrentUser();
  if (!currentUser) {
    return await signInAnonymously();
  }

  CandidateEssentialStores.Auth.initializeUserFromFirebase({
    user: {
      uid: currentUser.uid,
      email: currentUser.email,
    },
  });

  const currentTenantId: string | undefined = currentUser.tenantId ?? undefined;
  if (currentTenantId !== CANDIDATE_TENANT_ID) {
    // テナント ID が一致しないということは想定と異なる Firebase テナントを参照しようとしているので
    // サインアウトしてログインし直す
    await signOut();
    return await initializeCandidate(args);
  }

  if (currentUser.isAnonymous) {
    // Get the Token that is the target of UpdateClaims
    await setupAuthToken({ refreshToken: false });

    // 匿名ログイン後に UpdateClaimsForCandidate に失敗したあとにリロードすると
    // JWT の claims の user_type や role が更新できていない状態になる。
    // そのため user_type が invalid な状態のままになってしまうことを防ぐために、
    // 匿名ログイン後も必要に応じて Claim の更新を行う
    await updateClaimsIfNeeded(currentUser.uid);

    setCandidateCredentials();
    return;
  }

  // 候補者がログインしている場合
  await setupAuthToken({ refreshToken: false });

  await updateClaimsIfNeeded(currentUser.uid);

  setCandidateCredentials();
  if (args.candidate) {
    CandidateEssentialStores.Auth.setCandidate(args.candidate);
  }
};

const signInAnonymously = async () => {
  const { user } = await firebaseSignInAnonymously();
  if (!user) {
    CandidateEssentialStores.Auth.setError("INVALID_USER");
    return;
  }
  // Get the Token that is the target of UpdateClaims
  await setupAuthToken({ refreshToken: false });

  await updateClaimsIfNeeded(user.uid);

  setCandidateCredentials();
  CandidateEssentialStores.Auth.initializeUserFromFirebase({
    user: {
      uid: user.uid,
      email: user.email,
    },
  });

  return;
};

const updateClaimsIfNeeded = async (userId: string) => {
  const authToken = (await getIdToken()) ?? "";
  const claims = parseCustomClaims(authToken);

  // 候補者の場合は、 user_type を CANDIDATE とする必要がある
  const needsClaimsUpdate = claims === null || claims.user_type !== "CANDIDATE";

  if (!needsClaimsUpdate) {
    return;
  }
  const client = getGraphqlClient();
  await client.UpdateClaimsForCandidate({
    input: {
      uid: userId,
      role: "CANDIDATE",
      userType: "CANDIDATE",
      tenantId: CANDIDATE_TENANT_ID,
    },
  });

  // Claims を更新した場合は JWT をリフレッシュする
  await setupAuthToken({ refreshToken: true });
};

const setCandidateCredentials = () => {
  Credential.setCurrentUserType(Graphql.UserType.Candidate);
  Credential.setCustomClaimInfo({
    signedIn: true,
    customClaim: {
      role: "CANDIDATE",
      user_type: "CANDIDATE" as const,
    },
  });
};

export type InitializeCandidateArgs = {
  candidate: Graphql.EssentialCandidateFragment | null;
};

const initialize = async (args: InitializeCandidateArgs) => {
  App.setStatus("INITIALIZING");
  await initializeCandidate(args);
  App.setStatus("INITIALIZED");
};

export const Candidate = {
  initialize,
};
