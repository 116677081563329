import * as z from "zod";

const useSystemDesignScoringItem = () => {
  return z.object({
    scoring_item_id: z.number(),
    is_passed: z.boolean(),
    matched_ids: z.string().array().optional(),
    reason_ja: z.string().optional(),
    reason_en: z.string().optional(),
    updated: z.boolean().optional(),
  });
};

export type SystemDesignScoringItemSchema = z.infer<ReturnType<typeof useSystemDesignScoringItem>>;

export const useSystemDesignScoringItems = () => {
  const ScoringItem = useSystemDesignScoringItem();
  return ScoringItem.array();
};

export type SystemDesignScoringItemsSchema = z.infer<ReturnType<typeof useSystemDesignScoringItems>>;
