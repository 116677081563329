import languageExtensionMap from "@hireroo/code-editor/definition/languageExtensionMap";
import type { Widget } from "@hireroo/presentation";

export type GenerateChallengeReadonlyCodeEditorPropsArgs = {
  id: string;
  runtime: string;
  code: string;
};

export const useGenerateProps = (args: GenerateChallengeReadonlyCodeEditorPropsArgs): Widget.ChallengeReadonlyCodeEditorProps => {
  return {
    path: `readonly-challenge-${args.id}-${languageExtensionMap[args.runtime]}`,
    value: args.code,
  };
};
