import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { GenerateCompetitionResultDetailPropsArgs, useGenerateProps } from "./useGenerateProps";

export type CompetitionResultDetailContainerProps = GenerateCompetitionResultDetailPropsArgs;

const CompetitionResultDetailContainer: React.FC<CompetitionResultDetailContainerProps> = props => {
  const competitionResultDetailProps = useGenerateProps(props);
  return (
    <ErrorBoundary>
      <Widget.CompetitionResultDetail {...competitionResultDetailProps} />
    </ErrorBoundary>
  );
};

CompetitionResultDetailContainer.displayName = "CompetitionResultDetailContainer";

export default withErrorBoundary(CompetitionResultDetailContainer, {});
