import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { useGenerateProps } from "./useGenerateProps";

export type CompetitionApplicantLeaderboardContainerProps = {};

const CompetitionApplicantLeaderboardContainer: React.FC<CompetitionApplicantLeaderboardContainerProps> = () => {
  const competitionApplicantLeaderboardProps = useGenerateProps({});
  return (
    <ErrorBoundary>
      <Widget.CompetitionApplicantLeaderboard {...competitionApplicantLeaderboardProps} />
    </ErrorBoundary>
  );
};

CompetitionApplicantLeaderboardContainer.displayName = "CompetitionApplicantLeaderboardContainer";

export default withErrorBoundary(CompetitionApplicantLeaderboardContainer, {});
