import { DEFAULT_APPLICANT_FILTER, DEFAULT_APPLICANT_PAGER } from "./constants";
import { state } from "./State";
import type * as Types from "./types";

export const initialize = (competition: Types.Competition): void => {
  state.competition = competition;
};

export const clear = (): void => {
  state.competition = null;
  state.applicantsRes = null;
  clearApplicantFilter();
};

export const initializeApplicantsRes = (applicantsRes: Types.ApplicantsRes): void => {
  state.applicantsRes = applicantsRes;
};

export const clearApplicantFilter = () => {
  state.applicantFilter = DEFAULT_APPLICANT_FILTER;
  state.applicantPager = DEFAULT_APPLICANT_PAGER;
  state.applicantsRes = null;
  state.initializedApplicantFilter = false;
};

export const initializeApplicantFilter = (applicantFilter: Types.ApplicantFilter, pager: Omit<Types.ApplicantPager, "offset">): void => {
  state.applicantFilter = applicantFilter;
  state.applicantPager = {
    ...pager,
    offset: pager.page * pager.size,
  };
  state.initializedApplicantFilter = true;
};

export const updateApplicantFilter = (applicantFilter: Types.ApplicantFilter): void => {
  state.applicantFilter = applicantFilter;
};

export const updatePage = (page: number) => {
  state.applicantPager = {
    ...state.applicantPager,
    page,
    offset: page * state.applicantPager.size,
  };
};

export const updatePageSize = (size: number) => {
  state.applicantPager = {
    ...state.applicantPager,
    size: size,
    // Reset page when page size is updated
    page: 0,
    offset: 0,
  };
};

export const updateApplicantListParams = (pager: Partial<Types.ApplicantPager>): void => {
  state.applicantPager = {
    ...state.applicantPager,
    ...pager,
  };
};
