import { useTranslation } from "@hireroo/i18n";
import AccountTreeOutlined from "@mui/icons-material/AccountTreeOutlined";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { styled, useTheme } from "@mui/material/styles";
import TableCell from "@mui/material/TableCell";
import Typography from "@mui/material/Typography";
import * as React from "react";

import IconButton, { IconButtonProps } from "../../../../../../primitive/Button/IconButton/IconButton";
import DivTableRow from "../../../../../../primitive/DivTableRow/DivTableRow";
import Link from "../../../../../../primitive/Link/Link";

const SELECTED_CLASS_NAME = "__selected__";

const StyledTableRow = styled(DivTableRow)(({ theme }) => ({
  borderTop: "1px solid",
  borderBottom: "1px solid",
  borderColor: theme.palette.Other.Divider,
  display: "table-row",
  cursor: "pointer",
  "&:hover": {
    backgroundColor: theme.palette["Gray/Shades"].p8,
    cursor: "pointer",
  },
  [`&.${SELECTED_CLASS_NAME}`]: {
    backgroundColor: theme.palette["Gray/Shades"].p16,
    "&:hover": {
      backgroundColor: theme.palette["Gray/Shades"].p16,
      cursor: "pointer",
    },
  },
}));

const StyledTableCell = styled(TableCell)(() => ({
  border: "none",
  whiteSpace: "nowrap",
  wordBreak: "keep-all",
  display: "table-cell",
  paddingTop: "8px",
  paddingBottom: "8px",
}));

export type ScreeningTestListTableRowProps = {
  id: string;
  onClick: () => void;
  candidateName: string;
  candidateEmail?: string;
  screeningName?: string;
  selected?: boolean;
  detailButton: Pick<IconButtonProps, "onClick">;
  href: string;
};

const ScreeningTestListTableRow: React.FC<ScreeningTestListTableRowProps> = props => {
  const { t } = useTranslation();
  const theme = useTheme();

  const detailButton: IconButtonProps = {
    title: t("候補者詳細へ"),
    children: <OpenInNewIcon fontSize="small" />,
    onClick: event => {
      event.stopPropagation();
      event.preventDefault();
      props.detailButton?.onClick?.(event);
    },
  };
  return (
    <StyledTableRow
      onClick={event => {
        event.stopPropagation();
        event.preventDefault();
        props.onClick();
      }}
      className={props.selected ? SELECTED_CLASS_NAME : undefined}
      selected={props.selected}
    >
      <StyledTableCell component="div">
        <Box display="flex" alignItems="center" maxWidth="350px" whiteSpace="pre-wrap" flexWrap="wrap">
          <Typography whiteSpace="pre-wrap" maxWidth="350px" mr={1} sx={{ wordBreak: "break-word" }}>
            {props.candidateName}
          </Typography>
          {props.candidateEmail && (
            <Typography fontSize={12} color="textSecondary">
              {props.candidateEmail}
            </Typography>
          )}
        </Box>
      </StyledTableCell>
      <StyledTableCell component="div" sx={{ maxWidth: "10%" }}>
        <Stack direction="row" alignItems="center" spacing={0.5}>
          <AccountTreeOutlined fontSize="small" sx={{ color: theme.palette.text.secondary, width: "18px", height: "18px" }} />
          <Typography fontSize={13} color="textSecondary">
            {props.screeningName}
          </Typography>
        </Stack>
      </StyledTableCell>
      <StyledTableCell component="div" align="right">
        <Link target="_blank" href={props.href} underline="none">
          <IconButton {...detailButton} />
        </Link>
      </StyledTableCell>
    </StyledTableRow>
  );
};

ScreeningTestListTableRow.displayName = "ScreeningTestListTableRow";

export default ScreeningTestListTableRow;
