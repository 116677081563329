import { generateEntityKey, NormalizedEntity } from "@hireroo/app-helper/normalizer";
import { CompetitionsResultsId } from "@hireroo/app-store/page/c/competitions_results_id";
import { InterviewNavigation } from "@hireroo/app-store/widget/c/InterviewNavigation";
import { ChallengeCodingEditor } from "@hireroo/app-store/widget/shared/ChallengeCodingEditor";
import { TestTimelimit } from "@hireroo/app-store/widget/shared/TestTimelimit";

export const startSubscribeOnChangeResult = () => {
  return CompetitionsResultsId.subscribeOnChangeResult(result => {
    InterviewNavigation.clear();
    InterviewNavigation.initialize();
    TestTimelimit.clear();

    TestTimelimit.initialize2({
      remainTimeSeconds: result.remainingTimeSeconds,
      timeLimitSeconds: result.timeLimitSeconds,
    });
    const completedEntities = CompetitionsResultsId.generateCompletedEntities(result.entities);
    const completedEntityKeySet = new Set(completedEntities.map(entity => CompetitionsResultsId.generateEntityKey(entity)));

    result.entities.forEach(entity => {
      switch (entity.__typename) {
        case "ChallengeEntity": {
          ChallengeCodingEditor.initialize(entity, {
            enableWebSearch: false,
            enableChatGPT: false,
            enableHint: false,
          });
          break;
        }
      }
    });
    const entities = result.entities.map<InterviewNavigation.EntityForNavigation>(entity => {
      const key = CompetitionsResultsId.generateEntityKey(entity);
      const normalizedEntity = CompetitionsResultsId.generateNormalizedEntity(entity);
      return {
        ...CompetitionsResultsId.extractQuestionTitle(entity),
        key,
        completed: completedEntityKeySet.has(key),
        ...normalizedEntity,
      };
    }, []);
    InterviewNavigation.initializeEntities(entities);
    if (entities.length) {
      const entity = entities[0];
      InterviewNavigation.setSelectedEntityKey(entity.key);
    }
  });
};

const goToNotYetSubmittedQuestion = (submittedEntityKey: string): void => {
  const entities = CompetitionsResultsId.getEntities();
  const entitiesFromInterviewNavigation = InterviewNavigation.getEntities();
  const alreadyCompletedKeys = entitiesFromInterviewNavigation.filter(target => target.completed).map(target => target.key);
  const completedEntities = CompetitionsResultsId.getCompletedEntities();
  const completedKeySet = new Set([
    ...completedEntities.map(CompetitionsResultsId.generateEntityKey),
    ...alreadyCompletedKeys,
    submittedEntityKey,
  ]);
  const notCompletedEntities = entities.filter(entity => !completedKeySet.has(generateEntityKey(entity)));
  if (notCompletedEntities.length > 0) {
    const nextEntity = notCompletedEntities[0];
    const nextEntityKey = CompetitionsResultsId.generateEntityKey(nextEntity);
    InterviewNavigation.setSelectedEntityKey(nextEntityKey);
  }
};

type StartSubscribeShowingEntityKeyArgs = {
  onChangeShowingEntity: (entity: NormalizedEntity) => void;
};

export const startSubscribeOnChangeShowingEntity = (args: StartSubscribeShowingEntityKeyArgs) => {
  return InterviewNavigation.subscribeOnChangeShowingEntity((_, entity) => {
    args.onChangeShowingEntity({
      entityType: entity.entityType,
      entityId: entity.entityId,
      questionId: entity.questionId,
      questionVersion: entity.questionVersion,
    });
  });
};

export const startSubscribeChallengeCodingEditor = () => {
  return ChallengeCodingEditor.subscribeOnSubmitEntity(entity => {
    const submittedEntityKey = generateEntityKey(entity);
    InterviewNavigation.completeEntity(submittedEntityKey);
    goToNotYetSubmittedQuestion(submittedEntityKey);
  });
};

export const startSubscribeChallengeCodingEditorLoading = () => {
  return ChallengeCodingEditor.subscribeOnLoadingChange(status => {
    InterviewNavigation.updateLoadingStatus(status);
  });
};

export const startSubscribeTimeoutStatus = () => {
  return TestTimelimit.subscribeTimeoutStatus(() => {
    CompetitionsResultsId.updateEndStatus("TIMELIMIT_HAS_EXPIRED");
  });
};
