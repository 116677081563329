import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { useGenerateProps } from "./useGenerateProps";

export type CandidateDetailContainerProps = {};

const CandidateDetailContainer: React.FC<CandidateDetailContainerProps> = () => {
  const candidateDetailProps = useGenerateProps({});
  return (
    <ErrorBoundary>
      <Widget.CandidateDetail {...candidateDetailProps} />
    </ErrorBoundary>
  );
};

CandidateDetailContainer.displayName = "CandidateDetailContainer";

export default withErrorBoundary(CandidateDetailContainer, {});
