import { translateSalary } from "@hireroo/app-helper/competition";
import { CompetitionsIdDetail } from "@hireroo/app-store/page/c/competitions_id_detail";
import * as TimeFormatter from "@hireroo/formatter/time";
import { useLanguageCode, useTranslation } from "@hireroo/i18n";
import type { Widget } from "@hireroo/presentation";

export type GenerateCompetitionOverviewPropsArgs = {};

export const useGenerateProps = (_args: GenerateCompetitionOverviewPropsArgs): Widget.CompetitionOverviewProps => {
  const { t } = useTranslation();
  const competition = CompetitionsIdDetail.useCompetition();
  const lang = useLanguageCode();

  return {
    description: competition.description,
    salary: `${translateSalary({ salaryInTenK: competition.minSalary, lang: lang })}+`,
    timeLimit: TimeFormatter.formatMinutes(competition.timeLimitSeconds / 60, lang),
    questionCount: `${competition.questionCount} ${t("問")}`,
    hostCompany: {
      text: competition.hostCompany?.name || "",
      imgUrl: competition.hostCompany?.logo || "",
    },
  };
};
