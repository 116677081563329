import { useTranslation } from "@hireroo/i18n";
import PriceJPRounded from "@hireroo/ui-assets/images/Icon/PriceJpRounded.svg";
import BusinessCenterRoundedIcon from "@mui/icons-material/BusinessCenterRounded";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import { styled, useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";

import MoreMarkdown, { MoreMarkdownProps } from "../../../../modules/MoreMarkdown/MoreMarkdown";

const ICON_SIZE = 16;

const ProfileText = styled(Typography)({
  variant: "body1",
  fontSize: 14,
  whiteSpace: "pre-wrap",
});

export type SideSectionProps = {
  profileText: string;
  emptyProfileText: string;
  jobChangeStatus?: string;
  expectedSalary?: string;
};

const SideSection: React.FC<SideSectionProps> = props => {
  const theme = useTheme();
  const { t } = useTranslation();

  const sectionItems = [
    {
      icon: <BusinessCenterRoundedIcon sx={{ width: ICON_SIZE, height: ICON_SIZE }} htmlColor={theme.palette["Gray/Shades"].p56} />,
      text: props.jobChangeStatus ?? "-",
    },
    {
      icon: <img src={PriceJPRounded} alt="price icon jp" width={ICON_SIZE} height={ICON_SIZE} />,
      text: props.expectedSalary ?? "-",
    },
  ];

  const MoreMarkdownProps: MoreMarkdownProps = {
    description: props.profileText,
    maxLines: 5,
    moreButton: { variant: "text", sx: { margin: 0, padding: 0 } },
  };

  return (
    <Box padding={3} pl={1}>
      <Typography fontSize={16} fontWeight={700} color={"textSecondary"} mb={2}>
        {t("自己紹介")}
      </Typography>

      {props.profileText.length === 0 && props.emptyProfileText.length > 0 && (
        <ProfileText color="textSecondary">{props.emptyProfileText}</ProfileText>
      )}
      {props.profileText.length > 0 && (
        <Box sx={{ wordBreak: "break-all" }}>
          <MoreMarkdown {...MoreMarkdownProps} />
        </Box>
      )}

      <Divider sx={{ my: 3, opacity: 0.5 }} />

      <Stack direction="column" spacing={1}>
        {sectionItems.map((item, index) => (
          <Stack key={item.text + index} direction="row" alignItems="center" spacing={1}>
            {item.icon}
            <Typography variant="body1">{item.text}</Typography>
          </Stack>
        ))}
      </Stack>
    </Box>
  );
};

SideSection.displayName = "SideSection";

export default SideSection;
