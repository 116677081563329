import { subscribe } from "valtio";

import { state } from "./State";
import * as Types from "./types";

export type SubscribeMetricMapCallback = (metricMap: Map<Types.MetricId, Types.Metric>) => void;

export const subscribeMetricMap = (callback: SubscribeMetricMapCallback) => {
  return subscribe(state.metricMap, () => {
    callback(state.metricMap);
  });
};
