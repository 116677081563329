import { state } from "./State";
import type * as Types from "./types";

export const setCandidate = (candidate: Types.Candidate): void => {
  state.candidate = candidate;
};

export const clear = (): void => {
  state.candidate = null;
};
