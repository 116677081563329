import { state } from "./State";
import type * as Types from "./types";

export const setRes = (res: Types.Res): void => {
  state.res = res;
};

export const setOpen = (open: boolean) => {
  state.open = open;
};

export const updateListParams = (newListParams: Partial<Types.ListParams>): void => {
  state.listParams = {
    ...state.listParams,
    ...newListParams,
  };
};
export const updateFilters = (newFilters: Partial<Types.Filters>): void => {
  state.listParams = {
    ...state.listParams,
    filters: {
      ...state.listParams.filters,
      ...newFilters,
    },
    /** Reset page when search criteria are changed */
    page: 0,
  };
};

export const updateRefreshKey = () => {
  state.refreshKey = Math.random().toString(36);
};

export const updateSize = (newValue: number): void => {
  state.listParams = {
    ...state.listParams,
    size: newValue,
  };
  //refresh
  updateRefreshKey();
};

export const updatePage = (newValue: number): void => {
  state.listParams = {
    ...state.listParams,
    page: newValue,
  };
  //refresh
  updateRefreshKey();
};

export const clear = (): void => {
  state.open = false;
  state.step = "CHOOSE_OPTION";
  state.selectedSpotId = null;
};

export const updateStep = (step: Types.Step): void => {
  state.step = step;
};

export const updateSelectedSpotId = (selectedSpotId: string | null): void => {
  state.selectedSpotId = selectedSpotId;
};

export const clearSelectedSpotId = (): void => {
  state.selectedSpotId = null;
};
