import { DeepReadonly } from "@hireroo/app-helper/types";
import { useSnapshot } from "valtio";

import { state } from "./State";
import type * as Types from "./types";

const useSnapshotState = () => {
  return useSnapshot(state);
};

export const useInitialized = (): boolean => {
  const snapshot = useSnapshotState();
  return !!snapshot.res;
};

export const useRefreshKey = (): string => {
  const snapshot = useSnapshotState();
  return snapshot.refreshKey;
};

export const useListParams = (): DeepReadonly<Types.ListParams> => {
  const snapshot = useSnapshotState();
  return snapshot.listParams;
};

export const useOpen = (): boolean => {
  const snapshot = useSnapshotState();
  return snapshot.open;
};

export const useRes = (): DeepReadonly<Types.Res> | null => {
  const snapshot = useSnapshotState();
  return snapshot.res;
};

export const useSize = () => {
  const snapshot = useSnapshotState();
  return snapshot.listParams.size;
};

export const usePage = () => {
  const snapshot = useSnapshotState();
  return snapshot.listParams.page;
};

export const useStep = (): Types.Step => {
  const snapshot = useSnapshotState();
  return snapshot.step;
};

export const useSelectedSpotId = (): string | null => {
  const snapshot = useSnapshotState();
  return snapshot.selectedSpotId;
};
