import { useTranslation } from "@hireroo/i18n";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import * as React from "react";

import QuestionOverviewTableRow, { QuestionOverviewTableRowProps } from "./parts/QuestionOverviewTableRow/QuestionOverviewTableRow";

const StyledTable = styled(Table)(() => ({
  height: "fit-content",
  // wordBreak: "keep-all",
}));
const StyledTableCell = styled(TableCell)(() => ({
  border: "none",
  padding: "0 16px",
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  fontSize: 12,
  lineHeight: "266%",
  color: theme.palette.text.secondary,
}));

export type QuestionOverviewTableProps = {
  rows: QuestionOverviewTableRowProps[];
};

const QuestionOverviewTable: React.FC<QuestionOverviewTableProps> = props => {
  const { t } = useTranslation();

  if (props.rows.length === 0) {
    return (
      <Typography color="text.secondary" variant="body2" sx={{ textAlign: "center" }}>
        {t("出題予定の問題が登録されていません。インタビュー中に出題することも可能です。")}
      </Typography>
    );
  }

  return (
    <TableContainer>
      <StyledTable width="100%">
        <TableHead sx={{ width: "100%" }}>
          <TableRow sx={{ width: "100%" }}>
            <StyledTableCell sx={{ width: "65%" }}>
              <StyledTypography>{t("出題問題")}</StyledTypography>
            </StyledTableCell>
            <StyledTableCell sx={{ width: "10%" }}>
              <StyledTypography>{t("難易度")}</StyledTypography>
            </StyledTableCell>
            <StyledTableCell sx={{ width: "15%" }}>
              <StyledTypography>{t("深堀り質問")}</StyledTypography>
            </StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody sx={{ width: "100%" }}>
          {props.rows.map((row, index) => (
            <QuestionOverviewTableRow key={`row-${index}`} {...row} />
          ))}
        </TableBody>
      </StyledTable>
    </TableContainer>
  );
};

QuestionOverviewTable.displayName = "QuestionOverviewTable";

export default QuestionOverviewTable;
