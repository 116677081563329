import { useTranslation } from "@hireroo/i18n";
import Box from "@mui/material/Box";
import Button, { ButtonProps } from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";

import SignInFooter, { SignInFooterProps } from "../../ms-components/Auth/SignInFooter/SignInFooter";
import SignInHeader from "../../ms-components/Auth/SignInHeader/SignInHeader";
import SocialLoginButton, { SocialLoginButtonProps } from "../../ms-components/Auth/SocialLoginButton/SocialLoginButton";

const Wrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  height: "100vh",
  width: "100vw",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: theme.palette.background.default,
}));

const WrapperStack = styled(Stack)(({ theme }) => ({
  width: "416px",
  padding: theme.spacing(3),
  borderRadius: "16px",
  backgroundColor: theme.palette["Background/Paper"].p2,
}));

const BUTTON_WIDTH = 189;

export type CandidateSignInProps = {
  googleSignInButton: Pick<SocialLoginButtonProps, "onClick">;
  githubSignInButton: Pick<SocialLoginButtonProps, "onClick">;
  withoutSignInButton?: Pick<ButtonProps, "onClick">;
  SignInComponent: React.ReactNode;
  footer: SignInFooterProps;
  signUpButton: Pick<ButtonProps, "onClick">;
};

const CandidateSignIn: React.FC<CandidateSignInProps> = props => {
  const { t } = useTranslation();

  const signUpButton: ButtonProps = {
    ...props.signUpButton,
    color: "secondary",
    sx: {
      textTransform: "none",
    },
    children: t("アカウントを作成"),
  };

  const withoutSignInButton: ButtonProps = {
    ...props.withoutSignInButton,
    color: "secondary",
    sx: {
      textTransform: "none",
    },
    children: t("サインインしないで利用する"),
  };

  const googleLoginButton: SocialLoginButtonProps = {
    kind: "SIGN_IN",
    provider: "Google",
    onClick: props.googleSignInButton.onClick,
    width: BUTTON_WIDTH,
  };

  const githubLoginButton: SocialLoginButtonProps = {
    kind: "SIGN_IN",
    provider: "GitHub",
    onClick: props.githubSignInButton.onClick,
    width: BUTTON_WIDTH,
  };

  return (
    <Wrapper>
      <Stack direction="column" alignItems="center" width="416px" spacing={2}>
        <SignInHeader withoutLogoText={true} />
        <WrapperStack spacing={3}>
          <Typography variant="h6" fontSize={20} fontWeight={700}>
            {t("サインイン")}
          </Typography>
          {props.SignInComponent}
          <Box>
            <Divider>
              <Typography variant="body2" color="textSecondary">
                {t("または")}
              </Typography>
            </Divider>
          </Box>
          <Stack spacing={2} alignItems="center">
            <SocialLoginButton {...googleLoginButton} />
          </Stack>
          <Stack spacing={2} alignItems="center">
            <SocialLoginButton {...githubLoginButton} />
          </Stack>
        </WrapperStack>
        <Box display="flex" justifyContent="center">
          <Button {...signUpButton} />
        </Box>
        {props.withoutSignInButton && (
          <Box display="flex" justifyContent="center">
            <Button {...withoutSignInButton} />
          </Box>
        )}
        <SignInFooter {...props.footer} />
      </Stack>
    </Wrapper>
  );
};

CandidateSignIn.displayName = "CandidateSignIn";

export default CandidateSignIn;
