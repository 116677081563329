import * as z from "zod";

const useExpectedSalaryField = () => {
  return z.object({
    min: z.number(),
    max: z.number(),
  });
};

const useAverageScoreField = () => {
  return z.object({
    min: z.number(),
    max: z.number(),
  });
};

const useJobChangeStatus = () => {
  return z.array(z.union([z.literal("HIGH"), z.literal("MEDIUM"), z.literal("LOW"), z.literal("NONE")]));
};

const useAverageRank = () => {
  return z.array(z.union([z.literal("S"), z.literal("A"), z.literal("B"), z.literal("C"), z.literal("D")]));
};

export const useCandidateSearchQuery = () => {
  const expectedSalary = useExpectedSalaryField();
  const jobChangeStatuses = useJobChangeStatus();
  const averageScore = useAverageScoreField();
  const averageRank = useAverageRank();

  return z.object({
    textFilter: z.string(),
    expectedSalary,
    jobChangeStatuses,
    averageScore,
    averageRank,
  });
};

export type CandidateSearchQuerySchema = z.infer<ReturnType<typeof useCandidateSearchQuery>>;
