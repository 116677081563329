import { CompetitionResultDetail } from "@hireroo/app-store/widget/e/CompetitionResultDetail";
import { Widget } from "@hireroo/presentation";
import { useTransitionNavigate } from "@hireroo/router/hooks";
import * as React from "react";

export type GenerateCompetitionResultDetailPropsArgs = {
  Report: React.ReactNode;
};

export const useGenerateProps = (args: GenerateCompetitionResultDetailPropsArgs): Widget.CompetitionResultDetailProps => {
  const navigate = useTransitionNavigate();
  const result = CompetitionResultDetail.useResult();

  return {
    header: {
      candidateName: result.candidate?.displayName ?? "",
      candidateAvatarUrl: result.candidate?.photoUrl ?? "",
      competitionButton: {
        onClick: () => {
          navigate("/e/competitions/:id/detail", { pathParams: { id: result.competition?.competitionId ?? "" } });
        },
        children: result.competition?.title ?? "",
      },
    },
    children: args.Report,
  };
};
