import { ResetPasswordStore } from "@hireroo/app-store/page/reset_password";
import { Snackbar } from "@hireroo/app-store/widget/shared/Snackbar";
import { sendPasswordResetEmail } from "@hireroo/firebase";
import { useLanguageCode, useTranslationWithVariable } from "@hireroo/i18n";
import type { Widget } from "@hireroo/presentation";
import { generateHelpCenterUrl } from "@hireroo/router/api";
import { useHelpCenterNavigate, useTransitionNavigate } from "@hireroo/router/hooks";
import { ResetPasswordForm } from "@hireroo/validator";

export type GenerateResetPasswordPropsArgs = {};

export const useGenerateProps = (_args: GenerateResetPasswordPropsArgs): Widget.ResetPasswordProps => {
  const { t: t2 } = useTranslationWithVariable();

  const lang = useLanguageCode();
  const helpCenterNavigate = useHelpCenterNavigate(lang);
  const state = ResetPasswordStore.useSnapshotState();
  const navigate = useTransitionNavigate();
  const handleSubmit = async (field: ResetPasswordForm.RequestResetPasswordFormSchema) => {
    ResetPasswordStore.updateMailAddress(field.email);
    await sendPasswordResetEmail(field.email, lang)
      .then(() => {
        Snackbar.notify({
          severity: "success",
          message: t2("sendResetMailLinkSuccess", {
            mail: field.email,
          }),
        });
        ResetPasswordStore.updateStatus("SUCCESS_SEND_REST_MAIL_LINK");
      })
      .catch(() => {
        Snackbar.notify({
          severity: "error",
          message: t2("sendResetMailLinkFail", {
            mail: field.email,
          }),
        });
        ResetPasswordStore.updateStatus("FAILED_SEND_REST_MAIL_LINK");
      });
  };
  const formMap: Record<ResetPasswordStore.Status, Widget.ResetPasswordProps["form"]> = {
    SUCCESS_SEND_REST_MAIL_LINK: {
      kind: "RESET_REQUEST_SENT",
      sentMessage: t2("sendResetMailLinkSuccess", { mail: state.mailAddress }),
    },
    FAILED_SEND_REST_MAIL_LINK: {
      kind: "RESET_REQUEST",
      props: {
        onSubmit: handleSubmit,
      },
    },
    BEFORE_RESET_PASSWORD: {
      kind: "RESET_REQUEST",
      props: {
        onSubmit: handleSubmit,
      },
    },
  };

  return {
    form: formMap[state.status],
    backButton: {
      onClick: () => {
        navigate("/c/signin");
      },
    },
    header: {
      withoutLogoText: true,
    },
    footer: {
      helpCenterLink: {
        href: generateHelpCenterUrl(lang, "CANDIDATE_HELP_PAGE"),
        onClick: () => {
          helpCenterNavigate("CANDIDATE_HELP_PAGE", { _blank: true });
        },
      },
      termsOfUseLink: {
        href: generateHelpCenterUrl(lang, "TOS_FOR_CANDIDATE"),
        onClick: () => {
          helpCenterNavigate("TOS_FOR_CANDIDATE", { _blank: true });
        },
      },
      privacyPolicyLink: {
        href: generateHelpCenterUrl(lang, "PRIVACY_POLICY"),
        onClick: () => {
          helpCenterNavigate("PRIVACY_POLICY", { _blank: true });
        },
      },
    },
  };
};
