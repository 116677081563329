import { useTheme } from "@mui/material/styles";
import * as React from "react";
import { GithubLoginButton, GoogleLoginButton } from "react-social-login-buttons";

type Provider = "Google" | "GitHub";

export type SocialLoginButtonProps = {
  provider: Provider;
  kind: "SIGN_IN" | "SIGN_UP";
  onClick: () => void;
  width: number;
};

type LoginButtonProps = React.ComponentProps<typeof GoogleLoginButton>;

const createButtonText = (kind: SocialLoginButtonProps["kind"], provider: SocialLoginButtonProps["provider"]): string => {
  const textMap: Record<SocialLoginButtonProps["kind"], string> = {
    SIGN_IN: `Sign in with ${provider}`,
    SIGN_UP: `Sign up with ${provider}`,
  };

  return textMap[kind];
};

const SocialLoginButton: React.FC<SocialLoginButtonProps> = ({ provider, kind, onClick, width }) => {
  const theme = useTheme();

  const loginButtonProps = React.useMemo((): LoginButtonProps => {
    const text = createButtonText(kind, provider);
    return {
      align: "center",
      activeStyle: {
        backgroundColor: theme.palette["Background/Paper"].p2,
        border: `1px solid ${theme.palette.text.primary}`,
        fontWeight: 400,
      },
      style: {
        width: `${width}px`,
        height: 40,
        margin: 0,
        fontSize: "14px",
        fontWeight: 400,
        backgroundColor: theme.palette["Background/Paper"].p2,
        border: `1px solid ${theme.palette.divider}`,
        color: theme.palette.text.primary,
      },
      text: text,
      onClick: onClick,
    };
  }, [theme, kind, width, provider, onClick]);

  const LoginButtonMap = React.useMemo((): Record<Provider, React.ReactNode> => {
    return {
      Google: <GoogleLoginButton {...loginButtonProps} />,
      GitHub: <GithubLoginButton {...loginButtonProps} />,
    };
  }, [loginButtonProps]);

  return LoginButtonMap[provider];
};

SocialLoginButton.displayName = "SocialLoginButton";

export default SocialLoginButton;
