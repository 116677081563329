import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { useGenerateProps } from "./useGenerateProps";

export type CandidateListContainerProps = {};

const CandidateListContainer: React.FC<CandidateListContainerProps> = () => {
  const candidateListProps = useGenerateProps({});
  return (
    <ErrorBoundary>
      <Widget.CandidateList {...candidateListProps} />
    </ErrorBoundary>
  );
};

CandidateListContainer.displayName = "CandidateListContainer";

export default withErrorBoundary(CandidateListContainer, {});
