import { subscribeKey } from "valtio/utils";

import { state } from "./State";
import * as Types from "./types";

export type SubscribeOnChangeResultCallback = (result: Types.Result) => void;
export const subscribeOnChangeResult = (callback: SubscribeOnChangeResultCallback) => {
  return subscribeKey(state, "result", result => {
    if (result) {
      callback(result);
    }
  });
};
