import { useTranslation } from "@hireroo/i18n";
import PriceJPRounded from "@hireroo/ui-assets/images/Icon/PriceJpRounded.svg";
import BusinessIcon from "@mui/icons-material/Business";
import CheckRounded from "@mui/icons-material/CheckRounded";
import InsertInvitationRoundedIcon from "@mui/icons-material/InsertInvitationRounded";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import Avatar, { AvatarProps } from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Button, { ButtonProps } from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { styled, useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { ImgHTMLAttributes } from "react";

import MoreButton, { MoreButtonProps } from "../../../primitive/Button/MoreButton/MoreButton";
import GuidelinesDialog, { GuidelinesDialogProps } from "./parts/GuidelinesDialog/GuidelinesDialog";
import ProfileDialog, { ProfileDialogProps } from "./parts/ProfileDialog/ProfileDialog";
import RegisterDialog, { RegisterDialogProps } from "./parts/RegisterDialog/RegisterDialog";

const Wrapper = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3, 3, 2, 3),
  backgroundColor: theme.palette["Background/Paper"].p1,
}));

const ImageWrapper = styled(Box)(() => ({
  objectFit: "contain",
}));

const Image = styled("img")`
  height: 120px;
  aspect-ratio: auto;
  border-radius: 12px;
`;

const LabelWrapper = styled(Box)(() => ({
  borderRadius: "20px",
  backgroundColor: "white",
  padding: "1px 6px",
}));

const RegisteredStack = styled(Stack)(({ theme }) => ({
  borderRadius: "4px",
  border: "1px solid",
  borderColor: theme.palette["Primary/Shades"].p50,
  alignItems: "center",
  justifyContent: "center",
  padding: "4px 10px",
  height: "30px",
}));

type Company = {
  name: string;
  src?: AvatarProps["src"];
};

export type CompetitionDetailHeaderProps = {
  company: Company;
  title: string;
  duration: string;
  label: string;
  scale: string;
  salary: string;
  thumbnailImage?: Pick<ImgHTMLAttributes<HTMLImageElement>, "src" | "alt">;
  registerButton?: Pick<ButtonProps, "children" | "disabled" | "variant" | "onClick">;
  moreButton?: MoreButtonProps;
  guidelineDialog?: GuidelinesDialogProps;
  registerDialog?: RegisterDialogProps;
  willStartAt?: string;
  profileDialog?: ProfileDialogProps;
};

const CompetitionDetailHeader: React.FC<CompetitionDetailHeaderProps> = props => {
  const theme = useTheme();
  const { t } = useTranslation();
  const registerButton: ButtonProps | undefined = props.registerButton
    ? {
        ...props.registerButton,
        size: "small",
        color: "primary",
      }
    : undefined;
  return (
    <Wrapper>
      <Stack direction="row" spacing={1} mb={2} alignItems="center" justifyContent="space-between">
        <Stack direction="row" spacing={1} alignItems="center">
          <Avatar sx={{ width: 24, height: 24 }} alt={props.company.name} src={props.company.src}>
            <BusinessIcon fontSize="small" />
          </Avatar>
          <Typography fontSize={14}>{props.company.name}</Typography>
        </Stack>
        <Stack direction="row" spacing={1.5} alignItems="center">
          {registerButton && <Button {...registerButton} />}
          {props.willStartAt && (
            <RegisteredStack direction="row" spacing={1}>
              <CheckRounded fontSize="small" htmlColor={theme.palette.primary.main} />
              <Typography fontSize="13px" lineHeight="22px" fontWeight={400} color={theme.palette.primary.main}>
                {t("登録済み")}
              </Typography>
              <Typography fontSize="10px" color={theme.palette.primary.main}>
                {props.willStartAt}
              </Typography>
            </RegisteredStack>
          )}
          {props.moreButton && <MoreButton {...props.moreButton} variant="vertical" />}
        </Stack>
      </Stack>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Stack spacing={0.5}>
          <Typography fontSize={24} fontWeight={700} mb={1.5}>
            {props.title}
          </Typography>
          <Stack direction="row" alignItems="center" spacing={1} mb={0.25}>
            <InsertInvitationRoundedIcon fontSize="small" sx={{ width: 16, height: 16 }} htmlColor={theme.palette["Gray/Shades"].p56} />
            <Typography fontSize={14} color="textSecondary">
              {props.duration}
            </Typography>
            <LabelWrapper>
              <Typography fontSize={10} color="black">
                {props.label}
              </Typography>
            </LabelWrapper>
          </Stack>
          <Stack direction="row" alignItems="center" spacing={1}>
            <SupervisorAccountIcon fontSize="small" sx={{ width: 16, height: 16 }} htmlColor={theme.palette["Gray/Shades"].p56} />
            <Typography fontSize={14} color="textSecondary">
              {props.scale}
            </Typography>
          </Stack>
          <Stack direction="row" alignItems="center" spacing={1}>
            <img src={PriceJPRounded} alt="price icon jp" />
            <Typography fontSize={14} color="textSecondary">
              {props.salary}
            </Typography>
          </Stack>
        </Stack>
        {props.thumbnailImage && (
          <ImageWrapper>
            <Image src={props.thumbnailImage.src} alt={props.thumbnailImage.alt} />
          </ImageWrapper>
        )}
      </Stack>
      {props.guidelineDialog && <GuidelinesDialog {...props.guidelineDialog} />}
      {props.registerDialog && <RegisterDialog {...props.registerDialog} />}
      {props.profileDialog && <ProfileDialog {...props.profileDialog} />}
    </Wrapper>
  );
};

CompetitionDetailHeader.displayName = "CompetitionDetailHeader";

export default CompetitionDetailHeader;
