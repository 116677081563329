import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { useGenerateProps } from "./useGenerateProps";

export type CandidateMenuContainerProps = {};

const CandidateMenuContainer: React.FC<CandidateMenuContainerProps> = () => {
  const navigationPopupMenuProps = useGenerateProps({});
  return (
    <ErrorBoundary>
      <Widget.NavigationPopupMenu {...navigationPopupMenuProps} />
    </ErrorBoundary>
  );
};

CandidateMenuContainer.displayName = "CandidateMenuContainer";

export default withErrorBoundary(CandidateMenuContainer, {});
