import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import * as React from "react";

export type SectionProps = {
  title: string;
  children: React.ReactNode;
};

const Section: React.FC<SectionProps> = props => {
  return (
    <Stack spacing={3}>
      <Typography variant="h6" fontSize={20} fontWeight={700}>
        {props.title}
      </Typography>
      {props.children}
    </Stack>
  );
};

Section.displayName = "Section";

export default Section;
