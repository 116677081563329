import { CompetitionHistory } from "@hireroo/app-store/widget/e/CompetitionHistory";

export const isValidSortFieldValue = (value: string): value is CompetitionHistory.SortFieldValue => {
  return Object.keys(CompetitionHistory.SORT_FIELDS).includes(value);
};

export const convertSortParamsToSortFieldValue = (sortParams: CompetitionHistory.SortParams): CompetitionHistory.SortFieldValue => {
  for (const [key, value] of Object.entries(CompetitionHistory.SORT_PARAMS)) {
    if (value.sortMethod === sortParams.sortMethod && value.isDescending === sortParams.isDescending) {
      if (isValidSortFieldValue(key)) {
        return key;
      }
    }
  }
  return "COMPLETED_AT_DESCENDING";
};
