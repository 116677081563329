import { App, Credential, SignInOrSignUp } from "@hireroo/app-store/essential/shared";
import { Snackbar } from "@hireroo/app-store/widget/shared/Snackbar";
import { FirebaseUser, sendEmailVerification } from "@hireroo/firebase";
import { getLanguage, getTranslation, getTranslationWithVariable } from "@hireroo/i18n";
import { LastVisitedRoute, redirectWithRawUrl } from "@hireroo/router/api";
import { differenceInMinutes } from "date-fns";

type AfterSignupProcessArgs = {
  user: FirebaseUser;
  emailAddress: string;
};

export const afterSignUpProcess = async (args: AfterSignupProcessArgs) => {
  App.setStatus("INITIALIZING");
  SignInOrSignUp.updateSignInStatus("VERIFYING");
  const { user, emailAddress } = args;
  const { t } = getTranslation();
  const { t: t2 } = getTranslationWithVariable();
  const languageCode = getLanguage();
  /**
   * Send an verification email if within one hour of the time the user was created
   * At this point, there should be no metadata, but if it is undefined, put 0.
   */
  const diffMins: number = user.metadata.creationTime ? differenceInMinutes(new Date(), Date.parse(user.metadata.creationTime)) : 0;
  if (!user.emailVerified && diffMins < 60) {
    await sendEmailVerification(user, languageCode)
      .then(() => {
        Snackbar.notify({
          severity: "success",
          message: t2("sendEmailVerify", { email: emailAddress }),
        });
      })
      .catch(() => {
        Snackbar.notify({
          severity: "error",
          message: t("メールアドレスの確認メールの送信に失敗しました。再度サインインし直しください。"),
        });
      });
  }
  const res = await user.getIdTokenResult(true);
  Credential.setProviderId(res.signInProvider);
  Snackbar.notify({
    severity: "success",
    message: t("サインインに成功しました。15秒経ってもリダイレクトされない場合は手動でリロードして下さい。"),
  });

  const previousPathname = LastVisitedRoute.getAndClear();

  const redirectUrl: string = previousPathname || "/c/verify_email";

  await redirectWithRawUrl(redirectUrl);
};
