import { useCompetitionStatusMap } from "@hireroo/app-definition/competition";
import { translateSalary } from "@hireroo/app-helper/competition";
import { Competitions } from "@hireroo/app-store/page/c/competitions";
import * as TimeFormatter from "@hireroo/formatter/time";
import { useLanguageCode, useTranslation, useTranslationWithVariable } from "@hireroo/i18n";
import type { Widget } from "@hireroo/presentation";
import { generatePath } from "@hireroo/router/api";
import { useTransitionNavigate } from "@hireroo/router/hooks";
import * as React from "react";

import * as PrivateHelper from "./privateHelper";

type Item = Widget.CompetitionCardListProps["items"][number];

export type GenerateCompetitionCardListPropsArgs = {
  loading: boolean;
};

export const useGenerateProps = (args: GenerateCompetitionCardListPropsArgs): Widget.CompetitionCardListProps => {
  const lang = useLanguageCode();
  const { t } = useTranslation();
  const { t: t2 } = useTranslationWithVariable();
  const competitions = Competitions.useCompetitions();
  const res = Competitions.useRes();
  const navigate = useTransitionNavigate();
  const statusMap = useCompetitionStatusMap();

  const items = React.useMemo((): Item[] => {
    return competitions.map((competition): Item => {
      return {
        id: competition.id,
        title: competition.title,
        duration: `${TimeFormatter.unixToDatetimeFormat(competition.startsAtSeconds)} ~ ${TimeFormatter.unixToDatetimeFormat(competition.endsAtSeconds)}`,
        salary: `¥ ${translateSalary({ salaryInTenK: competition.minSalary, lang: lang })}+`,
        candidates: `${competition.applicantIds.length} ${t("人")}`,
        thumbnail: {
          header: {
            name: competition.hostCompany?.name || "",
            src: competition.hostCompany?.logo || undefined,
          },
          label: statusMap[competition.status],
          image: {
            src: competition.thumbnailUrl ?? undefined,
            alt: competition.title,
          },
        },
        href: generatePath("/c/competitions/:id/detail", {
          pathParams: {
            id: competition.competitionId,
          },
        }),
        onClick: () => {
          navigate("/c/competitions/:id/detail", {
            pathParams: {
              id: competition.competitionId,
            },
          });
        },
      };
    });
  }, [competitions, lang, t, statusMap, navigate]);

  return {
    searchResultBar: {
      defaultValue: "CREATED_AT_ASCENDING",
      resultText: t2("SearchResultCount", { count: res.count }),
      onChangeSortMethod: value => {
        const target = PrivateHelper.sortMethodMap[value];
        Competitions.updateSortParams({
          sortMethod: target.sortMethod,
          isDescending: target.isDescending,
        });
      },
    },
    items: items,
    loading: args.loading,
    onEndReached: () => {
      if (res.hasNext) {
        Competitions.updateOffset(items.length + 1);
      }
    },
  };
};
