import { Box, Paper } from "@mui/material";
import React from "react";

import LeaderboardTable from "./parts/LeaderboardTable/LeaderboardTable";
import { LeaderboardTableRowProps } from "./parts/LeaderboardTableRow/LeaderboardTableRow";

export type CompetitionApplicantLeaderboardProps = {
  leaderboard: LeaderboardTableRowProps[];
};

const CompetitionApplicantLeaderboard: React.FC<CompetitionApplicantLeaderboardProps> = ({ leaderboard: leaderboardData }) => {
  return (
    <Box p={3}>
      <Paper sx={{ boxShadow: "none", borderRadius: "16px", bgcolor: "#1E1E1E" }} elevation={2}>
        <LeaderboardTable items={leaderboardData} />
      </Paper>
    </Box>
  );
};

CompetitionApplicantLeaderboard.displayName = "CompetitionApplicantLeaderboard";

export default CompetitionApplicantLeaderboard;
