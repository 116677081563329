import { useTranslation } from "@hireroo/i18n";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { styled, useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";

import PieChart from "./parts/PieChart/PieChart";
import ScoreLabel from "./parts/ScoreLabel/ScoreLabel";
import StatusMessage from "./parts/StatusMessage/StatusMessage";

const WIDTH = 152;

const OuterWrapper = styled(Stack)(() => ({
  width: WIDTH,
  height: "190px",
  display: "flex",
  justifyContent: "space-between",
  borderRadius: "16px",
  overflow: "hidden",
}));

const ChartArea = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  textAlign: "center",
}));

const Footer = styled(Box)(({ theme }) => ({
  width: "100%",
  padding: theme.spacing(1),
  backgroundColor: "rgba(0, 0, 0, 0.40)",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));

const DefaultBackgroundColor = "linear-gradient(270deg, #686868 0%, #868686 100%)";
const WithRankingBackgroundColor = "#E84564";

type ErrorView = {
  kind: "ERROR";
  message: string;
};

type ScoringView = {
  kind: "SCORING";
};

type WithRankingView = {
  kind: "WITH_RANKING";
  ranking: number;
  participants: number;
};

type DisplayScoreArea = ScoringView | WithRankingView | ErrorView;

export type ScorePieChartWithRankingProps = {
  score: number;
  displayScoreArea: DisplayScoreArea;
};

const ScorePieChartWithRanking: React.FC<ScorePieChartWithRankingProps> = props => {
  const { t } = useTranslation();
  const theme = useTheme();

  const bgColor = React.useMemo((): string => {
    if (props.displayScoreArea.kind === "WITH_RANKING") {
      return WithRankingBackgroundColor;
    }
    return DefaultBackgroundColor;
  }, [props.displayScoreArea]);

  return (
    <OuterWrapper direction="column" sx={{ background: bgColor }}>
      <ChartArea>
        <PieChart value={props.score}>
          <Stack direction="column" spacing={-1}>
            <Typography variant="subtitle2">{t("スコア")}</Typography>
            <ScoreLabel score={props.score} />
          </Stack>
        </PieChart>
      </ChartArea>
      {props.displayScoreArea.kind === "WITH_RANKING" && (
        <Footer>
          <Stack direction="row" alignItems="baseline">
            <Typography variant="subtitle2" color={theme.palette.text.secondary} pr={1.5}>
              {t("順位")}
            </Typography>
            <Typography fontSize={14} fontWeight={700} color={theme.palette.text.primary}>
              {props.displayScoreArea.ranking}
            </Typography>
            <Typography fontSize={12} color={theme.palette.text.secondary}>
              /{props.displayScoreArea.participants}
            </Typography>
          </Stack>
        </Footer>
      )}
      {props.displayScoreArea.kind === "SCORING" && (
        <Footer>
          <StatusMessage kind="INFO" message={t("採点中")} />
        </Footer>
      )}
    </OuterWrapper>
  );
};

ScorePieChartWithRanking.displayName = "ScorePieChartWithRanking";

export default ScorePieChartWithRanking;
