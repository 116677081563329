type ContentObj = {
  titleEn?: string | null;
  titleJa?: string | null;
  title_en?: string | null;
  title_ja?: string | null;
  descriptionEn?: string | null;
  descriptionJa?: string | null;
  description_en?: string | null;
  description_ja?: string | null;
  questionJa?: string | null;
  questionEn?: string | null;
  modelAnswerJa?: string | null;
  modelAnswerEn?: string | null;
  purposeJa?: string | null;
  purposeEn?: string | null;
  additionalDetailJa?: string | null;
  additionalDetailEn?: string | null;
  messageJa?: string | null;
  messageEn?: string | null;
  nameEn?: string | null;
  nameJa?: string | null;
  reasonEn?: string | null;
  reasonJa?: string | null;
  reason_en?: string | null;
  reason_ja?: string | null;
  en?: string | null;
  ja?: string | null;
};

export type Language = "ja" | "en";
export type ContentType =
  | "title"
  | "description"
  | "additionalDetail"
  | "message"
  | "name"
  | "reason"
  | "translateText"
  | "question"
  | "modelAnswer"
  | "purpose";
type Key = `${ContentType}-${Language}`;

export type NoDataTextMap = Record<Language, string>;

const defaultNoDataTextMap: NoDataTextMap = {
  ja: "データが登録されていません",
  en: "No Data",
};

const convertEmptyToUndefined = (value: string | null | undefined): string | undefined => {
  if (value) return value;
  return undefined;
};

export const resolveLanguage = (
  obj: ContentObj,
  lang: Language | string,
  contentType: ContentType,
  noDataText: NoDataTextMap | string = defaultNoDataTextMap,
): string => {
  const noDataTextMap: NoDataTextMap =
    typeof noDataText === "string"
      ? {
          ja: noDataText,
          en: noDataText,
        }
      : noDataText;
  const titleEn = obj.titleEn ?? obj.title_en;
  const titleJa = obj.titleJa ?? obj.title_ja;
  const reasonEn = obj.reasonEn ?? obj.reason_en;
  const reasonJa = obj.reasonJa ?? obj.reason_ja;
  const map: Record<Key, string> = {
    "title-en": convertEmptyToUndefined(titleEn) ?? convertEmptyToUndefined(titleJa) ?? noDataTextMap.en,
    "title-ja": convertEmptyToUndefined(titleJa) ?? convertEmptyToUndefined(titleEn) ?? noDataTextMap.ja,
    "name-en": convertEmptyToUndefined(obj.nameEn) ?? convertEmptyToUndefined(obj.nameJa) ?? noDataTextMap.en,
    "name-ja": convertEmptyToUndefined(obj.nameJa) ?? convertEmptyToUndefined(obj.nameEn) ?? noDataTextMap.ja,
    "description-ja":
      convertEmptyToUndefined(obj.descriptionJa) ??
      convertEmptyToUndefined(obj.description_ja) ??
      convertEmptyToUndefined(obj.descriptionEn) ??
      noDataTextMap.ja,
    "description-en":
      convertEmptyToUndefined(obj.descriptionEn) ??
      convertEmptyToUndefined(obj.description_en) ??
      convertEmptyToUndefined(obj.descriptionJa) ??
      noDataTextMap.en,
    "question-ja": convertEmptyToUndefined(obj.questionJa) ?? convertEmptyToUndefined(obj.questionEn) ?? noDataTextMap.ja,
    "question-en": convertEmptyToUndefined(obj.questionEn) ?? convertEmptyToUndefined(obj.questionJa) ?? noDataTextMap.en,
    "modelAnswer-ja": convertEmptyToUndefined(obj.modelAnswerJa) ?? convertEmptyToUndefined(obj.modelAnswerEn) ?? noDataTextMap.ja,
    "modelAnswer-en": convertEmptyToUndefined(obj.modelAnswerEn) ?? convertEmptyToUndefined(obj.modelAnswerJa) ?? noDataTextMap.en,
    "purpose-ja": convertEmptyToUndefined(obj.purposeJa) ?? convertEmptyToUndefined(obj.purposeEn) ?? noDataTextMap.ja,
    "purpose-en": convertEmptyToUndefined(obj.purposeEn) ?? convertEmptyToUndefined(obj.purposeJa) ?? noDataTextMap.en,
    "additionalDetail-ja":
      convertEmptyToUndefined(obj.additionalDetailJa) ?? convertEmptyToUndefined(obj.additionalDetailEn) ?? noDataTextMap.ja,
    "additionalDetail-en":
      convertEmptyToUndefined(obj.additionalDetailEn) ?? convertEmptyToUndefined(obj.additionalDetailJa) ?? noDataTextMap.en,
    "message-ja": convertEmptyToUndefined(obj.messageJa) ?? convertEmptyToUndefined(obj.messageEn) ?? noDataTextMap.ja,
    "message-en": convertEmptyToUndefined(obj.messageEn) ?? convertEmptyToUndefined(obj.messageJa) ?? noDataTextMap.en,
    "reason-ja": convertEmptyToUndefined(reasonJa) ?? convertEmptyToUndefined(reasonEn) ?? noDataTextMap.ja,
    "reason-en": convertEmptyToUndefined(reasonEn) ?? convertEmptyToUndefined(reasonJa) ?? noDataTextMap.en,
    "translateText-ja": convertEmptyToUndefined(obj.ja) ?? convertEmptyToUndefined(obj.en) ?? noDataTextMap.ja,
    "translateText-en": convertEmptyToUndefined(obj.en) ?? convertEmptyToUndefined(obj.ja) ?? noDataTextMap.en,
  };
  const key = `${contentType}-${lang}` as Key;
  return map[key] || "";
};
