import { CompetitionsIdDetail } from "@hireroo/app-store/page/e/competitions_id_detail";
import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";
import { useSearchParams } from "react-router-dom";

import CompetitionApplicantsContainer from "./Container";
import * as PrivateHelper from "./privateHelper";

export type CompetitionApplicantsContainerProps = {};

const CompetitionApplicantsInitialContainer: React.FC<CompetitionApplicantsContainerProps> = () => {
  const [searchParams] = useSearchParams();
  const initializedApplicantFilter = CompetitionsIdDetail.useInitializedApplicantFilter();

  React.useEffect(() => {
    CompetitionsIdDetail.initializeApplicantFilter(
      {
        textFilter: searchParams.get(CompetitionsIdDetail.QueryKeys.NAME) ?? "",
        statuses: PrivateHelper.convertStringToStatuses(searchParams.getAll(CompetitionsIdDetail.QueryKeys.STATUS)),
        suspiciousDegrees: PrivateHelper.convertStringsToSuspiciousDegrees(
          searchParams.getAll(CompetitionsIdDetail.QueryKeys.SUSPICIOUS_DEGREES),
        ),
        score: {
          max:
            PrivateHelper.convertStringToTotalScore(searchParams.get(CompetitionsIdDetail.QueryKeys.MAX_SCORE)) ??
            CompetitionsIdDetail.DEFAULT_APPLICANT_FILTER.score.max,
          min:
            PrivateHelper.convertStringToTotalScore(searchParams.get(CompetitionsIdDetail.QueryKeys.MIN_SCORE)) ??
            CompetitionsIdDetail.DEFAULT_APPLICANT_FILTER.score.min,
        },
      },
      {
        page: PrivateHelper.convertStringToPage(searchParams.get(CompetitionsIdDetail.QueryKeys.PAGE)),
        size: PrivateHelper.convertStringToSize(searchParams.get(CompetitionsIdDetail.QueryKeys.SIZE)),
        sortParams: PrivateHelper.convertStringToSortFieldValue(searchParams.get(CompetitionsIdDetail.QueryKeys.SORT)),
      },
    );
  }, [searchParams]);

  if (!initializedApplicantFilter) {
    return <Widget.Loading kind="CENTER_%" />;
  }

  return (
    <ErrorBoundary>
      <CompetitionApplicantsContainer />
    </ErrorBoundary>
  );
};

CompetitionApplicantsInitialContainer.displayName = "CompetitionApplicantsInitialContainer";

export default withErrorBoundary(CompetitionApplicantsInitialContainer, {});
