import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import * as Subscriber from "./Subscriber";
import { useGenerateProps } from "./useGenerateProps";

export type EvaluationMetricsSectionContainerProps = {};

const EvaluationMetricsSectionContainer: React.FC<EvaluationMetricsSectionContainerProps> = () => {
  const evaluationMetricsSectionProps = useGenerateProps({});
  React.useEffect(() => {
    /**
     * Subscribe to the metric map from EvaluationMetricListForRemote and update the evaluation metrics in the store
     */
    const stop = Subscriber.subscribeEvaluationMetricMap();

    return () => {
      stop();
    };
  }, []);
  return (
    <ErrorBoundary>
      <Widget.EvaluationMetricsSection {...evaluationMetricsSectionProps} />
    </ErrorBoundary>
  );
};

EvaluationMetricsSectionContainer.displayName = "EvaluationMetricsSectionContainer";

export default withErrorBoundary(EvaluationMetricsSectionContainer, {});
