import { useEnabledLeakScore } from "@hireroo/app-helper/feature";
import { FeedbackLinkFactory, QuestionStats } from "@hireroo/app-helper/question";
import { useTitle } from "@hireroo/app-helper/react-use";
import { Credential } from "@hireroo/app-store/essential/shared";
import { QuestionsChallengeId } from "@hireroo/app-store/page/e/questions_challenge_id";
import { Snackbar } from "@hireroo/app-store/widget/shared/Snackbar";
import { languageMapForHighlight } from "@hireroo/challenge/definition";
import { formateRateToIntegerPercent } from "@hireroo/formatter/rate";
import * as Time from "@hireroo/formatter/time";
import { getGraphqlClient } from "@hireroo/graphql/client/request";
import { useLanguageCode, useTranslation, useTranslationWithVariable } from "@hireroo/i18n";
import { resolveLanguage } from "@hireroo/i18n/utils";
import { Widget } from "@hireroo/presentation";
import * as Sentry from "@sentry/react";
import * as React from "react";
import { useImmer } from "use-immer";

const DELIMITER = ",";

type ToolValue = Parameters<
  Exclude<Widget.ChallengeCodingEditorWithDetailProps["editorAndTestCases"]["editorToolbar"]["onChangeShowingCode"], undefined>
>[0];

export type GenerateChallengeCodingEditorWithDetailPropsArgs = {
  questionUid: string;
};

export const useGenerateProps = (args: GenerateChallengeCodingEditorWithDetailPropsArgs): Widget.ChallengeCodingEditorWithDetailProps => {
  const { t } = useTranslation();
  const { t: t2 } = useTranslationWithVariable();
  const currentUserMailAddress = Credential.useCurrentUserMailAddress();
  const [toolValue, setToolValue] = React.useState<ToolValue>("SHOW_ANSWER_CODE");
  const lang = useLanguageCode();
  const client = getGraphqlClient();
  const questionHooks = QuestionsChallengeId.useCreateQuestionHooks(args.questionUid);
  const questionAction = QuestionsChallengeId.createQuestionAction(args.questionUid);
  const question = questionHooks.useQuestion();
  const { selectedLanguage, testCases, selectedTestCaseIndex } = Widget.useChallengeCodingEditorContext();
  const questionCodeBodies = questionHooks.useCodeBodies();
  const [codeBodies, setCodeBodies] = useImmer<Record<string, string>>(questionCodeBodies);

  const runStatus = QuestionsChallengeId.useRunStatus();
  const testCaseResults = questionHooks.useTestCaseResults();
  const selectedTestCaseResult = testCaseResults.get(selectedTestCaseIndex);

  const enabledLeakScore = useEnabledLeakScore();

  useTitle(resolveLanguage(question, lang, "title"));

  const runCode = React.useCallback(
    async (requestId: number, inputs: string[], sourceCode?: string) => {
      QuestionsChallengeId.updateRunningStatus("RUNNING");
      questionAction.setLoadingOutputStatus(requestId);
      await client
        .ChallengeRunCode({
          questionId: question?.questionId ?? 0,
          questionVersion: question?.version ?? "",
          challengeId: 0,
          runtime: selectedLanguage,
          codeBody: sourceCode ?? codeBodies[selectedLanguage],
          input: inputs.join(DELIMITER),
          takeSnapshot: false,
        })
        .then(res => {
          if (res.challengeRunCode) {
            questionAction.setTestcaseResult(requestId, res.challengeRunCode);
            Snackbar.notify({
              severity: "success",
              message: t("実行完了。出力を確認してください。"),
            });
          }
        })
        .catch(error => {
          questionAction.setRejectedOutputStatus(requestId);
          Sentry.captureException(error);
        });
    },
    [client, question?.questionId, questionAction, question?.version, selectedLanguage, codeBodies, t],
  );

  const runAll = React.useCallback(
    async (sourceCode?: string) => {
      await Promise.all(
        testCases.map(async (testcase, index) => {
          return await runCode(index, testcase.inputs, sourceCode);
        }),
      ).finally(() => {
        QuestionsChallengeId.updateRunningStatus("READY");
      });
    },
    [runCode, testCases],
  );

  const outputResultMap =
    React.useMemo((): Widget.ChallengeCodingEditorWithDetailProps["editorAndTestCases"]["console"]["testCaseButtonGroups"]["outputResultMap"] => {
      const resultMap = new Map<number, "SUCCESS" | "FAILED">();
      Array.from(testCaseResults.keys()).map(key => {
        const result = testCaseResults.get(key);
        if (result) {
          resultMap.set(key, result.isAccepted ? "SUCCESS" : "FAILED");
        }
      });

      return Object.fromEntries(resultMap);
    }, [testCaseResults]);
  const elapseTimeObject = React.useMemo(() => {
    return Time.secondsToTimeObject(question.averageElapsedTimeSeconds, "MINUTES");
  }, [question.averageElapsedTimeSeconds]);

  const leakScoreChip: Widget.ChallengeCodingEditorWithDetailProps["content"]["detailInfo"]["leakScoreChip"] = React.useMemo(() => {
    if (!enabledLeakScore) {
      return undefined;
    }
    if (!question.isSupportedLeakScore) {
      return undefined;
    }
    if (!question.leakScore) {
      return { level: "NONE", reason: "NO_DATA" };
    }
    return { level: question.leakScore.level };
  }, [enabledLeakScore, question.isSupportedLeakScore, question.leakScore]);

  const initialCode = React.useMemo((): string => {
    const initialCodeMap: Record<string, string> = question.initialCode || {};
    return initialCodeMap[selectedLanguage] ?? "";
  }, [question.initialCode, selectedLanguage]);

  const codeEditorValue = React.useMemo((): string => {
    if (toolValue === "SHOW_ANSWER_CODE") {
      return codeBodies[selectedLanguage] ?? "";
    }
    return initialCode;
  }, [toolValue, initialCode, codeBodies, selectedLanguage]);

  const enabledLanguages = Array.from(question?.supportedLanguages || []).toSorted();

  return {
    content: {
      feedbackLink: FeedbackLinkFactory.generateFeedbackLink({
        mailAddress: currentUserMailAddress,
        targetUrl: window.location.href,
      }),
      sidebar: {
        questionSection: {
          title: resolveLanguage(question || {}, lang, "title"),
          description: resolveLanguage(question || {}, lang, "description"),
          isArchived: question.status === "ARCHIVED",
        },
        questionHintStack: {
          items: (question?.hints || []).map((hint, index) => {
            return {
              mode: "unlocked",
              value: {
                disabled: false,
                title: t2("ConfirmHint", { num: index + 1 }),
                description: resolveLanguage(hint, lang, "description"),
              },
            };
          }),
        },
        answers: (question?.answers || []).map(answer => {
          return {
            label: answer.label,
            title: resolveLanguage(answer || {}, lang, "title"),
            description: resolveLanguage(answer, lang, "description"),
          };
        }),
      },
      stats: {
        avgCorrectIntegerRate:
          question.numUses > 0
            ? {
                kind: "VALUE",
                value: formateRateToIntegerPercent(question.accuracyRate),
              }
            : { kind: "NONE" },
        numOfUsage: question.numUses.toLocaleString(),
        avgElapseTime:
          question.numUses > 0
            ? {
                kind: "VALUE",
                minutes: elapseTimeObject.minutes,
                seconds: elapseTimeObject.seconds,
              }
            : { kind: "NONE" },
      },
      scoreHistogram: {
        data: QuestionStats.generateScoreDistributionBinsForHistogram(question.scoreDistributionBins),
        dataKey: {
          x: "name",
          y: "value",
        },
      },
      detailInfo: {
        difficultyStars: {
          difficulty: question.difficulty,
        },
        numOfLanguages: {
          value: question.supportedLanguages.length,
        },
        timelimitMinutes: Time.secondsToTimeObject(question.timeLimitSeconds || 0, "MINUTES").minutes,
        createdBy: question.isOfficial
          ? {
              kind: "OFFICIAL",
            }
          : {
              kind: "PRIVATE",
              displayName: question.employee.displayName,
            },
        leakScoreChip: leakScoreChip,
      },
    },
    editorAndTestCases: {
      CodeEditors: [],
      editorToolbar: {
        onChangeShowingCode: newToolValue => {
          setToolValue(newToolValue);
        },
        runtimeSelector: {
          enabledLanguages: enabledLanguages,
        },
      },
      codeEditor: {
        language: languageMapForHighlight[selectedLanguage],
        value: codeEditorValue,
        onChange: value => {
          setCodeBodies(draft => {
            if (draft) {
              draft[selectedLanguage] = value ?? "";
            }
          });
        },
        onMount: (editor, monaco) => {
          // command + Enter
          editor.addAction({
            id: "run",
            label: "run",
            keybindings: [monaco.KeyMod.CtrlCmd | monaco.KeyCode.Enter],
            run: e => {
              const value = e.getValue();
              runAll(value);
            },
          });
        },
      },
      console: {
        testCaseButtonGroups: {
          resetButton: {
            onClick: () => {
              questionAction.clearTestcaseResults();
            },
          },
          outputResultMap: runStatus === "RUNNING" ? undefined : outputResultMap,
          isLoading: runStatus === "RUNNING",
        },
        toolbar: {
          runButton: {
            title: toolValue === "SHOW_INITIAL_CODE" ? t("初期コードを選択中のため実行できません。") : "",
            disabled: toolValue === "SHOW_INITIAL_CODE",
            onClick: async () => {
              await runAll();
            },
          },
        },
        outputConsole: {
          isLoading: runStatus === "RUNNING",
          output: selectedTestCaseResult && {
            expected: selectedTestCaseResult.expected,
            isAccepted: selectedTestCaseResult.isAccepted,
            output: selectedTestCaseResult.output,
            log: selectedTestCaseResult.log,
            status: selectedTestCaseResult.status,
            performanceNanoSeconds: `${Time.formatTime(selectedTestCaseResult.performance, Time.Unit.NANOSECOND)}`,
            maxMemoryMb: Math.floor(selectedTestCaseResult.maxMemory),
          },
        },
      },
    },
  };
};
