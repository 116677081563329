import { state } from "./State";
import type * as Types from "./types";

export const initialize = (applicants: Types.Applicant[]): void => {
  applicants.forEach(applicant => {
    state.applicantMap.set(applicant.applicantId, applicant);
  });
  state.initialized = true;
};

export const clear = (): void => {
  state.initialized = false;
  state.applicantMap.clear();
};
