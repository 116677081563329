import { ENTITY_REPORT_TOP } from "@hireroo/app-helper/entity";
import { scrollToContentForReportV2 } from "@hireroo/app-helper/html-element";
import { CompetitionResultReport } from "@hireroo/app-store/widget/e/CompetitionResultReport";
import { useCurrentLanguage } from "@hireroo/i18n";
import { resolveLanguage } from "@hireroo/i18n/utils";
import type { Widget } from "@hireroo/presentation";
import { updateHashParam } from "@hireroo/router/api";
import * as React from "react";

import { generateSmoothTargetId } from "./privateHelper";

type Item = Widget.CompetitionResultReportProps["footer"]["switcher"]["items"][0];

export const useGenerateFooterProps = (): Widget.CompetitionResultReportProps["footer"] => {
  const result = CompetitionResultReport.useResult();
  const lang = useCurrentLanguage();
  const selectedEntityId = CompetitionResultReport.useSelectedEntityId();

  const items = React.useMemo((): Item[] => {
    return result.entities.map((entity, index): Item => {
      if (entity.__typename === "ChallengeEntity" && entity.challengeQuestion) {
        const question = entity.challengeQuestion;
        const hashKey = generateSmoothTargetId(entity);
        const uniqueEntityId = CompetitionResultReport.generateUniqueEntityId(entity);
        return {
          value: uniqueEntityId,
          displayText: `Q${index + 1}`,
          disabled: false,
          status: "PENDING",
          tooltipText: resolveLanguage(question, lang, "title"),
          onClick: () => {
            CompetitionResultReport.updateSelectedEntityId(uniqueEntityId);
            updateHashParam(hashKey);
            scrollToContentForReportV2(ENTITY_REPORT_TOP);
          },
        };
      } else {
        throw new Error("unknown entity type");
      }
    });
  }, [result.entities, lang]);
  return {
    switcher: {
      items: items,
      onChange: () => {
        // Not necessary
      },
      defaultValue: selectedEntityId || "",
    },
    topButton: {
      onClick: () => {
        scrollToContentForReportV2(CompetitionResultReport.SUMMARY_ELEMENT_ID);
      },
    },
  };
};
