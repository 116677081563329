import { translateSalary } from "@hireroo/app-helper/competition";
import { Candidates } from "@hireroo/app-store/page/e/candidates";
import { useLanguageCode } from "@hireroo/i18n";
import type { Widget } from "@hireroo/presentation";
import React from "react";

export type GenerateCandidateSearchAreaPropsArgs = {};

export const useGenerateProps = (_args: GenerateCandidateSearchAreaPropsArgs): Widget.CandidateSearchAreaProps => {
  const lang = useLanguageCode();
  const currentSearchFilter = Candidates.useCurrentSearchFilter();
  const initialized = Candidates.useInitialized();

  const defaultValues = React.useMemo(() => {
    return {
      textFilter: currentSearchFilter.textFilter,
      expectedSalary: {
        max: currentSearchFilter.expectedSalary.max,
        min: currentSearchFilter.expectedSalary.min,
      },
      jobChangeStatuses: currentSearchFilter.jobChangeStatuses.slice(),
      averageScore: {
        max: currentSearchFilter.averageScore.max,
        min: currentSearchFilter.averageScore.min,
      },
      averageRank: currentSearchFilter.averageRank.slice(),
    };
  }, [currentSearchFilter]);

  return {
    disabled: !initialized,
    defaultValues: defaultValues,
    onChange: fields => {
      Candidates.updateCurrentSearchFilter(fields);
    },
    expectedSalaryField: {
      slider: {
        max: Candidates.EXPECTED_SALARY.MAX,
        min: Candidates.EXPECTED_SALARY.MIN,
        step: Candidates.EXPECTED_SALARY.STEP,
        valueLabelFormat: value => {
          return translateSalary({ salaryInTenK: value / 10000, lang: lang, withCurrency: true });
        },
      },
      customCurrentLabelFormat: value => {
        return `${translateSalary({ salaryInTenK: value.min / 10000, lang: lang, withCurrency: true })} ~ ${translateSalary({
          salaryInTenK: value.max / 10000,
          lang: lang,
          withCurrency: true,
        })}`;
      },
    },
  };
};
