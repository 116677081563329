import { PersonRounded } from "@mui/icons-material";
import AppRegistrationOutlinedIcon from "@mui/icons-material/AppRegistrationOutlined";
import AssignmentIndOutlinedIcon from "@mui/icons-material/AssignmentIndOutlined";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import Home from "@mui/icons-material/Home";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import MonitorOutlined from "@mui/icons-material/MonitorOutlined";
import VideocamRounded from "@mui/icons-material/VideocamRounded";
import { useTheme } from "@mui/material";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText, { ListItemTextProps } from "@mui/material/ListItemText";
import { styled } from "@mui/material/styles";
import * as React from "react";

import Link from "../../../../../../primitive/Link/Link";
import type * as Types from "../../../../types";

const StyledListItemIcon = styled(ListItemIcon)(() => ({
  width: "20px",
  minWidth: "20px",
  marginRight: "4px",
}));

const StyledListItemButton = styled(ListItemButton)(({ theme }) => ({
  padding: "8px",
  borderRadius: "4px",
  color: theme.palette.text.secondary,
  "&:hover": {
    color: theme.palette.text.primary,
    backgroundColor: theme.palette["Gray/Shades"].p16,
    svg: {
      color: theme.palette.text.primary,
    },
  },
  svg: {
    color: theme.palette.text.secondary,
    maxWidth: "16px",
  },
}));

const StyledListItemText = styled(ListItemText)(() => ({
  "& .MuiTypography-root": {
    fontSize: 14,
    fontWeight: "inherit",
    color: "inherit",
  },
}));

const ExpandWrapper = styled("div")`
  display: flex;
  position: absolute;
  top: 14px;
  left: -5px;
`;

export type NavigationMenuItemProps = Types.MenuItemWithoutChildren & {
  ExpandIcon?: React.ReactNode;
  root?: boolean;
};

const NavigationMenuItem: React.FC<NavigationMenuItemProps> = props => {
  const theme = useTheme();
  const StartIconMap: Record<Types.StartIcon, React.ReactNode> = {
    HOME: <Home htmlColor={theme.palette["Gray/Shades"].p56} fontSize="small" />,
    MONITOR: <MonitorOutlined htmlColor={theme.palette["Gray/Shades"].p56} fontSize="small" />,
    ASSIGNMENT: <AssignmentIndOutlinedIcon htmlColor={theme.palette["Gray/Shades"].p56} fontSize="small" />,
    VIDEOCAM: <VideocamRounded htmlColor={theme.palette["Gray/Shades"].p56} fontSize="small" />,
    REGISTRATION: <AppRegistrationOutlinedIcon htmlColor={theme.palette["Gray/Shades"].p56} fontSize="small" />,
    DOCUMENT: <InsertDriveFileOutlinedIcon htmlColor={theme.palette["Gray/Shades"].p56} fontSize="small" />,
    EVENT: <EmojiEventsIcon htmlColor={theme.palette["Gray/Shades"].p56} fontSize="small" />,
    PERSON: <PersonRounded htmlColor={theme.palette["Gray/Shades"].p56} fontSize="small" />,
  };

  const listItemTextProps: ListItemTextProps = {
    primary: props.children,
    sx: {
      fontWeight: 500,
      paddingLeft: props.startIcon ? undefined : "24px",
    },
  };
  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <StyledListItemButton onClick={props.onClick} component={Link} href={props.href}>
      <ExpandWrapper>{props.ExpandIcon}</ExpandWrapper>
      {props.startIcon && <StyledListItemIcon>{StartIconMap[props.startIcon]}</StyledListItemIcon>}
      <StyledListItemText {...listItemTextProps} />
    </StyledListItemButton>
  );
};

NavigationMenuItem.displayName = "NavigationMenuItem";

export default NavigationMenuItem;
