import { CandidateSideBarContent } from "@hireroo/app-store/widget/c/CandidateSideBarContent";
import { useTranslation } from "@hireroo/i18n";
import type { Widget } from "@hireroo/presentation";
import { generatePath } from "@hireroo/router/api";
import { useTransitionNavigate } from "@hireroo/router/hooks";
import * as React from "react";

export type GenerateCandidateSideBarContentPropsArgs = {};

type MenuItem = Exclude<Widget.LayoutSideBarContentProps["menuItemSections"], undefined>[0]["items"][0];
type MenuItemSection = Exclude<Widget.LayoutSideBarContentProps["menuItemSections"], undefined>[0];

export const useGenerateProps = (_args: GenerateCandidateSideBarContentPropsArgs): Widget.LayoutSideBarContentProps => {
  const { t } = useTranslation();
  const mode = CandidateSideBarContent.useMode();
  const navigate = useTransitionNavigate();

  const menuItemSections = React.useMemo((): Widget.LayoutSideBarContentProps["menuItemSections"] => {
    const baseMenuItemSections: MenuItemSection[] = [];

    const skillHiringItems: MenuItem[] = [
      {
        id: "COMPETITIONS",
        children: t("コンペ一覧"),
        startIcon: "EVENT",
        href: generatePath("/c/competitions"),
        onClick: () => {
          navigate("/c/competitions");
        },
      },
    ];

    const skillInterviewSection: MenuItemSection = {
      items: skillHiringItems,
    };

    baseMenuItemSections.push(skillInterviewSection);

    return baseMenuItemSections;
  }, [t, navigate]);

  return {
    mode: mode,
    menuItemSections: menuItemSections,
  };
};
