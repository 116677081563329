import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import ButtonBase from "@mui/material/ButtonBase";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import * as React from "react";

import Rank, { RankMarkProps } from "../../../../modules/RankMark/RankMark";
import Link, { LinkProps } from "../../../../primitive/Link/Link";

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  borderTop: "1px solid",
  borderBottom: "1px solid",
  borderColor: theme.palette.Other.Divider,
  display: "table-row",
}));

const CustomTableRow: React.FC<LinkProps> = props => {
  // FIXME I would like to ask the mui team how to define this type definition accurately.
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return <ButtonBase as={Link} {...props} disableRipple />;
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  border: "none",
  align: "center",
  whiteSpace: "nowrap",
  wordBreak: "keep-all",
  display: "table-cell",
  paddingTop: theme.spacing(1.5),
  paddingBottom: theme.spacing(1.5),
  paddingRight: theme.spacing(2),
  paddingLeft: theme.spacing(2),
}));

type User = {
  name: string;
  imgUrl?: string;
};

export type CandidateTableRowProps = {
  id: string;
  user: User;
  href: string;
  onClick: () => void;
  expectedSalary: string;
  jobChangeStatus: string;
  averageScore?: number;
  averageRank: RankMarkProps["rank"];
  disabled?: boolean;
};

const CandidateTableRow: React.FC<CandidateTableRowProps> = props => {
  return (
    <StyledTableRow
      as={!props.disabled ? CustomTableRow : undefined}
      // FIXME I would like to ask the mui team how to define this type definition accurately.
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      component={"a"}
      href={props.href}
      onClick={event => {
        event.stopPropagation();
        event.preventDefault();
        props.onClick?.();
      }}
    >
      <StyledTableCell component="div">
        <Stack direction="row" alignItems="center" spacing={2}>
          <Avatar alt={props.user.name} sx={{ width: "32px", height: "32px" }} src={props.user.imgUrl} />
          <Typography fontWeight="bold">{props.user.name}</Typography>
        </Stack>
      </StyledTableCell>
      <StyledTableCell component="div" align="center" sx={{ width: "100px" }}>
        <Typography>{props.expectedSalary}</Typography>
      </StyledTableCell>
      <StyledTableCell component="div" align="center" sx={{ width: "100px" }}>
        <Typography>{props.jobChangeStatus}</Typography>
      </StyledTableCell>
      <StyledTableCell component="div" align="center" sx={{ width: "100px" }}>
        <Typography>{props.averageScore ?? "-"}</Typography>
      </StyledTableCell>
      <StyledTableCell component="div" align="center" sx={{ width: "100px" }}>
        {props.averageRank === "UNKNOWN" && <Typography>-</Typography>}
        {props.averageRank !== "UNKNOWN" && (
          <Box sx={{ textAlign: "center" }}>
            <Rank rank={props.averageRank} />
          </Box>
        )}
      </StyledTableCell>
      <StyledTableCell component="div" align="right"></StyledTableCell>
    </StyledTableRow>
  );
};

CandidateTableRow.displayName = "CandidateTableRow";

export default CandidateTableRow;
