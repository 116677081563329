import { useRemoteTechnicalCommentRepository } from "@hireroo/app-helper/hooks";
import { RemotesId } from "@hireroo/app-store/page/e/remotes_id";
import type { Widget } from "@hireroo/presentation";
import { TechnicalCommentForm } from "@hireroo/validator";
import * as React from "react";

import TechnicalCommentEvaluationsFormContainer from "../TechnicalCommentEvaluationsForm/Container";

export type GenerateRemoteInterviewEvaluationPropsArgs = {
  remoteId: string;
};

export const useGenerateProps = (args: GenerateRemoteInterviewEvaluationPropsArgs): Widget.RemoteInterviewEvaluationProps => {
  const preparedMetrics = RemotesId.useRemotePreparedMetrics();

  const initialEvaluationSchema = React.useMemo((): TechnicalCommentForm.RemoteEvaluationsSchema | undefined => {
    if (!preparedMetrics) {
      return undefined;
    }

    return preparedMetrics.reduce<TechnicalCommentForm.RemoteEvaluationsSchema>((all, metric) => {
      all[metric.metricId.toString()] = {
        comment: "",
        metricId: metric.metricId,
        numStars: 0,
      };
      return all;
    }, {});
  }, [preparedMetrics]);

  const { addEvaluations, removeEvaluation, updateEvaluation, technicalComment } = useRemoteTechnicalCommentRepository(
    args.remoteId,
    initialEvaluationSchema,
  );

  const evaluations = React.useMemo(() => {
    return Object.keys(technicalComment.schemas.evaluations).map(key => technicalComment.schemas.evaluations[Number(key)]);
  }, [technicalComment.schemas.evaluations]);

  React.useEffect(() => {
    // Initialize for to keep out selected items.
    evaluations.forEach(evaluation => {
      RemotesId.selectMetric(evaluation.metricId);
    });
  }, [evaluations]);

  const handleSelectMetrics = React.useCallback(
    (metricIds: number[]) => {
      addEvaluations(metricIds);
      RemotesId.selectMetrics(metricIds);
    },
    [addEvaluations],
  );
  const handleRemoveMetric = React.useCallback(
    (metricId: number) => {
      removeEvaluation(metricId);
      RemotesId.removeMetric(metricId);
    },
    [removeEvaluation],
  );

  const handleChangeEvaluationRating = React.useCallback(
    (metricId: number, numStars: number) => {
      updateEvaluation(metricId, {
        numStars,
      });
    },
    [updateEvaluation],
  );

  const handleChangeEvaluationComment = React.useCallback(
    (metricId: number, comment: string) => updateEvaluation(metricId, { comment }),
    [updateEvaluation],
  );

  return {
    TechnicalCommentEvaluationsForm: (
      <TechnicalCommentEvaluationsFormContainer
        uniqueKey={args.remoteId}
        evaluations={evaluations}
        onChangeRating={handleChangeEvaluationRating}
        onChangeComment={handleChangeEvaluationComment}
        onSelectMetrics={handleSelectMetrics}
        onRemoveMetric={handleRemoveMetric}
      />
    ),
  };
};
