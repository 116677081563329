import { translateSalary } from "@hireroo/app-helper/competition";
import { Competitions } from "@hireroo/app-store/page/e/competitions";
import { useLanguageCode } from "@hireroo/i18n";
import type { Widget } from "@hireroo/presentation";
import * as React from "react";

export type GenerateCompetitionSearchAreaPropsArgs = {};

export const useGenerateProps = (_args: GenerateCompetitionSearchAreaPropsArgs): Widget.CompetitionSearchAreaProps => {
  const lang = useLanguageCode();
  const currentSearchFilter = Competitions.useCurrentSearchFilter();
  const initialized = Competitions.useInitialized();

  const defaultValues = React.useMemo(() => {
    return {
      textFilter: currentSearchFilter.textFilter,
      statuses: currentSearchFilter.statuses.slice(),
      minSalary: {
        max: currentSearchFilter.minSalary.max,
        min: currentSearchFilter.minSalary.min,
      },
      selfHostedOnly: currentSearchFilter.selfHostedOnly,
    };
  }, [currentSearchFilter]);

  return {
    disabled: !initialized,
    defaultValues: defaultValues,
    onChange: fields => {
      Competitions.updateCurrentSearchFilter(fields);
    },
    minSalaryField: {
      slider: {
        max: Competitions.MIN_SALARY.MAX,
        min: Competitions.MIN_SALARY.MIN,
        step: Competitions.MIN_SALARY.STEP,
        valueLabelFormat: value => {
          return translateSalary({ salaryInTenK: value, lang: lang, withCurrency: true });
        },
      },
      customCurrentLabelFormat: value => {
        return `${translateSalary({ salaryInTenK: value.min, lang: lang, withCurrency: true })} ~ ${translateSalary({
          salaryInTenK: value.max,
          lang: lang,
          withCurrency: true,
        })}`;
      },
    },
    showSelfHostedFilter: true,
  };
};
