import Box, { BoxProps } from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import * as React from "react";

import AppBarLayout, { AppBarLayoutProps } from "../../modules/AppBarLayout/AppBarLayout";
import LogoSideBarDrawer, { LogoSideBarDrawerProps } from "../../modules/LogoSideBarDrawer/LogoSideBarDrawer";

const HEADER_HEIGHT = 48;
const NOTIFICATION_BANNER_HEIGHT = 48;

const OPEN_SIDEBAR_WIDTH = 248;
const CLOSED_SIDEBAR_WIDTH = 48;

const RootWrapper = styled(Box)(() => ({
  height: "100%",
  width: "100%",
  display: "flex",
  flexDirection: "column",
}));

const MainWrapper = styled(Box)(() => ({
  flexGrow: 1,
  position: "relative",
}));

const NotificationBannerWrapper = styled(Box)(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 100,
  width: "100%",
  height: NOTIFICATION_BANNER_HEIGHT,
}));

const ContentWrapper = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  minHeight: `calc(100vh - ${HEADER_HEIGHT}px)`,
  height: "100%",
}));

const DrawerHeader = styled(Paper)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  flexShrink: 0,
  boxShadow: "none",
  borderBottom: `1px solid ${theme.palette.divider}`,
  borderRadius: "0",
  height: HEADER_HEIGHT,
  minHeight: HEADER_HEIGHT,
}));

export type CandidateLayoutProps = {
  className?: string;
  NotificationBanner: React.ReactNode;
  openSidebar: boolean;
  onChangeOpenSidebar: (open: boolean) => void;
  loading?: boolean;
  navigationBreadcrumbs?: AppBarLayoutProps["navigationBreadcrumbs"];
  HeaderRightContent: React.ReactNode;
  SideBarContent: React.ReactNode;
  Footer: React.ReactNode;
  Snackbar: React.ReactNode;
  sideBarHeader: Pick<LogoSideBarDrawerProps["sideBarHeader"], "logo">;
  /**
   * default fixed
   */
  headerPosition?: "fixed" | "static";
  contentWrapper?: {
    className?: string;
  };
};

/**
 * Header Height: 48px
 */
const CandidateLayout: React.FC<React.PropsWithChildren<CandidateLayoutProps>> = props => {
  const { openSidebar, headerPosition = "fixed" } = props;
  const sidebarWidth = openSidebar ? OPEN_SIDEBAR_WIDTH : CLOSED_SIDEBAR_WIDTH;

  const topMargin = props.NotificationBanner ? `${NOTIFICATION_BANNER_HEIGHT}px` : `0px`;

  const mainWrapperProps: BoxProps = {
    width: `calc(100% - ${sidebarWidth}px)`,
    sx: { top: headerPosition === "fixed" ? topMargin : 0 },
  };

  const appBar: AppBarLayoutProps = {
    navigationBreadcrumbs: props.navigationBreadcrumbs,
    HeaderRightContent: props.HeaderRightContent,
    openSidebar: openSidebar,
    headerPosition: headerPosition,
    topMargin: topMargin,
  };

  const sideBarDrawer: LogoSideBarDrawerProps = {
    openSidebar: openSidebar,
    topMargin: topMargin,
    onChangeOpenSidebar: props.onChangeOpenSidebar,
    sideBarHeader: {
      logo: props.sideBarHeader.logo,
      height: HEADER_HEIGHT,
      width: sidebarWidth,
    },
    SideBarContent: props.SideBarContent,
  };

  return (
    <RootWrapper className={props.className}>
      {props.loading && <LinearProgress sx={{ width: "100%", position: "fixed", zIndex: 2000, top: 0 }} color="secondary" />}
      {props.NotificationBanner && (
        <NotificationBannerWrapper width="100%" position="fixed">
          {props.NotificationBanner}
        </NotificationBannerWrapper>
      )}
      <AppBarLayout {...appBar} />

      <Box sx={{ top: topMargin, display: "flex", width: "100%", flexDirection: "row" }}>
        <LogoSideBarDrawer {...sideBarDrawer} />

        <MainWrapper component="main" {...mainWrapperProps}>
          {headerPosition === "fixed" && <DrawerHeader />}
          <ContentWrapper {...props.contentWrapper}>{props.children}</ContentWrapper>
        </MainWrapper>
      </Box>
      {props.Snackbar}
    </RootWrapper>
  );
};

CandidateLayout.displayName = "CandidateLayout";

export default CandidateLayout;
