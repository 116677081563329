import { useTranslation } from "@hireroo/i18n";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";

import EmployeeLayout, { EmployeeLayoutProps } from "../../layouts/EmployeeLayout/EmployeeLayout";

const HeadingTypography = styled(Typography)(() => ({
  fontWeight: "bold",
  fontSize: 24,
  whiteSpace: "nowrap",
  wordBreak: "keep-all",
}));

export type CandidateListProps = {
  layout: EmployeeLayoutProps;
  SearchArea: React.ReactNode;
  CandidateList: React.ReactNode;
};

const CandidateList: React.FC<CandidateListProps> = props => {
  const { t } = useTranslation();
  return (
    <EmployeeLayout {...props.layout}>
      <Box p={3}>
        <Stack mb={2}>
          <HeadingTypography>{t("候補者一覧")}</HeadingTypography>
        </Stack>

        <Stack spacing={2}>
          {props.SearchArea}
          {props.CandidateList}
        </Stack>
      </Box>
    </EmployeeLayout>
  );
};

CandidateList.displayName = "CandidateList";

export default CandidateList;
