import { ApplicantSearchForm as ApplicantSearchFormValidator } from "@hireroo/validator";
import { zodResolver } from "@hookform/resolvers/zod";
import * as React from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";

import SearchForm, { SearchFormProps } from "../../../../modules/SearchForm/SearchForm";
import * as PrivateHelper from "./privateHelper";

export type ApplicantSearchFormProps = {
  disabled: boolean;
  defaultValues: ApplicantSearchFormValidator.ApplicantSearchQuerySchema;
  onChange: SubmitHandler<ApplicantSearchFormValidator.ApplicantSearchQuerySchema>;
  onReset?: () => void;
  placeholder: string;
};

const ApplicantSearchForm: React.FC<ApplicantSearchFormProps> = props => {
  const { onChange } = props;
  const validateSchema = ApplicantSearchFormValidator.useApplicantSearchQuery();
  const methods = useForm<ApplicantSearchFormValidator.ApplicantSearchQuerySchema>({
    resolver: zodResolver(validateSchema),
    defaultValues: props.defaultValues,
  });
  const textFilterField = PrivateHelper.useTextFilterField({ placeholder: props.placeholder });
  const statusesField = PrivateHelper.useStatusesField();
  const scoreField = PrivateHelper.useScoreField();
  const suspiciousDegreeField = PrivateHelper.useSuspiciousDegreeField();

  React.useEffect(() => {
    const subscriber = methods.watch(() => {
      methods.handleSubmit(onChange, console.warn)();
    });
    return () => {
      subscriber.unsubscribe();
    };
  }, [methods, onChange]);

  const searchFormProps: SearchFormProps = {
    kind: "MULTI_LINE",
    disabled: props.disabled,
    textFilter: textFilterField,
    fields: React.useMemo(
      () => [statusesField, scoreField, suspiciousDegreeField].flatMap(v => (v ? [v] : [])),
      [scoreField, statusesField, suspiciousDegreeField],
    ),
    onReset: () => {
      const resetValues: ApplicantSearchFormValidator.ApplicantSearchQuerySchema = {
        textFilter: "",
        score: {
          min: 0,
          max: 100,
        },
        statuses: [],
        suspiciousDegrees: [],
      };
      methods.reset(resetValues);
      props.onReset?.();
    },
  };
  return (
    <FormProvider {...methods}>
      <SearchForm {...searchFormProps} />
    </FormProvider>
  );
};

ApplicantSearchForm.displayName = "ApplicantSearchForm";

export default ApplicantSearchForm;
