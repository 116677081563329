import { state } from "./State";
import type * as Types from "./types";

export const initialize = (competition: Types.Competition, applicant: Types.Applicant | null): void => {
  state.competition = competition;
  state.applicant = applicant;
};

export const clear = (): void => {
  state.competition = null;
  state.applicant = null;
  state.dialogStatus = "CLOSED";
  state.loadingStatus = "READY";
};

export const updateDialogStatus = (status: Types.DialogStatus) => {
  state.dialogStatus = status;
};

export const updateLoadingStatus = (status: Types.LoadingStatus) => {
  state.loadingStatus = status;
};
