import { useAverageRankEvaluationMap, useJobChangeInterestDetailMap } from "@hireroo/app-definition/auth";
import { translateSalary } from "@hireroo/app-helper/competition";
import { Home } from "@hireroo/app-store/page/c/home";
import { formatScore } from "@hireroo/formatter/score";
import { useLanguageCode, useTranslation, useTranslationWithVariable } from "@hireroo/i18n";
import type { Widget } from "@hireroo/presentation";
import { useTransitionNavigate } from "@hireroo/router/hooks";

import CompetitionHistoryInitialContainer from "../CompetitionHistory/InitialContainer";

export type GenerateCandidateDetailPropsArgs = {};

export const useGenerateProps = (_args: GenerateCandidateDetailPropsArgs): Widget.CandidateDetailProps => {
  const { t } = useTranslation();
  const { t: t2 } = useTranslationWithVariable();
  const lang = useLanguageCode();
  const candidate = Home.useCandidate();
  const navigate = useTransitionNavigate();
  const jobChangeInterestDetailMap = useJobChangeInterestDetailMap();
  const averageRankEvaluationMap = useAverageRankEvaluationMap();

  return {
    header: {
      imgUrl: candidate.photoUrl || undefined,
      name: candidate.displayName || t("名無し"),
      averageScore: candidate.averageScore !== null ? formatScore(candidate.averageScore) : undefined,
      averageRank: averageRankEvaluationMap[candidate.averageRankEvaluation],
      editButton: {
        onClick: () => {
          navigate("/c/settings/user");
        },
      },
    },
    sideSection: {
      profileText: candidate.profileText,
      emptyProfileText: t("自己紹介が未入力です。\nあなたの今後チャレンジしたいことや学習中の技術を企業にアピールしましょう。"),
      jobChangeStatus: jobChangeInterestDetailMap[candidate.jobChangeInterest],
      expectedSalary:
        candidate.expectedSalaryYen !== null && candidate.expectedSalaryYen > 0
          ? t2("MoreThanDesired", { salary: translateSalary({ salaryInTenK: candidate.expectedSalaryYen / 10000, lang, withCurrency: true }) })
          : "-",
    },
    CompetitionHistory: <CompetitionHistoryInitialContainer candidateId={candidate.candidateId} />,
  };
};
