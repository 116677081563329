import type * as Types from "./types";

export const DEFAULT_PAGER: Types.Pager = {
  page: 0,
  size: 20,
  offset: 0,
  sortParams: {
    sortMethod: "AVERAGE_SCORE",
    isDescending: true,
  },
};

export const DEFAULT_SORT_PARAMS: Types.SortParams = {
  sortMethod: "AVERAGE_SCORE",
  isDescending: true,
};

export const EXPECTED_SALARY = {
  MAX: 10000000,
  MIN: 4000000,
  STEP: 1000000,
} as const;

export const AVERAGE_SCORE = {
  MAX: 100,
  MIN: 0,
};

export const DEFAULT_SEARCH_FILTER: Types.SearchFilter = {
  textFilter: "",
  expectedSalary: {
    max: EXPECTED_SALARY.MAX,
    min: EXPECTED_SALARY.MIN,
  },
  jobChangeStatuses: [],
  averageScore: {
    max: AVERAGE_SCORE.MAX,
    min: AVERAGE_SCORE.MIN,
  },
  averageRank: [],
};

export const QueryKeys = {
  PAGE: "page",
  SIZE: "size",
  DISPLAY_NAME: "displayName",
  EXPECTED_SALARY_MIN: "minExpectedSalary",
  EXPECTED_SALARY_MAX: "maxExpectedSalary",
  JOB_CHANGE_STATUS: "jobChangeStatus",
  AVERAGE_SCORE_MIN: "minAverageScore",
  AVERAGE_SCORE_MAX: "maxAverageScore",
  AVERAGE_RANK: "averageRank",
  SORT: "sorting",
} as const;
export type QueryType = (typeof QueryKeys)[keyof typeof QueryKeys];
