import { useTranslation } from "@hireroo/i18n";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import * as React from "react";

import SelectField, { SelectFieldProps } from "../../../../primitive/InputControl/SelectField/SelectField";

export type ExpectedSalaryFieldProps = Pick<SelectFieldProps, "items" | "onChange">;

const ExpectedSalaryField: React.FC<ExpectedSalaryFieldProps> = props => {
  const { t } = useTranslation();
  return (
    <Stack direction="column" spacing={1.5}>
      <Typography fontSize={14} fontWeight={700}>
        {t("最低希望年収")}
      </Typography>
      <Box width={"50%"}>
        <SelectField name="expectedSalary" color="secondary" {...props} />
      </Box>
    </Stack>
  );
};

ExpectedSalaryField.displayName = "ExpectedSalaryField";

export default ExpectedSalaryField;
