import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { useGenerateProps } from "./useGenerateProps";

export type CandidateSideBarContentContainerProps = {};

const CandidateSideBarContentContainer: React.FC<CandidateSideBarContentContainerProps> = () => {
  const layoutSideBarContentProps = useGenerateProps({});
  return (
    <ErrorBoundary>
      <Widget.LayoutSideBarContent {...layoutSideBarContentProps} />
    </ErrorBoundary>
  );
};

CandidateSideBarContentContainer.displayName = "CandidateSideBarContentContainer";

export default withErrorBoundary(CandidateSideBarContentContainer, {});
