import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { useGenerateProps } from "./useGenerateProps";

export type CandidateProfileContainerProps = {};

const CandidateProfileContainer: React.FC<CandidateProfileContainerProps> = () => {
  const candidateProfileProps = useGenerateProps({});
  return (
    <ErrorBoundary>
      <Widget.CandidateProfile {...candidateProfileProps} />
    </ErrorBoundary>
  );
};

CandidateProfileContainer.displayName = "CandidateProfileContainer";

export default withErrorBoundary(CandidateProfileContainer, {});
