import * as React from "react";

import ArchiveQuestionDialog, {
  ArchiveQuestionDialogProps,
} from "../../../../ms-components/Question/ArchiveQuestionDialog/ArchiveQuestionDialog";
import DeleteQuestionDialog, { DeleteQuestionDialogProps } from "../../../../ms-components/Question/DeleteQuestionDialog/DeleteQuestionDialog";
import BaseQuestionTableRow, {
  QuestionTableRowProps as BaseQuestionTableRowProps,
} from "../../../../ms-components/Question/QuestionTableRow/QuestionTableRow";
import MoreButton, { MoreButtonProps } from "../../../../primitive/Button/MoreButton/MoreButton";

export type QuestionTableRowProps = Omit<BaseQuestionTableRowProps, "ActionComponent"> & {
  id: string;
  options: {
    delete?: Omit<MoreButtonProps["options"][0], "onClick">;
    archive?: Omit<MoreButtonProps["options"][0], "onClick">;
  };
  deleteQuestionDialog: Pick<DeleteQuestionDialogProps, "onDelete" | "items">;
  archiveQuestionDialog: Pick<ArchiveQuestionDialogProps, "onArchive" | "items">;
};

const QuestionTableRow: React.FC<QuestionTableRowProps> = props => {
  const { options, ...rest } = props;
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
  const [openArchiveDialog, setOpenArchiveDialog] = React.useState(false);
  const deleteQuestionDialogProps: DeleteQuestionDialogProps = {
    ...props.deleteQuestionDialog,
    open: openDeleteDialog,
    onClose: () => {
      setOpenDeleteDialog(false);
    },
  };
  const archiveQuestionDialogProps: ArchiveQuestionDialogProps = {
    ...props.archiveQuestionDialog,
    open: openArchiveDialog,
    onClose: () => {
      setOpenArchiveDialog(false);
    },
  };

  const optionsProps = React.useMemo((): MoreButtonProps["options"] => {
    if (options.delete) {
      return [
        {
          ...options.delete,
          color: "error",
          onClick: () => {
            setOpenDeleteDialog(true);
          },
        },
      ];
    }
    if (options.archive) {
      return [
        {
          ...options.archive,
          color: "error",
          onClick: () => {
            setOpenArchiveDialog(true);
          },
        },
      ];
    }
    return [];
  }, [options]);

  const moreButtonProps: MoreButtonProps = {
    options: optionsProps,
    variant: "vertical",
  };

  const baseQuestionTableRowProps: BaseQuestionTableRowProps = {
    ...rest,
    ActionComponent: <MoreButton {...moreButtonProps} />,
  };

  return (
    <>
      <BaseQuestionTableRow {...baseQuestionTableRowProps} />
      {options.delete && <DeleteQuestionDialog {...deleteQuestionDialogProps} />}
      {options.archive && <ArchiveQuestionDialog {...archiveQuestionDialogProps} />}
    </>
  );
};

QuestionTableRow.displayName = "QuestionTableRow";

export default QuestionTableRow;
