import { CompetitionSearchForm as CompetitionSearchFormValidator } from "@hireroo/validator";
import { zodResolver } from "@hookform/resolvers/zod";
import * as React from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";

import SearchForm, { SearchFormProps } from "../../modules/SearchForm/SearchForm";
import * as PrivateHelper from "./privateHelper";

export type CompetitionSearchAreaProps = {
  disabled: boolean;
  defaultValues: CompetitionSearchFormValidator.CompetitionSearchSchema;
  onChange: SubmitHandler<CompetitionSearchFormValidator.CompetitionSearchSchema>;
  minSalaryField: PrivateHelper.MinSalaryFieldArgs;
  showSelfHostedFilter: boolean;
};

const CompetitionSearchArea: React.FC<CompetitionSearchAreaProps> = props => {
  const { onChange, showSelfHostedFilter } = props;
  const validateSchema = CompetitionSearchFormValidator.useCompetitionSearch();
  const methods = useForm<CompetitionSearchFormValidator.CompetitionSearchSchema>({
    resolver: zodResolver(validateSchema),
    defaultValues: props.defaultValues,
  });
  const textFilterField = PrivateHelper.useTextFilterField();
  const statusesField = PrivateHelper.useStatusesField();
  const minSalaryField = PrivateHelper.useMinSalaryField(props.minSalaryField);
  const selfHostedOnlyField = PrivateHelper.useSelfHostedOnlyField();

  React.useEffect(() => {
    const subscriber = methods.watch(() => {
      methods.handleSubmit(onChange, console.warn)();
    });
    return () => {
      subscriber.unsubscribe();
    };
  }, [methods, onChange]);

  const searchFormProps: SearchFormProps = {
    kind: "MULTI_LINE",
    disabled: props.disabled,
    textFilter: textFilterField,
    fields: React.useMemo(() => {
      return [statusesField, minSalaryField, showSelfHostedFilter && selfHostedOnlyField].flatMap(v => (v ? [v] : []));
    }, [statusesField, minSalaryField, selfHostedOnlyField, showSelfHostedFilter]),
    onReset: () => {
      const resetValues: CompetitionSearchFormValidator.CompetitionSearchSchema = {
        textFilter: "",
        statuses: [],
        minSalary: {
          max: props.minSalaryField.slider.max,
          min: props.minSalaryField.slider.min,
        },
        selfHostedOnly: false,
      };
      methods.reset(resetValues);
    },
  };

  return (
    <FormProvider {...methods}>
      <SearchForm {...searchFormProps} />
    </FormProvider>
  );
};

CompetitionSearchArea.displayName = "CompetitionSearchForm";

export default CompetitionSearchArea;
