import Box from "@mui/material/Box";
import Paper, { PaperProps } from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import * as React from "react";

import SideBarDrawer from "./parts/SideBarDrawer/SideBarDrawer";
import SideBarHeader, { SideBarHeaderProps } from "./parts/SideBarHeader/SideBarHeader";

const DrawerHeader = styled(Paper)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  flexShrink: 0,
  boxShadow: "none",
  borderBottom: `1px solid ${theme.palette.divider}`,
  borderRadius: "0",
}));

export type LogoSideBarDrawerProps = {
  openSidebar: boolean;
  topMargin: string;
  onChangeOpenSidebar: (open: boolean) => void;
  sideBarHeader: Pick<SideBarHeaderProps, "logo" | "height" | "width">;
  SideBarContent: React.ReactNode;
};

const LogoSideBarDrawer: React.FC<LogoSideBarDrawerProps> = props => {
  const { openSidebar, topMargin } = props;

  const sideBarHeaderProps: SideBarHeaderProps = {
    ...props.sideBarHeader,
    open: openSidebar,
    closeButton: {
      onClick: () => {
        props.onChangeOpenSidebar(false);
      },
    },
    onOpen: () => {
      props.onChangeOpenSidebar(true);
    },
  };
  const height = props.sideBarHeader.height;

  const drawerHeaderProps: PaperProps = {
    sx: openSidebar
      ? { display: "flex", justifyContent: "center", height: height, minHeight: height }
      : { display: "flex", justifyContent: "center", py: 2, px: 0.5, height: "unset", minHeight: height },
  };
  return (
    <SideBarDrawer variant="permanent" open={openSidebar}>
      <Box sx={{ paddingTop: topMargin }}>
        <DrawerHeader {...drawerHeaderProps}>
          <SideBarHeader {...sideBarHeaderProps} />
        </DrawerHeader>
        <Box>{props.SideBarContent}</Box>
      </Box>
    </SideBarDrawer>
  );
};

LogoSideBarDrawer.displayName = "LogoSideBarDrawer";

export default LogoSideBarDrawer;
