import { Pages } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { useGenerateProps } from "./useGenerateProps";

export type CompetitionIdDetailForCandidateContainerProps = {};

const CompetitionIdDetailForCandidateContainer: React.FC<CompetitionIdDetailForCandidateContainerProps> = () => {
  const competitionIdDetailForCandidateProps = useGenerateProps({});
  return (
    <ErrorBoundary>
      <Pages.CompetitionIdDetailForCandidate {...competitionIdDetailForCandidateProps} />
    </ErrorBoundary>
  );
};

CompetitionIdDetailForCandidateContainer.displayName = "CompetitionIdDetailForCandidateContainer";

export default withErrorBoundary(CompetitionIdDetailForCandidateContainer, {});
