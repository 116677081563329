import { useTranslation, useTranslationWithVariable } from "@hireroo/i18n";
import { Typography } from "@mui/material";
import Button, { type ButtonProps } from "@mui/material/Button";
import Dialog, { DialogProps } from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Stack from "@mui/material/Stack";
import * as React from "react";

export type RegisterDialogProps = {
  dialog: Pick<DialogProps, "open" | "onClose">;
  yesButton: Pick<ButtonProps, "onClick" | "disabled">;
  startAt: string;
};

const RegisterDialog: React.FC<RegisterDialogProps> = props => {
  const { t } = useTranslation();
  const { t: t2 } = useTranslationWithVariable();
  const noButton: ButtonProps = {
    color: "outline-only",
    variant: "text",
    children: t("キャンセル"),
    onClick: event => {
      props.dialog.onClose?.(event, "backdropClick");
    },
  };

  const yesButton: ButtonProps = {
    ...props.yesButton,
    children: t("事前登録する"),
    variant: "contained",
    color: "primary",
  };
  return (
    <Dialog {...props.dialog}>
      <DialogTitle>{t("コンペ事前登録")}</DialogTitle>
      <DialogContent>
        <Typography fontSize={16} fontWeight={700} mb={1.5}>
          {t2("CompetitionWillStart", { date: props.startAt })}
        </Typography>
        <Typography>{t("コンペに事前登録すると、コンペが開始したタイミングで登録されているメールアドレスにメールが送信されます。")}</Typography>
      </DialogContent>
      <DialogActions>
        <Stack direction="row" spacing={1}>
          <Button {...noButton} />
          <Button {...yesButton} />
        </Stack>
      </DialogActions>
    </Dialog>
  );
};

RegisterDialog.displayName = "RegisterDialog";

export default RegisterDialog;
