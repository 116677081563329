import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";

import SortField, { SortFieldProps } from "../../../../modules/SortField/SortField";

const Wrapper = styled(Stack)(() => ({
  alignItems: "center",
  justifyContent: "space-between",
}));

const StyledTypography = styled(Typography)(() => ({
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  wordBreak: "keep-all",
  overflow: "hidden",
}));

export type SearchResultBarProps = {
  resultText: string;
  sortField: SortFieldProps;
};

const SearchResultBar: React.FC<SearchResultBarProps> = props => {
  return (
    <Wrapper direction="row" px={2} py={0.5}>
      <Stack direction="row" alignItems="center" justifyContent="center" spacing={2}>
        <StyledTypography variant="body2">{props.resultText}</StyledTypography>
      </Stack>
      <SortField {...props.sortField} />
    </Wrapper>
  );
};

SearchResultBar.displayName = "SearchResultBar";

export default SearchResultBar;
