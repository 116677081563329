import { useTranslation } from "@hireroo/i18n";
import { ApplicantSearchForm as ApplicantSearchFormValidator } from "@hireroo/validator";

import type { SearchFormProps } from "../../../../modules/SearchForm/SearchForm";

type FieldProps = SearchFormProps["fields"][0];
export type MultiChoiceFieldProps = Extract<
  FieldProps,
  {
    kind: "MULTI_CHOICE";
  }
>;
export type RangeFieldProps = Extract<
  FieldProps,
  {
    kind: "RANGE";
  }
>;

type FieldKeyName = keyof ApplicantSearchFormValidator.ApplicantSearchQuerySchema;
const FieldName = {
  TEXT_FILTER: "textFilter",
  SCORE: "score",
  STATUSES: "statuses",
  SUSPICIOUS_DEGREES: "suspiciousDegrees",
} satisfies Record<string, FieldKeyName>;

type TextFilterFieldArgs = {
  placeholder: string;
};

export const useTextFilterField = (args: TextFilterFieldArgs): SearchFormProps["textFilter"] => {
  return {
    name: FieldName.TEXT_FILTER,
    placeholder: args.placeholder,
  };
};

export const useScoreField = (): RangeFieldProps => {
  const { t } = useTranslation();
  return {
    kind: "RANGE",
    name: FieldName.SCORE,
    title: t("スコア"),
    label: t("スコア"),
  };
};

type StatusesOption = {
  displayName: string;
  value: ApplicantSearchFormValidator.ApplicantSearchQuerySchema["statuses"][0];
};

export const useStatusesField = (): MultiChoiceFieldProps => {
  const { t } = useTranslation();
  const options: StatusesOption[] = [
    { value: "CREATED", displayName: t("未実施") },
    { value: "STARTED", displayName: t("実施中") },
    { value: "COMPLETED", displayName: t("実施済み") },
    { value: "CONFIRMED", displayName: t("採点済み") },
  ];
  return {
    kind: "MULTI_CHOICE",
    title: t("ステータス"),
    name: FieldName.STATUSES,
    options: options,
  };
};

type SuspiciousDegreeOption = {
  displayName: string;
  value: ApplicantSearchFormValidator.ApplicantSearchQuerySchema["suspiciousDegrees"][0];
};

export const useSuspiciousDegreeField = (): MultiChoiceFieldProps => {
  const { t } = useTranslation();
  const options: SuspiciousDegreeOption[] = [
    { value: "CLEAN", displayName: t("正常") },
    { value: "SUSPICIOUS", displayName: t("不審") },
  ];
  return {
    kind: "MULTI_CHOICE",
    name: FieldName.SUSPICIOUS_DEGREES,
    title: t("不審度"),
    options: options,
  };
};
