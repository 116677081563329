import { useSnapshot } from "valtio";

import { state } from "./State";

const useSnapshotState = () => {
  return useSnapshot(state);
};

export const useInitialized = (): boolean => {
  const snapshot = useSnapshotState();
  return !!snapshot.result;
};

export const useResult = () => {
  const snapshot = useSnapshotState();
  return snapshot.result;
};

export const useEndStatus = () => {
  const snapshot = useSnapshotState();
  return snapshot.endStatus;
};

export const useEndLoadingStatus = () => {
  const snapshot = useSnapshotState();
  return snapshot.endLoadingStatus;
};

export const useEntities = () => {
  const result = useResult();
  return result?.entities || [];
};

export const useResultStatus = () => {
  const snapshot = useSnapshotState();
  return snapshot.result?.status;
};

export const useCandidateName = () => {
  const result = useResult();
  return result?.candidate?.displayName ?? "";
};
