import { ChallengeTestReport } from "@hireroo/app-store/view-domain/ChallengeTestReport";
import { TotalScoreRankVisualizer } from "@hireroo/app-store/widget/shared/TotalScoreRankVisualizer";
import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { useGenerateHeaderProps } from "../../../../props-factory/v2/view-domain/ChallengeTestReport/useGenerateHeaderProps";
import { useGenerateQuestionDetailProps } from "../../../../props-factory/v2/view-domain/ChallengeTestReport/useGenerateQuestionDetailProps";
import ChallengeTestReportContainer, { ChallengeTestReportContainerProps } from "./Container";
import { useStatusMessageMap } from "./privateHelper";
import { startSubscribeQueryKey } from "./Subscriber";

export type ChallengeTestReportMiddleContainerProps = {
  companyId: number;
  challengeId: number;
  canShowPlayback: boolean;
  canShowStatistics: boolean;
  canShowFollowUpQuestions: boolean;
  canShowPasteAndTabStatistics: boolean;
  canShowCheatDetectionSection: boolean;
  canShowFeedbackSuspiciousDegree: boolean;
  showUsedHintSection: boolean;
};

const ChallengeTestReportMiddleContainer: React.FC<ChallengeTestReportMiddleContainerProps> = props => {
  const { challengeId, companyId } = props;
  const challengeHooks = ChallengeTestReport.useCreateChallengeHooks(challengeId);
  const challenge = challengeHooks.useChallenge();
  const currentSubmission = challengeHooks.useCurrentSubmission();
  const messageMap = useStatusMessageMap();
  const showNotStartedMessage = challenge.challengeStatus === "CREATED" || challenge.challengeStatus === "STARTED";
  const header = useGenerateHeaderProps({ challengeId: challengeId, showDetail: true });
  const questionDetail = useGenerateQuestionDetailProps({ challengeId: challengeId, showAnswer: true, showArchivedMark: true });

  const uniqueKey = React.useMemo((): ChallengeTestReport.UniqueKey | null => {
    if (!currentSubmission) {
      return null;
    }
    /**
     * Multiple submissions and unique by EntityId
     */
    return `Challenge-${challengeId}-${currentSubmission.challengeSubmissionId}`;
  }, [currentSubmission, challengeId]);

  React.useEffect(() => {
    if (!currentSubmission || !uniqueKey || showNotStartedMessage) {
      return;
    }
    TotalScoreRankVisualizer.createTotalScoreStatisticsState({
      uniqueKey: uniqueKey,
      query: {
        endDate: currentSubmission?.evaluatedAtSeconds ? new Date(currentSubmission.evaluatedAtSeconds * 1000) : null,
      },
    });
    const stop = startSubscribeQueryKey({
      uniqueKey,
      challengeId: challengeId,
      companyId: companyId,
      submissionId: currentSubmission.challengeSubmissionId,
      questionId: currentSubmission.questionId,
      questionVersion: currentSubmission.questionVersion,
    });
    TotalScoreRankVisualizer.refreshQuery(uniqueKey);
    return () => {
      stop?.();
    };
  }, [showNotStartedMessage, challengeId, companyId, currentSubmission, uniqueKey]);

  if (!currentSubmission || !uniqueKey || showNotStartedMessage) {
    const challengeTestReportProps: Widget.ChallengeTestReportProps = {
      header: header,
      questionDetail: questionDetail,
      announcement: messageMap[challenge.challengeStatus],
    };
    return <Widget.ChallengeTestReport {...challengeTestReportProps} />;
  }

  const containerProps: ChallengeTestReportContainerProps = {
    ...props,
    uniqueKey,
  };

  return (
    <ErrorBoundary>
      <ChallengeTestReportContainer key={uniqueKey} {...containerProps} />
    </ErrorBoundary>
  );
};

ChallengeTestReportMiddleContainer.displayName = "ChallengeTestReportMiddleContainer";

export default withErrorBoundary(ChallengeTestReportMiddleContainer, {});
