import { useTranslation } from "@hireroo/i18n";
import InfoIcon from "@mui/icons-material/Info";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Button, { ButtonProps } from "@mui/material/Button";
import { styled, useTheme } from "@mui/material/styles";
import * as React from "react";

import RestrictableTab, { RestrictableTabProps } from "../../modules/RestrictableTab/RestrictableTab";
import CompetitionDetailHeader, {
  CompetitionDetailHeaderProps,
} from "../../ms-components/Competition/CompetitionDetailHeader/CompetitionDetailHeader";
import Link from "../../primitive/Link/Link";

const StyledAlert = styled(Alert)(({ theme }) => ({
  backgroundColor: theme.palette["Action"]["Hover"].p8,
  color: theme.palette.primary.main,
  fontsize: 16,
  fontWeight: 700,
}));

export type CompetitionIdDetailForCandidateProps = {
  profileHref?: string;
  header: CompetitionDetailHeaderProps;
  items: RestrictableTabProps["items"];
  defaultTabId?: string;
};

const CompetitionIdDetailForCandidate: React.FC<CompetitionIdDetailForCandidateProps> = props => {
  const { t } = useTranslation();
  const theme = useTheme();
  const cautionAction: ButtonProps = {
    children: t("プロフィール編集"),
    variant: "outlined",
    color: "outline-only",
    size: "small",
    endIcon: <OpenInNewIcon fontSize="small" />,
    sx: {
      borderColor: theme.palette["Other"]["Divider"],
    },
  };
  const restrictableTab: RestrictableTabProps = {
    defaultTab: props.defaultTabId,
    items: props.items,
    tabs: {
      "aria-label": "competition-detail-tabs",
    },
    tabBox: {
      sx: {
        paddingLeft: "24px",
        paddingRight: "24px",
        background: theme.palette["Background/Paper"].p1,
      },
    },
    tabPanelBox: {
      pt: 0,
      sx: {
        height: "100%",
      },
    },
    variant: "OUTLINED",
  };

  return (
    <Box>
      {props.profileHref && (
        <StyledAlert
          severity="info"
          variant="filled"
          icon={<InfoIcon fontSize="small" htmlColor={theme.palette.primary.main} />}
          action={
            <Link href={props.profileHref} target="_blank">
              <Button {...cautionAction} />
            </Link>
          }
        >
          {t("コンペ参加にはプロフィール入力が必要です。")}
        </StyledAlert>
      )}
      <CompetitionDetailHeader {...props.header} />
      <RestrictableTab {...restrictableTab} />
    </Box>
  );
};

CompetitionIdDetailForCandidate.displayName = "CompetitionIdDetailForCandidate";

export default CompetitionIdDetailForCandidate;
