import { CompetitionHistory } from "@hireroo/app-store/widget/c/CompetitionHistory";
import { formatScore } from "@hireroo/formatter/score";
import { useTranslation, useTranslationWithVariable } from "@hireroo/i18n";
import type { Widget } from "@hireroo/presentation";
import { generatePath } from "@hireroo/router/api";
import { useTransitionNavigate } from "@hireroo/router/hooks";
import * as React from "react";

import * as PrivateHelper from "./privateHelper";

type Item = Widget.CompetitionHistoryListProps["table"]["items"][number];

export type GenerateCompetitionHistoryListPropsArgs = {};

export const useGenerateProps = (_args: GenerateCompetitionHistoryListPropsArgs): Widget.CompetitionHistoryListProps => {
  const { t } = useTranslation();
  const { t: t2 } = useTranslationWithVariable();
  const navigate = useTransitionNavigate();

  const pager = CompetitionHistory.usePager();
  const applicants = CompetitionHistory.useApplicants();
  const res = CompetitionHistory.useResponse();

  const items = React.useMemo((): Item[] => {
    return applicants.map((applicant): Item => {
      const paramId = applicant.competition?.competitionId ?? "";
      const ranking = (() => {
        // コンペ全体の採点が終わるまで非表示
        if (applicant.competition?.status !== "CONFIRMED") {
          return undefined;
        }
        if (applicant.result?.rank === undefined || applicant.result.rank <= 0) {
          return undefined;
        }
        return {
          rank: applicant.result.rank,
          subset: applicant.result.numSubset,
        };
      })();

      return {
        id: applicant.competition?.competitionId ?? "",
        title: applicant.competition?.title ?? "",
        hostCompany: {
          name: applicant.competition?.hostCompany?.name ?? "",
          imgUrl: applicant.competition?.hostCompany?.logo ?? "",
        },
        href: generatePath("/c/competitions/:id/detail", {
          pathParams: {
            id: paramId,
          },
        }),
        onClick: () => {
          navigate("/c/competitions/:id/detail", {
            pathParams: {
              id: paramId,
            },
          });
        },
        score: applicant.result?.totalScore ? formatScore(applicant.result.totalScore) : undefined,
        ranking: ranking,
        disabled: paramId === "",
      };
    });
  }, [applicants, navigate]);

  const sortOptions = React.useMemo<Widget.CompetitionHistoryListProps["searchResultBar"]["sortField"]["options"]>(
    () => [
      {
        displayName: t("スコアが高い順"),
        value: CompetitionHistory.SORT_FIELDS.SCORE_DESCENDING,
      },
      {
        displayName: t("スコアが低い順"),
        value: CompetitionHistory.SORT_FIELDS.SCORE_ASCENDING,
      },
      {
        displayName: t("コンペ登録日時の新しい順"),
        value: CompetitionHistory.SORT_FIELDS.APPLIED_AT_DESCENDING,
      },
      {
        displayName: t("コンペ登録日時の古い順"),
        value: CompetitionHistory.SORT_FIELDS.APPLIED_AT_ASCENDING,
      },
    ],
    [t],
  );

  return {
    searchResultBar: {
      resultText: t2("SearchResultCount", { count: res.count }),
      sortField: {
        defaultValue: PrivateHelper.convertSortParamsToSortFieldValue(pager.sortParams),
        options: sortOptions,
        onChange: value => {
          if (PrivateHelper.isValidSortFieldValue(value)) {
            const target = CompetitionHistory.SORT_PARAMS[value];
            CompetitionHistory.updateSortParams({
              sortMethod: target.sortMethod,
              isDescending: target.isDescending,
            });
          }
        },
      },
    },
    table: {
      items: items,
    },
    tablePagination: {
      count: res.count,
      rowsPerPage: pager.size,
      onRowsPerPageChange: event => {
        const newSize = Number(event.target.value);
        CompetitionHistory.updatePageSize(newSize);
      },
      page: pager.page,
      onPageChange: (_, newPage) => {
        CompetitionHistory.updatePage(newPage);
      },
    },
  };
};
