import firebase from "firebase/compat/app";

export type FirebaseUser = firebase.User;

export function getCurrentUser(): Promise<firebase.User | null> {
  return new Promise<firebase.User | null>(resolve => {
    firebase.auth().onAuthStateChanged(user => {
      resolve(user);
    });
  });
}

export async function updateProfile(displayName?: string): Promise<void> {
  const currentUser = firebase.auth().currentUser;
  return currentUser?.updateProfile({
    displayName: displayName,
  });
}
