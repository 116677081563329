import { useTranslation } from "@hireroo/i18n";
import Add from "@mui/icons-material/Add";
import Divider from "@mui/material/Divider";
import Stack, { StackProps } from "@mui/material/Stack";
import { styled, useTheme } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";
import * as React from "react";

import ButtonWithTooltip, { ButtonWithTooltipProps } from "../../primitive/Button/ButtonWithTooltip/ButtonWithTooltip";
import NavigationMenu from "./parts/NavigationMenu/NavigationMenu";
import NavigationMenuV2 from "./parts/NavigationMenuV2/NavigationMenuV2";
import PopupMenu from "./parts/PopupMenu/PopupMenu";
import Section from "./parts/Section/Section";
import type { MenuItem } from "./types";

type Mode = "MINIMIZED" | "EXPANDED";

const StyledButtonWithTooltip = styled(ButtonWithTooltip)(() => ({
  minWidth: "unset",
}));

type MenuItemSection = {
  title?: string;
  items: MenuItem[];
};

export type LayoutSideBarContentProps = {
  mode: Mode;
  /**
   * v1 design @deprecated
   */
  menuItems?: MenuItem[];
  CompanySelect?: React.ReactNode;
  createButton?: Pick<ButtonWithTooltipProps, "onClick">;
  /**
   * v2 design
   */
  menuItemSections?: MenuItemSection[];
};

const LayoutSideBarContent: React.FC<LayoutSideBarContentProps> = props => {
  const { t } = useTranslation();
  const theme = useTheme();
  const createButtonPropsMap: Record<Mode, ButtonWithTooltipProps> = {
    MINIMIZED: {
      ...props.createButton,
      fullWidth: true,
      size: "medium",
      variant: "outlined",
      children: <Add />,
      sx: {
        width: 36,
        height: 36,
      },
    },
    EXPANDED: {
      ...props.createButton,
      fullWidth: true,
      startIcon: "ADD",
      size: "medium",
      variant: props.menuItemSections ? "outlined" : "contained",
      children: t("作成"),
    },
  };

  const wrapperStackProps: StackProps = {
    direction: "column",
    spacing: 2,
    divider: (
      <Divider
        sx={{
          borderColor: props.menuItemSections ? theme.palette.divider : theme.palette["Other"].WeakDivider,
          width: "100%",
        }}
      />
    ),
    sx:
      props.mode === "EXPANDED"
        ? {
            py: 3,
            px: 2,
          }
        : {
            py: 2,
            px: 0.5,
            justifyContent: "center",
            alignItems: "center",
          },
  };
  return (
    <Stack {...wrapperStackProps}>
      {props.CompanySelect && <Stack sx={{ alignItems: "center" }}>{props.CompanySelect}</Stack>}
      {props.createButton && (
        <Tooltip title={props.mode === "MINIMIZED" ? t("作成") : ""} placement="left-start">
          <span>
            <StyledButtonWithTooltip {...createButtonPropsMap[props.mode]} />
          </span>
        </Tooltip>
      )}
      {props.menuItemSections && (
        <>
          {props.mode === "EXPANDED" && (
            <Stack
              spacing={0.5}
              divider={
                <Divider
                  sx={{
                    width: "100%",
                  }}
                />
              }
            >
              {props.menuItemSections.map((section, index) => (
                <Section key={`${section.title}-${index}`} title={section.title}>
                  <NavigationMenuV2 root items={section.items} />
                </Section>
              ))}
            </Stack>
          )}
          {props.mode === "MINIMIZED" && (
            <Stack
              spacing={0.5}
              divider={
                <Divider
                  sx={{
                    width: "100%",
                  }}
                />
              }
            >
              {props.menuItemSections.map((section, index) => (
                <PopupMenu key={`menu-${index}`} root items={section.items} variant="GRAY" />
              ))}
            </Stack>
          )}
        </>
      )}
      {!props.menuItemSections && (
        <>
          {props.mode === "EXPANDED" && <NavigationMenu root items={props.menuItems ?? []} />}
          {props.mode === "MINIMIZED" && <PopupMenu root items={props.menuItems ?? []} />}
        </>
      )}
    </Stack>
  );
};

LayoutSideBarContent.displayName = "LayoutSideBarContent";

export default LayoutSideBarContent;
