import { useSpotStatusMap } from "@hireroo/app-definition/interview";
import * as ErrorHandlingHelper from "@hireroo/app-helper/error-handling";
import { useEnabledSinSClassPhase2 } from "@hireroo/app-helper/feature";
import { hasSpotStatus } from "@hireroo/app-helper/interview";
import { Auth, Payment, Role } from "@hireroo/app-store/essential/employee";
import { ScreeningsTestsStore } from "@hireroo/app-store/page/e/screenings_tests";
import { Snackbar } from "@hireroo/app-store/widget/shared/Snackbar";
import { formatScore } from "@hireroo/formatter/score";
import { getGraphqlClient } from "@hireroo/graphql/client/request";
import { useTranslation, useTranslationWithVariable } from "@hireroo/i18n";
import type { Widget } from "@hireroo/presentation";
import { generateCurrentOriginUrl, generatePath } from "@hireroo/router/api";
import { useTransitionNavigate } from "@hireroo/router/hooks";
import * as Sentry from "@sentry/react";
import * as React from "react";

import RemoteCreateDialogFromEvaluationMetricsContainer from "../../../../../../widget/v2/e/RemoteInterviewResourceEditor/widgets/RemoteCreateDialogFromEvaluationMetrics/Container";
import * as PrivateHelper from "./privateHelper";
import { useGenerateBulkListActivityBarProps } from "./useGenerateBulkListActivityBarProps";

type ScreeningTestListTableProps = Widget.ScreeningTestListProps["screeningTestListTable"];
type ScreeningTestListTableRowProps = ScreeningTestListTableProps["items"][0];
type SuspiciousDegree = Exclude<ScreeningTestListTableRowProps["suspiciousDegree"], undefined>["degree"];

const ENABLED_MULTI_SELECT = true;

export type GenerateTestListTablePropsArgs = {
  refresh: () => void;
};

export const useGenerateProps = (args: GenerateTestListTablePropsArgs): Widget.ScreeningTestListProps => {
  const res = ScreeningsTestsStore.useResponse();
  const { t: t2 } = useTranslationWithVariable();
  const page = ScreeningsTestsStore.usePage();
  const size = ScreeningsTestsStore.useSize();
  const { isDescending, sortMethod } = ScreeningsTestsStore.useListParams();
  const currentUid = Auth.useCurrentUid();
  const bulkListActivityBar = useGenerateBulkListActivityBarProps({ refresh: args.refresh });

  const selectableTags = ScreeningsTestsStore.useTags().map(tag => tag.name);
  const { t } = useTranslation();
  const isAvailableFeature = Payment.useIsAvailableFeature();
  const navigate = useTransitionNavigate();
  const csvDownloadState = ScreeningsTestsStore.useCsvDownload();
  const matchingRole = Role.useMatchingRole();
  const currentRoleDisplayText = Role.useCurrentRoleDisplayText();
  const expectedRole = Role.useRoleToText("MANAGER");
  const count = ScreeningsTestsStore.useCount();
  const listFetchingStatus = ScreeningsTestsStore.useListFetchingStatus();
  const spots = ScreeningsTestsStore.useSpots();
  const refreshKey = ScreeningsTestsStore.useRefreshKey();
  const selectedSpotIds = ScreeningsTestsStore.useSelectedSpotIds();
  const spotStatusMap = useSpotStatusMap();

  const enabledSinSClassPhase2 = useEnabledSinSClassPhase2();
  const [selectedSpotId, setSelectedSpotId] = React.useState<string | null>(null);

  const client = getGraphqlClient();
  const testPath = "/e/screenings/tests/:id/detail";

  const items = React.useMemo((): ScreeningTestListTableRowProps[] => {
    return (spots || []).map((spot): ScreeningTestListTableRowProps => {
      const tagDialog: ScreeningTestListTableRowProps["actionMenu"]["tagDialog"] = {
        defaultValues: {
          tags: spot.tags.map(tag => tag.name),
        },
        tagInputField: {
          disabled: listFetchingStatus === "FETCHING",
          options: selectableTags,
        },
        onSubmit: async (fields, controller) => {
          controller.setLoading(true);
          await client
            .UpdateSpotTags({
              updateSpotInput: {
                spotId: spot.id,
                tagNames: fields.tags,
              },
            })
            .then(res => {
              const updateSpot = res.updateSpot;
              if (updateSpot) {
                ScreeningsTestsStore.updateSpot(updateSpot);
              }
              Snackbar.notify({
                severity: "success",
                message: t("テスト情報を更新しました。"),
              });
              controller.close();
            })
            .catch(error => {
              Sentry.captureException(error);
              const errorNotification = ErrorHandlingHelper.generateErrorNotification(error, t("テストの更新に失敗しました。"));
              Snackbar.notify({
                severity: "error",
                message: errorNotification.message,
              });
              controller.setLoading(false);
            })
            .finally(() => {
              controller.setLoading(false);
            });
        },
      };
      const isScored = spot.status === "REVIEWED" || spot.status === "FINALIZED" || spot.status === "EVALUATED";
      const rank = isScored && spot.isReliableRank && isAvailableFeature("test.rank.read") ? spot.rankEvaluation : undefined;
      const suspiciousDegree: SuspiciousDegree = isScored && spot.suspiciousDegree === "UNCALCULATED" ? "NOT_SUPPORTED" : spot.suspiciousDegree;
      const name = spot.isRetake ? `${t("（再試験）")}${spot.candidateName}` : spot.candidateName;

      return {
        enabledMultiSelect: ENABLED_MULTI_SELECT,
        enabledSuspiciousDegree: isAvailableFeature("test.suspicious-degree.read"),
        id: spot.spotId,
        href: generatePath(testPath, {
          pathParams: {
            id: spot.spotId,
          },
        }),
        onClick: () => {
          navigate(testPath, {
            pathParams: {
              id: spot.spotId,
            },
          });
        },
        candidateName: name,
        candidateEmail: spot.candidateEmail || undefined,
        screeningName: spot.screening ? spot.screening.name || "" : spot.name,
        status: {
          text: spotStatusMap[spot.status],
          color: spot.status === "FINALIZED" ? "PRIMARY" : "GRAY",
        },
        tags: spot.tags.map(t => t.name),
        score: isScored
          ? {
              value: `${formatScore(spot.customScore)}%`,
            }
          : undefined,
        rank: rank
          ? {
              value: rank,
            }
          : undefined,
        evaluation: (() => {
          if (spot.status === "FINALIZED") {
            if (!matchingRole.ADMIN_OR_MANAGER.matched) {
              return {
                status: "EVALUATION_POSSIBLE_NO_PERMISSION",
                disabledText: t2("PermissionTooltip", {
                  actualRole: currentRoleDisplayText,
                  expectedRole: expectedRole,
                }),
              };
            }

            return {
              testName: spot.name,
              candidateName: spot.candidateName,
              url: generateCurrentOriginUrl(testPath, {
                pathParams: {
                  id: spot.id,
                },
              }),
              status: "CAN_EVALUATE",
              onSubmit: async (fields, controller) => {
                controller.setLoading(true);
                await client
                  .EvaluateInterviewForInterviews({
                    spotId: spot.id,
                    employeeId: currentUid,
                    isPassed: fields.result === "PASSED",
                    evaluationComment: fields.comment,
                  })
                  .then(res => {
                    if (res.evaluateSpot) {
                      ScreeningsTestsStore.updateSpot(res.evaluateSpot);
                    }
                    Snackbar.notify({
                      severity: "success",
                      message: t("テストの評価を完了しました。"),
                    });
                    controller.close();
                  })
                  .catch(error => {
                    Sentry.captureException(error);
                    const errorNotification = ErrorHandlingHelper.generateErrorNotification(
                      error,
                      t("テストの評価に失敗しました。しばらくしてから再度お試し頂くか、ヘルプボタンよりお問い合わせください。"),
                    );
                    Snackbar.notify({
                      severity: "error",
                      message: errorNotification.message,
                    });
                    controller.setLoading(false);
                  });
              },
            };
          }
          if (spot.status === "EVALUATED") {
            return {
              status: spot.isPassed ? "PASSED" : "NOT_PASSED",
            };
          }
          return {
            status: "NOT_GRADED",
          };
        })(),
        suspiciousDegree: {
          degree: suspiciousDegree,
        },
        actionMenu: {
          tagDialog: tagDialog,
          createInterviewButton:
            isAvailableFeature("interview.create") && (spot.status === "FINALIZED" || spot.status === "EVALUATED")
              ? {
                  onClick: () => {
                    if (enabledSinSClassPhase2) {
                      setSelectedSpotId(spot.id);
                      return;
                    }
                    navigate("/e/remotes/create", {
                      queryParams: {
                        spotId: spot.id,
                      },
                    });
                  },
                  disabled: !matchingRole.ADMIN_OR_MANAGER.matched,
                  disabledText: matchingRole.ADMIN_OR_MANAGER.messageOnUnmatched,
                }
              : undefined,
          deleteButton: {
            disabled: !matchingRole.ADMIN_OR_MANAGER.matched || hasSpotStatus(spot.status, ["STARTED", "COMPLETED"]),
            disabledText: !matchingRole.ADMIN_OR_MANAGER.matched
              ? t2("PermissionTooltip", {
                  actualRole: currentRoleDisplayText,
                  expectedRole: expectedRole,
                })
              : hasSpotStatus(spot.status, ["STARTED", "COMPLETED"])
                ? t("実施中または採点中の候補者は削除できません。")
                : undefined,
          },
          deleteDialog: {
            name: name,
            onSubmit: controller => {
              controller.setLoading(true);
              client
                .DeleteSpotForScreeningsTests({
                  spotId: spot.spotId,
                })
                .then(() => {
                  ScreeningsTestsStore.deleteSpots([spot.spotId]);
                  ScreeningsTestsStore.updateRefreshKey();
                  Snackbar.notify({
                    severity: "success",
                    message: t("テストを削除しました。"),
                  });
                  controller.close();
                })
                .catch(error => {
                  Sentry.captureException(error);
                  const errorNotification = ErrorHandlingHelper.generateErrorNotification(
                    error,
                    t("テストの削除に失敗しました。しばらくしてから再度お試し頂くか、ヘルプボタンよりお問い合わせください。"),
                  );
                  Snackbar.notify({
                    severity: "error",
                    message: errorNotification.message,
                  });
                })
                .finally(() => {
                  controller.setLoading(false);
                });
            },
          },
        },
      };
    });
  }, [
    spots,
    listFetchingStatus,
    selectableTags,
    isAvailableFeature,
    t,
    spotStatusMap,
    matchingRole.ADMIN_OR_MANAGER.matched,
    matchingRole.ADMIN_OR_MANAGER.messageOnUnmatched,
    t2,
    currentRoleDisplayText,
    expectedRole,
    client,
    navigate,
    currentUid,
    enabledSinSClassPhase2,
  ]);
  const handleClose = () => {
    setSelectedSpotId(null);
  };
  return {
    screeningTestListTable: {
      enabledStatisticsContents: isAvailableFeature("test.rank.read"),
      enabledScreeningName: true,
      enabledMultiSelect: ENABLED_MULTI_SELECT,
      enabledSuspiciousDegree: isAvailableFeature("test.suspicious-degree.read"),
      onChange: fields => {
        const selectedItems = fields.items.filter(item => item.selected);
        ScreeningsTestsStore.updateSelectedSpotIds(selectedItems.map(({ itemId }) => itemId));
      },
      items: items,
      CreateRemoteDialog: enabledSinSClassPhase2
        ? selectedSpotId && (
            <RemoteCreateDialogFromEvaluationMetricsContainer dialog={{ open: true, onClose: handleClose }} selectedSpotId={selectedSpotId} />
          )
        : undefined,
    },
    tablePagination: {
      count: res.count ?? 0,
      rowsPerPage: size,
      onRowsPerPageChange: event => {
        const newSize = parseInt(event.target.value, 10);
        ScreeningsTestsStore.updateSize(newSize);
        /**
         * clear selected spot ids to refresh state inside the list
         */
        ScreeningsTestsStore.clearSelectedSpotIds();
      },
      page: page,
      onPageChange: (_, newPage) => {
        ScreeningsTestsStore.updatePage(newPage);
        /**
         * clear selected spot ids to refresh state inside the list
         */
        ScreeningsTestsStore.clearSelectedSpotIds();
      },
    },
    refreshKey: `${items.map(i => i.id).join(",")}-${refreshKey}`,
    searchResultBar: {
      defaultValue: PrivateHelper.generateSortMethod({ isDescending, sortMethod }),
      downloadStatus: csvDownloadState.status === "PENDING" ? "LOADING" : "READY",
      resultText: t2("SearchResultCount", { count: listFetchingStatus !== "FETCHED" ? "-" : count }),
      bulkListActivityBar: bulkListActivityBar,
      mode: selectedSpotIds.length > 0 ? "SELECTED" : "NONE",
      csvDownloadButton: isAvailableFeature("test.csv-export.read")
        ? {
            disabled: !matchingRole.ADMIN_OR_MANAGER.matched ? true : count === 0 || listFetchingStatus !== "FETCHED",
            title: (() => {
              if (!matchingRole.ADMIN_OR_MANAGER.matched) {
                return t2("PermissionTooltip", {
                  actualRole: currentRoleDisplayText,
                  expectedRole: matchingRole.ADMIN_OR_MANAGER.minimumRoleDisplayText,
                });
              }
              if (csvDownloadState.status === "PENDING") {
                return t("データの出力処理が実行中です");
              }
              return;
            })(),
            onClick: () => {
              ScreeningsTestsStore.DownloadAction.toggleDownloadDialog();
            },
          }
        : undefined,
      onChangeSortMethod: sortMethod => {
        const target = PrivateHelper.sortMethodMap[sortMethod];
        ScreeningsTestsStore.updateIsDescending(target.isDescending);
        ScreeningsTestsStore.updateSortMethod(target.sortMethod);
      },
    },
  };
};
