/* eslint-disable no-prototype-builtins */
import "firebase/compat/database";

import { getRef, getTimestamp } from "@hireroo/firebase";
import {
  AddComment,
  AddElement,
  AdjustPosition,
  CommentElement,
  COMPONENT_TYPE,
  ComponentType,
  ConnectNodes,
  DeleteElements,
  EdgeElement,
  EditComment,
  ELEMENT_TYPE,
  ElementLabel,
  ElementType,
  FLOW_ACTION,
  FlowAction,
  FlowElement,
  MoveElements,
  OPERATION_TYPE,
  OperationType,
  PasteElements,
  ReconnectEdge,
  ResetElements,
  ReviveElements,
  Settings,
  ShapeElement,
  UnionSettingsFields,
  UpdateSettings,
  UserState,
} from "@hireroo/system-design/features";
import { isValidComponentType, parseFlowChartSnapshot } from "@hireroo/system-design/helpers/flowChart";
import { useSystemDesignContext } from "@hireroo/system-design/react/FlowChart";
import firebase from "firebase/compat/app";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useImmer } from "use-immer";

import { colorFromUserId } from "../color";
import { revisionFromId, revisionToId, SyncState } from "../firepad";
import { tuple } from "../tuple";
import { SendPayload, useFirebaseSender } from "./useBrowserWindowEventDetect";

const timeInSeconds = (): number => {
  return new Date().getTime();
};

export type CollaborativeState = {
  collaborators: UserState[];
  selectedQuestion: number;
  systemDesignReady: boolean;
  usedHints: Set<number>;
  ready: boolean;
  canUndo: boolean;
  canRedo: boolean;
  pushEventHistory: (payload: SendPayload) => void;
  selectedComponentType: ComponentType;
};

export type CollaborativeAction = {
  submitQuestion: () => void;
  useHint: (hintId: number) => void;
  runTestcase: (hintId: number) => void;
  selectComponentType: (componentType: ComponentType) => void;
  addElement: (
    id: string,
    type: ElementType,
    label: ElementLabel,
    x: number,
    y: number,
    w: number,
    h: number,
    initialSettings: Settings,
    operationType: OperationType,
  ) => void;
  addComment: (id: string, content: string, x: number, y: number, fontSize: number, operationType: OperationType) => void;
  editComment: (id: string, content: string, operationType: OperationType) => void;
  deleteElements: (ids: string[], operationType: OperationType) => void;
  moveElements: (ids: string[], dx: number, dy: number, operationType: OperationType) => void;
  shapeElement: (id: string, position: AdjustPosition, dx: number, dy: number, operationType: OperationType) => void;
  connectNodes: (id: string, source: string, target: string, operationType: OperationType) => void;
  reconnectEdge: (id: string, source: string, target: string, operationType: OperationType) => void;
  pasteElements: (destElements: FlowElement[], operationType: OperationType) => void;
  updateSettings: (id: string, updates: UnionSettingsFields, operationType: OperationType) => void;
  resetElements: () => void;
  redo: () => void;
  undo: () => void;
  selectElement: (ids: string[]) => void;
  saveEditingCommentId: (id: string | null) => void;
  moveCursor: (x: number, y: number) => void;
};

export type SystemDesignRealtimeDatabaseArgs = {
  systemDesignId: number;
  isCandidate: boolean;
  isInterviewing?: boolean;
  initialSnapshot: string;
  userName: string;
  uid: string;
  selectedQuestionId: number;
};

export const useSystemDesignRealtimeDatabase = (args: SystemDesignRealtimeDatabaseArgs): [CollaborativeState, CollaborativeAction] => {
  const { systemDesignId, isCandidate, isInterviewing, userName, uid } = args;
  const store = useSystemDesignContext();
  // To write the realtime log at systemDesigns/${systemDesignId}
  const systemDesignIndexRef = useRef<number>(0);
  const systemDesignRef = useRef<firebase.database.Reference | undefined>();
  const systemDesignLastStateRef = useRef<SyncState>();
  const [systemDesignReady, setSystemDesignReady] = useState<boolean>(false);

  // To write the realtime log at systemDesigns/${systemDesignId}/questions/${questionId}
  const questionIndexRef = useRef<number>(0);
  const questionRef = useRef<firebase.database.Reference | undefined>();
  const [questionReady, setQuestionReady] = useState<boolean>(false);

  // To write the realtime log at systemDesigns/${systemDesignId}/questions/${questionId}/componentTypes/${componentType}
  const componentTypeIndexRef = useRef<number>(0);
  const componentTypeRef = useRef<firebase.database.Reference | undefined>();
  const [componentTypeReady, setComponentTypeReady] = useState<boolean>(false);

  const systemDesignFactory = store.hooks.useSystemDesign();

  const systemDesign = useMemo(() => {
    return systemDesignFactory(systemDesignId);
  }, [systemDesignFactory, systemDesignId]);

  const initialElements = useMemo(() => {
    if (args.initialSnapshot) {
      const { ok, result } = parseFlowChartSnapshot(args.initialSnapshot);
      return ok ? result.elements : [];
    } else {
      return [];
    }
  }, [args.initialSnapshot]);

  const historyRef = useRef<firebase.database.Reference | undefined>();
  const [historyReady, setHistoryReady] = useState<boolean>(false);

  const userRef = useRef<firebase.database.Reference | undefined>();
  const [userReady, setUserReady] = useState(false);
  const [users, setUsers] = useImmer<Record<string, UserState>>({});
  const collaborators = useMemo(() => {
    return Object.keys(users)
      .filter(id => id !== uid)
      .map(id => users[id]);
  }, [uid, users]);

  const [selectedQuestion, setSelectedQuestion] = useState<number>(args.selectedQuestionId);
  const [selectedComponentType, setSelectedComponentType] = useState<ComponentType>(
    systemDesign.componentTypesList && systemDesign.componentTypesList.length > 0
      ? (systemDesign.componentTypesList[0] as ComponentType)
      : COMPONENT_TYPE.default,
  );
  const [usedHints, setUsedHints] = useState<Set<number>>(new Set());

  const [undoStack, setUndoStack] = useImmer<FlowAction[]>([]);
  const [redoStack, setRedoStack] = useImmer<FlowAction[]>([]);

  const elementFactory = store.hooks.useElement();
  const withNeighborEdge = store.hooks.useWithNeighborEdge();

  const ready = useMemo<boolean>(() => {
    return systemDesignReady && questionReady && historyReady && userReady;
  }, [systemDesignReady, questionReady, historyReady, userReady]);

  const [isConnected, setIsConnected] = useState<boolean>(false);

  const { pushEventHistory } = useFirebaseSender({
    appType: "systemDesign",
    uid: args.uid,
    systemDesignId: systemDesignId,
    questionId: selectedQuestion,
  });

  const setInQuestionWrapper = useCallback(
    (newSelectedQuestion: number) => {
      if (!systemDesignReady) return;
      systemDesignRef.current?.child(revisionToId(systemDesignIndexRef.current)).set({
        s: "inq",
        v: newSelectedQuestion,
        t: getTimestamp(),
      });
    },
    [systemDesignReady],
  );

  const setOutQuestionWrapper = useCallback(
    (newSelectedQuestion: number) => {
      if (!systemDesignReady) return;
      systemDesignRef.current?.child(revisionToId(systemDesignIndexRef.current)).set({
        s: "outq",
        v: newSelectedQuestion,
        t: getTimestamp(),
      });
    },
    [systemDesignReady],
  );

  const submitQuestion = useCallback(() => {
    if (!componentTypeReady) return;
    componentTypeRef.current?.child(revisionToId(componentTypeIndexRef.current)).set({
      s: "subq",
      v: selectedQuestion,
      t: getTimestamp(),
    });
  }, [componentTypeReady, selectedQuestion]);

  const useHint = useCallback(
    (hintId: number) => {
      if (!questionReady) return;
      questionRef.current?.child(revisionToId(questionIndexRef.current)).set({
        s: "useh",
        v: hintId,
        t: getTimestamp(),
      });
    },
    [questionReady],
  );

  const runTestcase = useCallback(
    (snapshotId: number) => {
      if (!questionReady) return;
      questionRef.current?.child(revisionToId(questionIndexRef.current)).set({
        s: "runt",
        v: snapshotId,
        t: getTimestamp(),
      });
    },
    [questionReady],
  );

  const selectComponentType = useCallback(
    (componentType: ComponentType) => {
      if (!questionReady) return;
      questionRef.current?.child(revisionToId(questionIndexRef.current)).set({
        s: "selc",
        v: componentType,
        t: getTimestamp(),
      });
    },
    [questionReady],
  );

  /*
    1. A Mouse event detected in the drawing triggers the functions defined below.
    2. In these functions, push a history node in the firebase after saving the operation and the inverse one to stacks.
    3. A firebase event that the history node is added triggers an update of the flowchart state.
    Note that a firebase event is triggered immediately by optimistic updates, so no round trip for a local state update.
  */

  const addElement = useCallback(
    (
      id: string,
      type: ElementType,
      label: ElementLabel,
      x: number,
      y: number,
      w: number,
      h: number,
      initialSettings: Settings,
      operationType: OperationType,
    ) => {
      const operation: AddElement = {
        s: FLOW_ACTION.addElement,
        v: {
          id,
          t: type,
          l: label,
          x,
          y,
          w,
          h,
          s: initialSettings,
        },
        a: uid,
        t: timeInSeconds(),
      };
      const inverseOperation: DeleteElements = {
        s: FLOW_ACTION.deleteElements,
        v: {
          ids: [id],
        },
        a: uid,
        t: timeInSeconds(),
      };
      if (operationType === OPERATION_TYPE.do || operationType === OPERATION_TYPE.redo) {
        setUndoStack(draft => {
          draft.push(inverseOperation);
        });
      }
      if (operationType === OPERATION_TYPE.undo) {
        setRedoStack(draft => {
          draft.push(inverseOperation);
        });
      }
      historyRef.current?.push(operation);
    },
    [setRedoStack, setUndoStack, uid],
  );

  const addComment = useCallback(
    (id: string, content: string, x: number, y: number, fontSize: number, operationType: OperationType) => {
      const operation: AddComment = {
        s: FLOW_ACTION.addComment,
        v: {
          id,
          c: content,
          x,
          y,
          f: fontSize,
        },
        a: uid,
        t: timeInSeconds(),
      };
      const inverseOperation: DeleteElements = {
        s: FLOW_ACTION.deleteElements,
        v: {
          ids: [id],
        },
        a: uid,
        t: timeInSeconds(),
      };
      if (operationType === OPERATION_TYPE.do || operationType === OPERATION_TYPE.redo) {
        setUndoStack(draft => {
          draft.push(inverseOperation);
        });
      }
      if (operationType === OPERATION_TYPE.undo) {
        setRedoStack(draft => {
          draft.push(inverseOperation);
        });
      }
      historyRef.current?.push(operation);
    },
    [setRedoStack, setUndoStack, uid],
  );

  const editComment = useCallback(
    (id: string, content: string, operationType: OperationType) => {
      const operation: EditComment = {
        s: FLOW_ACTION.editComment,
        v: {
          id,
          c: content,
        },
        a: uid,
        t: timeInSeconds(),
      };
      const comment = elementFactory(id) as CommentElement;
      if (!comment) return;
      const inverseOperation: EditComment = {
        s: FLOW_ACTION.editComment,
        v: {
          id,
          c: comment.content,
        },
        a: uid,
        t: timeInSeconds(),
      };

      if (operationType === OPERATION_TYPE.do || operationType === OPERATION_TYPE.redo) {
        setUndoStack(draft => {
          draft.push(inverseOperation);
        });
      }
      if (operationType === OPERATION_TYPE.undo) {
        setRedoStack(draft => {
          draft.push(inverseOperation);
        });
      }
      historyRef.current?.push(operation);
    },
    [elementFactory, setRedoStack, setUndoStack, uid],
  );

  const deleteElements = useCallback(
    (ids: string[], operationType: OperationType) => {
      if (ids.length === 0) return;
      const operation: DeleteElements = {
        s: FLOW_ACTION.deleteElements,
        v: {
          ids: withNeighborEdge(ids),
        },
        a: uid,
        t: timeInSeconds(),
      };
      const inverseOperation: ReviveElements = {
        s: FLOW_ACTION.reviveElements,
        v: {
          ids: withNeighborEdge(ids),
        },
        a: uid,
        t: timeInSeconds(),
      };
      if (operationType === OPERATION_TYPE.do || operationType === OPERATION_TYPE.redo) {
        setUndoStack(draft => {
          draft.push(inverseOperation);
        });
      }
      if (operationType === OPERATION_TYPE.undo) {
        setRedoStack(draft => {
          draft.push(inverseOperation);
        });
      }
      historyRef.current?.push(operation);
    },
    [setRedoStack, setUndoStack, uid, withNeighborEdge],
  );

  const reviveElements = useCallback(
    (ids: string[], operationType: OperationType) => {
      if (ids.length === 0) return;
      const operation: ReviveElements = {
        s: FLOW_ACTION.reviveElements,
        v: {
          ids,
        },
        a: uid,
        t: timeInSeconds(),
      };
      const inverseOperation: DeleteElements = {
        s: FLOW_ACTION.deleteElements,
        v: {
          ids,
        },
        a: uid,
        t: timeInSeconds(),
      };
      if (operationType === OPERATION_TYPE.do || operationType === OPERATION_TYPE.redo) {
        setUndoStack(draft => {
          draft.push(inverseOperation);
        });
      }
      if (operationType === OPERATION_TYPE.undo) {
        setRedoStack(draft => {
          draft.push(inverseOperation);
        });
      }
      historyRef.current?.push(operation);
    },
    [setRedoStack, setUndoStack, uid],
  );

  const moveElements = useCallback(
    (ids: string[], dx: number, dy: number, operationType: OperationType) => {
      if (ids.length === 0) return;
      const operation: MoveElements = {
        s: FLOW_ACTION.moveElements,
        v: {
          ids,
          dx,
          dy,
        },
        a: uid,
        t: timeInSeconds(),
      };
      const inverseOperation: MoveElements = {
        s: FLOW_ACTION.moveElements,
        v: {
          ids,
          dx: -dx,
          dy: -dy,
        },
        a: uid,
        t: timeInSeconds(),
      };
      if (operationType === OPERATION_TYPE.do || operationType === OPERATION_TYPE.redo) {
        setUndoStack(draft => {
          draft.push(inverseOperation);
        });
      }
      if (operationType === OPERATION_TYPE.undo) {
        setRedoStack(draft => {
          draft.push(inverseOperation);
        });
      }
      // Only the peer's move is reflected via the firebase event since a move operation continuously changes the local state,
      // but redo and undo is the exception, hence update the local state here
      if (operationType === OPERATION_TYPE.undo || operationType === OPERATION_TYPE.redo) {
        store.action.moveElements({ ids, dx, dy });
      }
      historyRef.current?.push(operation);
    },
    [setRedoStack, setUndoStack, store.action, uid],
  );

  const shapeElement = useCallback(
    (id: string, position: AdjustPosition, dx: number, dy: number, operationType: OperationType) => {
      const operation: ShapeElement = {
        s: FLOW_ACTION.shapeElement,
        v: {
          id,
          p: position,
          dx,
          dy,
        },
        a: uid,
        t: timeInSeconds(),
      };
      const inverseOperation: ShapeElement = {
        s: FLOW_ACTION.shapeElement,
        v: {
          id,
          p: position,
          dx: -dx,
          dy: -dy,
        },
        a: uid,
        t: timeInSeconds(),
      };
      if (operationType === OPERATION_TYPE.do || operationType === OPERATION_TYPE.redo) {
        setUndoStack(draft => {
          draft.push(inverseOperation);
        });
      }
      if (operationType === OPERATION_TYPE.undo) {
        setRedoStack(draft => {
          draft.push(inverseOperation);
        });
      }
      if (operationType === OPERATION_TYPE.undo || operationType === OPERATION_TYPE.redo) {
        store.action.shapeElement({ id, dx, dy, adjustPosition: position });
      }
      historyRef.current?.push(operation);
    },
    [setRedoStack, setUndoStack, store.action, uid],
  );

  const connectNodes = useCallback(
    (id: string, source: string, target: string, operationType: OperationType) => {
      const operation: ConnectNodes = {
        s: FLOW_ACTION.connectNodes,
        v: {
          id,
          s: source,
          t: target,
        },
        a: uid,
        t: timeInSeconds(),
      };
      const inverseOperation: DeleteElements = {
        s: FLOW_ACTION.deleteElements,
        v: {
          ids: [id],
        },
        a: uid,
        t: timeInSeconds(),
      };
      if (operationType === OPERATION_TYPE.do || operationType === OPERATION_TYPE.redo) {
        setUndoStack(draft => {
          draft.push(inverseOperation);
        });
      }
      if (operationType === OPERATION_TYPE.undo) {
        setRedoStack(draft => {
          draft.push(inverseOperation);
        });
      }
      historyRef.current?.push(operation);
    },
    [setRedoStack, setUndoStack, uid],
  );

  const reconnectEdge = useCallback(
    (id: string, source: string, target: string, operationType: OperationType) => {
      const operation: ReconnectEdge = {
        s: FLOW_ACTION.reconnectEdge,
        v: {
          id,
          s: source,
          t: target,
        },
        a: uid,
        t: timeInSeconds(),
      };
      const edge = elementFactory(id) as EdgeElement;
      const inverseOperation: ReconnectEdge = {
        s: FLOW_ACTION.reconnectEdge,
        v: {
          id,
          s: edge.source,
          t: edge.target,
        },
        a: uid,
        t: timeInSeconds(),
      };
      if (operationType === OPERATION_TYPE.do || operationType === OPERATION_TYPE.redo) {
        setUndoStack(draft => {
          draft.push(inverseOperation);
        });
      }
      if (operationType === OPERATION_TYPE.undo) {
        setRedoStack(draft => {
          draft.push(inverseOperation);
        });
      }
      historyRef.current?.push(operation);
    },
    [elementFactory, setRedoStack, setUndoStack, uid],
  );

  const pasteElements = useCallback(
    (destElements: FlowElement[], operationType: OperationType) => {
      if (destElements.length === 0) return;
      const operation: PasteElements = {
        s: FLOW_ACTION.pasteElements,
        v: {
          dst: destElements,
        },
        a: uid,
        t: timeInSeconds(),
      };
      const inverseOperation: DeleteElements = {
        s: FLOW_ACTION.deleteElements,
        v: {
          ids: destElements.map(element => element.id),
        },
        a: uid,
        t: timeInSeconds(),
      };
      if (operationType === OPERATION_TYPE.do || operationType === OPERATION_TYPE.redo) {
        setUndoStack(draft => {
          draft.push(inverseOperation);
        });
      }
      if (operationType === OPERATION_TYPE.undo) {
        setRedoStack(draft => {
          draft.push(inverseOperation);
        });
      }
      historyRef.current?.push(operation);
    },
    [setRedoStack, setUndoStack, uid],
  );

  const updateSettings = useCallback(
    (id: string, updates: UnionSettingsFields, operationType: OperationType) => {
      // Just return when there is no update
      if (Object.keys(updates).length === 0) return;
      const operation: UpdateSettings = {
        s: FLOW_ACTION.updateSettings,
        v: {
          id,
          u: updates,
        },
        a: uid,
        t: timeInSeconds(),
      };
      const inverseUpdates = {};
      const element = elementFactory(id);

      if (element) {
        if (element.type === ELEMENT_TYPE.comment) {
          if (updates.hasOwnProperty("fontSize")) Object.assign(inverseUpdates, { fontSize: element.settings.fontSize });
        } else if (element.type === ELEMENT_TYPE.edge) {
          if (updates.hasOwnProperty("direction")) Object.assign(inverseUpdates, { direction: element.settings.direction });
        } else {
          if (updates.name) Object.assign(inverseUpdates, { name: element.settings.name });
        }
      }

      const inverseOperation: UpdateSettings = {
        s: FLOW_ACTION.updateSettings,
        v: {
          id,
          u: inverseUpdates,
        },
        a: uid,
        t: timeInSeconds(),
      };
      if (operationType === OPERATION_TYPE.do || operationType === OPERATION_TYPE.redo) {
        setUndoStack(draft => {
          draft.push(inverseOperation);
        });
      }
      if (operationType === OPERATION_TYPE.undo) {
        setRedoStack(draft => {
          draft.push(inverseOperation);
        });
      }
      historyRef.current?.push(operation);
    },
    [elementFactory, setRedoStack, setUndoStack, uid],
  );

  const resetElements = useCallback(() => {
    const operation: ResetElements = {
      s: FLOW_ACTION.resetElements,
      a: uid,
      t: timeInSeconds(),
    };
    setUndoStack([]);
    setRedoStack([]);
    historyRef.current?.push(operation);
  }, [setRedoStack, setUndoStack, uid]);

  const undo = useCallback(() => {
    const history = undoStack[undoStack.length - 1];
    if (!history) return;

    setUndoStack(draft => {
      draft.pop();
    });

    switch (history.s) {
      case FLOW_ACTION.addElement:
        addElement(
          history.v.id,
          history.v.t,
          history.v.l,
          history.v.x,
          history.v.y,
          history.v.w,
          history.v.h,
          history.v.s,
          OPERATION_TYPE.undo,
        );
        return;
      case FLOW_ACTION.addComment:
        addComment(history.v.id, history.v.c, history.v.x, history.v.y, history.v.f, OPERATION_TYPE.undo);
        return;
      case FLOW_ACTION.editComment:
        editComment(history.v.id, history.v.c, OPERATION_TYPE.undo);
        return;
      case FLOW_ACTION.deleteElements:
        deleteElements(history.v.ids, OPERATION_TYPE.undo);
        return;
      case FLOW_ACTION.reviveElements:
        reviveElements(history.v.ids, OPERATION_TYPE.undo);
        return;
      case FLOW_ACTION.moveElements:
        moveElements(history.v.ids, history.v.dx, history.v.dy, OPERATION_TYPE.undo);
        return;
      case FLOW_ACTION.shapeElement:
        shapeElement(history.v.id, history.v.p, history.v.dx, history.v.dy, OPERATION_TYPE.undo);
        return;
      case FLOW_ACTION.connectNodes:
        connectNodes(history.v.id, history.v.s, history.v.t, OPERATION_TYPE.undo);
        return;
      case FLOW_ACTION.reconnectEdge:
        reconnectEdge(history.v.id, history.v.s, history.v.t, OPERATION_TYPE.undo);
        return;
      case FLOW_ACTION.pasteElements:
        pasteElements(history.v.dst, OPERATION_TYPE.undo);
        return;
      case FLOW_ACTION.updateSettings:
        updateSettings(history.v.id, history.v.u, OPERATION_TYPE.undo);
        return;
    }
  }, [
    addComment,
    addElement,
    connectNodes,
    deleteElements,
    editComment,
    moveElements,
    pasteElements,
    reconnectEdge,
    reviveElements,
    setUndoStack,
    shapeElement,
    undoStack,
    updateSettings,
  ]);

  const redo = useCallback(() => {
    const history = redoStack[redoStack.length - 1];
    if (!history) return;

    setRedoStack(draft => {
      draft.pop();
    });

    switch (history.s) {
      case FLOW_ACTION.addElement:
        addElement(
          history.v.id,
          history.v.t,
          history.v.l,
          history.v.x,
          history.v.y,
          history.v.w,
          history.v.h,
          history.v.s,
          OPERATION_TYPE.redo,
        );
        return;
      case FLOW_ACTION.addComment:
        addComment(history.v.id, history.v.c, history.v.x, history.v.y, history.v.f, OPERATION_TYPE.redo);
        return;
      case FLOW_ACTION.editComment:
        editComment(history.v.id, history.v.c, OPERATION_TYPE.redo);
        return;
      case FLOW_ACTION.deleteElements:
        deleteElements(history.v.ids, OPERATION_TYPE.redo);
        return;
      case FLOW_ACTION.reviveElements:
        reviveElements(history.v.ids, OPERATION_TYPE.redo);
        return;
      case FLOW_ACTION.moveElements:
        moveElements(history.v.ids, history.v.dx, history.v.dy, OPERATION_TYPE.redo);
        return;
      case FLOW_ACTION.shapeElement:
        shapeElement(history.v.id, history.v.p, history.v.dx, history.v.dy, OPERATION_TYPE.redo);
        return;
      case FLOW_ACTION.connectNodes:
        connectNodes(history.v.id, history.v.s, history.v.t, OPERATION_TYPE.redo);
        return;
      case FLOW_ACTION.reconnectEdge:
        reconnectEdge(history.v.id, history.v.s, history.v.t, OPERATION_TYPE.redo);
        return;
      case FLOW_ACTION.pasteElements:
        pasteElements(history.v.dst, OPERATION_TYPE.redo);
        return;
      case FLOW_ACTION.updateSettings:
        updateSettings(history.v.id, history.v.u, OPERATION_TYPE.redo);
        return;
    }
  }, [
    addComment,
    addElement,
    connectNodes,
    deleteElements,
    editComment,
    moveElements,
    pasteElements,
    reconnectEdge,
    redoStack,
    reviveElements,
    setRedoStack,
    shapeElement,
    updateSettings,
  ]);

  const selectElement = useCallback(
    (ids: string[]) => {
      if (!isConnected) return;
      userRef.current?.child(uid).update({
        select: ids,
      });
    },
    [isConnected, uid],
  );

  const saveEditingCommentId = useCallback(
    (id: string | null) => {
      if (!isConnected) return;
      userRef.current?.child(uid).update({
        edit: id,
      });
    },
    [isConnected, uid],
  );

  const moveCursor = useCallback(
    (x: number, y: number) => {
      if (!isConnected) return;
      userRef.current?.child(uid).update({
        cursor: {
          x,
          y,
        },
      });
    },
    [isConnected, uid],
  );

  const getLatestState = useCallback(
    (data: { [key: string]: SyncState }): { q?: number; c?: string; h?: Set<number>; k?: number; i?: boolean } => {
      // q: questionId, c: selected component type,h: used hint set, i: last state is "inq" or not, k: latest index,
      const v: { q?: number; c?: string; h?: Set<number>; i?: boolean; k?: number } = {};
      const inqOutq = new Set<string>();

      Object.keys(data)
        .sort()
        .forEach((key: string) => {
          if (data[key].s === "selq") v.q = data[key].v as number;
          if (data[key].s === "selc") v.c = data[key].v as string;

          if (data[key].s === "useh") {
            if (!v.h) v.h = new Set();
            v.h.add(data[key].v as number);
          }

          if (data[key].s === "inq") inqOutq.add("inq");
          if (data[key].s === "outq") inqOutq.clear();
          v.k = revisionFromId(key);
        });

      v.i = inqOutq.size > 0;
      return v;
    },
    [],
  );

  const setLatestUser = useCallback(
    (data: { [key: string]: UserState }): void => {
      Object.keys(data).forEach((key: string) => {
        setUsers(draft => {
          draft[key] = { ...data[key], uid: key };
        });
      });
    },
    [setUsers],
  );

  const setStateFromEvent = useCallback((state: SyncState) => {
    switch (state.s) {
      case "selq":
        setSelectedQuestion(state.v);
        break;
      case "selc":
        setSelectedComponentType(state.v);
        break;
      case "useh":
        setUsedHints(prev => prev.add(state.v));
        break;
    }
  }, []);

  const setHistoryFromEvent = useCallback(
    (history: FlowAction) => {
      switch (history.s) {
        case FLOW_ACTION.addElement:
          store.action.addElement({
            id: history.v.id,
            type: history.v.t,
            label: history.v.l,
            geometry: {
              minX: history.v.x,
              minY: history.v.y,
              maxX: history.v.x + history.v.w,
              maxY: history.v.y + history.v.h,
            },
            initialSettings: history.v.s,
            timestamp: history.t,
          });
          if (history.a === uid) {
            store.action.selectElements({ ids: [history.v.id] });
          }
          break;
        case FLOW_ACTION.addComment:
          store.action.addComment({
            id: history.v.id,
            content: history.v.c,
            geometry: { minX: history.v.x, minY: history.v.y, maxX: 0, maxY: 0 },
            fontSize: history.v.f,
            timestamp: history.t,
          });
          if (history.a === uid) {
            store.action.selectElements({ ids: [history.v.id] });
          }
          break;
        case FLOW_ACTION.editComment:
          store.action.editComment({ id: history.v.id, content: history.v.c, timestamp: history.t });
          break;
        case FLOW_ACTION.deleteElements:
          store.action.deleteElements({ ids: history.v.ids });
          break;
        case FLOW_ACTION.reviveElements:
          store.action.reviveElements({ ids: history.v.ids });
          break;
        case FLOW_ACTION.moveElements:
          // User's own move operation is continuously reflected to the state, hence only accept peer's change
          if (history.a !== uid) {
            store.action.moveElements({ ids: history.v.ids, dx: history.v.dx, dy: history.v.dy });
          }
          break;
        case FLOW_ACTION.shapeElement:
          if (history.a !== uid) {
            store.action.shapeElement({
              id: history.v.id,
              dx: history.v.dx,
              dy: history.v.dy,
              adjustPosition: history.v.p,
            });
          }
          break;
        case FLOW_ACTION.connectNodes:
          store.action.connectNodes({
            id: history.v.id,
            source: history.v.s,
            target: history.v.t,
            timestamp: history.t,
          });
          break;
        case FLOW_ACTION.reconnectEdge:
          store.action.reconnectEdge({
            id: history.v.id,
            source: history.v.s,
            target: history.v.t,
            timestamp: history.t,
          });
          break;
        case FLOW_ACTION.pasteElements:
          store.action.pasteElements({ elements: history.v.dst });
          if (history.a === uid) {
            store.action.selectElements({ ids: history.v.dst.map(element => element.id) });
          }
          break;
        case FLOW_ACTION.updateSettings:
          store.action.updateSettings({ id: history.v.id, updates: history.v.u, timestamp: history.t });
          break;
        case FLOW_ACTION.resetElements:
          store.action.resetElements({ elements: initialElements, timestamp: history.t });
          break;
      }
    },
    [initialElements, store.action, uid],
  );

  useEffect(() => {
    setSelectedQuestion(args.selectedQuestionId);
  }, [args.selectedQuestionId]);

  useEffect(() => {
    // Connect to firebase for realtime sync
    systemDesignRef.current = getRef("systemDesign", `systemDesigns/${systemDesignId}/state`);
    let initialLoaded = false;

    systemDesignRef.current?.on("child_added", snapshot => {
      if (!initialLoaded) return;
      const latest = snapshot.val() as SyncState;
      systemDesignLastStateRef.current = latest;
      systemDesignIndexRef.current = revisionFromId(snapshot.key as string) + 1;
      setStateFromEvent(latest);
    });

    systemDesignRef.current?.once("value", snapshot => {
      const data: Record<string, SyncState> | null = snapshot.val();
      if (data) {
        // state under systemDesigns/${systemDesignId}/state holds `selq` only.
        // only latest.q will be considered, and others will be ignored.
        const latest = getLatestState(data);
        if (latest.q !== undefined) setSelectedQuestion(latest.q);
        if (latest.k !== undefined) systemDesignIndexRef.current = latest.k + 1;

        // The interviewer's log is unnecessary and will not go through.
        // And, No values are recorded, even for tests that have already been completed.
        if (isCandidate && isInterviewing) {
          // If 'inq' already exists as latest, do not add 'inq'
          if (latest.i === undefined || latest.i === false) {
            setInQuestionWrapper(selectedQuestion);
          }
        }
      } else {
        if (isCandidate && isInterviewing) {
          // If this is the first landing where data does not exist, enter an initial value.
          setInQuestionWrapper(selectedQuestion);
        }
      }

      // Initial sync is done.
      initialLoaded = true;
      setSystemDesignReady(true);
    });
  }, [getLatestState, isCandidate, isInterviewing, selectedQuestion, setInQuestionWrapper, setStateFromEvent, systemDesignId]);

  const isOutState = useCallback((state?: SyncState) => {
    return state?.s === "outq";
  }, []);

  // If the cleanup function that throws 'outq' is called after IndexRef.current,
  // IT MUST BE CALLED FIRST because the value will be after initialization.
  useEffect(() => {
    if (!isInterviewing) return;
    // Set systemDesign out event to firebase.
    // This works only when the question switched.
    return () => {
      if (systemDesignReady && !isOutState(systemDesignLastStateRef.current)) setOutQuestionWrapper(selectedQuestion);
    };
  }, [isInterviewing, isOutState, selectedQuestion, setOutQuestionWrapper, systemDesignReady]);

  useEffect(() => {
    return () => {
      systemDesignRef.current = undefined;
      systemDesignIndexRef.current = 0;
      setSystemDesignReady(false);

      questionRef.current = undefined;
      questionIndexRef.current = 0;
      setQuestionReady(false);

      componentTypeRef.current = undefined;
      componentTypeIndexRef.current = 0;
      setComponentTypeReady(false);
    };
  }, []);

  useEffect(() => {
    if (!systemDesignReady) return;
    // Connect to firebase for realtime sync
    questionRef.current = getRef("systemDesign", `systemDesigns/${systemDesignId}/questions/${selectedQuestion}/state`);
    questionIndexRef.current = 0;
    let initialLoaded = false;

    questionRef.current?.on("child_added", snapshot => {
      if (!initialLoaded) return;
      questionIndexRef.current = revisionFromId(snapshot.key as string) + 1;
      setStateFromEvent(snapshot.val());
    });

    questionRef.current?.once("value", snapshot => {
      const data: Record<string, SyncState> | null = snapshot.val();
      if (data) {
        // state under systemDesigns/${systemDesignId}/questions/${questionId}/state holds `self` only.
        // only latest.f and latest.s will be considered, and others will be ignored.
        const latest = getLatestState(data);
        if (latest.c !== undefined && isValidComponentType(latest.c)) setSelectedComponentType(latest.c);
        if (latest.h !== undefined) setUsedHints(latest.h);
        if (latest.k !== undefined) questionIndexRef.current = latest.k + 1;
      }

      // Initial sync is done.
      initialLoaded = true;
      setQuestionReady(true);
    });
  }, [systemDesignId, selectedQuestion, setStateFromEvent, getLatestState, systemDesignReady]);

  useEffect(() => {
    if (!questionReady) return;
    // Connect to firebase for realtime sync
    componentTypeRef.current = getRef(
      "systemDesign",
      `systemDesigns/${systemDesignId}/questions/${selectedQuestion}/componentTypes/${selectedComponentType}/state`,
    );

    componentTypeRef.current?.once("value", () => {
      // Initial sync is done.
      setComponentTypeReady(true);
    });

    return () => {
      setComponentTypeReady(false);
    };
  }, [questionReady, selectedComponentType, selectedQuestion, systemDesignId]);

  useEffect(() => {
    if (!componentTypeReady) return;
    // Connect to firebase for realtime sync
    historyRef.current = getRef(
      "systemDesign",
      `systemDesigns/${systemDesignId}/questions/${selectedQuestion}/componentTypes/${selectedComponentType}/history`,
    );

    historyRef.current?.once("value", () => {
      // Initial sync is done.
      setHistoryReady(true);
    });

    return () => {
      setHistoryReady(false);
    };
  }, [systemDesignId, selectedQuestion, setStateFromEvent, getLatestState, selectedComponentType, componentTypeReady]);

  useEffect(() => {
    if (!historyReady) return;

    // ref: https://gist.github.com/katowulf/6383103
    historyRef.current
      ?.orderByChild("t")
      .startAt(Date.now())
      .on("child_added", snapshot => {
        // Update the local flowchart state from a newly added history
        setHistoryFromEvent(snapshot.val());
      });

    return () => {
      historyRef.current?.off("child_added");
      historyRef.current = undefined;
    };
  }, [historyReady, selectedQuestion, setHistoryFromEvent, systemDesignId]);

  useEffect(() => {
    // Connect to firebase for realtime sync
    userRef.current = getRef("systemDesign", `systemDesigns/${systemDesignId}/questions/${selectedQuestion}/users`);

    // Initialize user data in the realtime database
    userRef.current?.child(uid).set({
      name: userName,
      color: colorFromUserId(uid),
    });

    userRef.current?.once("value", snapshot => {
      setLatestUser(snapshot.val());
      // Initial sync is done.
      setUserReady(true);
    });

    return () => {
      setUserReady(false);
    };
  }, [selectedQuestion, setLatestUser, systemDesignId, uid, userName]);

  useEffect(() => {
    if (!userReady) return;

    // Set user state from the realtime database
    userRef.current?.on("child_added", snapshot => {
      const addedUserId = snapshot.key as string;
      if (!Object.keys(users).includes(addedUserId)) {
        setUsers(draft => {
          draft[addedUserId] = { ...snapshot.val(), uid: addedUserId } as UserState;
        });
      }
    });

    // Track a peer's state such as cursor movement or selecting elements
    userRef.current?.on("child_changed", snapshot => {
      setUsers(draft => {
        draft[snapshot.key as string] = snapshot.val() as UserState;
      });
    });

    // If a peer's session has disconnected, delete the peer from the local state
    userRef.current?.on("child_removed", snapshot => {
      setUsers(draft => {
        delete draft[snapshot.key as string];
      });
    });

    return () => {
      userRef.current?.off("child_added");
      userRef.current?.off("child_changed");
      userRef.current?.off("child_removed");
    };
  }, [setUsers, uid, userReady, users]);

  useEffect(() => {
    const connectedRef = getRef("systemDesign", ".info/connected");
    connectedRef.on("value", snapshot => {
      if (snapshot.val()) {
        setIsConnected(true);
      } else {
        setIsConnected(false);
      }
    });
    return () => {
      connectedRef.off("value");
    };
  }, [uid]);

  useEffect(() => {
    if (isConnected) {
      userRef.current?.child(uid).set({
        name: userName,
        color: colorFromUserId(uid),
        cursor: { x: 0, y: 0 },
      });
    }
  }, [uid, userName, isConnected]);

  // When a user's connection has lost, remove the user from the realtime database
  userRef.current?.child(uid).onDisconnect().remove();

  const state = {
    collaborators,
    selectedQuestion,
    systemDesignReady,
    usedHints,
    ready,
    canUndo: undoStack.length > 0,
    canRedo: redoStack.length > 0,
    pushEventHistory,
    selectedComponentType: selectedComponentType,
  };

  const dispatcher = useMemo((): CollaborativeAction => {
    return {
      submitQuestion,
      useHint,
      runTestcase,
      selectComponentType,
      addElement,
      addComment,
      editComment,
      deleteElements,
      moveElements,
      shapeElement,
      connectNodes,
      reconnectEdge,
      pasteElements,
      updateSettings,
      resetElements,
      undo,
      redo,
      selectElement,
      saveEditingCommentId,
      moveCursor,
    };
  }, [
    submitQuestion,
    selectComponentType,
    useHint,
    runTestcase,
    addElement,
    addComment,
    editComment,
    deleteElements,
    moveElements,
    shapeElement,
    connectNodes,
    reconnectEdge,
    pasteElements,
    updateSettings,
    resetElements,
    undo,
    redo,
    selectElement,
    saveEditingCommentId,
    moveCursor,
  ]);

  return tuple(state, dispatcher);
};
