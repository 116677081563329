import { useSnapshot } from "valtio";

import { state } from "./State";

export const useSnapshotState = () => {
  return useSnapshot(state);
};

export const useInitialized = () => {
  const snapshot = useSnapshotState();
  return !!snapshot.result;
};

export const useResult = () => {
  const snapshot = useSnapshotState();
  if (!snapshot.result) {
    throw new Error("need to initialize result");
  }
  return snapshot.result;
};

export const useSelectedEntityId = () => {
  const snapshot = useSnapshotState();
  return snapshot.selectedEntityId;
};
