import { CompetitionHistory } from "@hireroo/app-store/widget/e/CompetitionHistory";
import * as Graphql from "@hireroo/graphql/client/urql";

export const SortMethodReverseMap: Record<string, Graphql.ApplicantSortMethod> = {
  [Graphql.ApplicantSortMethod.CompletedAt]: Graphql.ApplicantSortMethod.CompletedAt,
  [Graphql.ApplicantSortMethod.TotalScore]: Graphql.ApplicantSortMethod.TotalScore,
};

export const convertStringToSortFieldValue = (value: string | null): CompetitionHistory.SortParams => {
  if (value === null) return CompetitionHistory.DEFAULT_SORT_PARAMS;
  try {
    // "sortField+isDesc" (e.g.) "CREATED_AT-true"
    const [field, isDesc] = value.split("-");
    const sortField = SortMethodReverseMap[field] ?? CompetitionHistory.DEFAULT_SORT_PARAMS.sortMethod;
    return {
      sortMethod: sortField,
      isDescending: isDesc === "true",
    };
  } catch (_) {
    return CompetitionHistory.DEFAULT_SORT_PARAMS;
  }
};

export const convertStringToPage = (value: string | null): number => {
  if (value === null) return 0;
  const page = Number(value);
  // Set it to 0 if it is invalid (NaN or negative). Note that page > 0 is false if page is NaN.
  // Actual (zero-index) page number = (page number from the query param) - 1
  if (!Number.isInteger(page) || page < 1) {
    return 0;
  }
  return page - 1;
};

const DEFAULT_SIZE = CompetitionHistory.DEFAULT_PAGER.size;
const SIZES = [20, 30, 50];
export const convertStringToSize = (value: string | null) => {
  if (value === null) return DEFAULT_SIZE;
  const size = Number(value);
  // Set it to the default value if it is invalid.
  if (!SIZES.includes(size)) {
    return DEFAULT_SIZE;
  }
  return size;
};
