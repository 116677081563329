import { add, differenceInSeconds, format, formatDistance, formatDuration, isBefore, secondsToMinutes } from "date-fns";
import fromUnixTime from "date-fns/fromUnixTime";
import { enUS, ja } from "date-fns/locale";

import { type Language, translation } from "./language";

export * from "./constants";
export { remainingTimeFormat } from "./remainingTimeFormat";
export { secondsToTimeObject, type TimeObject } from "./secondsToTimeObject";

export const Unit = {
  SECOND: 0,
  MILLISECOND: 1,
  MICROSECOND: 2,
  NANOSECOND: 3,
} as const;

export type Unit = (typeof Unit)[keyof typeof Unit];

const UNIT_LIST = ["s", "ms", "μs", "ns"] as const;

const nextUnitMap = {
  [Unit.SECOND]: Unit.SECOND, // Max
  [Unit.MILLISECOND]: Unit.SECOND,
  [Unit.MICROSECOND]: Unit.MILLISECOND,
  [Unit.NANOSECOND]: Unit.MICROSECOND,
} as const;

const getNextUnit = (unit: Unit): Unit => {
  if (unit in nextUnitMap) {
    return nextUnitMap[unit];
  }
  return Unit.SECOND;
};

export const getTimeUnitTextAsFloat = (value: number, unit: Unit, decimalPlace: number): string => {
  if (unit <= Unit.SECOND || value < 1000) {
    return `${value.toFixed(decimalPlace)} ${UNIT_LIST[unit]}`;
  }

  return getTimeUnitTextAsFloat(value / 1000, getNextUnit(unit), decimalPlace);
};

export const getTimeUnitText = (value: number, unit: Unit): string => {
  if (unit <= Unit.SECOND || value < 1000) {
    return `${Math.floor(value)} ${UNIT_LIST[unit]}`;
  }

  return getTimeUnitText(value / 1000, getNextUnit(unit));
};

export const formatTime = (value: number, unit: Unit): string => {
  if (value < 1000) {
    return `${Math.floor(value)}${UNIT_LIST[unit]}`;
  }
  const nextUnit = getNextUnit(unit);
  if (nextUnit === unit) {
    return `${Math.floor(value)}${UNIT_LIST[unit]}`;
  }
  return formatTime(value / 1000, getNextUnit(unit));
};

export const formatSeconds = (seconds: number, language: Language): string => {
  if (seconds >= 60) {
    const minutes = Math.floor(seconds / 60);
    const sec = seconds % 60;
    if (sec <= 0) {
      return `${minutes}${translation.minutes[language]}`;
    }
    return `${minutes}${translation.minutes[language]} ${Math.round(sec)}${translation.seconds[language]} `;
  }
  return `${Math.round(seconds)} ${translation.seconds[language]}`;
};

export const formatMinutes = (minutes: number, language: Language): string => {
  if (minutes >= 60) {
    const hour = Math.floor(minutes / 60);
    const min = minutes % 60;
    if (min <= 0) {
      return `${hour}${translation.hours[language]}`;
    }
    return `${hour}${translation.hours[language]} ${Math.round(min)}${translation.minutes[language]}`;
  }
  return `${Math.round(minutes)}${translation.minutes[language]}`;
};

/**
 * @returns yyyy/MM/dd HH:mm
 */
export const datetimeFormat = (updatedAt: Date): string => {
  return format(new Date(updatedAt), "yyyy/MM/dd HH:mm");
};

/**
 * @returns yyyy/MM/dd HH:mm
 */
export const unixTimeSecondsToFormat = (unixTimeSeconds: number): string => {
  return format(new Date(unixTimeSeconds * 1000), "yyyy/MM/dd HH:mm");
};

/**
 * @returns yyyy/MM/dd HH:mm:ss
 */
export const unixTimeMilliSecondsToFormat = (unixTimeMilliSeconds: number, fmt: "yyyy/MM/dd HH:mm:ss" | "HH:mm:ss"): string => {
  return format(new Date(unixTimeMilliSeconds), fmt);
};

export const unixToDateFormat = (unixTimeSeconds: number): string => {
  return datetimeFormat(fromUnixTime(unixTimeSeconds));
};

const localeMap: Record<Language, Locale> = {
  ja: ja,
  en: enUS,
};

export const formatTimeDistance = (origin: Date, now: Date, language: Language): string => {
  return formatDistance(origin, now, {
    locale: localeMap[language],
    addSuffix: true,
  });
};

export const formatDurationSeconds = (durationSeconds: number, language: Language): string => {
  const ONE_DAY_SECONDS = 86400;
  const ONE_HOURS_SECONDS = 3600;
  const ONE_MINUTES_SECONDS = 60;
  const ONE_SECONDS = 60;
  return formatDuration(
    {
      days: durationSeconds >= ONE_DAY_SECONDS ? Math.floor(durationSeconds / ONE_DAY_SECONDS) : undefined,
      hours:
        ONE_DAY_SECONDS > durationSeconds && durationSeconds >= ONE_HOURS_SECONDS ? Math.floor(durationSeconds / ONE_HOURS_SECONDS) : undefined,
      minutes:
        ONE_HOURS_SECONDS > durationSeconds && durationSeconds >= ONE_MINUTES_SECONDS
          ? Math.floor(durationSeconds / ONE_MINUTES_SECONDS)
          : undefined,
      seconds: ONE_SECONDS > durationSeconds && durationSeconds > 0 ? durationSeconds : undefined,
    },
    {
      locale: localeMap[language],
    },
  );
};

/**
 * @returns YYYY/MM/dd
 */
export const dateWithYearFormat = (date: Date): string => {
  return format(new Date(date), "yyyy/MM/dd");
};

/**
 * @returns YYYY/MM/dd
 */
export const unixToDatetimeFormat = (unixTimeSeconds: number): string => {
  return dateWithYearFormat(fromUnixTime(unixTimeSeconds));
};

/**
 * @returns YYYY/MM
 */
export const yearMonthFormat = (
  date: Date,
  option?: {
    noZeroPadding?: boolean;
  },
): string => {
  const pattern = option?.noZeroPadding ? "yyyy/M" : "yyyy/MM";
  return format(new Date(date), pattern);
};

/**
 * @returns YYYY/MM
 */
export const unixToYearMonthFormat = (unixTimeSeconds: number): string => {
  return yearMonthFormat(fromUnixTime(unixTimeSeconds));
};

export const dateFormat = (date: Date): string => {
  return format(new Date(date), "M/d");
};

export const timeFormat = (date: Date): string => {
  return format(new Date(date), "HH:mm");
};

export const calculateTimeLimit = (willEndAtSeconds: number, timeLimitSeconds: number): number => {
  const now = new Date();
  const willFinishDate = add(now, { seconds: timeLimitSeconds ?? 0 });
  const willEndAtDate = new Date((willEndAtSeconds ?? 0) * 1000);

  const comparingDate = isBefore(willEndAtDate, willFinishDate) ? willEndAtDate : willFinishDate;
  return secondsToMinutes(differenceInSeconds(comparingDate, now));
};

export const elapsedTimeFormat = (timeInMs: number): string => {
  const maxLength = 2;
  const fillString = "0";
  const hour = Math.floor(timeInMs / 1000 / 60 / 60) % 60;
  const elapsedTime = hour > 0 ? `${hour.toString().padStart(maxLength, fillString)}:` : "";
  const minutesInString = (Math.floor(timeInMs / 1000 / 60) % 60).toString().padStart(maxLength, fillString);
  const secondsInString = (Math.floor(timeInMs / 1000) % 60).toString().padStart(maxLength, fillString);

  return `${elapsedTime}${minutesInString}:${secondsInString}`;
};

export const secondsToFormatMinutes = (seconds: number, language: Language) => {
  return formatMinutes(secondsToMinutes(seconds), language);
};

export const formatMillisecondToHumanTime = (milliseconds: number): string => {
  const seconds = Math.floor(milliseconds / 1000);
  const minutes = Math.floor(seconds / 60);
  const remainSeconds = seconds % 60;
  if (minutes > 0) {
    return `${minutes}m ${remainSeconds}s`;
  }
  return `${seconds}s`;
};

/**
 * Formats seconds into a human-readable string with translation
 * @param seconds - Number of seconds to format (should be non-negative)
 * @param language - Language code ('ja' | 'en')
 * @returns Formatted string
 * @example
 * // Japanese (ja)
 * formatSecondsToHumanTimeWithTranslation(3665, 'ja') // => '1時間 1分 5秒'
 * formatSecondsToHumanTimeWithTranslation(65, 'ja')   // => '1分 5秒'
 * formatSecondsToHumanTimeWithTranslation(5, 'ja')    // => '5秒'
 * // English (en)
 * formatSecondsToHumanTimeWithTranslation(3665, 'en') // => '1h 1m 5s'
 * formatSecondsToHumanTimeWithTranslation(65, 'en')   // => '1m 5s'
 * formatSecondsToHumanTimeWithTranslation(5, 'en')    // => '5s'
 */
export const formatSecondsToHumanTimeWithTranslation = (seconds: number, language: Language): string => {
  if (seconds < 0) {
    return `0${translation.seconds[language]}`;
  }

  const minutes = Math.floor(seconds / 60);
  const remainSeconds = seconds % 60;
  const hours = Math.floor(minutes / 60);
  const remainMinutes = minutes % 60;

  if (hours > 0) {
    return `${hours}${translation.hours[language]} ${remainMinutes}${translation.minutes[language]} ${remainSeconds}${translation.seconds[language]}`;
  }
  if (remainMinutes > 0) {
    return `${remainMinutes}${translation.minutes[language]} ${remainSeconds}${translation.seconds[language]}`;
  }
  return `${remainSeconds}${translation.seconds[language]}`;
};
