import { state } from "./State";
import type * as Types from "./types";

export const initialize = (res: Types.Response): void => {
  res.metrics.forEach(metric => {
    state.metricMap.set(metric.metricId, metric);
  });
  state.pagination = {
    ...state.pagination,
    pager: res.pager,
    count: res.count,
  };
  /**
   * Refresh key to reset the form state
   */
  refreshKey();
  resetSelectedMetricIds();
};

export const clear = (): void => {
  state.metricMap.clear();
  state.pagination = {
    size: 20,
    page: 0,
    count: 0,
    pager: null,
    nextPager: null,
  };
  resetSelectedMetricIds();
};

export const resetSelectedMetricIds = () => {
  state.selectedMetricIds = [];
};

export const refreshKey = () => {
  state.refreshKey = Math.random().toString(36);
};

export const updatePager = (): void => {
  if (state.pagination.pager?.hasNext) {
    state.pagination.nextPager = state.pagination.pager;
  }
};

export const updatePage = (page: number) => {
  state.pagination.page = page;
  updatePager();
  refreshKey();
};

export const updateSize = (size: number) => {
  state.pagination.size = size;
  /**
   * reset page on size change
   */
  state.pagination.page = 0;
};

export const setSelectedMetricIds = (selectedMetricIds: number[]) => {
  state.selectedMetricIds = selectedMetricIds;
};
