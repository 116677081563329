import { App } from "@hireroo/app-store/essential/employee";
import { CandidatesIdDetail } from "@hireroo/app-store/page/e/candidates_id_detail";
import { CompetitionHistory } from "@hireroo/app-store/widget/e/CompetitionHistory";
import * as Graphql from "@hireroo/graphql/client/urql";
import { Widget } from "@hireroo/presentation";
import { withErrorBoundary } from "@sentry/react";
import * as React from "react";
import { useParams } from "react-router-dom";

import CandidateDetailContainer, { CandidateDetailContainerProps } from "./Container";

export type CandidateDetailFetchContainerProps = {};

const CandidateDetailFetchContainer: React.FC<CandidateDetailFetchContainerProps> = () => {
  const appStatus = App.useStatus();
  const { id } = useParams<{ id: string }>();
  const candidateId = `${id}`;
  const initialized = CandidatesIdDetail.useInitialized();

  const [result, refresh] = Graphql.useGetCandidateForEmployerCandidateDetailQuery({
    variables: {
      candidateId: candidateId,
    },
    pause: appStatus !== "INITIALIZED",
    requestPolicy: "cache-and-network",
  });

  React.useEffect(() => {
    if (result.data?.candidate) {
      CandidatesIdDetail.setCandidate(result.data.candidate);
    }
  }, [result.data]);

  React.useEffect(() => {
    return () => {
      CandidatesIdDetail.clear();
      CompetitionHistory.clear();
    };
  }, []);

  if (result.error) {
    return <Widget.RefreshPanel refresh={refresh} />;
  }
  if (result.fetching || appStatus !== "INITIALIZED" || !initialized) {
    return <Widget.Loading kind="CENTER_%" />;
  }

  const containerProps: CandidateDetailContainerProps = {};

  return <CandidateDetailContainer {...containerProps} />;
};

CandidateDetailFetchContainer.displayName = "CandidateDetailFetchContainer";

export default withErrorBoundary(CandidateDetailFetchContainer, {});
