import RankingFirst from "@hireroo/ui-assets/images/Ranking/RankingFirst.svg";
import RankingSecond from "@hireroo/ui-assets/images/Ranking/RankingSecond.svg";
import RankingThird from "@hireroo/ui-assets/images/Ranking/RankingThird.svg";
import Avatar from "@mui/material/Avatar";
import Box, { BoxProps } from "@mui/material/Box";
import ButtonBase from "@mui/material/ButtonBase";
import Stack, { StackProps } from "@mui/material/Stack";
import { styled, useTheme } from "@mui/material/styles";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import * as React from "react";

import SuspiciousDegree, { SuspiciousDegreeProps } from "../../../../ms-components/Suspicious/SuspiciousDegree/SuspiciousDegree";
import Link, { LinkProps } from "../../../../primitive/Link/Link";
import ApplicantPreview, { ApplicantPreviewProps } from "../ApplicantPreview/ApplicantPreview";

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  borderTop: "1px solid",
  borderBottom: "1px solid",
  borderColor: theme.palette.Other.Divider,
  display: "table-row",
}));

const CustomTableRow: React.FC<LinkProps> = props => {
  // FIXME I would like to ask the mui team how to define this type definition accurately.
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return <ButtonBase as={Link} {...props} disableRipple />;
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  border: "none",
  align: "center",
  whiteSpace: "nowrap",
  wordBreak: "keep-all",
  display: "table-cell",
  paddingTop: theme.spacing(1.5),
  paddingBottom: theme.spacing(1.5),
  paddingRight: theme.spacing(2),
  paddingLeft: theme.spacing(2),
}));

const StyledChip = styled(Box)(() => ({
  display: "flex",
  borderRadius: "20px",
  alignItems: "center",
  justifyContent: "center",
  height: "22px",
  fontSize: "12px",
}));

type User = {
  name: string;
  imgUrl?: string;
};

const TOP_THREE_RANKING = [1, 2, 3];

type StatusColor = "PRIMARY" | "DEFAULT";

type Status = {
  text: string;
  color: StatusColor;
};

export type CompetitionApplicantTableRowProps = {
  id: string;
  user: User;
  href: string;
  onClick: () => void;
  elapsedTime: string;
  ranking?: number;
  score: string;
  status: Status;
  disabled?: boolean;
  suspiciousDegree?: SuspiciousDegreeProps;
  applicantPreview: Omit<ApplicantPreviewProps, "popover">;
};

const CompetitionApplicantTableRow: React.FC<CompetitionApplicantTableRowProps> = props => {
  const theme = useTheme();
  const [openedPopover, setOpenedPopover] = React.useState(false);
  const popoverAnchor = React.useRef(null);

  const statusChipMap: Record<StatusColor, BoxProps> = {
    PRIMARY: {
      bgcolor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
    DEFAULT: {
      bgcolor: theme.palette["Action"]["Disabled Background"].p12,
      color: theme.palette.text.primary,
    },
  };

  const handlePopoverOpen = () => {
    setOpenedPopover(true);
  };

  const handlePopoverClose = () => {
    setOpenedPopover(false);
  };

  const popoverId = `applicant-preview-popover-${props.id}`;

  const nameStackProps: StackProps = {
    "aria-owns": openedPopover ? popoverId : undefined,
    "aria-haspopup": "true",
    onMouseEnter: handlePopoverOpen,
    onMouseLeave: handlePopoverClose,
  };
  const applicantPreviewProps: ApplicantPreviewProps = {
    ...props.applicantPreview,
    popover: {
      id: popoverId,
      open: openedPopover,
      anchorEl: popoverAnchor.current,
      onClose: handlePopoverClose,
      PaperProps: {
        onMouseEnter: handlePopoverOpen,
        onMouseLeave: handlePopoverClose,
      },
    },
  };

  return (
    <StyledTableRow
      as={CustomTableRow}
      // FIXME I would like to ask the mui team how to define this type definition accurately.
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      component={"a"}
      href={props.href}
      sx={{
        cursor: props.disabled ? "unset" : "cursor",
      }}
      onClick={event => {
        event.stopPropagation();
        event.preventDefault();
        props.onClick?.();
      }}
    >
      <StyledTableCell component="div" align="center" sx={{ width: "38px" }}>
        {props.ranking === 1 && <img src={RankingFirst} alt="RankingFirst" />}
        {props.ranking === 2 && <img src={RankingSecond} alt="RankingSecond" />}
        {props.ranking === 3 && <img src={RankingThird} alt="RankingThird" />}

        {(props.ranking && !TOP_THREE_RANKING.includes(props.ranking) && props.ranking) ?? "-"}
      </StyledTableCell>
      <StyledTableCell component="div">
        <Stack direction="row" alignItems="center" spacing={2} {...nameStackProps} ref={popoverAnchor}>
          <Avatar alt={props.user.name} sx={{ width: "32px", height: "32px" }} src={props.user.imgUrl} />
          <Typography>{props.user.name}</Typography>
          <ApplicantPreview {...applicantPreviewProps} />
        </Stack>
      </StyledTableCell>
      <StyledTableCell component="div" sx={{ width: "100px" }}>
        <StyledChip {...statusChipMap[props.status.color]}>{props.status.text}</StyledChip>
      </StyledTableCell>
      <StyledTableCell component="div" align="center" sx={{ width: "100px" }}>
        <Typography fontSize={12} color="textSecondary">
          {props.elapsedTime}
        </Typography>
      </StyledTableCell>
      <StyledTableCell component="div" align="center" sx={{ width: "100px" }}>
        <Typography>{props.score}</Typography>
      </StyledTableCell>
      <StyledTableCell component="div" align="center" sx={{ width: "100px" }}>
        {props.suspiciousDegree && <SuspiciousDegree {...props.suspiciousDegree} />}
      </StyledTableCell>
      <StyledTableCell component="div" align="right"></StyledTableCell>
    </StyledTableRow>
  );
};

CompetitionApplicantTableRow.displayName = "CompetitionApplicantTableRow";

export default CompetitionApplicantTableRow;
