import { App } from "@hireroo/app-store/essential/employee";
import { CompetitionResultDetail } from "@hireroo/app-store/widget/e/CompetitionResultDetail";
import * as Graphql from "@hireroo/graphql/client/urql";
import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import React from "react";

import CompetitionResultDetailContainer, { CompetitionResultDetailContainerProps } from "./Container";

export type CompetitionResultDetailFetchContainerProps = {
  resultId: string;
  Report: React.ReactNode;
};

const CompetitionResultDetailFetchContainer: React.FC<CompetitionResultDetailFetchContainerProps> = props => {
  const appStatus = App.useStatus();
  const initialized = CompetitionResultDetail.useInitialized();
  const [result, refresh] = Graphql.useGetResultForCompetitionResultDetailQuery({
    variables: {
      id: props.resultId,
    },
    pause: appStatus !== "INITIALIZED",
    requestPolicy: "cache-and-network",
  });
  React.useEffect(() => {
    return () => {
      CompetitionResultDetail.clear();
    };
  }, []);

  React.useEffect(() => {
    if (result.data?.result) {
      CompetitionResultDetail.initialize(result.data.result);
    }
  }, [result.data]);

  if (result.error) {
    return <Widget.RefreshPanel refresh={refresh} />;
  }
  if (result.fetching || appStatus !== "INITIALIZED" || !initialized) {
    return <Widget.Loading kind="TOP" />;
  }

  const containerProps: CompetitionResultDetailContainerProps = {
    Report: props.Report,
  };
  return (
    <ErrorBoundary>
      <CompetitionResultDetailContainer {...containerProps} />
    </ErrorBoundary>
  );
};

CompetitionResultDetailFetchContainer.displayName = "CompetitionResultDetailFetchContainer";

export default withErrorBoundary(CompetitionResultDetailFetchContainer, {});
