import { DEFAULT_SEARCH_FILTER } from "./constants";
import { state } from "./State";
import type * as Types from "./types";

export const initializeFilters = (filter: Types.SearchFilter, pager: Omit<Types.Pager, "offset">) => {
  state.currentSearchFilter = filter;
  state.pager = {
    ...pager,
    offset: pager.page * pager.size,
  };

  state.initializedSearchFilter = true;
};

export const updateCurrentSearchFilter = (filter: Types.SearchFilter) => {
  state.currentSearchFilter = filter;
  // Reset page when search filter is updated
  state.pager = {
    ...state.pager,
    page: 0,
    offset: 0,
  };
};

export const clear = (): void => {
  state.res = null;
  state.candidateMap.clear();
  state.currentSearchFilter = DEFAULT_SEARCH_FILTER;
  state.initializedSearchFilter = false;
};

export const setResponse = (res: Types.CandidatesResponse): void => {
  state.res = res;

  state.candidateIds = res.candidates.map(candidate => candidate.candidateId);

  res.candidates.forEach(candidate => {
    state.candidateMap.set(candidate.candidateId, candidate);
  });
};

export const updatePage = (page: number) => {
  state.pager = {
    ...state.pager,
    page,
    offset: page * state.pager.size,
  };
};

export const updatePageSize = (size: number) => {
  state.pager = {
    ...state.pager,
    size: size,
    // Reset page when page size is updated
    page: 0,
    offset: 0,
  };
};

export const updateSortParams = (sortParams: Types.SortParams) => {
  state.pager = {
    ...state.pager,
    sortParams,
    // Reset page when sortParams is updated
    page: 0,
    offset: 0,
  };
};
