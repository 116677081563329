import { state } from "./State";
import type * as Types from "./types";

export const initialize = (newValue: Types.Result): void => {
  state.result = newValue;
};

export const clear = () => {
  state.result = null;
};
