import { colorFromUserId } from "@hireroo/app-helper/color";
import { RemoteInterviewPlayback } from "@hireroo/app-store/widget/e/RemoteInterviewPlayback";
import { useTranslation } from "@hireroo/i18n";
import type { Widget } from "@hireroo/presentation";
import { FlowElement } from "@hireroo/system-design/features";
import { elementsFromFirebase, parseFlowChartSnapshot, userStateFromFirebase } from "@hireroo/system-design/helpers/flowChart";
import { useSystemDesignContext } from "@hireroo/system-design/react/FlowChart";
import * as React from "react";

export type GenerateRemoteInterviewStaticFlowChartPropsArgs = {};

export const useGenerateProps = (_args: GenerateRemoteInterviewStaticFlowChartPropsArgs): Widget.RemoteInterviewStaticFlowChartProps => {
  const { t } = useTranslation();
  const revisionIndex = RemoteInterviewPlayback.useSliderValue();
  const histories = RemoteInterviewPlayback.useSystemDesignRevisions();

  const participantMap = RemoteInterviewPlayback.useParticipantMap();
  const { hooks } = useSystemDesignContext();
  const componentType = hooks.useComponentType();

  const activeSession = RemoteInterviewPlayback.useActiveSession();

  const initialElements: FlowElement[] = React.useMemo(() => {
    if (!activeSession) return [];

    // コーディングテストから引き継いだ提出結果があるときはそちらを優先
    if (activeSession.questionExtras?.__typename === "LiveCodingSystemDesignQuestionExtras" && activeSession.questionExtras.initialFlowChart) {
      const { ok, result } = parseFlowChartSnapshot(activeSession.questionExtras.initialFlowChart);
      return ok ? result.elements : [];
    }

    // それ以外の場合は問題に設定されている初期配置を使用
    if (activeSession.systemDesignQuestion?.initialSnapshot) {
      const { ok, result } = parseFlowChartSnapshot(activeSession.systemDesignQuestion.initialSnapshot);
      return ok ? result.elements : [];
    }

    return [];
  }, [activeSession]);

  const elements = React.useMemo((): FlowElement[] => {
    if (!histories) return [];
    const { elements, elementIds } = elementsFromFirebase(histories, componentType, initialElements, revisionIndex);
    return elementIds.map(id => elements[id]);
  }, [histories, componentType, initialElements, revisionIndex]);

  const participant = React.useMemo(() => {
    if (!histories) return null;
    const history = histories[revisionIndex];
    return participantMap.get(history?.a) ?? null;
  }, [histories, participantMap, revisionIndex]);

  const authors = React.useMemo(() => {
    if (!histories) return [];
    const history = histories[revisionIndex];
    if (!history || !participant) return [];

    const userState = userStateFromFirebase(history);
    userState.uid = participant.userId;
    userState.name = participant.userName || t("匿名ユーザー");
    userState.color = colorFromUserId(participant.userId);
    return [userState];
  }, [histories, participant, revisionIndex, t]);

  const lastElements = React.useMemo((): FlowElement[] => {
    if (!histories) return [];
    const { elements, elementIds } = elementsFromFirebase(histories, componentType, initialElements);
    return elementIds.map(id => elements[id]);
  }, [histories, componentType, initialElements]);

  return {
    staticFlowChart: {
      elements: elements,
      componentType: componentType,
      authors: authors,
    },
    lastElements: lastElements,
  };
};
