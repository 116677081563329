import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { useGenerateProps } from "./useGenerateProps";

export type CandidateSearchAreaContainerProps = {};

const CandidateSearchAreaContainer: React.FC<CandidateSearchAreaContainerProps> = () => {
  const candidateSearchAreaProps = useGenerateProps({});
  return (
    <ErrorBoundary>
      <Widget.CandidateSearchArea {...candidateSearchAreaProps} />
    </ErrorBoundary>
  );
};

CandidateSearchAreaContainer.displayName = "CandidateSearchAreaContainer";

export default withErrorBoundary(CandidateSearchAreaContainer, {});
