import { useTranslation } from "@hireroo/i18n";
import { TestSearchForm as TestSearchFormValidator } from "@hireroo/validator";

import { SearchFormProps } from "../../../../modules/SearchForm/SearchForm";

type FieldProps = SearchFormProps["fields"][0];
export type MultiChoiceFieldProps = Extract<
  FieldProps,
  {
    kind: "MULTI_CHOICE";
  }
>;
export type SingleChoiceFieldProps = Extract<
  FieldProps,
  {
    kind: "SINGLE_CHOICE";
  }
>;
export type GroupMultiChoiceFieldProps = Extract<
  FieldProps,
  {
    kind: "GROUP_MULTI_CHOICE";
  }
>;
export type RangeFieldProps = Extract<
  FieldProps,
  {
    kind: "RANGE";
  }
>;
export type TagFieldProps = Extract<
  FieldProps,
  {
    kind: "TAG";
  }
>;

type FieldKeyName = keyof TestSearchFormValidator.TestSearchQuerySchema;

const FieldName = {
  TEXT_FILTER: "textFilter",
  CREATED_BY: "createdBy",
  SCREENING_IDS: "screeningIds",
  TAGS: "tags",
  SCORE: "score",
  QUESTION_VARIANTS: "questionVariants",
  STATUSES: "statuses",
  RESULT: "result",
  RANK: "rank",
  SUSPICIOUS_DEGREES: "suspiciousDegrees",
} satisfies Record<string, FieldKeyName>;

export const useScreeningField = (props: Pick<MultiChoiceFieldProps, "options">): MultiChoiceFieldProps => {
  const { t } = useTranslation();
  return {
    ...props,
    kind: "MULTI_CHOICE",
    name: FieldName.SCREENING_IDS,
    title: t("コーディングテスト"),
  };
};

type TextFilterFieldArgs = {
  enabledScreeningFeature: boolean;
};

export const useTextFilterField = (args: TextFilterFieldArgs): SearchFormProps["textFilter"] => {
  const { t } = useTranslation();
  return {
    name: FieldName.TEXT_FILTER,
    placeholder: args.enabledScreeningFeature ? t("候補者名、メールアドレスを検索") : t("タイトルを検索"),
  };
};

export const useTagsField = (props: Pick<TagFieldProps, "selectableTags">): TagFieldProps => {
  const { t } = useTranslation();
  return {
    ...props,
    kind: "TAG",
    name: FieldName.TAGS,
    title: t("タグ"),
  };
};
