import { useTranslation } from "@hireroo/i18n";
import { ShareRounded } from "@mui/icons-material";
import KeyboardArrowRightOutlinedIcon from "@mui/icons-material/KeyboardArrowRightOutlined";
import Button, { ButtonProps } from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { styled, useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";

import ButtonWithTooltip, { ButtonWithTooltipProps } from "../../../primitive/Button/ButtonWithTooltip/ButtonWithTooltip";
import IconButtonWithTooltip, { IconButtonWithTooltipProps } from "../../../primitive/Button/IconButtonWithTooltip/IconButtonWithTooltip";
import MoreButton, { MoreButtonProps } from "../../../primitive/Button/MoreButton/MoreButton";

const TextButton = styled(Button)(({ theme }) => ({
  lineHeight: "22px",
  minHeight: "26px",
  textAlign: "left",
  textTransform: "none",
  textOverflow: "ellipsis",
  "& .MuiButton-root": {
    textAlign: "left",
    flexShrink: 0,
    flexGrow: 0,
  },
  "& .MuiButton-endIcon": {
    marginLeft: theme.spacing(0.5),
  },
  paddingLeft: theme.spacing(0),
  alignItems: "center",
  justifyContent: "flex-start",
}));

const WrapperStack = styled(Stack)(({ theme }) => ({
  paddingTop: theme.spacing(2),
  paddingRight: theme.spacing(3),
  paddingLeft: theme.spacing(3),
  paddingBottom: theme.spacing(1),
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
}));

const LeftSideStack = styled(Stack)(() => ({
  display: "flex",
  justifyContent: "center",
}));

const RightSideStack = styled(Stack)(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

export type DetailPageHeaderProps = {
  className?: string;
  screeningButton?: Pick<ButtonProps, "onClick" | "children">;
  candidateName: string;
  candidateEmail?: string;
  shareButton?: Pick<ButtonWithTooltipProps, "onClick" | "title" | "disabled">;
  createInterviewButton?: Pick<ButtonWithTooltipProps, "onClick" | "disabled" | "title">;
  CreateInterviewDialog?: React.ReactNode;
  reviewButton?: Pick<ButtonWithTooltipProps, "onClick" | "disabled">;
  evaluateButton?: Pick<ButtonWithTooltipProps, "onClick" | "disabled" | "title">;
  moreButton: Pick<MoreButtonProps, "options">;
};

const DetailPageHeader: React.FC<DetailPageHeaderProps> = props => {
  const { t } = useTranslation();
  const theme = useTheme();
  const screeningButton: ButtonProps | undefined = props.screeningButton && {
    ...props.screeningButton,
    color: "outline-only",
    variant: "text",
    size: "small",
    endIcon: <KeyboardArrowRightOutlinedIcon fontSize="small" />,
  };
  const moreButton: MoreButtonProps = {
    ...props.moreButton,
    color: theme.palette.text.primary,
    variant: "vertical",
  };
  const reviewButton: ButtonWithTooltipProps | undefined = props.reviewButton && {
    ...props.reviewButton,
    startIcon: "RATE_REVIEW",
    variant: "outlined",
    children: t("技術レビュー"),
  };
  const shareButton: IconButtonWithTooltipProps | undefined = props.shareButton && {
    ...props.shareButton,
    sx: {
      color: theme.palette.text.primary,
    },
    children: <ShareRounded color="inherit" />,
  };
  const evaluateButton: ButtonWithTooltipProps | undefined = props.evaluateButton && {
    ...props.evaluateButton,
    startIcon: "CHECK",
    variant: "contained",
    children: t("最終評価"),
  };
  const createInterviewButton: ButtonWithTooltipProps | undefined = props.createInterviewButton && {
    ...props.createInterviewButton,
    startIcon: "VIDEO",
    variant: "outlined",
    color: "outline-only",
    children: t("続きからインタビュー作成"),
  };

  return (
    <WrapperStack className={props.className} direction="row">
      <LeftSideStack direction="column">
        {screeningButton && <TextButton {...screeningButton} />}
        <Stack direction="row" spacing={1} alignItems="center">
          <Typography fontSize={20} fontWeight={700}>
            {props.candidateName}
          </Typography>
          <Typography fontSize={14} color="textSecondary">
            {props.candidateEmail}
          </Typography>
        </Stack>
      </LeftSideStack>
      <RightSideStack direction="row" spacing={1}>
        {createInterviewButton && <ButtonWithTooltip {...createInterviewButton} />}
        {shareButton && <IconButtonWithTooltip {...shareButton} />}
        {reviewButton && <ButtonWithTooltip {...reviewButton} />}
        {evaluateButton && <ButtonWithTooltip {...evaluateButton} />}
        <MoreButton {...moreButton} />
      </RightSideStack>
      {props.CreateInterviewDialog}
    </WrapperStack>
  );
};

DetailPageHeader.displayName = "DetailPageHeader";

export default DetailPageHeader;
