import { App } from "@hireroo/app-store/essential/candidate";
import { CompetitionsResultsId } from "@hireroo/app-store/page/c/competitions_results_id";
import * as Graphql from "@hireroo/graphql/client/urql";
import { Widget } from "@hireroo/presentation";
import { withErrorBoundary } from "@sentry/react";
import React from "react";
import { useParams } from "react-router-dom";

import ErrorPanelContainer from "../../../../widget/v2/c/ErrorPanel/Container";
import CompetitionsResultsIdContainer, { CompetitionsResultsIdContainerProps } from "./Container";

export type CompetitionsResultsIdFetchContainerProps = {};

const CompetitionResultsIdFetchContainer: React.FC<CompetitionsResultsIdFetchContainerProps> = () => {
  const appStatus = App.useStatus();
  const { id } = useParams<{ id: string }>();
  const [resultId, setResultId] = React.useState<string>(id || "");

  const initialized = CompetitionsResultsId.useInitialized();
  const [result] = Graphql.useGetResultForCompetitionsResultsIdQuery({
    variables: {
      id: `${id}`,
    },
    requestPolicy: "network-only",
    pause: appStatus !== "INITIALIZED" || initialized,
  });

  React.useEffect(() => {
    if (result.data) {
      setResultId(result.data.result.resultId);
      CompetitionsResultsId.setResult(result.data.result);
    }
  }, [result.data]);

  if (result.error) {
    return <ErrorPanelContainer graphqlErrors={result.error.graphQLErrors} />;
  }

  if (result.fetching || appStatus !== "INITIALIZED" || !initialized) {
    return <Widget.Loading kind="CENTER" color="secondary" />;
  }

  const containerProps: CompetitionsResultsIdContainerProps = {
    resultId: resultId,
  };
  return <CompetitionsResultsIdContainer {...containerProps} />;
};

CompetitionResultsIdFetchContainer.displayName = "CompetitionResultsIdFetchContainer";

export default withErrorBoundary(CompetitionResultsIdFetchContainer, {});
