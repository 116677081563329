import * as Graphql from "@hireroo/graphql/client/urql";
import { useTranslation } from "@hireroo/i18n";

export const useSystemDesignFollowUpQuestionCategoryMap = (): Record<Graphql.SystemDesignFollowUpQuestionCategory, string> => {
  const { t } = useTranslation();

  return {
    [Graphql.SystemDesignFollowUpQuestionCategory.Unknown]: t("不明"),
    [Graphql.SystemDesignFollowUpQuestionCategory.Availability]: t("可用性"),
    [Graphql.SystemDesignFollowUpQuestionCategory.Performance]: t("パフォーマンス"),
    [Graphql.SystemDesignFollowUpQuestionCategory.Scalability]: t("スケーラビリティ"),
  };
};
