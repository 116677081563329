import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import * as React from "react";

export type SectionProps = {
  title?: string;
  children: React.ReactNode;
};

const Section: React.FC<SectionProps> = props => {
  return (
    <Stack spacing={0.5}>
      {props.title && (
        <Typography color="textSecondary" fontSize={12} variant="caption">
          {props.title}
        </Typography>
      )}
      <Stack>{props.children}</Stack>
    </Stack>
  );
};

Section.displayName = "Section";

export default Section;
