import Box from "@mui/material/Box";
import * as React from "react";

import TablePagination, { TablePaginationProps } from "../../modules/TablePagination/TablePagination";
import ScreeningTestListTable, { ScreeningTestListTableProps } from "./parts/ScreeningTestListTable/ScreeningTestListTable";
import SearchResultBar, { SearchResultBarProps } from "./parts/SearchResultBar/SearchResultBar";

export type ScreeningTestListSingleChoiceProps = {
  screeningTestListTable: ScreeningTestListTableProps;
  searchResultBar: SearchResultBarProps;
  tablePagination: TablePaginationProps;
  refreshKey: string;
};

const ScreeningTestListSingleChoice: React.FC<ScreeningTestListSingleChoiceProps> = props => {
  return (
    <Box>
      <SearchResultBar {...props.searchResultBar} />
      <ScreeningTestListTable key={`${props.refreshKey}`} {...props.screeningTestListTable} />
      <Box display="flex" justifyContent="flex-end">
        <TablePagination {...props.tablePagination} />
      </Box>
    </Box>
  );
};

ScreeningTestListSingleChoice.displayName = "ScreeningTestListSingleChoice";

export default ScreeningTestListSingleChoice;
