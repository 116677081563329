import { useTranslation } from "@hireroo/i18n";
import AddIcon from "@mui/icons-material/Add";
import Box from "@mui/material/Box";
import Button, { type ButtonProps } from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import * as React from "react";

import MetricItemTable, { MetricItemTableProps } from "../../ms-components/Evaluation/MetricItemTable/MetricItemTable";
import DialogWithCloseIcon, { DialogWithCloseIconProps } from "../../primitive/DialogWithCloseIcon/DialogWithCloseIcon";

type DialogController = {
  close: () => void;
  setLoading: (loading: boolean) => void;
};

export type EvaluationMetricsSectionProps = {
  selectedMetrics?: Omit<MetricItemTableProps, "enableCheckbox" | "enableActionButton">;
  Metrics: React.ReactNode;
  metricDialog: {
    onSubmit: (controller: DialogController) => void;
    disabled?: boolean;
  };
};

const EvaluationMetricsSection: React.FC<EvaluationMetricsSectionProps> = props => {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const metricItemTable: MetricItemTableProps | undefined = props.selectedMetrics
    ? {
        ...props.selectedMetrics,
        enableCheckbox: false,
        enableActionButton: true,
        enableTopBorder: true,
      }
    : undefined;

  const addButton: ButtonProps = {
    color: "outline-only",
    variant: "outlined",
    size: "small",
    startIcon: <AddIcon />,
    onClick: () => {
      setOpen(true);
    },
    children: t("追加"),
  };

  const dialog: DialogWithCloseIconProps = {
    title: t("評価項目を追加"),
    open: open,
    onClose: () => {
      setOpen(false);
    },
    noButton: {
      onClick: () => {
        setOpen(false);
      },
      color: "secondary",
      disabled: loading,
      children: t("キャンセル"),
    },
    yesButton: {
      onClick: () => {
        const controller: DialogController = {
          close: () => {
            setOpen(false);
          },
          setLoading: setLoading,
        };
        props.metricDialog.onSubmit(controller);
      },
      color: "secondary",
      disabled: loading || props.metricDialog.disabled,
      children: t("追加"),
    },
  };
  return (
    <Stack spacing={1.5}>
      {metricItemTable && <MetricItemTable {...metricItemTable} />}
      {!metricItemTable && <Typography sx={{ display: "flex", justifyContent: "center" }}>{t("評価項目がありません。")}</Typography>}
      <Box>
        <Button {...addButton} />
      </Box>
      <DialogWithCloseIcon {...dialog}>{props.Metrics}</DialogWithCloseIcon>
    </Stack>
  );
};

EvaluationMetricsSection.displayName = "EvaluationMetricsSection";

export default EvaluationMetricsSection;
