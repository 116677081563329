import { useTranslation, useTranslationWithVariable } from "@hireroo/i18n";
import { Fields } from "@hireroo/validator";
import List from "@mui/material/List";
import { styled } from "@mui/material/styles";
import * as React from "react";
import { useController } from "react-hook-form";

import QuestionDetailEditableGroupListItem, {
  QuestionDetailEditableGroupListItemProps,
} from "./parts/QuestionDetailEditableGroupListItem/QuestionDetailEditableGroupListItem";
import QuestionDetailEditableListItem, {
  QuestionDetailEditableListItemProps,
} from "./parts/QuestionDetailEditableListItem/QuestionDetailEditableListItem";
import * as PrivateHelper from "./privateHelper";

const ITEM_CLASS_NAME = "__question_detail_editable_list_item__";

const StyledList = styled(List)(({ theme }) => ({
  padding: 0,
  [`& > .${ITEM_CLASS_NAME}`]: {
    marginBottom: theme.spacing(1),
    "&:last-child": {
      marginBottom: 0,
    },
  },
}));

type UniqueKey = Fields.EntitySource.EntitySource["uniqueKey"];
type FieldValue = Fields.EntityTrack.EntityTrack[];

const move = <T,>(list: T[], index: number, count: number): T[] => {
  if (index < 0 || index >= list.length || index + count < 0 || index + count >= list.length) {
    throw new Error("Invalid index or count");
  }

  const element = list[index];
  const newList = [...list];
  newList.splice(index, 1);
  newList.splice(index + count, 0, element);

  return newList;
};

type EntitySource = {
  title: string;
  name: string;
  variant?: string;
  options?: QuestionDetailEditableListItemProps["options"];
  itemLabel?: QuestionDetailEditableListItemProps["itemLabel"];
};

type EntityTrackType = Fields.EntityTrack.EntityTrack["type"];

export type QuestionDetailEditableListProps = {
  name: "entityTracks";
  entitySources: Record<UniqueKey, EntitySource>;
  disabledAll?: boolean;
  onClickSelectQuestionsButton: (type: EntityTrackType, targetIndex: number, entitySources: Fields.EntitySource.EntitySource[]) => void;
};

const QuestionDetailEditableList: React.FC<QuestionDetailEditableListProps> = props => {
  const { entitySources } = props;
  const { t } = useTranslation();
  const { t: t2 } = useTranslationWithVariable();
  const { field } = useController<Record<string, FieldValue>>({
    name: props.name,
  });

  const Items = React.useMemo(() => {
    return field.value.reduce<React.ReactNode[]>((all, entityTrack, index) => {
      const titlePrefix = `Q${index + 1}`;
      const last = field.value.length - 1;
      const controlBar: QuestionDetailEditableGroupListItemProps["controlBar"] = {
        upButton: {
          disabled: index === 0 || props.disabledAll,
          onClick: () => {
            field.onChange(move(field.value, index, -1));
          },
        },
        downButton: {
          disabled: index === last || props.disabledAll,
          onClick: () => {
            field.onChange(move(field.value, index, 1));
          },
        },
        removeButton: {
          disabled: props.disabledAll,
          onClick: () => {
            field.onChange(field.value.filter((_, valueIndex) => valueIndex !== index));
          },
        },
      };
      if (entityTrack.type !== "FIXED") {
        const nestedItems = entityTrack.entitySources.reduce<QuestionDetailEditableGroupListItemProps["items"]>((all2, current, childIndex) => {
          const item = PrivateHelper.generateQuestionDetailEditableListItemProps({
            name: `${props.name}.${index}.entitySources.${childIndex}`,
            index: childIndex,
            titlePrefix: `Q${index + 1} - ${childIndex + 1}`,
            uniqueKey: current.uniqueKey,
            disabledAll: props.disabledAll,
            entitySources,
          });
          if (item) {
            all2.push(item);
          }
          return all2;
        }, []);
        switch (entityTrack.type) {
          case "RANDOM_FROM_QUESTIONS": {
            const item: QuestionDetailEditableGroupListItemProps = {
              label: t("ランダムに出題"),
              title: `${titlePrefix} ${t("ランダムに問題が出題される")}`,
              description: t2("OneOfTheFollowingQuestionsWillBeSelected", {
                num: nestedItems.length,
              }),
              items: nestedItems,
              controlBar: {
                ...controlBar,
                selectQuestionsButton: {
                  onClick: () => {
                    props.onClickSelectQuestionsButton("RANDOM_FROM_QUESTIONS", index, entityTrack.entitySources);
                  },
                },
              },
            };
            const key = entityTrack.entitySources.map(entitySource => entitySource.uniqueKey).join("-");
            return all.concat(<QuestionDetailEditableGroupListItem key={key} {...item} className={ITEM_CLASS_NAME} />);
          }
          case "SELECTABLE_FROM_QUESTIONS": {
            const item: QuestionDetailEditableGroupListItemProps = {
              label: t("受験者が選べる"),
              title: `${titlePrefix} ${t("受験者が問題を選択できる")}`,
              description: t2("TestTakersSelectOnlyOneQuestion", {
                num: nestedItems.length,
              }),
              items: nestedItems,
              controlBar: {
                ...controlBar,
                selectQuestionsButton: {
                  onClick: () => {
                    props.onClickSelectQuestionsButton("SELECTABLE_FROM_QUESTIONS", index, entityTrack.entitySources);
                  },
                },
              },
            };
            const key = entityTrack.entitySources.map(entitySource => entitySource.uniqueKey).join("-");
            return all.concat(<QuestionDetailEditableGroupListItem key={key} {...item} className={ITEM_CLASS_NAME} />);
          }
          default:
            throw new Error(`Unsupported Entity Track: ${entityTrack satisfies never}`);
        }
      }
      const uniqueKey = entityTrack.entitySource.uniqueKey;
      const item = PrivateHelper.generateQuestionDetailEditableListItemProps({
        name: `${props.name}.${index}.entitySource`,
        titlePrefix: titlePrefix,
        index,
        uniqueKey,
        disabledAll: props.disabledAll,
        entitySources,
      });
      if (!item) {
        return all;
      }
      return all.concat(<QuestionDetailEditableListItem key={uniqueKey} {...item} className={ITEM_CLASS_NAME} controlBar={controlBar} />);
    }, []);
  }, [field, props, entitySources, t, t2]);

  return <StyledList>{Items}</StyledList>;
};

QuestionDetailEditableList.displayName = "QuestionDetailEditableList";

export default QuestionDetailEditableList;
