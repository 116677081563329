import { CompetitionHistory } from "@hireroo/app-store/widget/c/CompetitionHistory";
import { updateQueryParamsByObject } from "@hireroo/router/api";

export const startSubscribeListParams = () => {
  return CompetitionHistory.subscribeAllState(state => {
    if (!state.pager) {
      return;
    }
    const { pager } = state;
    const { sortParams } = pager;

    const isDefaultSortParams =
      sortParams.sortMethod === CompetitionHistory.DEFAULT_SORT_PARAMS.sortMethod &&
      sortParams.isDescending === CompetitionHistory.DEFAULT_SORT_PARAMS.isDescending;
    /**
     * Do not update query parameters if they are different from the Default Value when the page is visited
     * (i.e., the user has not manipulated them).
     */
    updateQueryParamsByObject({
      [CompetitionHistory.QueryKeys.SORT]: !isDefaultSortParams ? [`${sortParams.sortMethod}-${sortParams.isDescending}`] : [],
      /**
       * Set page number to "1" if it is not a page change
       */
      [CompetitionHistory.QueryKeys.PAGE]: pager.page > 0 ? [(pager.page + 1).toString()] : [],
      [CompetitionHistory.QueryKeys.SIZE]: pager.size !== CompetitionHistory.DEFAULT_PAGER.size ? [pager.size.toString()] : [],
    });
  });
};
