import { App, Auth } from "@hireroo/app-store/essential/candidate";
import { Home } from "@hireroo/app-store/page/c/home";
import { CompetitionHistory } from "@hireroo/app-store/widget/c/CompetitionHistory";
import * as Graphql from "@hireroo/graphql/client/urql";
import { Widget } from "@hireroo/presentation";
import { withErrorBoundary } from "@sentry/react";
import React, { useEffect } from "react";

import CandidateDetailContainer, { CandidateDetailContainerProps } from "./Container";

export type CandidateDetailFetchContainerProps = {};

const CandidateDetailFetchContainer: React.FC<CandidateDetailFetchContainerProps> = () => {
  const appStatus = App.useStatus();
  const uid = Auth.useCurrentUid();
  const initialized = Home.useInitialized();
  const [result, refresh] = Graphql.useGetCandidateForCandidateDetailQuery({
    variables: {
      candidateId: uid,
    },
    requestPolicy: "cache-and-network",
    pause: appStatus !== "INITIALIZED",
  });

  useEffect(() => {
    if (result.data?.candidate) {
      Home.setCandidate(result.data.candidate);
    }
  }, [result.data]);

  useEffect(() => {
    return () => {
      Home.clear();
      CompetitionHistory.clear();
    };
  }, []);

  if (result.error) {
    return <Widget.RefreshPanel refresh={refresh} />;
  }
  if (result.fetching || appStatus !== "INITIALIZED" || !initialized) {
    return <Widget.Loading kind="CENTER_%" />;
  }

  const containerProps: CandidateDetailContainerProps = {};

  return <CandidateDetailContainer {...containerProps} />;
};

CandidateDetailFetchContainer.displayName = "CandidateDetailFetchContainer";

export default withErrorBoundary(CandidateDetailFetchContainer, {});
