import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { useGenerateProps } from "./useGenerateProps";

export type CompetitionHistoryListContainerProps = {};

const CompetitionHistoryListContainer: React.FC<CompetitionHistoryListContainerProps> = () => {
  const competitionHistoryListProps = useGenerateProps({});
  return (
    <ErrorBoundary>
      <Widget.CompetitionHistoryList {...competitionHistoryListProps} />
    </ErrorBoundary>
  );
};

CompetitionHistoryListContainer.displayName = "CompetitionHistoryListContainer";

export default withErrorBoundary(CompetitionHistoryListContainer, {});
