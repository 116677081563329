import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { useGenerateProps } from "./useGenerateProps";

export type CandidateSignInContainerProps = {};

const CandidateSignInContainer: React.FC<CandidateSignInContainerProps> = () => {
  const signInProps = useGenerateProps({});
  return (
    <ErrorBoundary>
      <Widget.CandidateSignIn {...signInProps} />
    </ErrorBoundary>
  );
};

CandidateSignInContainer.displayName = "CandidateSignInContainer";

export default withErrorBoundary(CandidateSignInContainer, {});
