import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import * as React from "react";

import ApplicantSearchForm, { ApplicantSearchFormProps } from "./parts/ApplicantSearchForm/ApplicantSearchForm";

export type CompetitionApplicantsProps = {
  searchForm: ApplicantSearchFormProps;
  List: React.ReactNode;
};

const CompetitionApplicants: React.FC<CompetitionApplicantsProps> = props => {
  return (
    <Box p={3}>
      <Stack spacing={2}>
        <ApplicantSearchForm {...props.searchForm} />
        {props.List}
      </Stack>
    </Box>
  );
};

CompetitionApplicants.displayName = "CompetitionApplicants";

export default CompetitionApplicants;
