import { DeepReadonly } from "@hireroo/app-helper/types";
import * as Graphql from "@hireroo/graphql/client/urql";
import { Language, resolveLanguage } from "@hireroo/i18n/utils";
import type { Widget } from "@hireroo/presentation";

type QuestionsTable = Exclude<Widget.RemoteInterviewOverviewProps["questionsTable"], undefined>;

type Section = Exclude<QuestionsTable["rows"][number]["details"], undefined>["categorySections"][number];
type FollowUpQuestion = Section["questions"][number];

export const generateCategorySectionsFromFollowUpQuestions = (
  followUpQuestions: DeepReadonly<Graphql.SessionForRemoteInterviewOverviewFragment["followUpQuestions"]>,
  lang: Language,
  followUpQuestionPriorityMap: Record<Graphql.FollowUpQuestionPriority, string>,
  challengeCategoryMap: Record<Graphql.ChallengeFollowUpQuestionCategory, string>,
  systemDesignCategoryMap: Record<Graphql.SystemDesignFollowUpQuestionCategory, string>,
): Section[] => {
  const categoryQuestionMap = new Map<string, FollowUpQuestion[]>();
  followUpQuestions.forEach(followUpQuestion => {
    const priority: FollowUpQuestion["priority"] =
      followUpQuestion.priority === "UNKNOWN"
        ? {
            kind: "LOW",
            label: followUpQuestionPriorityMap[followUpQuestion.priority],
          }
        : {
            kind: followUpQuestion.priority,
            label: followUpQuestionPriorityMap[followUpQuestion.priority],
          };

    const question: FollowUpQuestion = {
      question: resolveLanguage(followUpQuestion, lang, "question"),
      answer: resolveLanguage(followUpQuestion, lang, "modelAnswer"),
      purpose: resolveLanguage(followUpQuestion, lang, "purpose"),
      priority: priority,
    };

    let categoryText = "";
    const categoryTypename = followUpQuestion.category.__typename;
    switch (categoryTypename) {
      case "LiveCodingChallengeFollowUpQuestionCategory":
        categoryText = challengeCategoryMap[followUpQuestion.category.challenge];
        break;
      case "LiveCodingSystemDesignFollowUpQuestionCategory":
        categoryText = systemDesignCategoryMap[followUpQuestion.category.systemDesign];
        break;
      default:
        throw new Error(`Invalid ${categoryTypename satisfies never} `);
    }
    if (!categoryQuestionMap.has(categoryText)) {
      categoryQuestionMap.set(categoryText, [question]);
    } else {
      categoryQuestionMap.get(categoryText)?.push(question);
    }
  });

  return Array.from(categoryQuestionMap.entries()).map(([category, questions]) => ({
    title: category,
    questions: questions,
  }));
};
