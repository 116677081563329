import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { useGenerateProps } from "./useGenerateProps";

export type ScreeningTestListSingleChoiceContainerProps = {};

const ScreeningTestListSingleChoiceContainer: React.FC<ScreeningTestListSingleChoiceContainerProps> = () => {
  const screeningTestListSingleChoiceProps = useGenerateProps({});
  return (
    <ErrorBoundary>
      <Widget.ScreeningTestListSingleChoice {...screeningTestListSingleChoiceProps} />
    </ErrorBoundary>
  );
};

ScreeningTestListSingleChoiceContainer.displayName = "ScreeningTestListSingleChoiceContainer";

export default withErrorBoundary(ScreeningTestListSingleChoiceContainer, {});
