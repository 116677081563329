import { CompetitionsIdDetail } from "@hireroo/app-store/page/e/competitions_id_detail";
import { updateQueryParamsByObject } from "@hireroo/router/api";

export const startSubscribeListParams = () => {
  return CompetitionsIdDetail.subscribeAllState(state => {
    if (!state.applicantFilter) {
      return;
    }
    const { applicantFilter, applicantPager } = state;
    const { sortParams } = applicantPager;

    const isDefaultSortParams =
      sortParams.sortMethod === CompetitionsIdDetail.DEFAULT_SORT_PARAMS.sortMethod &&
      sortParams.isDescending === CompetitionsIdDetail.DEFAULT_SORT_PARAMS.isDescending;
    /**
     * Do not update query parameters if they are different from the Default Value when the page is visited
     * (i.e., the user has not manipulated them).
     */
    updateQueryParamsByObject({
      [CompetitionsIdDetail.QueryKeys.NAME]:
        applicantFilter.textFilter && applicantFilter.textFilter.length > 0 ? [applicantFilter.textFilter] : [],
      [CompetitionsIdDetail.QueryKeys.STATUS]: applicantFilter.statuses.length > 0 ? applicantFilter.statuses : [],
      [CompetitionsIdDetail.QueryKeys.MAX_SCORE]:
        applicantFilter.score.max !== CompetitionsIdDetail.DEFAULT_APPLICANT_FILTER.score.max ? [applicantFilter.score.max.toFixed()] : [],
      [CompetitionsIdDetail.QueryKeys.MIN_SCORE]:
        applicantFilter.score.min !== CompetitionsIdDetail.DEFAULT_APPLICANT_FILTER.score.min ? [applicantFilter.score.min.toFixed()] : [],
      [CompetitionsIdDetail.QueryKeys.SORT]: !isDefaultSortParams ? [`${sortParams.sortMethod}-${sortParams.isDescending}`] : [],
      [CompetitionsIdDetail.QueryKeys.SUSPICIOUS_DEGREES]:
        applicantFilter.suspiciousDegrees.length > 0 ? applicantFilter.suspiciousDegrees : [],
    });
  });
};
