import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { useGenerateProps } from "./useGenerateProps";

export type CandidateHeaderRightContentContainerProps = {};

const CandidateHeaderRightContentContainer: React.FC<CandidateHeaderRightContentContainerProps> = () => {
  const candidateHeaderRightContentProps = useGenerateProps({});
  return (
    <ErrorBoundary>
      <Widget.CandidateHeaderRightContent {...candidateHeaderRightContentProps} />
    </ErrorBoundary>
  );
};

CandidateHeaderRightContentContainer.displayName = "CandidateHeaderRightContentContainer";

export default withErrorBoundary(CandidateHeaderRightContentContainer, {});
