import firebase from "firebase/compat/app";
import { jwtDecode, JwtPayload } from "jwt-decode";

export async function getIdToken(forceRefresh?: boolean): Promise<string | undefined> {
  return await firebase.auth().currentUser?.getIdToken(forceRefresh);
}

export async function getExpireDate() {
  const result = await firebase.auth().currentUser?.getIdTokenResult();
  if (!result) {
    return;
  }
  return new Date(result.expirationTime);
}

type FirebaseClaim = {
  sign_in_provider: string;
  tenant: string;
};

type Claims = {
  auth_time: number;
  firebase: FirebaseClaim;
  provider_id: string;
  role: string;
  user_id: string;
  user_type: string;
  company_id?: number;
  system_admin: boolean;
} & JwtPayload;

export const parseCustomClaims = (idToken: string): Claims | null => {
  try {
    return jwtDecode<Claims>(idToken);
  } catch (error) {
    return null;
  }
};
