import { useTranslation } from "@hireroo/i18n";
import { useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import * as React from "react";

import CompetitionHistoryTableRow, { CompetitionHistoryTableRowProps } from "../CompetitionHistoryTableRow/CompetitionHistoryTableRow";

const StyledTableHeaderCell = styled(TableCell)(({ theme }) => ({
  color: theme.palette.text.secondary,
  fontWeight: "normal",
  fontSize: "12px",
  border: "none",
  paddingTop: theme.spacing(0.5),
  paddingBottom: theme.spacing(0.5),
  height: 40,
  wordBreak: "keep-all",
  whiteSpace: "nowrap",
}));

export type CompetitionHistoryTableProps = {
  items: CompetitionHistoryTableRowProps[];
};

const CompetitionHistoryTable: React.FC<CompetitionHistoryTableProps> = props => {
  const theme = useTheme();
  const { t } = useTranslation();

  if (props.items.length === 0) {
    return (
      <Box sx={{ width: "100%" }} p={2}>
        <Typography variant="body2" textAlign="center" color="textSecondary">
          {t("コンペの参加実績がありません。")}
        </Typography>
      </Box>
    );
  }

  return (
    <TableContainer component="div">
      <Table component="div" sx={{ display: "table" }}>
        <TableHead component="div" sx={{ display: "table-header-group" }}>
          <TableRow sx={{ borderTop: "1px solid", borderBottom: "1px solid", borderColor: theme.palette["Gray/Shades"].p8 }} component="div">
            <StyledTableHeaderCell align="left" component="div">
              {t("コンペ")}
            </StyledTableHeaderCell>
            <StyledTableHeaderCell align="left" component="div">
              {t("主催企業")}
            </StyledTableHeaderCell>
            <StyledTableHeaderCell align="center" component="div" width="100px">
              {t("スコア")}
            </StyledTableHeaderCell>
            <StyledTableHeaderCell align="center" component="div" width="100px">
              {t("順位")}
            </StyledTableHeaderCell>
          </TableRow>
        </TableHead>
        <TableBody component="div">
          {props.items.map(item => (
            <CompetitionHistoryTableRow key={item.id} {...item} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

CompetitionHistoryTable.displayName = "CompetitionHistoryTable";

export default CompetitionHistoryTable;
