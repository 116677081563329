import { Auth } from "@hireroo/app-store/essential/candidate";
import { signOut } from "@hireroo/firebase";
import { useTranslation } from "@hireroo/i18n";
import type { Widget } from "@hireroo/presentation";
import { generatePath } from "@hireroo/router/api";
import { useTransitionNavigate } from "@hireroo/router/hooks";

export type GenerateCandidateMenuPropsArgs = {};

export const useGenerateProps = (_args: GenerateCandidateMenuPropsArgs): Widget.NavigationPopupMenuProps => {
  const { t } = useTranslation();
  const candidate = Auth.useCurrentCandidate();

  const navigate = useTransitionNavigate();

  const links: (Widget.NavigationPopupMenuProps["menuList"]["links"][0] | false)[] = [
    { children: t("プロフィール編集"), href: generatePath("/c/settings/user"), onClick: () => navigate("/c/settings/user") },
    {
      children: t("サインアウト"),
      onClick: async () => {
        await signOut();
      },
    },
  ];

  return {
    photoUrl: candidate.photoUrl,
    menuList: {
      displayName: candidate.displayName,
      email: candidate.email,
      links: links.flatMap(v => (v ? [v] : [])),
    },
  };
};
