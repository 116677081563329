import { useSnapshot } from "valtio";

import { state } from "./State";

const useSnapshotState = () => {
  return useSnapshot(state);
};

export const useInitialized = (): boolean => {
  const snapshot = useSnapshotState();
  return !!snapshot.res;
};

export const useCompetitions = () => {
  const snapshot = useSnapshotState();
  return Array.from(snapshot.currentCompetitionIdSet).map(id => snapshot.competitionMap.get(id)!);
};

export const useRes = () => {
  const snapshot = useSnapshotState();
  if (!snapshot.res) throw new Error("res is not initialized");
  return snapshot.res;
};

export const useCurrentSearchFilter = () => {
  const snapshot = useSnapshotState();
  return snapshot.currentSearchFilter;
};

export const useSortParams = () => {
  const snapshot = useSnapshotState();
  return snapshot.sortParams;
};

export const useOffset = () => {
  const snapshot = useSnapshotState();
  return snapshot.offset;
};

export const useInitializedSearchFilter = () => {
  const snapshot = useSnapshotState();
  return snapshot.initializedSearchFilter;
};
