import * as Graphql from "@hireroo/graphql/client/urql";
import type { Widget } from "@hireroo/presentation";

type SortMethod = Parameters<Widget.ScreeningTestListSingleChoiceProps["searchResultBar"]["onChangeSortMethod"]>[0];

export const sortMethodMap: Record<
  SortMethod,
  {
    sortMethod: Graphql.SpotsByCompanyIdSortMethod;
    isDescending: boolean;
  }
> = {
  /**
   * - 作成日時が新しい順 = 降順
   * - Creation date and time = descending order
   *
   * @example 2024/02/01 -> 2024/01/31 -> 2024/01/31
   */
  CREATED_AT_DESCENDING: { sortMethod: Graphql.SpotsByCompanyIdSortMethod.CreatedAt, isDescending: true },
  CREATED_AT_ASCENDING: { sortMethod: Graphql.SpotsByCompanyIdSortMethod.CreatedAt, isDescending: false },
  TOTAL_SCORE_DESCENDING: { sortMethod: Graphql.SpotsByCompanyIdSortMethod.TotalScore, isDescending: false },
  TOTAL_SCORE_ASCENDING: { sortMethod: Graphql.SpotsByCompanyIdSortMethod.TotalScore, isDescending: true },
  RANK_DESCENDING: { sortMethod: Graphql.SpotsByCompanyIdSortMethod.Rank, isDescending: false },
  RANK_ASCENDING: { sortMethod: Graphql.SpotsByCompanyIdSortMethod.Rank, isDescending: true },
};

export const generateSortMethod = (args: { sortMethod: Graphql.SpotsByCompanyIdSortMethod; isDescending: boolean }): SortMethod => {
  const SUFFIX = args.isDescending ? ("DESCENDING" as const) : ("ASCENDING" as const);
  const prefixMap: Record<Graphql.SpotsByCompanyIdSortMethod, "CREATED_AT" | "TOTAL_SCORE" | "RANK"> = {
    [Graphql.SpotsByCompanyIdSortMethod.CreatedAt]: "CREATED_AT",
    [Graphql.SpotsByCompanyIdSortMethod.TotalScore]: "TOTAL_SCORE",
    [Graphql.SpotsByCompanyIdSortMethod.Rank]: "RANK",
    /** Normalize to default sorting scheme */
    [Graphql.SpotsByCompanyIdSortMethod.DidStartAt]: "CREATED_AT",
    [Graphql.SpotsByCompanyIdSortMethod.EvaluatedAt]: "CREATED_AT",
    [Graphql.SpotsByCompanyIdSortMethod.Unknown]: "CREATED_AT",
  };
  const PREFIX = prefixMap[args.sortMethod];
  return `${PREFIX}_${SUFFIX}` as const;
};
