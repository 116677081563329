import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { useGenerateProps } from "./useGenerateProps";

export type ScreeningTestListForRemoteContentContainerProps = {};

const ScreeningTestListForRemoteContentContainer: React.FC<ScreeningTestListForRemoteContentContainerProps> = () => {
  const screeningTestListForRemoteContentProps = useGenerateProps({});
  return (
    <ErrorBoundary>
      <Widget.ScreeningTestListForRemoteContent {...screeningTestListForRemoteContentProps} />
    </ErrorBoundary>
  );
};

ScreeningTestListForRemoteContentContainer.displayName = "ScreeningTestListForRemoteContentContainer";

export default withErrorBoundary(ScreeningTestListForRemoteContentContainer, {});
