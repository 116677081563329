import { useTranslation } from "@hireroo/i18n";
import { Divider } from "@mui/material";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";

import FollowUpQuestion, { FollowUpQuestionProps } from "../../../../../modules/FollowUpQuestion/FollowUpQuestion";

const StyledFollowUpQuestion = styled(FollowUpQuestion)(({ theme }) => ({
  backgroundColor: theme.palette["Secondary/Shades"].p8,
}));

type CategorySection = {
  title: string;
  questions: FollowUpQuestionProps[];
};

export type FollowUpQuestionsProps = {
  categorySections: CategorySection[];
};

const FollowUpQuestions: React.FC<FollowUpQuestionsProps> = props => {
  const { t } = useTranslation();
  return (
    <Stack spacing={1} p={1.5}>
      {props.categorySections.map((section, index) => (
        <Stack key={`section-${index}`} spacing={1}>
          <Stack direction="row" alignItems="center" spacing={2}>
            <Typography whiteSpace="nowrap" fontSize={14} color="textSecondary">{`${t("カテゴリ")}: ${section.title}`}</Typography>
            <Box width="100%">
              <Divider textAlign="center" variant="fullWidth" />
            </Box>
          </Stack>
          {section.questions.map((question, index) => (
            <StyledFollowUpQuestion key={`question-${index}`} {...question} />
          ))}
        </Stack>
      ))}
    </Stack>
  );
};

FollowUpQuestions.displayName = "FollowUpQuestions";

export default FollowUpQuestions;
