import { state } from "./State";
import type * as Types from "./types";

export type InitializeUserFromFirebaseArgs = {
  user: Types.User;
};

export const clear = () => {
  state.user = null;
  state.candidate = null;
  state.initializeError = null;
};

export const initializeUserFromFirebase = (args: InitializeUserFromFirebaseArgs): void => {
  state.user = args.user;
  /**
   * NOTE: ここで state.user = user とすると、
   *       CORS エラーが発生するため、必要なデータのみセットする
   */
  state.user = {
    uid: args.user.uid,
    email: args.user.email,
  };
};

export const setError = (initializeError: Types.InitializeError) => {
  state.initializeError = initializeError;
};

export const setCandidate = (candidate: Types.Candidate): void => {
  state.candidate = candidate;
};
