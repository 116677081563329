import * as Graphql from "@hireroo/graphql/client/urql";
import type { Widget } from "@hireroo/presentation";

type SortMethod = Parameters<Widget.CompetitionCardListProps["searchResultBar"]["onChangeSortMethod"]>[0];

export const sortMethodMap: Record<
  SortMethod,
  {
    sortMethod: Graphql.CompetitionSortMethod;
    isDescending: boolean;
  }
> = {
  CREATED_AT_ASCENDING: { sortMethod: Graphql.CompetitionSortMethod.CreatedAt, isDescending: false },
  CREATED_AT_DESCENDING: { sortMethod: Graphql.CompetitionSortMethod.CreatedAt, isDescending: true },
  STARTS_AT_ASCENDING: { sortMethod: Graphql.CompetitionSortMethod.StartsAt, isDescending: false },
  STARTS_AT_DESCENDING: { sortMethod: Graphql.CompetitionSortMethod.StartsAt, isDescending: true },
  MIN_SALARY_ASCENDING: { sortMethod: Graphql.CompetitionSortMethod.MinSalary, isDescending: false },
  MIN_SALARY_DESCENDING: { sortMethod: Graphql.CompetitionSortMethod.MinSalary, isDescending: true },
};
