import { CompetitionHistorySearchForm } from "@hireroo/validator";
import { zodResolver } from "@hookform/resolvers/zod";
import Paper from "@mui/material/Paper";
import * as React from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";

import SearchForm, { SearchFormProps } from "../../../../modules/SearchForm/SearchForm";
import * as PrivateHelper from "./privateHelper";

export type CompetitionSearchFormProps = {
  disabled: boolean;
  defaultValues: CompetitionHistorySearchForm.CompetitionHistorySearchQuerySchema;
  onChange: SubmitHandler<CompetitionHistorySearchForm.CompetitionHistorySearchQuerySchema>;
};

const CompetitionSearchForm: React.FC<CompetitionSearchFormProps> = props => {
  const { onChange } = props;
  const validateSchema = CompetitionHistorySearchForm.useCompetitionHistorySearchQuery();
  const methods = useForm<CompetitionHistorySearchForm.CompetitionHistorySearchQuerySchema>({
    resolver: zodResolver(validateSchema),
    defaultValues: props.defaultValues,
  });

  React.useEffect(() => {
    const subscriber = methods.watch(() => {
      const fields = methods.getValues();
      onChange(fields);
    });
    return () => {
      subscriber.unsubscribe();
    };
  }, [methods, onChange]);

  const textFilterField = PrivateHelper.useTextFilterField();

  const searchFormProps: SearchFormProps = {
    kind: "SINGLE_LINE",
    disabled: props.disabled,
    textFilter: textFilterField,
    fields: [],
  };

  return (
    <FormProvider {...methods}>
      <Paper
        sx={{
          borderRadius: "8px",
          overflowX: "hidden",
          padding: "12px",
        }}
      >
        <SearchForm {...searchFormProps} />
      </Paper>
    </FormProvider>
  );
};

CompetitionSearchForm.displayName = "CompetitionSearchForm";

export default CompetitionSearchForm;
