import { currentWindowMatchPath } from "./matchPath";

export type RoutingType =
  | "EMPLOYEE"
  | "CANDIDATE"
  | "CANDIDATE_OR_ANONYMOUS"
  | "SIGN_IN_CANDIDATE"
  | "SIGN_UP_CANDIDATE"
  | "ROOT"
  | "TALENT"
  | "SIGN_IN_EMPLOYEE_OR_TALENT"
  | "SIGN_UP_EMPLOYEE_OR_TALENT"
  | "NOT_FOUND"
  | "RESET_PASSWORD"
  | "RESET_PASSWORD_CANDIDATE"
  | "AUTH"
  | "INVITATION_FOR_EMPLOYEE"
  | "INVITATION_FOR_TALENT";

export const getCurrentRoutingType = (): RoutingType => {
  const pathname = window.location.pathname;
  if (currentWindowMatchPath("/")) {
    return "ROOT";
  }
  if (currentWindowMatchPath("/signin")) {
    return "SIGN_IN_EMPLOYEE_OR_TALENT";
  }
  if (currentWindowMatchPath("/signup")) {
    return "SIGN_UP_EMPLOYEE_OR_TALENT";
  }
  if (pathname.startsWith("/e")) {
    if (currentWindowMatchPath("/e/invitations/:id")) {
      return "INVITATION_FOR_EMPLOYEE";
    }
    return "EMPLOYEE";
  }
  if (pathname.startsWith("/t")) {
    if (currentWindowMatchPath("/t/invitations/:id")) {
      return "INVITATION_FOR_TALENT";
    }
    return "TALENT";
  }
  if (pathname.startsWith("/c")) {
    if (currentWindowMatchPath("/c/signin")) {
      return "SIGN_IN_CANDIDATE";
    }

    if (currentWindowMatchPath("/c/signup")) {
      return "SIGN_UP_CANDIDATE";
    }

    if (currentWindowMatchPath("/c/reset/password")) {
      return "RESET_PASSWORD_CANDIDATE";
    }

    if (isCandidateOrAnonymous(pathname)) {
      return "CANDIDATE_OR_ANONYMOUS";
    }

    return "CANDIDATE";
  }
  if (currentWindowMatchPath("/auth")) {
    return "AUTH";
  }
  if (currentWindowMatchPath("/reset/password")) {
    return "RESET_PASSWORD";
  }
  return "NOT_FOUND";
};

const isCandidateOrAnonymous = (pathname: string): boolean => {
  const anonymousPrefixRoutes: string[] = ["/c/screenings", "/c/demos", "/c/remotes", "/c/interviews", "/c/interview-groups"];

  return anonymousPrefixRoutes.some(route => pathname.startsWith(route));
};
