import { RemoteInterviewResourceEditorStore } from "@hireroo/app-store/view-domain/RemoteInterviewResourceEditor";
import { EvaluationMetricListForRemote } from "@hireroo/app-store/widget/e/EvaluationMetricListForRemote";
import type { Widget } from "@hireroo/presentation";
import { RemoteInterviewResourceEditor } from "@hireroo/validator";
import * as React from "react";
import { useFormContext } from "react-hook-form";

import EvaluationMetricListForRemoteFetchContainer from "../../../EvaluationMetricListForRemote/FetchContainer";

type Item = Exclude<Widget.EvaluationMetricsSectionProps["selectedMetrics"], undefined>["items"][0];

export type GenerateEvaluationMetricsSectionPropsArgs = {};

export const useGenerateProps = (_args: GenerateEvaluationMetricsSectionPropsArgs): Widget.EvaluationMetricsSectionProps => {
  const methods = useFormContext<RemoteInterviewResourceEditor.RemoteReportSetupFormSchema>();
  const selectedMetricIdsForDialog = EvaluationMetricListForRemote.useSelectedMetricIds();

  const metricMap = RemoteInterviewResourceEditorStore.useEvaluationMetricMap();
  const selectedMetricIds = methods.watch("evaluationMetricIds");

  const items = React.useMemo((): Item[] => {
    return selectedMetricIds.reduce<Item[]>((all, selectedMetricId) => {
      const metric = metricMap.get(selectedMetricId);
      if (!metric) {
        return all;
      }
      const item: Item = {
        id: String(metric.metricId),
        title: metric.title,
        description: metric.description,
        deleteButton: {
          onClick: () => {
            methods.setValue(
              "evaluationMetricIds",
              selectedMetricIds.filter(id => id !== selectedMetricId),
            );
          },
        },
      };
      return all.concat(item);
    }, []);
  }, [selectedMetricIds, metricMap, methods]);

  return {
    selectedMetrics:
      items.length > 0
        ? {
            items: items,
          }
        : undefined,
    Metrics: (
      <EvaluationMetricListForRemoteFetchContainer
        onChange={fields => {
          const selectedIds: number[] = fields.items.filter(item => item.selected).map(item => Number(item.itemId));
          EvaluationMetricListForRemote.setSelectedMetricIds(selectedIds);
        }}
        filterMetricIds={selectedMetricIds}
      />
    ),
    metricDialog: {
      onSubmit: controller => {
        methods.setValue("evaluationMetricIds", [...selectedMetricIds, ...selectedMetricIdsForDialog]);
        EvaluationMetricListForRemote.resetSelectedMetricIds();
        controller.close();
      },
      disabled: selectedMetricIdsForDialog.length === 0,
    },
  };
};
