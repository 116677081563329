import { useTitle } from "@hireroo/app-helper/react-use";
import { App } from "@hireroo/app-store/essential/candidate";
import { CompetitionsIdDetail } from "@hireroo/app-store/page/c/competitions_id_detail";
import { CandidateSideBarContent } from "@hireroo/app-store/widget/c/CandidateSideBarContent";
import { useTranslation } from "@hireroo/i18n";
import type { Pages } from "@hireroo/presentation";
import { generatePath } from "@hireroo/router/api";
import { useTransitionNavigate } from "@hireroo/router/hooks";

import CandidateHeaderRightContentContainer from "../../../../widget/v2/c/CandidateHeaderRightContent/Container";
import CandidateSideBarContentContainer from "../../../../widget/v2/c/CandidateSideBarContent/Container";
import FooterContainer from "../../../../widget/v2/c/Footer/Container";
import SnackbarContainer from "../../../../widget/v2/shared/Snackbar/Container";
import CompetitionIdDetailForCandidateFetchContainer from "./widgets/CompetitionIdDetailForCandidate/FetchContainer";
export type GenerateCompetitionIdDetailForCandidatePropsArgs = {};

export const useGenerateProps = (_args: GenerateCompetitionIdDetailForCandidatePropsArgs): Pages.CompetitionIdDetailForCandidateProps => {
  const { t } = useTranslation();
  const appStatus = App.useStatus();
  const navigate = useTransitionNavigate();
  const loadingStatus = CompetitionsIdDetail.useLoadingStatus();

  useTitle(t("コンペ詳細"));

  return {
    layout: {
      openSidebar: CandidateSideBarContent.useOpenSidebar(),
      onChangeOpenSidebar: open => {
        CandidateSideBarContent.setOpenSidebar(open);
      },
      loading: appStatus === "INITIALIZING" || loadingStatus === "LOADING",
      NotificationBanner: null,
      HeaderRightContent: <CandidateHeaderRightContentContainer />,
      SideBarContent: <CandidateSideBarContentContainer />,
      Footer: <FooterContainer />,
      Snackbar: <SnackbarContainer />,
      sideBarHeader: {
        logo: {
          href: generatePath("/c/home"),
          onClick: () => {
            navigate("/c/home");
          },
        },
      },
      navigationBreadcrumbs: {
        parentLinks: [
          {
            href: generatePath("/c/home"),
            children: t("ホーム"),
            onClick: () => {
              navigate("/c/home");
            },
          },
          {
            href: generatePath("/c/competitions"),
            children: t("コンペ一覧"),
            onClick: () => {
              navigate("/c/competitions");
            },
          },
        ],
        current: t("コンペ詳細"),
      },
    },
    children: <CompetitionIdDetailForCandidateFetchContainer />,
  };
};
