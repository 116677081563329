import { useSnapshot } from "valtio";

import { state } from "./State";

const useSnapshotState = () => {
  return useSnapshot(state);
};

export const useInitialized = (): boolean => {
  const snapshot = useSnapshotState();
  return !!snapshot.res;
};

export const useInitializedSearchFilter = () => {
  const snapshot = useSnapshotState();
  return snapshot.initializedSearchFilter;
};

export const useCurrentSearchFilter = () => {
  const snapshot = useSnapshotState();
  return snapshot.currentSearchFilter;
};

export const usePager = () => {
  const snapshot = useSnapshotState();
  return snapshot.pager;
};

export const useResponse = () => {
  const snapshot = useSnapshotState();
  if (!snapshot.res) {
    throw new Error("Response not initialized");
  }
  return snapshot.res;
};

export const useCandidateIds = () => {
  const snapshot = useSnapshotState();
  return snapshot.candidateIds;
};

export const useCandidates = () => {
  const snapshot = useSnapshotState();
  const candidateIds = useCandidateIds();

  return candidateIds.map(id => {
    const candidate = snapshot.candidateMap.get(id);
    if (!candidate) throw new Error(`Candidate not found: ${id}`);
    return candidate;
  });
};
