import { useTranslation } from "@hireroo/i18n";
import { useTheme } from "@mui/material";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";

import DialogWithCloseIcon, { DialogWithCloseIconProps } from "../../primitive/DialogWithCloseIcon/DialogWithCloseIcon";
import CreateOptionContent, { CreateOptionContentProps } from "./parts/CreateOptionContent/CreateOptionContent";

const ChipWrap = styled(Typography)(({ theme }) => ({
  fontSize: 10,
  padding: "1px 4px",
  color: theme.palette.text.secondary,
  backgroundColor: theme.palette["Other"]["FilledInputBG"],
  borderRadius: "20px",
  alignItems: "center",
  justifyContent: "center",
  height: "20px",
  display: "flex",
}));
type Step = "CHOOSE_OPTION" | "TEST_LIST" | "EVALUATION_METRICS";

export type RemoteCreateDialogProps = {
  dialog: Pick<DialogWithCloseIconProps, "open" | "onClose" | "title">;
  createOption: CreateOptionContentProps;
  step: Step;
  TestList: React.ReactNode;
  actionButtons: Pick<DialogWithCloseIconProps, "yesButton" | "noButton">;
  EvaluationMetrics: React.ReactNode;
};

const RemoteCreateDialog: React.FC<RemoteCreateDialogProps> = props => {
  const { t } = useTranslation();
  const theme = useTheme();

  const dialog: DialogWithCloseIconProps = {
    ...props.dialog,
    optionalDialog: {
      PaperProps: {
        sx: { minWidth: "640px", maxWidth: "840px", bgcolor: theme.palette.background.paper },
        elevation: 3,
      },
    },
    disableEnter: true,
    title: props.dialog.title ?? t("インタビュー作成"),
    yesButton:
      props.actionButtons.yesButton === undefined
        ? undefined
        : {
            ...props.actionButtons.yesButton,
            color: "secondary",
          },
    noButton:
      props.actionButtons.noButton === undefined
        ? undefined
        : {
            ...props.actionButtons.noButton,
            children: t("戻る"),
            variant: "outlined",
            color: "outline-only",
          },
  };
  const stepMap: Record<Step, React.ReactNode> = {
    CHOOSE_OPTION: <CreateOptionContent {...props.createOption} />,
    TEST_LIST: props.TestList,
    EVALUATION_METRICS: (
      <Stack pt={2}>
        <Stack direction="row" spacing={1.25} alignItems="center">
          <ChipWrap>{t("任意")}</ChipWrap>
          <Typography fontWeight={700}>{t("評価項目の選択")}</Typography>
        </Stack>
        {props.EvaluationMetrics}
      </Stack>
    ),
  };

  return <DialogWithCloseIcon {...dialog}>{stepMap[props.step]}</DialogWithCloseIcon>;
};

RemoteCreateDialog.displayName = "RemoteCreateDialog";

export default RemoteCreateDialog;
