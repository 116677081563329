import { useTranslation, useTranslationWithVariable } from "@hireroo/i18n";
import Box from "@mui/material/Box";
import Button, { ButtonProps } from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { Trans } from "react-i18next";

import SignInFooter, { SignInFooterProps } from "../../ms-components/Auth/SignInFooter/SignInFooter";
import SignInHeader from "../../ms-components/Auth/SignInHeader/SignInHeader";
import SocialLoginButton, { SocialLoginButtonProps } from "../../ms-components/Auth/SocialLoginButton/SocialLoginButton";
import Link from "../../primitive/Link/Link";

const BUTTON_WIDTH = 189;

const Wrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  height: "100vh",
  width: "100vw",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: theme.palette.background.default,
}));

const WrapperStack = styled(Stack)(({ theme }) => ({
  width: "416px",
  padding: theme.spacing(3),
  borderRadius: "16px",
  backgroundColor: theme.palette["Background/Paper"].p2,
}));

export type CandidateSignUpProps = {
  googleSignUpButton: Pick<SocialLoginButtonProps, "onClick">;
  githubSignUpButton: Pick<SocialLoginButtonProps, "onClick">;
  SignUpComponent: React.ReactNode;
  footer: SignInFooterProps;
  signInButton: Pick<ButtonProps, "onClick">;
  tosUrl: string;
  privacyPolicyUrl: string;
};

const CandidateSignUp: React.FC<CandidateSignUpProps> = props => {
  const { t } = useTranslation();
  const { t: t2 } = useTranslationWithVariable();
  const signInButton: ButtonProps = {
    ...props.signInButton,
    color: "secondary",
    children: t("サインインして利用する"),
    sx: {
      textTransform: "none",
    },
  };

  const googleLoginButton: SocialLoginButtonProps = {
    kind: "SIGN_UP",
    provider: "Google",
    onClick: props.googleSignUpButton.onClick,
    width: BUTTON_WIDTH,
  };

  const githubLoginButton: SocialLoginButtonProps = {
    kind: "SIGN_UP",
    provider: "GitHub",
    onClick: props.githubSignUpButton.onClick,
    width: BUTTON_WIDTH,
  };

  return (
    <Wrapper>
      <Stack direction="column" alignItems="center" width="416px" spacing={2}>
        <SignInHeader />
        <WrapperStack spacing={3}>
          <Typography variant="h6" fontSize={20} fontWeight={700}>
            {t("サインアップ")}
          </Typography>
          {props.SignUpComponent}
          <Box>
            <Divider>
              <Typography variant="body2" color="textSecondary">
                {t("または")}
              </Typography>
            </Divider>
          </Box>
          <Stack spacing={2} alignItems="center">
            <SocialLoginButton {...googleLoginButton} />
          </Stack>
          <Stack spacing={2} alignItems="center">
            <SocialLoginButton {...githubLoginButton} />
          </Stack>
          <Typography fontSize={14} color="textSecondary">
            <Trans
              values={{ termsOfUse: `<l>${t("利用規約")}</l>`, privacyPolicy: `<li>${t("プライバシーポリシー")}</li>` }}
              components={{
                l: <Link href={props.tosUrl} target="_blank" underline="hover" color="secondary" />,
                li: <Link href={props.privacyPolicyUrl} target="_blank" underline="hover" color="secondary" />,
              }}
            >
              {t2("SignUpAgreement")}
            </Trans>
          </Typography>
        </WrapperStack>
        <Box display="flex" justifyContent="center">
          <Button {...signInButton} />
        </Box>
        <SignInFooter {...props.footer} />
      </Stack>
    </Wrapper>
  );
};

CandidateSignUp.displayName = "CandidateSignUp";

export default CandidateSignUp;
