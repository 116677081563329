import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import * as React from "react";

import CompetitionResultDetailHeader, {
  CompetitionResultDetailHeaderProps,
} from "../../ms-components/Competition/CompetitionResultDetailHeader/CompetitionResultDetailHeader";

const PAGE_HEADER_HEIGHT = 114;
const NON_CONTENT_HEIGHT = 72;

/**
 * 72px was calculated from the non-content height of EmployeeLayout
 */
const StyledBox = styled(Box)(({ theme }) => ({
  height: `calc(100vh - ${NON_CONTENT_HEIGHT}px)`,
  overflow: "hidden",
  backgroundColor: theme.palette["Background/Paper"].p2,
  minWidth: 900,
}));

const ContentWrapper = styled(Box)(({ theme }) => ({
  overflowY: "auto",
  overflowX: "hidden",
  background: theme.palette.background.default,
}));

export type CompetitionResultDetailProps = {
  header: CompetitionResultDetailHeaderProps;
  children: React.ReactNode;
};

const CompetitionResultDetail: React.FC<CompetitionResultDetailProps> = props => {
  return (
    <StyledBox>
      <CompetitionResultDetailHeader {...props.header} />
      <ContentWrapper
        id="report-content-wrapper"
        sx={{
          height: `calc(100% - ${PAGE_HEADER_HEIGHT}px)`,
        }}
      >
        {props.children}
      </ContentWrapper>
    </StyledBox>
  );
};

CompetitionResultDetail.displayName = "CompetitionResultDetail";

export default CompetitionResultDetail;
