import { useSnapshot } from "valtio";

import { state } from "./State";

export const useSnapshotState = () => {
  return useSnapshot(state);
};

export const useActiveStep = () => {
  const snapshot = useSnapshotState();
  return snapshot.activeStep;
};

export const useSelectedEmployees = () => {
  const snapshot = useSnapshotState();
  return snapshot.employeeIds;
};

export const useSelectedEmployeeGroups = () => {
  const snapshot = useSnapshotState();
  return snapshot.groupIds;
};

export const useSelectableTags = () => {
  const snapshot = useSnapshotState();
  return Array.from(snapshot.tags.values());
};

export const useSelectedTags = () => {
  const snapshot = useSnapshotState();
  return Array.from(snapshot.draftTags.values());
};

export const useQuestionGenerator = () => {
  const snapshot = useSnapshotState();
  return (key: string) => snapshot.originalQuestions.get(key);
};

export const useQuestionKeys = () => {
  const snapshot = useSnapshotState();
  const selected = snapshot.selectedQuestionKeys;
  const unselected = snapshot.unselectedQuestionKeys;

  return {
    selected: Array.from(selected.values()),
    unselected: Array.from(unselected.values()),
  };
};

export const useFetchedRemotes = () => {
  const snapshot = useSnapshotState();
  return snapshot.remotes;
};

export const useRemoteItems = () => {
  const snapshot = useSnapshotState();
  return Array.from(snapshot.remoteItems.values());
};

export const useFormData = () => {
  const snapshot = useSnapshotState();
  const { selected } = useQuestionKeys();

  return {
    generalSettings: snapshot.generalSettings,
    employeeIds: snapshot.employeeIds,
    groupIds: snapshot.groupIds,
    tags: Array.from(snapshot.draftTags.values()),
    questions: selected.map(key => snapshot.originalQuestions.get(key)).flatMap(v => (v ? [v] : [])),
  };
};

export const useFormRawData = () => {
  const snapshot = useSnapshotState();
  const generalSettings = snapshot.generalSettings;

  return {
    title: generalSettings.title,
    candidateName: generalSettings.candidateName,
    memo: generalSettings.memo,
    isPrivate: generalSettings.isPrivate,
  };
};

export const useRemoteLoadingStatus = () => {
  const snapshot = useSnapshotState();
  return {
    create: snapshot.isCreateRemoteLoading,
    update: snapshot.isUpdateRemoteLoading,
    fetch: snapshot.isFetchRemoteLoading,
  };
};

export const useQuestionLoadingStatus = () => {
  const snapshot = useSnapshotState();
  return {
    list: snapshot.listQuestionStatus,
  };
};

export const useSubmitValues = () => {
  const snapshot = useSnapshotState();
  return snapshot.submitValues;
};

export const useQuestionKeyInheritedEntitySourceMap = () => {
  const snapshot = useSnapshotState();
  return snapshot.questionKeyInheritedEntitySourceMap;
};

export const useEvaluationMetricMap = () => {
  const snapshot = useSnapshotState();
  return snapshot.evaluationMetricMap;
};

export const useInitialEvaluationMetricIds = () => {
  const snapshot = useSnapshotState();
  return snapshot.initialEvaluationMetricIds;
};
