import { useTranslation } from "@hireroo/i18n";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";

import SortField, { SortFieldProps } from "../../../../modules/SortField/SortField";

const Wrapper = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  flexDirection: "row",
  justifyContent: "space-between",
}));

const StyledTypography = styled(Typography)(() => ({
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  wordBreak: "keep-all",
  overflow: "hidden",
}));

const FieldValues = {
  EXPECTED_SALARY_DESCENDING: "EXPECTED_SALARY_DESCENDING",
  EXPECTED_SALARY_ASCENDING: "EXPECTED_SALARY_ASCENDING",
  AVERAGE_SCORE_DESCENDING: "AVERAGE_SCORE_DESCENDING",
  AVERAGE_SCORE_ASCENDING: "AVERAGE_SCORE_ASCENDING",
  AVERAGE_RANK_DESCENDING: "AVERAGE_RANK_DESCENDING",
  AVERAGE_RANK_ASCENDING: "AVERAGE_RANK_ASCENDING",
} as const;
type FieldValue = (typeof FieldValues)[keyof typeof FieldValues];

export type SearchResultBarProps = {
  defaultValue: FieldValue;
  resultText: string;
  onChangeSortMethod: (value: FieldValue) => void;
};

const SearchResultBar: React.FC<SearchResultBarProps> = props => {
  const { t } = useTranslation();

  const sortFieldProps: SortFieldProps = {
    defaultValue: props.defaultValue,
    options: [
      {
        displayName: t("希望年収の高い順"),
        value: FieldValues.EXPECTED_SALARY_DESCENDING,
      },
      {
        displayName: t("希望年収の低い順"),
        value: FieldValues.EXPECTED_SALARY_ASCENDING,
      },
      {
        displayName: t("平均スコアが高い順"),
        value: FieldValues.AVERAGE_SCORE_DESCENDING,
      },
      {
        displayName: t("平均スコアが低い順"),
        value: FieldValues.AVERAGE_SCORE_ASCENDING,
      },
      {
        displayName: t("平均ランクが高い順"),
        value: FieldValues.AVERAGE_RANK_DESCENDING,
      },
      {
        displayName: t("平均ランクが低い順"),
        value: FieldValues.AVERAGE_RANK_ASCENDING,
      },
    ],
    onChange: value => {
      if (Object.values(FieldValues).includes(value as FieldValue)) {
        props.onChangeSortMethod(value as FieldValue);
      }
    },
  };

  return (
    <Wrapper px={2} py={0.5}>
      <Stack direction="row" alignItems="center" justifyContent="center" spacing={2}>
        <StyledTypography variant="body2">{props.resultText}</StyledTypography>
      </Stack>
      <SortField {...sortFieldProps} />
    </Wrapper>
  );
};

SearchResultBar.displayName = "SearchResultBar";

export default SearchResultBar;
