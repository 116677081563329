import { useInterview } from "@hireroo/app-helper/hooks";
import { CompetitionsResultsId } from "@hireroo/app-store/page/c/competitions_results_id";
import { InterviewNavigation } from "@hireroo/app-store/widget/c/InterviewNavigation";
import { Pages, Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import CandidateFooterContainer from "../../../../widget/v2/c/CandidateFooter/Container";
import ScreeningTestHeaderContainer from "../../../../widget/v2/c/ScreeningTestHeader/Container";
import SnackbarContainer from "../../../../widget/v2/shared/Snackbar/Container";
import TestAnnouncementContainer from "../../../../widget/v2/shared/TestAnnouncement/Container";
import * as Subscriber from "./Subscriber";
import { useGenerateProps } from "./useGenerateProps";

export type CompetitionsResultsIdContainerProps = {
  resultId: string;
};

const CompetitionsResultsIdContainer: React.FC<CompetitionsResultsIdContainerProps> = props => {
  const entities = CompetitionsResultsId.useEntities();
  const firstEntity = CompetitionsResultsId.generateNormalizedEntity(entities[0]);
  const [interviewState, interviewAction] = useInterview({
    interviewId: props.resultId,
    interviewType: "result",
    entityId: firstEntity.entityId,
    entityType: firstEntity.entityType,
    questionId: firstEntity.questionId,
    version: firstEntity.questionVersion,
  });

  const competitionsResultsIdProps = useGenerateProps({
    resultId: props.resultId,
    canConnect: interviewState.interviewReady,
  });
  const initialEntityKey = `${interviewState.entityType}-${interviewState.entityId}-${interviewState.questionMap.id}-${interviewState.questionMap.version}`;
  React.useEffect(() => {
    if (!interviewState.interviewReady) {
      return;
    }
    const stopStartSubscribeShowingEntity = Subscriber.startSubscribeOnChangeShowingEntity({
      onChangeShowingEntity: entity => {
        interviewAction.setChangeQuestion(entity.entityId, entity.entityType, entity.questionId, entity.questionVersion);
      },
    });
    const stopSubscribeChallengeCodingEditor = Subscriber.startSubscribeChallengeCodingEditor();
    const stopSubscribeChallengeCodingEditorLoading = Subscriber.startSubscribeChallengeCodingEditorLoading();

    return () => {
      stopStartSubscribeShowingEntity();
      stopSubscribeChallengeCodingEditor();
      stopSubscribeChallengeCodingEditorLoading();
    };
  });
  React.useEffect(() => {
    if (!interviewState.interviewReady) {
      return;
    }
    InterviewNavigation.setSelectedEntityKey(initialEntityKey);
  }, [initialEntityKey, interviewState.interviewReady]);

  if (!interviewState.interviewReady) {
    return <Widget.Loading kind="CENTER" color="secondary" />;
  }
  if (!interviewState.isInterviewing) {
    return (
      <ErrorBoundary>
        <Pages.ScreeningTest
          layout={{
            NotificationBanner: null,
            Header: (
              <ScreeningTestHeaderContainer
                {...{
                  showingMode: "READONLY",
                  onEndInterview: undefined,
                  willBlockEndInterview: true,
                  disabledFinishButton: true,
                  messageForCandidate: undefined,
                }}
              />
            ),
            Footer: <CandidateFooterContainer />,
            Snackbar: <SnackbarContainer />,
            Tutorial: null,
          }}
          Content={<TestAnnouncementContainer kind="ALL_QUESTION_SUBMITTED" />}
        />
      </ErrorBoundary>
    );
  }
  return (
    <ErrorBoundary>
      <Pages.ScreeningTest {...competitionsResultsIdProps} />
    </ErrorBoundary>
  );
};

CompetitionsResultsIdContainer.displayName = "CompetitionsResultsIdContainer";

export default withErrorBoundary(CompetitionsResultsIdContainer, {});
