import * as Graphql from "@hireroo/graphql/client/urql";
import { useTranslation } from "@hireroo/i18n";

export const useCompetitionStatusMap = (): Record<Graphql.CompetitionStatus, string> => {
  const { t } = useTranslation();

  return {
    [Graphql.CompetitionStatus.Created]: t("開催前"),
    [Graphql.CompetitionStatus.Started]: t("開催中"),
    [Graphql.CompetitionStatus.Ended]: t("採点中"),
    [Graphql.CompetitionStatus.Confirmed]: t("採点済み"),
  };
};
