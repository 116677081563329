import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { useGenerateProps } from "./useGenerateProps";

export type RemoteCreateDialogContainerProps = {};

const RemoteCreateDialogContainer: React.FC<RemoteCreateDialogContainerProps> = () => {
  const remoteCreateDialogProps = useGenerateProps({});
  return (
    <ErrorBoundary>
      <Widget.RemoteCreateDialog {...remoteCreateDialogProps} />
    </ErrorBoundary>
  );
};

RemoteCreateDialogContainer.displayName = "RemoteCreateDialogContainer";

export default withErrorBoundary(RemoteCreateDialogContainer, {});
