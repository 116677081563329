import { App } from "@hireroo/app-store/essential/employee";
import { CompetitionsIdDetail } from "@hireroo/app-store/page/e/competitions_id_detail";
import * as Graphql from "@hireroo/graphql/client/urql";
import { Widget } from "@hireroo/presentation";
import { withErrorBoundary } from "@sentry/react";
import React from "react";
import { useParams } from "react-router-dom";

import CompetitionIdDetailContainer, { CompetitionIdDetailContainerProps } from "./Container";

export type CompetitionIdDetailFetchContainerProps = {};

const CompetitionIdDetailFetchContainer: React.FC<CompetitionIdDetailFetchContainerProps> = () => {
  const appStatus = App.useStatus();
  const { id } = useParams<{ id: string }>();
  const competitionId = `${id}`;
  const initialized = CompetitionsIdDetail.useInitialized();

  const [result, refresh] = Graphql.useGetCompetitionForEmployeeCompetitionsIdDetailQuery({
    variables: {
      id: competitionId,
    },
    pause: appStatus !== "INITIALIZED",
    requestPolicy: "cache-and-network",
  });

  React.useEffect(() => {
    if (result.data?.competition) {
      CompetitionsIdDetail.initialize(result.data.competition);
    }
  }, [result.data?.competition]);

  if (result.error) {
    return <Widget.RefreshPanel refresh={refresh} />;
  }
  if (result.fetching || appStatus !== "INITIALIZED" || !initialized) {
    return <Widget.Loading kind="CENTER_%" />;
  }

  const containerProps: CompetitionIdDetailContainerProps = {};

  return <CompetitionIdDetailContainer {...containerProps} />;
};

CompetitionIdDetailFetchContainer.displayName = "CompetitionIdDetailFetchContainer";

export default withErrorBoundary(CompetitionIdDetailFetchContainer, {});
