import * as Graphql from "@hireroo/graphql/client/urql";
import { useTranslation } from "@hireroo/i18n";

export const useChallengeFollowUpQuestionCategoryMap = (): Record<Graphql.ChallengeFollowUpQuestionCategory, string> => {
  const { t } = useTranslation();

  return {
    [Graphql.ChallengeFollowUpQuestionCategory.Unknown]: t("不明"),
    [Graphql.ChallengeFollowUpQuestionCategory.CodingSkill]: t("コーディングスキル"),
    [Graphql.ChallengeFollowUpQuestionCategory.DataStructureAndAlgorithm]: t("データ構造とアルゴリズム"),
    [Graphql.ChallengeFollowUpQuestionCategory.ProblemSolvingSkill]: t("課題解決能力"),
  };
};
