import { INITIAL_VERSION } from "@hireroo/app-definition/question";
import { checkIsQueryMatch } from "@hireroo/app-helper/regex";
import * as Graphql from "@hireroo/graphql/client/urql";
import * as React from "react";
import { useSnapshot } from "valtio";

import * as Def from "./definition";
import { state } from "./State";
import type * as Types from "./types";

const useSnapshotState = () => {
  return useSnapshot(state);
};

const useEntity = () => {
  const snapshot = useSnapshotState();
  return snapshot.remote?.entity;
};

export const useInitialized = () => {
  const snapshot = useSnapshotState();
  if (snapshot.fetchStatus === "BEFORE_FETCH") {
    return false;
  }
  /**
   * If an Employee has removed all sessions, the activeSessionId may not exist even after Fetch.
   */
  if (snapshot.activeSessionMap.size === 0) {
    return true;
  }
  /**
   * If at least one session exists, there will always be an activeSessionId.
   */
  return !!snapshot.remote && snapshot.activeSessionId !== null;
};

export const useActiveSessionId = () => {
  const snapshot = useSnapshotState();
  return snapshot.activeSessionId;
};

export const useActiveSession = () => {
  const snapshot = useSnapshotState();
  return snapshot.activeSessionId ? snapshot.activeSessionMap.get(snapshot.activeSessionId) : null;
};

export const useActiveSessions = () => {
  const snapshot = useSnapshotState();
  return Array.from(snapshot.activeSessionMap.values());
};

export const useActiveSessionCount = () => {
  const activeSessions = useActiveSessions();
  return activeSessions.length;
};

export const useActiveSessionRemoteQuestionVariant = (): Types.LiveCodingQuestionVariant => {
  const activeSession = useActiveSession();
  if (!activeSession) {
    return "UNKNOWN";
  }
  if (activeSession.algorithmQuestion) {
    return activeSession.algorithmQuestion.variant;
  }
  if (activeSession.systemDesignQuestion) {
    return "SYSTEM_DESIGN";
  }
  return "UNKNOWN";
};

export const useHasSession = () => {
  const activeSessions = useActiveSessions();
  return activeSessions.length > 0;
};

export const useNormalizedSessions = () => {
  const activeSessions = useActiveSessions();
  return activeSessions.map((session): Types.NormalizedSession => {
    if (session?.algorithmQuestion) {
      return {
        questionId: session.algorithmQuestion.questionId,
        questionVersion: session.algorithmQuestion.version,
        questionType: Graphql.LiveCodingQuestionType.Algorithm,
        variant: session.algorithmQuestion.variant,
        liveCodingId: session.liveCodingId,
        liveCodingSessionId: session.liveCodingSessionId,
        titleJa: session.algorithmQuestion.titleJa,
        titleEn: session.algorithmQuestion.titleEn,
        descriptionJa: session.algorithmQuestion.descriptionJa,
        descriptionEn: session.algorithmQuestion.descriptionEn,
        difficulty: session.algorithmQuestion.difficulty,
        answers: session.algorithmQuestion.answers.map(answer => {
          return {
            titleJa: answer.titleJa,
            titleEn: answer.titleEn,
            descriptionJa: answer.descriptionJa,
            descriptionEn: answer.descriptionEn,
          };
        }),
      };
    } else if (session?.systemDesignQuestion) {
      return {
        questionId: session.systemDesignQuestion.questionId,
        questionType: Graphql.LiveCodingQuestionType.SystemDesign,
        questionVersion: INITIAL_VERSION,
        variant: "SYSTEM_DESIGN",
        liveCodingId: session.liveCodingId,
        liveCodingSessionId: session.liveCodingSessionId,
        titleJa: session.systemDesignQuestion.titleJa,
        titleEn: session.systemDesignQuestion.titleEn,
        descriptionJa: session.systemDesignQuestion.descriptionJa,
        descriptionEn: session.systemDesignQuestion.descriptionEn,
        difficulty: session.systemDesignQuestion.difficulty,
        answers: session.systemDesignQuestion.answers.map(answer => {
          return {
            titleJa: answer.titleJa,
            titleEn: answer.titleEn,
            descriptionJa: answer.descriptionJa,
            descriptionEn: answer.descriptionEn,
          };
        }),
      };
    }
    throw new Error("Strange error");
  });
};

const useNormalizedActiveSession = () => {
  const snapshot = useSnapshotState();
  const normalizedSessions = useNormalizedSessions();
  return normalizedSessions.find(session => session.liveCodingSessionId === snapshot.activeSessionId);
};

export const useNormalizedCurrentSession = () => {
  const snapshot = useSnapshotState();
  const sessions = useNormalizedSessions();
  return sessions.find(session => session.liveCodingSessionId === snapshot.activeSessionId);
};

export const useLiveCodingId = () => {
  const entity = useEntity();
  if (!entity?.liveCoding?.liveCodingId) {
    throw new Error("Not found live coding ID");
  }
  return entity?.liveCoding?.liveCodingId;
};

export const useLiveCodingStatus = () => {
  const entity = useEntity();
  if (!entity?.liveCoding?.status) {
    throw new Error("Not found live coding status");
  }
  return entity?.liveCoding?.status;
};

export const useLiveCodingVideoChatRoomId = (): string => {
  const snapshot = useSnapshotState();
  return snapshot.liveCodingVideoChatRoomId;
};

export const useLiveCodingVideoChatConversationId = (): string => {
  const snapshot = useSnapshotState();
  return snapshot.liveCodingVideoChatConversationId;
};

export const useLiveCodingCheckJoinStatus = (uid: string | null) => {
  const entity = useEntity();
  if (!uid) {
    return false;
  }
  const participants = entity?.liveCoding?.participants || [];
  return participants.findIndex(participant => participant.userId === uid) >= 0;
};

export const useAlgorithmId = (): string => {
  const activeSession = useActiveSession();
  if (activeSession?.algorithmQuestion) {
    return activeSession?.liveCodingSessionId.toString() ?? Def.ALGORITHM_ID;
  }
  return Def.ALGORITHM_ID;
};

export const useSystemDesignId = (): string => {
  const activeSession = useActiveSession();
  if (activeSession?.systemDesignQuestion) {
    return activeSession?.liveCodingSessionId.toString() ?? Def.SYSTEM_DESIGN_DUMMY_ID;
  }
  return Def.SYSTEM_DESIGN_DUMMY_ID;
};

export const useInterviewStatus = () => {
  const snapshot = useSnapshotState();
  return snapshot.remote?.status;
};

export const useRemotePreparedMetrics = () => {
  const snapshot = useSnapshotState();
  return snapshot.remote?.metrics;
};

export const useLiveCodingQuestionVariant = () => {
  const snapshot = useSnapshotState();
  return snapshot.liveCodingQuestionVariant;
};

export const useLiveCodingTemplateCodes = () => {
  const snapshot = useSnapshotState();
  const normalizedActiveSession = useNormalizedActiveSession();
  if (!normalizedActiveSession) {
    return null;
  }
  const key: Types.LiveCodingTemplateCodesMapKey = `${normalizedActiveSession.questionId}-${normalizedActiveSession.questionVersion}`;
  return snapshot.liveCodingTemplateCodesMap.get(key);
};

export const useLiveCodingTemplateCodeFetched = (): boolean => {
  const snapshot = useSnapshotState();
  const normalizedActiveSession = useNormalizedActiveSession();
  if (!normalizedActiveSession) {
    return false;
  }
  const key: Types.LiveCodingTemplateCodesMapKey = `${normalizedActiveSession.questionId}-${normalizedActiveSession.questionVersion}`;
  return snapshot.liveCodingTemplateCodesMap.has(key);
};

export const useMetrics = () => {
  const snapshot = useSnapshotState();
  return Array.from(snapshot.metrics.values());
};

export const useMetricMap = () => {
  const snapshot = useSnapshotState();
  return snapshot.metrics;
};

export const useMetricPagination = () => {
  const snapshot = useSnapshotState();
  return snapshot.metricPagination;
};

export const useSearchText = () => {
  const snapshot = useSnapshotState();
  return snapshot.searchText;
};

export const useSelectableMetrics = () => {
  const { searchText, selectedMetricIds } = useSnapshotState();
  const original = useMetrics();
  return React.useMemo(() => {
    const selectedMetricSet = selectedMetricIds;
    const selectableMetrics = original.filter(metric => !selectedMetricSet.has(metric.metricId));
    const filter = (metric: Types.Metric): boolean => {
      if (searchText === "") {
        return true;
      }
      return checkIsQueryMatch(searchText, metric.title) || checkIsQueryMatch(searchText, metric.description);
    };
    return selectableMetrics.filter(filter);
  }, [original, searchText, selectedMetricIds]);
};

export const useEmployeeUser = (uid: string) => {
  const entity = useEntity();
  const participant = entity?.liveCoding?.participants.find(participant => participant.userId === uid);
  if (!participant) {
    throw new Error("user not found. It will not work properly.");
  }
  return participant;
};
