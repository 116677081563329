/* eslint-disable no-prototype-builtins */
import "firebase/compat/database";

import { initialLanguageMap } from "@hireroo/challenge/definition";
import { getRef, getTimestamp, TimeStamp } from "@hireroo/firebase";
import * as Graphql from "@hireroo/graphql/client/urql";
import {
  AddComment,
  AddElement,
  AdjustPosition,
  CommentElement,
  COMPONENT_TYPE,
  ComponentType,
  ConnectNodes,
  DeleteElements,
  EdgeElement,
  EditComment,
  ELEMENT_TYPE,
  ElementLabel,
  ElementType,
  FLOW_ACTION,
  FlowAction,
  FlowElement,
  MoveElements,
  OPERATION_TYPE,
  OperationType,
  PasteElements,
  ReconnectEdge,
  ResetElements,
  ReviveElements,
  Settings,
  ShapeElement,
  UnionSettingsFields,
  UpdateSettings,
  UserState,
} from "@hireroo/system-design/features";
import { isValidComponentType, parseFlowChartSnapshot } from "@hireroo/system-design/helpers/flowChart";
import { useSystemDesignContext } from "@hireroo/system-design/react/FlowChart";
import firebase from "firebase/compat/app";
import * as React from "react";
import { useImmer } from "use-immer";

import { colorFromUserId } from "../color";
import { revisionFromId, revisionToId, SyncState } from "../firepad";
import { BrowserWindowEventDetect, useFirebaseSender } from "./useBrowserWindowEventDetect";

const timeInSeconds = (): number => {
  return new Date().getTime();
};

export type CollaborativeState = {
  collaborators: UserState[];
  users: Record<string, UserState>;
  participantNames: string[];
  selectedQuestion: number;
  selectedQuestionType: Graphql.LiveCodingQuestionType;
  selectedQuestionVariant: Graphql.LiveCodingQuestionVariant;
  selectedSession: number;
  isLiveCodingFinished: boolean;
  liveCodingReady: boolean;
  ready: boolean;
  canUndo: boolean;
  canRedo: boolean;
  selectedLanguage: string;
  selectedComponentType: ComponentType;
  sortedCurrentParticipantUids: string[];
};

type SelectSessionPayload = {
  s: "sels";
  /** Session ID */
  v: number;
  t: TimeStamp;
};

type InSessionPayload = {
  s: "ins";
  /** Session ID */
  v: number;
  t: TimeStamp;
};

type OutSessionPayload = {
  s: "outs";
  /** Session ID */
  v: number;
  t: TimeStamp;
};

type SelectLanguagePayload = {
  s: "sell";
  /** Language */
  v: string;
  t: TimeStamp;
};

type SelectComponentTypePayload = {
  s: "selc";
  /** Component Type */
  v: string;
  t: TimeStamp;
};

type AddSessionPayload = {
  s: "adds";
  /** Session Id */
  v: number;
  t: TimeStamp;
};

type DeleteSessionPayload = {
  s: "dels";
  /** Session Id */
  v: number;
  t: TimeStamp;
};

type FinishLiveCodingPayload = {
  s: "finl";
  /** Live Coding ID */
  v: number;
  t: TimeStamp;
};

type LiveCodingPayload =
  | SelectSessionPayload
  | InSessionPayload
  | OutSessionPayload
  | SelectLanguagePayload
  | SelectComponentTypePayload
  | AddSessionPayload
  | DeleteSessionPayload
  | FinishLiveCodingPayload;

export type SyncStateCallback = (payload: LiveCodingPayload) => void;

export type CollaborativeAction = {
  selectComponentType: (componentType: ComponentType) => void;
  setSelectedLanguageWrapper: (newSelectedLanguage: string) => void;
  setSelectedSessionWrapper: (newSessionId: number) => void;
  setSelectedQuestionWrapper: (
    newQuestionId: number,
    newQuestionVersion: string,
    newQuestionVariant: Graphql.LiveCodingQuestionVariant,
    newQuestionType: Graphql.LiveCodingQuestionType,
  ) => void;
  setOutSessionWrapper: (currentSessionId: number) => void;
  addSession: (sessionId: number) => void;
  finishLiveCoding: () => void;
  deleteSession: (sessionId: number) => void;
  addElement: (
    id: string,
    type: ElementType,
    label: ElementLabel,
    x: number,
    y: number,
    w: number,
    h: number,
    initialSettings: Settings,
    operationType: OperationType,
  ) => void;
  addComment: (id: string, content: string, x: number, y: number, fontSize: number, operationType: OperationType) => void;
  editComment: (id: string, content: string, operationType: OperationType) => void;
  deleteElements: (ids: string[], operationType: OperationType) => void;
  moveElements: (ids: string[], dx: number, dy: number, operationType: OperationType) => void;
  shapeElement: (id: string, position: AdjustPosition, dx: number, dy: number, operationType: OperationType) => void;
  connectNodes: (id: string, source: string, target: string, operationType: OperationType) => void;
  reconnectEdge: (id: string, source: string, target: string, operationType: OperationType) => void;
  pasteElements: (destElements: FlowElement[], operationType: OperationType) => void;
  updateSettings: (id: string, updates: UnionSettingsFields, operationType: OperationType) => void;
  resetElements: () => void;
  redo: () => void;
  undo: () => void;
  selectElement: (ids: string[]) => void;
  saveEditingCommentId: (id: string | null) => void;
  moveCursor: (x: number, y: number) => void;
};

export type LiveCodingRealtimeDatabaseArgs = {
  onChangeSyncState?: SyncStateCallback;
  liveCodingId: number;
  sessionId?: number;
  isInterviewing?: boolean;
  uid: string;
  participantName: string;
  isCandidate: boolean;
  // TODO: Refactor this interface with initialState
  session: {
    id: number;
    liveCodingId: number;
    liveCodingQuestionType: Graphql.LiveCodingQuestionType;
    algorithmQuestion: {
      id: number;
      version: string;
      questionVariant: Graphql.LiveCodingQuestionVariant;
      initialLanguage: string;
    } | null;
    systemDesignQuestion: {
      id: number;
      initialFlowChartSnapshot: string;
      initialComponentType: ComponentType;
    } | null;
  } | null;
  enableBrowserEventDetector: boolean;
};

type ReturnValue = {
  collaborativeState: CollaborativeState;
  collaborativeAction: CollaborativeAction;
};

export const useLiveCodingRealtimeDatabase = (args: LiveCodingRealtimeDatabaseArgs): ReturnValue => {
  const { onChangeSyncState, isCandidate, participantName, session, liveCodingId, sessionId, isInterviewing, uid } = args;

  const store = useSystemDesignContext();
  // To write the realtime log at liveCodings/${liveCodingId}
  const liveCodingIndexRef = React.useRef<number>(0);
  const liveCodingRef = React.useRef<firebase.database.Reference | null>(null);
  const liveCodingLastStateRef = React.useRef<SyncState>();
  const [liveCodingReady, setLiveCodingReady] = React.useState<boolean>(false);

  // To write the realtime log at liveCoding liveCodings/${liveCodingId}/sessions/${sessionId}
  const sessionIndexRef = React.useRef<number>(0);
  const sessionRef = React.useRef<firebase.database.Reference | undefined>();
  const [sessionReady, setSessionReady] = React.useState<boolean>(false);

  // To write the realtime log at liveCodings/${liveCodingId}/sessions/${sessionId}/componentTypes/${componentType}
  const componentTypeIndexRef = React.useRef<number>(0);
  const componentTypeRef = React.useRef<firebase.database.Reference | undefined>();
  const [componentTypeReady, setComponentTypeReady] = React.useState<boolean>(false);

  const historyRef = React.useRef<firebase.database.Reference | undefined>();
  const [historyReady, setHistoryReady] = React.useState<boolean>(false);

  const userRef = React.useRef<firebase.database.Reference | null>(null);
  const [userReady, setUserReady] = React.useState(false);
  const [users, setUsers] = useImmer<Record<string, UserState>>({});
  const collaborators = React.useMemo(() => {
    return Object.keys(users)
      .filter(id => id !== uid)
      .map(id => users[id]);
  }, [uid, users]);

  const LiveCodingState = React.useMemo(() => {
    return {
      setup: () => {
        const key = `liveCodings/${liveCodingId}/state`;
        const currentKey = liveCodingRef.current?.toString();
        /**
         * If it is the same key, use it.
         */
        if (currentKey && currentKey.match(key)) {
          return;
        }
        liveCodingRef.current = getRef("liveCoding", `liveCodings/${liveCodingId}/state`);
      },
      set: (payload: LiveCodingPayload) => {
        liveCodingRef.current?.child(revisionToId(liveCodingIndexRef.current)).set(payload);
      },
      onChildAdded: (callback: (snapshot: firebase.database.DataSnapshot) => void) => {
        liveCodingRef.current?.on("child_added", callback);
      },
      onceValue: (callback: (snapshot: firebase.database.DataSnapshot) => void) => {
        liveCodingRef.current?.once("value", callback);
      },
      clear: () => {
        liveCodingRef.current = null;
      },
    };
  }, [liveCodingId]);

  const participantNames = React.useMemo(() => {
    return Object.values(users).map(u => u.name);
  }, [users]);

  // To write the realtime log at liveCodings/${liveCodingId}/sessions/${sessionId}/languages/${language}
  const languageIndexRef = React.useRef<number>(0);
  const languageRef = React.useRef<firebase.database.Reference | undefined>();
  const [languageReady, setLanguageReady] = React.useState<boolean>(false);

  const [selectedQuestion, setSelectedQuestion] = React.useState<number>(0);
  const [selectedQuestionVariant, setSelectedQuestionVariant] = React.useState<Graphql.LiveCodingQuestionVariant>(
    Graphql.LiveCodingQuestionVariant.Unknown,
  );
  const [selectedQuestionType, setSelectedQuestionType] = React.useState<Graphql.LiveCodingQuestionType>(
    Graphql.LiveCodingQuestionType.Unknown,
  );
  const [selectedSessionId, setSelectedSessionId] = React.useState<number>(sessionId ?? 0);
  const [selectedLanguage, setSelectedLanguage] = React.useState<string>(
    args.session?.algorithmQuestion?.initialLanguage ?? initialLanguageMap["ALGORITHM"],
  );
  const [selectedComponentType, setSelectedComponentType] = React.useState<ComponentType>(
    args.session?.systemDesignQuestion?.initialComponentType ?? COMPONENT_TYPE.default,
  );
  const [isLiveCodingFinished, setIsLiveCodingFinished] = React.useState<boolean>(false);

  const LiveCodingUsers = React.useMemo(() => {
    return {
      setup: () => {
        // Connect to firebase for realtime sync
        userRef.current = getRef("liveCoding", `liveCodings/${liveCodingId}/sessions/${selectedSessionId}/users`);
      },
      set: (payload: object) => {
        userRef.current?.child(uid).set(payload);
      },
      update: (payload: object) => {
        userRef.current?.child(uid).update(payload);
      },
      onChildAdded: (callback: (snapshot: firebase.database.DataSnapshot) => void) => {
        // Set user state from the realtime database
        userRef.current?.on("child_added", callback);
      },
      onceValue: (callback: (snapshot: firebase.database.DataSnapshot) => void) => {
        userRef.current?.once("value", callback);
      },
      onChildChanged: (callback: (snapshot: firebase.database.DataSnapshot) => void) => {
        // Track a peer's state such as cursor movement or selecting elements
        userRef.current?.once("child_changed", callback);
      },
      onChildRemoved: (callback: (snapshot: firebase.database.DataSnapshot) => void) => {
        // If a peer's session has disconnected, delete the peer from the local state
        userRef.current?.once("child_removed", callback);
      },
      onDisconnect: () => {
        // When a user's connection has lost, remove the user from the realtime database
        userRef.current?.child(uid).onDisconnect().remove();
      },
      off: () => {
        userRef.current?.off("child_added");
        userRef.current?.off("child_changed");
        userRef.current?.off("child_removed");
      },
      clear: () => {
        userRef.current = null;
      },
    };
  }, [liveCodingId, selectedSessionId, uid]);
  React.useEffect(() => {
    if (!session) return;

    if (session.algorithmQuestion) {
      const question = session.algorithmQuestion;
      setSelectedQuestion(question.id);
      switch (question.questionVariant) {
        case "ALGORITHM":
          setSelectedQuestionVariant(Graphql.LiveCodingQuestionVariant.Algorithm);
          break;
        case "CLASS":
          setSelectedQuestionVariant(Graphql.LiveCodingQuestionVariant.Class);
          break;
        case "DATABASE":
          setSelectedQuestionVariant(Graphql.LiveCodingQuestionVariant.Database);
          break;
      }
    }

    if (session.systemDesignQuestion) {
      setSelectedQuestion(session.systemDesignQuestion.id);
      setSelectedQuestionVariant("SYSTEM_DESIGN");
      // TODO: It's probably better to do set initial flowchart's componentType
    }

    setSelectedQuestionType(session.liveCodingQuestionType);
  }, [session]);

  const initialFlowChartSnapshot = React.useMemo((): string => {
    if (session?.systemDesignQuestion) {
      return session.systemDesignQuestion.initialFlowChartSnapshot;
    }
    return "";
  }, [session?.systemDesignQuestion]);

  const initialElements: FlowElement[] = React.useMemo(() => {
    if (!initialFlowChartSnapshot) return [];
    const { ok, result } = parseFlowChartSnapshot(initialFlowChartSnapshot);
    return ok ? result.elements : [];
  }, [initialFlowChartSnapshot]);

  const [undoStack, setUndoStack] = useImmer<FlowAction[]>([]);
  const [redoStack, setRedoStack] = useImmer<FlowAction[]>([]);

  const elementFactory = store.hooks.useElement();
  const withNeighborEdge = store.hooks.useWithNeighborEdge();

  const ready = React.useMemo<boolean>(() => {
    const readyWithoutVariant = liveCodingReady && sessionReady && historyReady && userReady;
    switch (selectedQuestionVariant) {
      case "ALGORITHM":
      case "DATABASE":
      case "CLASS":
        return languageReady;
      case "SYSTEM_DESIGN":
        return componentTypeReady;
      case "UNKNOWN":
        return readyWithoutVariant;
      default:
        throw new Error(`Unknown type: ${selectedQuestionVariant as { type: "__invalid__" }}`);
    }
  }, [componentTypeReady, historyReady, languageReady, liveCodingReady, selectedQuestionVariant, sessionReady, userReady]);

  const [isConnected, setIsConnected] = React.useState<boolean>(false);
  const { pushEventHistory } = useFirebaseSender({
    appType: "liveCoding",
    uid: args.uid,
    liveCodingId: liveCodingId,
    sessionId: selectedSessionId,
  });
  const browserWindowEventDetector = React.useRef(
    new BrowserWindowEventDetect({
      callback: pushEventHistory,
    }),
  );
  React.useEffect(() => {
    if (!args.enableBrowserEventDetector) {
      return;
    }
    if (isLiveCodingFinished) {
      return;
    }
    const stop = browserWindowEventDetector.current.subscribe();
    return () => {
      stop();
    };
  }, [browserWindowEventDetector, args.enableBrowserEventDetector, isLiveCodingFinished]);

  const setOutSessionWrapper = React.useCallback(
    (newSelectedSession: number) => {
      if (!liveCodingReady) return;
      const payload: OutSessionPayload = {
        s: "outs",
        v: newSelectedSession,
        t: timeInSeconds(),
      };
      LiveCodingState.set(payload);
    },
    [LiveCodingState, liveCodingReady],
  );

  const setSelectedLanguageWrapper = React.useCallback(
    (newSelectedLanguage: string) => {
      if (!sessionReady) return;
      const payload: SelectLanguagePayload = {
        s: "sell",
        v: newSelectedLanguage,
        t: getTimestamp(),
      };
      sessionRef.current?.child(revisionToId(sessionIndexRef.current)).set(payload);
    },
    [sessionReady],
  );

  const selectComponentType = React.useCallback(
    (componentType: ComponentType) => {
      if (!sessionReady) return;
      const payload: SelectComponentTypePayload = {
        s: "selc",
        v: componentType,
        t: timeInSeconds(),
      };
      sessionRef.current?.child(revisionToId(sessionIndexRef.current)).set(payload);
    },
    [sessionReady],
  );

  const setSelectedSessionWrapper = React.useCallback(
    (newSessionId: number) => {
      setSelectedSessionId(newSessionId);
      // session is probably empty
      if (!liveCodingReady) return;
      const payload: SelectSessionPayload = {
        s: "sels",
        v: newSessionId,
        t: getTimestamp(),
      };
      LiveCodingState.set(payload);
    },
    [LiveCodingState, liveCodingReady],
  );

  const setSelectedQuestionWrapper = React.useCallback(
    (
      newQuestionId: number,
      newQuestionVersion: string,
      newQuestionVariant: Graphql.LiveCodingQuestionVariant,
      newQuestionType: Graphql.LiveCodingQuestionType,
    ) => {
      if (!liveCodingReady) return;
      setSelectedQuestion(newQuestionId);
      setSelectedQuestionVariant(newQuestionVariant);
      setSelectedQuestionType(newQuestionType);
    },
    [liveCodingReady],
  );

  const addSession = React.useCallback(
    (sessionId: number) => {
      setSelectedSessionId(sessionId);
      const payload: AddSessionPayload = {
        s: "adds",
        v: sessionId,
        t: getTimestamp(),
      };
      LiveCodingState.set(payload);
    },
    [LiveCodingState],
  );

  const deleteSession = React.useCallback(
    (sessionId: number) => {
      const payload: DeleteSessionPayload = {
        s: "dels",
        v: sessionId,
        t: getTimestamp(),
      };
      LiveCodingState.set(payload);
    },
    [LiveCodingState],
  );

  const finishLiveCoding = React.useCallback(() => {
    const payload: FinishLiveCodingPayload = {
      s: "finl",
      v: liveCodingId,
      t: getTimestamp(),
    };
    LiveCodingState.set(payload);
  }, [LiveCodingState, liveCodingId]);

  // <--- This is the end of the implementation regarding Live Coding. -->

  /*
    1. A Mouse event detected in the drawing triggers the functions defined below.
    2. In these functions, push a history node in the firebase after saving the operation and the inverse one to stacks.
    3. A firebase event that the history node is added triggers an update of the flowchart state.
    Note that a firebase event is triggered immediately by optimistic updates, so no round trip for a local state update.
  */

  const addElement = React.useCallback(
    (
      id: string,
      type: ElementType,
      label: ElementLabel,
      x: number,
      y: number,
      w: number,
      h: number,
      initialSettings: Settings,
      operationType: OperationType,
    ) => {
      const operation: AddElement = {
        s: FLOW_ACTION.addElement,
        v: {
          id,
          t: type,
          l: label,
          x,
          y,
          w,
          h,
          s: initialSettings,
        },
        a: uid,
        t: timeInSeconds(),
      };
      const inverseOperation: DeleteElements = {
        s: FLOW_ACTION.deleteElements,
        v: {
          ids: [id],
        },
        a: uid,
        t: timeInSeconds(),
      };
      if (operationType === OPERATION_TYPE.do || operationType === OPERATION_TYPE.redo) {
        setUndoStack(draft => {
          draft.push(inverseOperation);
        });
      }
      if (operationType === OPERATION_TYPE.undo) {
        setRedoStack(draft => {
          draft.push(inverseOperation);
        });
      }

      historyRef.current?.push(operation);
    },
    [setRedoStack, setUndoStack, uid],
  );

  const addComment = React.useCallback(
    (id: string, content: string, x: number, y: number, fontSize: number, operationType: OperationType) => {
      const operation: AddComment = {
        s: FLOW_ACTION.addComment,
        v: {
          id,
          c: content,
          x,
          y,
          f: fontSize,
        },
        a: uid,
        t: timeInSeconds(),
      };
      const inverseOperation: DeleteElements = {
        s: FLOW_ACTION.deleteElements,
        v: {
          ids: [id],
        },
        a: uid,
        t: timeInSeconds(),
      };
      if (operationType === OPERATION_TYPE.do || operationType === OPERATION_TYPE.redo) {
        setUndoStack(draft => {
          draft.push(inverseOperation);
        });
      }
      if (operationType === OPERATION_TYPE.undo) {
        setRedoStack(draft => {
          draft.push(inverseOperation);
        });
      }
      historyRef.current?.push(operation);
    },
    [setRedoStack, setUndoStack, uid],
  );

  const editComment = React.useCallback(
    (id: string, content: string, operationType: OperationType) => {
      const operation: EditComment = {
        s: FLOW_ACTION.editComment,
        v: {
          id,
          c: content,
        },
        a: uid,
        t: timeInSeconds(),
      };
      const comment = elementFactory(id) as CommentElement;
      if (!comment) return;
      const inverseOperation: EditComment = {
        s: FLOW_ACTION.editComment,
        v: {
          id,
          c: comment.content,
        },
        a: uid,
        t: timeInSeconds(),
      };

      if (operationType === OPERATION_TYPE.do || operationType === OPERATION_TYPE.redo) {
        setUndoStack(draft => {
          draft.push(inverseOperation);
        });
      }
      if (operationType === OPERATION_TYPE.undo) {
        setRedoStack(draft => {
          draft.push(inverseOperation);
        });
      }
      historyRef.current?.push(operation);
    },
    [elementFactory, setRedoStack, setUndoStack, uid],
  );

  const deleteElements = React.useCallback(
    (ids: string[], operationType: OperationType) => {
      if (ids.length === 0) return;
      const operation: DeleteElements = {
        s: FLOW_ACTION.deleteElements,
        v: {
          ids: withNeighborEdge(ids),
        },
        a: uid,
        t: timeInSeconds(),
      };
      const inverseOperation: ReviveElements = {
        s: FLOW_ACTION.reviveElements,
        v: {
          ids: withNeighborEdge(ids),
        },
        a: uid,
        t: timeInSeconds(),
      };
      if (operationType === OPERATION_TYPE.do || operationType === OPERATION_TYPE.redo) {
        setUndoStack(draft => {
          draft.push(inverseOperation);
        });
      }
      if (operationType === OPERATION_TYPE.undo) {
        setRedoStack(draft => {
          draft.push(inverseOperation);
        });
      }
      historyRef.current?.push(operation);
    },
    [setRedoStack, setUndoStack, uid, withNeighborEdge],
  );

  const reviveElements = React.useCallback(
    (ids: string[], operationType: OperationType) => {
      if (ids.length === 0) return;
      const operation: ReviveElements = {
        s: FLOW_ACTION.reviveElements,
        v: {
          ids,
        },
        a: uid,
        t: timeInSeconds(),
      };
      const inverseOperation: DeleteElements = {
        s: FLOW_ACTION.deleteElements,
        v: {
          ids,
        },
        a: uid,
        t: timeInSeconds(),
      };
      if (operationType === OPERATION_TYPE.do || operationType === OPERATION_TYPE.redo) {
        setUndoStack(draft => {
          draft.push(inverseOperation);
        });
      }
      if (operationType === OPERATION_TYPE.undo) {
        setRedoStack(draft => {
          draft.push(inverseOperation);
        });
      }
      historyRef.current?.push(operation);
    },
    [setRedoStack, setUndoStack, uid],
  );

  const moveElements = React.useCallback(
    (ids: string[], dx: number, dy: number, operationType: OperationType) => {
      if (ids.length === 0) return;
      const operation: MoveElements = {
        s: FLOW_ACTION.moveElements,
        v: {
          ids,
          dx,
          dy,
        },
        a: uid,
        t: timeInSeconds(),
      };
      const inverseOperation: MoveElements = {
        s: FLOW_ACTION.moveElements,
        v: {
          ids,
          dx: -dx,
          dy: -dy,
        },
        a: uid,
        t: timeInSeconds(),
      };
      if (operationType === OPERATION_TYPE.do || operationType === OPERATION_TYPE.redo) {
        setUndoStack(draft => {
          draft.push(inverseOperation);
        });
      }
      if (operationType === OPERATION_TYPE.undo) {
        setRedoStack(draft => {
          draft.push(inverseOperation);
        });
      }
      // Only the peer's move is reflected via the firebase event since a move operation continuously changes the local state,
      // but redo and undo is the exception, hence update the local state here
      if (operationType === OPERATION_TYPE.undo || operationType === OPERATION_TYPE.redo) {
        store.action.moveElements({ ids, dx, dy });
      }
      historyRef.current?.push(operation);
    },
    [store.action, setRedoStack, setUndoStack, uid],
  );

  const shapeElement = React.useCallback(
    (id: string, position: AdjustPosition, dx: number, dy: number, operationType: OperationType) => {
      const operation: ShapeElement = {
        s: FLOW_ACTION.shapeElement,
        v: {
          id,
          p: position,
          dx,
          dy,
        },
        a: uid,
        t: timeInSeconds(),
      };
      const inverseOperation: ShapeElement = {
        s: FLOW_ACTION.shapeElement,
        v: {
          id,
          p: position,
          dx: -dx,
          dy: -dy,
        },
        a: uid,
        t: timeInSeconds(),
      };
      if (operationType === OPERATION_TYPE.do || operationType === OPERATION_TYPE.redo) {
        setUndoStack(draft => {
          draft.push(inverseOperation);
        });
      }
      if (operationType === OPERATION_TYPE.undo) {
        setRedoStack(draft => {
          draft.push(inverseOperation);
        });
      }
      if (operationType === OPERATION_TYPE.undo || operationType === OPERATION_TYPE.redo) {
        store.action.shapeElement({ id, dx, dy, adjustPosition: position });
      }
      historyRef.current?.push(operation);
    },
    [store.action, setRedoStack, setUndoStack, uid],
  );

  const connectNodes = React.useCallback(
    (id: string, source: string, target: string, operationType: OperationType) => {
      const operation: ConnectNodes = {
        s: FLOW_ACTION.connectNodes,
        v: {
          id,
          s: source,
          t: target,
        },
        a: uid,
        t: timeInSeconds(),
      };
      const inverseOperation: DeleteElements = {
        s: FLOW_ACTION.deleteElements,
        v: {
          ids: [id],
        },
        a: uid,
        t: timeInSeconds(),
      };
      if (operationType === OPERATION_TYPE.do || operationType === OPERATION_TYPE.redo) {
        setUndoStack(draft => {
          draft.push(inverseOperation);
        });
      }
      if (operationType === OPERATION_TYPE.undo) {
        setRedoStack(draft => {
          draft.push(inverseOperation);
        });
      }
      historyRef.current?.push(operation);
    },
    [setRedoStack, setUndoStack, uid],
  );

  const reconnectEdge = React.useCallback(
    (id: string, source: string, target: string, operationType: OperationType) => {
      const operation: ReconnectEdge = {
        s: FLOW_ACTION.reconnectEdge,
        v: {
          id,
          s: source,
          t: target,
        },
        a: uid,
        t: timeInSeconds(),
      };
      const edge = elementFactory(id) as EdgeElement;
      const inverseOperation: ReconnectEdge = {
        s: FLOW_ACTION.reconnectEdge,
        v: {
          id,
          s: edge.source,
          t: edge.target,
        },
        a: uid,
        t: timeInSeconds(),
      };
      if (operationType === OPERATION_TYPE.do || operationType === OPERATION_TYPE.redo) {
        setUndoStack(draft => {
          draft.push(inverseOperation);
        });
      }
      if (operationType === OPERATION_TYPE.undo) {
        setRedoStack(draft => {
          draft.push(inverseOperation);
        });
      }
      historyRef.current?.push(operation);
    },
    [elementFactory, setRedoStack, setUndoStack, uid],
  );

  const pasteElements = React.useCallback(
    (destElements: FlowElement[], operationType: OperationType) => {
      if (destElements.length === 0) return;
      const operation: PasteElements = {
        s: FLOW_ACTION.pasteElements,
        v: {
          dst: destElements,
        },
        a: uid,
        t: timeInSeconds(),
      };
      const inverseOperation: DeleteElements = {
        s: FLOW_ACTION.deleteElements,
        v: {
          ids: destElements.map(element => element.id),
        },
        a: uid,
        t: timeInSeconds(),
      };
      if (operationType === OPERATION_TYPE.do || operationType === OPERATION_TYPE.redo) {
        setUndoStack(draft => {
          draft.push(inverseOperation);
        });
      }
      if (operationType === OPERATION_TYPE.undo) {
        setRedoStack(draft => {
          draft.push(inverseOperation);
        });
      }
      historyRef.current?.push(operation);
    },
    [setRedoStack, setUndoStack, uid],
  );

  const updateSettings = React.useCallback(
    (id: string, updates: UnionSettingsFields, operationType: OperationType) => {
      // Just return when there is no update
      if (Object.keys(updates).length === 0) return;
      const operation: UpdateSettings = {
        s: FLOW_ACTION.updateSettings,
        v: {
          id,
          u: updates,
        },
        a: uid,
        t: timeInSeconds(),
      };
      const inverseUpdates = {};
      const element = elementFactory(id);

      if (element) {
        if (element.type === ELEMENT_TYPE.comment) {
          if (updates.hasOwnProperty("fontSize")) Object.assign(inverseUpdates, { fontSize: element.settings.fontSize });
        } else if (element.type === ELEMENT_TYPE.edge) {
          if (updates.hasOwnProperty("direction")) Object.assign(inverseUpdates, { direction: element.settings.direction });
        } else {
          if (updates.name) Object.assign(inverseUpdates, { name: element.settings.name });
        }
      }

      const inverseOperation: UpdateSettings = {
        s: FLOW_ACTION.updateSettings,
        v: {
          id,
          u: inverseUpdates,
        },
        a: uid,
        t: timeInSeconds(),
      };
      if (operationType === OPERATION_TYPE.do || operationType === OPERATION_TYPE.redo) {
        setUndoStack(draft => {
          draft.push(inverseOperation);
        });
      }
      if (operationType === OPERATION_TYPE.undo) {
        setRedoStack(draft => {
          draft.push(inverseOperation);
        });
      }
      historyRef.current?.push(operation);
    },
    [elementFactory, setRedoStack, setUndoStack, uid],
  );

  const resetElements = React.useCallback(() => {
    const operation: ResetElements = {
      s: FLOW_ACTION.resetElements,
      a: uid,
      t: timeInSeconds(),
    };
    setUndoStack([]);
    setRedoStack([]);
    historyRef.current?.push(operation);
  }, [setRedoStack, setUndoStack, uid]);

  const undo = React.useCallback(() => {
    const history = undoStack[undoStack.length - 1];
    if (!history) return;

    setUndoStack(draft => {
      draft.pop();
    });

    switch (history.s) {
      case FLOW_ACTION.addElement:
        addElement(
          history.v.id,
          history.v.t,
          history.v.l,
          history.v.x,
          history.v.y,
          history.v.w,
          history.v.h,
          history.v.s,
          OPERATION_TYPE.undo,
        );
        return;
      case FLOW_ACTION.addComment:
        addComment(history.v.id, history.v.c, history.v.x, history.v.y, history.v.f, OPERATION_TYPE.undo);
        return;
      case FLOW_ACTION.editComment:
        editComment(history.v.id, history.v.c, OPERATION_TYPE.undo);
        return;
      case FLOW_ACTION.deleteElements:
        deleteElements(history.v.ids, OPERATION_TYPE.undo);
        return;
      case FLOW_ACTION.reviveElements:
        reviveElements(history.v.ids, OPERATION_TYPE.undo);
        return;
      case FLOW_ACTION.moveElements:
        moveElements(history.v.ids, history.v.dx, history.v.dy, OPERATION_TYPE.undo);
        return;
      case FLOW_ACTION.shapeElement:
        shapeElement(history.v.id, history.v.p, history.v.dx, history.v.dy, OPERATION_TYPE.undo);
        return;
      case FLOW_ACTION.connectNodes:
        connectNodes(history.v.id, history.v.s, history.v.t, OPERATION_TYPE.undo);
        return;
      case FLOW_ACTION.reconnectEdge:
        reconnectEdge(history.v.id, history.v.s, history.v.t, OPERATION_TYPE.undo);
        return;
      case FLOW_ACTION.pasteElements:
        pasteElements(history.v.dst, OPERATION_TYPE.undo);
        return;
      case FLOW_ACTION.updateSettings:
        updateSettings(history.v.id, history.v.u, OPERATION_TYPE.undo);
        return;
    }
  }, [
    addComment,
    addElement,
    connectNodes,
    deleteElements,
    editComment,
    moveElements,
    pasteElements,
    reconnectEdge,
    reviveElements,
    setUndoStack,
    shapeElement,
    undoStack,
    updateSettings,
  ]);

  const redo = React.useCallback(() => {
    const history = redoStack[redoStack.length - 1];
    if (!history) return;

    setRedoStack(draft => {
      draft.pop();
    });

    switch (history.s) {
      case FLOW_ACTION.addElement:
        addElement(
          history.v.id,
          history.v.t,
          history.v.l,
          history.v.x,
          history.v.y,
          history.v.w,
          history.v.h,
          history.v.s,
          OPERATION_TYPE.redo,
        );
        return;
      case FLOW_ACTION.addComment:
        addComment(history.v.id, history.v.c, history.v.x, history.v.y, history.v.f, OPERATION_TYPE.redo);
        return;
      case FLOW_ACTION.editComment:
        editComment(history.v.id, history.v.c, OPERATION_TYPE.redo);
        return;
      case FLOW_ACTION.deleteElements:
        deleteElements(history.v.ids, OPERATION_TYPE.redo);
        return;
      case FLOW_ACTION.reviveElements:
        reviveElements(history.v.ids, OPERATION_TYPE.redo);
        return;
      case FLOW_ACTION.moveElements:
        moveElements(history.v.ids, history.v.dx, history.v.dy, OPERATION_TYPE.redo);
        return;
      case FLOW_ACTION.shapeElement:
        shapeElement(history.v.id, history.v.p, history.v.dx, history.v.dy, OPERATION_TYPE.redo);
        return;
      case FLOW_ACTION.connectNodes:
        connectNodes(history.v.id, history.v.s, history.v.t, OPERATION_TYPE.redo);
        return;
      case FLOW_ACTION.reconnectEdge:
        reconnectEdge(history.v.id, history.v.s, history.v.t, OPERATION_TYPE.redo);
        return;
      case FLOW_ACTION.pasteElements:
        pasteElements(history.v.dst, OPERATION_TYPE.redo);
        return;
      case FLOW_ACTION.updateSettings:
        updateSettings(history.v.id, history.v.u, OPERATION_TYPE.redo);
        return;
    }
  }, [
    addComment,
    addElement,
    connectNodes,
    deleteElements,
    editComment,
    moveElements,
    pasteElements,
    reconnectEdge,
    redoStack,
    reviveElements,
    setRedoStack,
    shapeElement,
    updateSettings,
  ]);

  const selectElement = React.useCallback(
    (ids: string[]) => {
      if (!isConnected) return;
      LiveCodingUsers.update({
        select: ids,
      });
    },
    [isConnected, LiveCodingUsers],
  );

  const saveEditingCommentId = React.useCallback(
    (id: string | null) => {
      if (!isConnected) return;
      LiveCodingUsers.update({
        edit: id,
      });
    },
    [isConnected, LiveCodingUsers],
  );

  const moveCursor = React.useCallback(
    (x: number, y: number) => {
      if (!isConnected) return;
      LiveCodingUsers.update({
        cursor: {
          x,
          y,
        },
      });
    },
    [LiveCodingUsers, isConnected],
  );

  const getLatestState = React.useCallback(
    (data: {
      [key: string]: SyncState;
    }): {
      q?: number;
      s?: number;
      c?: string;
      l?: string;
      k?: number;
      i?: boolean;
      f?: number;
    } => {
      // q: questionId, s: sessionId, c: selected component type, i: last state is "inq" or not, k: latest index,
      const v: {
        q?: number;
        s?: number;
        c?: string;
        l?: string;
        h?: Set<number>;
        i?: boolean;
        k?: number;
        f?: number;
      } = {};
      const inqOutq = new Set<string>();

      Object.keys(data)
        .sort()
        .forEach((key: string) => {
          if (data[key].s === "selq") v.q = data[key].v as number;
          if (data[key].s === "sels") v.s = data[key].v as number;
          if (data[key].s === "selc") v.c = data[key].v as string;
          if (data[key].s === "sell") v.l = data[key].v as string;

          if (data[key].s === "finl") v.f = data[key].v as number;
          if (data[key].s === "inq") inqOutq.add("inq");
          if (data[key].s === "outq") inqOutq.clear();
          v.k = revisionFromId(key);
        });

      v.i = inqOutq.size > 0;
      return v;
    },
    [],
  );

  const setLatestUser = React.useCallback(
    (data: { [key: string]: UserState }): void => {
      Object.keys(data).forEach((key: string) => {
        setUsers(draft => {
          draft[key] = { ...data[key], uid: key };
        });
      });
    },
    [setUsers],
  );

  const setStateFromEvent = React.useCallback(
    (state: SyncState) => {
      onChangeSyncState?.(state as LiveCodingPayload);
      switch (state.s) {
        case "sels":
          setSelectedSessionId(state.v);
          break;
        case "adds":
          setSelectedSessionId(state.v);
          break;
        case "dels":
          break;
        case "selc":
          setSelectedComponentType(state.v);
          break;
        case "sell":
          setSelectedLanguage(state.v);
          break;
        case "finl":
          setIsLiveCodingFinished(true);
          break;
      }
    },
    [onChangeSyncState],
  );

  const setHistoryFromEvent = React.useCallback(
    (history: FlowAction) => {
      switch (history.s) {
        case FLOW_ACTION.addElement:
          store.action.addElement({
            id: history.v.id,
            type: history.v.t,
            label: history.v.l,
            geometry: {
              minX: history.v.x,
              minY: history.v.y,
              maxX: history.v.x + history.v.w,
              maxY: history.v.y + history.v.h,
            },
            initialSettings: history.v.s,
            timestamp: history.t,
          });
          if (history.a === uid) {
            store.action.selectElements({ ids: [history.v.id] });
          }
          break;
        case FLOW_ACTION.addComment:
          store.action.addComment({
            id: history.v.id,
            content: history.v.c,
            geometry: { minX: history.v.x, minY: history.v.y, maxX: 0, maxY: 0 },
            fontSize: history.v.f,
            timestamp: history.t,
          });
          if (history.a === uid) {
            store.action.selectElements({ ids: [history.v.id] });
          }
          break;
        case FLOW_ACTION.editComment:
          store.action.editComment({ id: history.v.id, content: history.v.c, timestamp: history.t });
          break;
        case FLOW_ACTION.deleteElements:
          store.action.deleteElements({ ids: history.v.ids });
          break;
        case FLOW_ACTION.reviveElements:
          store.action.reviveElements({ ids: history.v.ids });
          break;
        case FLOW_ACTION.moveElements:
          // User's own move operation is continuously reflected to the state, hence only accept peer's change
          if (history.a !== uid) {
            store.action.moveElements({ ids: history.v.ids, dx: history.v.dx, dy: history.v.dy });
          }
          break;
        case FLOW_ACTION.shapeElement:
          if (history.a !== uid) {
            store.action.shapeElement({
              id: history.v.id,
              dx: history.v.dx,
              dy: history.v.dy,
              adjustPosition: history.v.p,
            });
          }
          break;
        case FLOW_ACTION.connectNodes:
          store.action.connectNodes({
            id: history.v.id,
            source: history.v.s,
            target: history.v.t,
            timestamp: history.t,
          });
          break;
        case FLOW_ACTION.reconnectEdge:
          store.action.reconnectEdge({
            id: history.v.id,
            source: history.v.s,
            target: history.v.t,
            timestamp: history.t,
          });
          break;
        case FLOW_ACTION.pasteElements:
          store.action.pasteElements({ elements: history.v.dst });
          if (history.a === uid) {
            store.action.selectElements({ ids: history.v.dst.map(element => element.id) });
          }
          break;
        case FLOW_ACTION.updateSettings:
          store.action.updateSettings({ id: history.v.id, updates: history.v.u, timestamp: history.t });
          break;
        case FLOW_ACTION.resetElements:
          store.action.resetElements({ elements: initialElements, timestamp: history.t });
          break;
      }
    },
    [store.action, initialElements, uid],
  );

  React.useEffect(() => {
    if (liveCodingReady) return;
    // Connect to firebase for realtime sync
    LiveCodingState.setup();
    let initialLoaded = false;

    LiveCodingState.onChildAdded(snapshot => {
      if (!initialLoaded) return;
      const latest = snapshot.val() as SyncState;
      liveCodingLastStateRef.current = latest;
      liveCodingIndexRef.current = revisionFromId(snapshot.key as string) + 1;
      setStateFromEvent(latest);
    });

    LiveCodingState.onceValue(snapshot => {
      const data: Record<string, SyncState> | null = snapshot.val();
      if (data) {
        // state under liveCodings/${liveCodingId}/state holds `selq` or `sels` only.
        // only latest.q will be considered, and others will be ignored.
        const latest = getLatestState(data);
        if (latest.q !== undefined) setSelectedQuestion(latest.q);
        if (latest.s !== undefined) setSelectedSessionId(latest.s);
        if (latest.k !== undefined) liveCodingIndexRef.current = latest.k + 1;
        if (latest.f !== undefined) setIsLiveCodingFinished(true);

        // The interviewer's log is unnecessary and will not go through.
        // And, No values are recorded, even for tests that have already been completed.
        if (isCandidate && isInterviewing) {
          // If 'sels' already exists as latest, do not add 'sels'
          if (latest.i === undefined || latest.i === false) {
            setSelectedSessionWrapper(latest.s ?? selectedSessionId);
          }
        }
      }

      // Initial sync is done.
      initialLoaded = true;

      LiveCodingState.onceValue(snapshot => {
        const data: Record<string, SyncState> | null = snapshot.val();
        if (data) return;
        if (!(isInterviewing && selectedSessionId && !liveCodingReady)) return;

        // There is no record in realtime database, it means that users land interview page for the first time.
        // So in this case, we need to create new record, sels, in order to calculate elapsed time based on this timestamp.
        LiveCodingState.set({
          s: "sels",
          v: selectedSessionId,
          t: getTimestamp(),
        });
      });

      setLiveCodingReady(true);
    });
  }, [
    LiveCodingState,
    getLatestState,
    isCandidate,
    isInterviewing,
    liveCodingReady,
    selectedSessionId,
    setSelectedSessionWrapper,
    setStateFromEvent,
  ]);

  React.useEffect(() => {
    if (!sessionReady || !selectedLanguage) return;
    languageRef.current = getRef("liveCoding", `liveCodings/${liveCodingId}/sessions/${selectedSessionId}/languages/${selectedLanguage}/state`);
    languageIndexRef.current = 0;
    let initialLoaded = false;

    languageRef.current?.on("child_added", snapshot => {
      if (!initialLoaded) return;
      languageIndexRef.current = revisionFromId(snapshot.key as string) + 1;
      setStateFromEvent(snapshot.val());
    });

    languageRef.current?.once("value", snapshot => {
      const data: Record<string, SyncState> | null = snapshot.val();
      if (data) {
        // state under liveCodings/${liveCodingId}/sessions/${sessionId}/languages/${language}/state doesn't hold anything.
        // only latest.k will be considered, and others will be ignored.
        const latest = getLatestState(data);
        if (latest.k !== undefined) languageIndexRef.current = latest.k + 1;
      }

      // Initial sync is done.
      initialLoaded = true;
      setLanguageReady(true);
    });
  }, [getLatestState, liveCodingId, selectedLanguage, selectedSessionId, sessionReady, setStateFromEvent]);

  React.useEffect(() => {
    return () => {
      LiveCodingState.clear();
      liveCodingIndexRef.current = 0;
      setLiveCodingReady(false);

      languageRef.current = undefined;
      languageIndexRef.current = 0;
      setLanguageReady(false);

      sessionRef.current = undefined;
      sessionIndexRef.current = 0;
      setSessionReady(false);

      componentTypeRef.current = undefined;
      componentTypeIndexRef.current = 0;
      setComponentTypeReady(false);
    };
  }, [LiveCodingState]);

  React.useEffect(() => {
    if (!liveCodingReady) return;
    if (!selectedSessionId) {
      // when selectedSession is undefined, there are no questions selected or first landing the interview page.
      // So skip connecting firebase.
      setSessionReady(true);
      return;
    }
    // Connect to firebase for realtime sync
    sessionRef.current = getRef("liveCoding", `liveCodings/${liveCodingId}/sessions/${selectedSessionId}/state`);
    sessionIndexRef.current = 0;
    let initialLoaded = false;

    sessionRef.current?.on("child_added", snapshot => {
      if (!initialLoaded) return;
      sessionIndexRef.current = revisionFromId(snapshot.key as string) + 1;
      setStateFromEvent(snapshot.val());
    });

    sessionRef.current?.once("value", snapshot => {
      const data: Record<string, SyncState> | null = snapshot.val();
      if (data) {
        // state under liveCodings/${liveCodingId}/sessions/${questionId}/state holds `self` only.
        // only latest.f and latest.s will be considered, and others will be ignored.
        const latest = getLatestState(data);
        if (latest.c !== undefined && isValidComponentType(latest.c)) {
          setSelectedComponentType(latest.c);
        }
        if (latest.s !== undefined) setSelectedSessionId(latest.s);
        if (latest.l !== undefined) setSelectedLanguage(latest.l);
        if (latest.k !== undefined) sessionIndexRef.current = latest.k + 1;
      }

      // Initial sync is done.
      initialLoaded = true;
      setSessionReady(true);
    });
  }, [liveCodingId, selectedQuestion, setStateFromEvent, getLatestState, liveCodingReady, selectedSessionId]);

  React.useEffect(() => {
    if (!sessionReady) return;
    // Connect to firebase for realtime sync
    componentTypeRef.current = getRef(
      "liveCoding",
      `liveCodings/${liveCodingId}/sessions/${selectedSessionId}/componentTypes/${selectedComponentType}/state`,
    );

    componentTypeRef.current?.once("value", () => {
      // Initial sync is done.
      setComponentTypeReady(true);
    });

    return () => {
      setComponentTypeReady(false);
    };
  }, [liveCodingId, selectedComponentType, selectedSessionId, sessionReady]);

  const setHistoryFromEventCallback = React.useCallback(
    (snapshot: firebase.database.DataSnapshot) => {
      // Update the local flowchart state from a newly added history
      setHistoryFromEvent(snapshot.val());
    },
    [setHistoryFromEvent],
  );

  React.useEffect(() => {
    if (!componentTypeReady) return;
    /**
     * Prevent unlimited registrations
     */
    historyRef.current?.off("child_added", setHistoryFromEventCallback);

    // Connect to firebase for realtime sync
    historyRef.current = getRef(
      "liveCoding",
      `liveCodings/${liveCodingId}/sessions/${selectedSessionId}/componentTypes/${selectedComponentType}/history`,
    );

    historyRef.current.once("value", () => {
      // Initial sync is done.
      setHistoryReady(true);
    });

    // ref: https://gist.github.com/katowulf/6383103
    historyRef.current.orderByChild("t").startAt(Date.now()).on("child_added", setHistoryFromEventCallback);

    return () => {
      setHistoryReady(false);
    };
  }, [componentTypeReady, liveCodingId, selectedComponentType, selectedSessionId, setHistoryFromEventCallback]);

  React.useEffect(() => {
    return () => {
      historyRef.current?.off("child_added");
      historyRef.current = undefined;
    };
  }, [selectedComponentType, selectedSessionId]);

  React.useEffect(() => {
    if (!selectedSessionId) {
      setUserReady(true);
    }

    LiveCodingUsers.setup();

    // Initialize user data in the realtime database
    LiveCodingUsers.set({
      name: participantName,
      color: colorFromUserId(uid),
    });

    LiveCodingUsers.onceValue(snapshot => {
      setLatestUser(snapshot.val());
      // Initial sync is done.
      setUserReady(true);
    });
  }, [LiveCodingUsers, participantName, selectedSessionId, setLatestUser, uid]);

  React.useEffect(() => {
    if (!userReady) return;

    LiveCodingUsers.onChildAdded(snapshot => {
      const addedUserId = snapshot.key as string;
      if (!Object.keys(users).includes(addedUserId)) {
        setUsers(draft => {
          draft[addedUserId] = { ...snapshot.val(), uid: addedUserId } as UserState;
        });
      }
    });

    LiveCodingUsers.onChildChanged(snapshot => {
      const updatingUserId = snapshot.key as string;
      setUsers(draft => {
        draft[updatingUserId] = { ...snapshot.val(), uid: updatingUserId } as UserState;
      });
    });

    LiveCodingUsers.onChildRemoved(snapshot => {
      setUsers(draft => {
        delete draft[snapshot.key as string];
      });
    });

    return () => {
      LiveCodingUsers.off();
    };
  }, [LiveCodingUsers, setUsers, userReady, users]);

  React.useEffect(() => {
    const connectedRef = getRef("liveCoding", ".info/connected");
    connectedRef.on("value", snapshot => {
      if (snapshot.val()) {
        setIsConnected(true);
      } else {
        setIsConnected(false);
      }
    });
    return () => {
      connectedRef.off("value");
    };
  }, [uid]);

  React.useEffect(() => {
    if (isConnected) {
      LiveCodingUsers.set({
        name: participantName,
        color: colorFromUserId(uid),
        cursor: { x: 0, y: 0 },
      });
    }
  }, [LiveCodingUsers, isConnected, participantName, uid]);

  LiveCodingUsers.onDisconnect();

  const sortedCurrentParticipantUids = React.useMemo(() => {
    return Object.values(users)
      .map(user => user.uid)
      .sort();
  }, [users]);

  const state: CollaborativeState = {
    collaborators,
    users,
    participantNames,
    selectedQuestion,
    selectedQuestionVariant,
    selectedQuestionType,
    selectedSession: selectedSessionId,
    isLiveCodingFinished,
    liveCodingReady,
    ready,
    canUndo: undoStack.length > 0,
    canRedo: redoStack.length > 0,
    selectedLanguage: selectedLanguage,
    selectedComponentType: selectedComponentType,
    sortedCurrentParticipantUids,
  };

  const dispatcher = React.useMemo((): CollaborativeAction => {
    return {
      selectComponentType,
      setSelectedLanguageWrapper,
      setSelectedSessionWrapper,
      setSelectedQuestionWrapper,
      setOutSessionWrapper,
      addSession,
      deleteSession,
      finishLiveCoding,
      addElement,
      addComment,
      editComment,
      deleteElements,
      moveElements,
      shapeElement,
      connectNodes,
      reconnectEdge,
      pasteElements,
      updateSettings,
      resetElements,
      undo,
      redo,
      selectElement,
      saveEditingCommentId,
      moveCursor,
    };
  }, [
    selectComponentType,
    setSelectedLanguageWrapper,
    setSelectedSessionWrapper,
    setSelectedQuestionWrapper,
    setOutSessionWrapper,
    addSession,
    deleteSession,
    finishLiveCoding,
    addElement,
    addComment,
    editComment,
    deleteElements,
    moveElements,
    shapeElement,
    connectNodes,
    reconnectEdge,
    pasteElements,
    updateSettings,
    resetElements,
    undo,
    redo,
    selectElement,
    saveEditingCommentId,
    moveCursor,
  ]);

  return {
    collaborativeState: state,
    collaborativeAction: dispatcher,
  };
};
