import EvaluationResultPlaygroundContainer from "../pages/devonly/EvaluationResultPlayground/Container";
import MonacoEditorPlaygroundContainer from "../pages/devonly/MonacoEditorPlayground/Container";
import Root from "../pages/root/Container";
import Auth from "../pages/v2/auth/Container";
import CandidateCompetitions from "../pages/v2/c/competitions/Container";
import CandidateCompetitionIdDetail from "../pages/v2/c/competitions_id_detail/Container";
import CandidateCompetitionsResultsId from "../pages/v2/c/competitions_results_id/InitialContainer";
import CandidateDemoInterviewsId from "../pages/v2/c/demos_id/InitialContainer";
import CandidateDemoStart from "../pages/v2/c/demos_start/Container";
import CandidateHome from "../pages/v2/c/home/Container";
import CandidateRemotesId from "../pages/v2/c/remotes_id/FetchContainer";
import CandidateResetPassword from "../pages/v2/c/reset_password/Container";
import CandidateScreeningsId from "../pages/v2/c/screenings_id/FetchContainer";
import CandidateScreeningsTestsId from "../pages/v2/c/screenings_tests_id/InitialContainer";
import CandidateScreeningTestIdDetail from "../pages/v2/c/screenings_tests_id_detail/InitialContainer";
import CandidateUserSettings from "../pages/v2/c/settings_user/Container";
import CandidateSignIn from "../pages/v2/c/signin/Container";
import CandidateSignUp from "../pages/v2/c/signup/Container";
import CandidateNeedEmailVerification from "../pages/v2/c/verify_email/Container";
import AssessmentsForEmployee from "../pages/v2/e/assessments/Container";
import AssessmentCreate from "../pages/v2/e/assessments_create/Container";
import AssessmentsIdDetailForEmployee from "../pages/v2/e/assessments_id_detail/InitialContainer";
import AssessmentUpdate from "../pages/v2/e/assessments_id_update/Container";
import CandidateList from "../pages/v2/e/candidates/Container";
import CandidateDetailForEmployee from "../pages/v2/e/candidates_id_detail/Container";
import EmployeeCompetitions from "../pages/v2/e/competitions/Container";
import EmployeeCompetitionsIdDetail from "../pages/v2/e/competitions_id_detail/Container";
import EmployeeCompetitionsResultsIdDetail from "../pages/v2/e/competitions_results_id_detail/Container";
import EvaluationsMetricsGroups from "../pages/v2/e/evaluations_groups/Container";
import EvaluationsMetrics from "../pages/v2/e/evaluations_metrics/Container";
import EmployeeExamsIdReview from "../pages/v2/e/exams_id_review/Container";
import Home from "../pages/v2/e/home/Container";
import EmployeeInvitationsId from "../pages/v2/e/invitations_id/FetchContainer";
import QuestionList from "../pages/v2/e/questions/Container";
import QuestionsChallengeId from "../pages/v2/e/questions_challenge_id/Container";
import QuestionsProjectId from "../pages/v2/e/questions_project_id/Container";
import QuestionsQuizId from "../pages/v2/e/questions_quiz_id/Container";
import QuestionsSystemDesignId from "../pages/v2/e/questions_systemdesign_id/Container";
import RemoteInterviewList from "../pages/v2/e/remotes/Container";
import RemoteInterviewCreate from "../pages/v2/e/remotes_create/Container";
import EmployeeRemotesId from "../pages/v2/e/remotes_id/FetchContainer";
import RemoteInterviewDetail from "../pages/v2/e/remotes_id_detail/Container";
import EmployeeRemotesIdEvaluate from "../pages/v2/e/remotes_id_evaluate/FetchContainer";
import RemoteInterviewIdUpdate from "../pages/v2/e/remotes_id_update/Container";
import ScreeningList from "../pages/v2/e/screenings/Container";
import ScreeningCreate from "../pages/v2/e/screenings_create/Container";
import ScreeningIdDetail from "../pages/v2/e/screenings_id_detail/FetchContainer";
import ScreeningIdUpdate from "../pages/v2/e/screenings_id_update/Container";
import ScreeningTestList from "../pages/v2/e/screenings_tests/FetchContainer";
import ScreeningTestIdDetail from "../pages/v2/e/screenings_tests_id_detail/InitialContainer";
import ScreeningTestReview from "../pages/v2/e/screenings_tests_id_review/Container";
import BillingInvoices from "../pages/v2/e/settings_billing_invoices/Container";
import BillingOverview from "../pages/v2/e/settings_billing_overview/Container";
import BillingUsages from "../pages/v2/e/settings_billing_usages/Container";
import EmployeeSettings from "../pages/v2/e/settings_company_employee/Container";
import EmployeeGroupSettings from "../pages/v2/e/settings_company_group_employee/Container";
import IntegrationSonarSettings from "../pages/v2/e/settings_company_integration_sonar/Container";
import SlackNotificationSettings from "../pages/v2/e/settings_company_notification_slack/Container";
import CompanyProfileSettings from "../pages/v2/e/settings_company_profile/Container";
import SecuritySettings from "../pages/v2/e/settings_company_security/Container";
import TalentSettings from "../pages/v2/e/settings_company_talent/Container";
import SettingsOrderHistory from "../pages/v2/e/settings_order_history/Container";
import PaymentMethodUpdate from "../pages/v2/e/settings_payment_method_update/Container";
import PlanList from "../pages/v2/e/settings_plan_list/Container";
import EmployeeUserSettings from "../pages/v2/e/settings_user/Container";
import TemplateRemoteCreate from "../pages/v2/e/templates_remote_create/Container";
import TemplateRemoteIdUpdate from "../pages/v2/e/templates_remote_id_update/Container";
import ResetPassword from "../pages/v2/reset_password/Container";
import SignIn from "../pages/v2/signin/Container";
import SignUp from "../pages/v2/signup/Container";
import AssessmentsForTalent from "../pages/v2/t/assessments/Container";
import AssessmentsIdDetailForTalent from "../pages/v2/t/assessments_id_detail/InitialContainer";
import ExamsIdForTalent from "../pages/v2/t/exams_id/InitialContainer";
import TalentExamsIdReview from "../pages/v2/t/exams_id_review/Container";
import TalentInvitationsId from "../pages/v2/t/invitations_id/FetchContainer";
import TalentUserSettings from "../pages/v2/t/settings_user/Container";

export default {
  Root: Root,
  CandidateCompetitions: CandidateCompetitions,
  CandidateCompetitionsResultsId: CandidateCompetitionsResultsId,
  CandidateDemoStart: CandidateDemoStart,
  CandidateDemoInterviewsId: CandidateDemoInterviewsId,
  CandidateScreeningsId: CandidateScreeningsId,
  CandidateScreeningsTestsId: CandidateScreeningsTestsId,
  ScreeningTestIdDetail: ScreeningTestIdDetail,
  ScreeningTestList: ScreeningTestList,
  ScreeningTestReview: ScreeningTestReview,
  EmployeeRemotesId: EmployeeRemotesId,
  CandidateRemotesId: CandidateRemotesId,
  CandidateCompetitionIdDetail: CandidateCompetitionIdDetail,
  CandidateResetPassword: CandidateResetPassword,
  CandidateHome: CandidateHome,
  CandidateSignIn: CandidateSignIn,
  CandidateSignUp: CandidateSignUp,
  CandidateNeedEmailVerification: CandidateNeedEmailVerification,
  CandidateList: CandidateList,
  CandidateDetailForEmployee: CandidateDetailForEmployee,
  CandidateUserSettings: CandidateUserSettings,
  EmployeeRemotesIdEvaluate: EmployeeRemotesIdEvaluate,
  EmployeeCompetitions: EmployeeCompetitions,
  EmployeeCompetitionsIdDetail: EmployeeCompetitionsIdDetail,
  EmployeeCompetitionsResultsIdDetail: EmployeeCompetitionsResultsIdDetail,
  QuestionsSystemDesignId: QuestionsSystemDesignId,
  QuestionsChallengeId: QuestionsChallengeId,
  QuestionsProjectId: QuestionsProjectId,
  QuestionsQuizId: QuestionsQuizId,
  TalentInvitationsId: TalentInvitationsId,
  TalentUserSettings: TalentUserSettings,
  EmployeeUserSettings: EmployeeUserSettings,
  AssessmentsForTalent: AssessmentsForTalent,
  ExamsIdForTalent: ExamsIdForTalent,
  AssessmentsIdDetailForEmployee: AssessmentsIdDetailForEmployee,
  AssessmentsIdDetailForTalent: AssessmentsIdDetailForTalent,
  AssessmentsForEmployee: AssessmentsForEmployee,
  AssessmentCreate: AssessmentCreate,
  AssessmentUpdate: AssessmentUpdate,
  EmployeeExamsIdReview: EmployeeExamsIdReview,
  TalentExamsIdReview: TalentExamsIdReview,
  CompanySettingsBillingOverview: BillingOverview,
  CompanySettingsBillingUsages: BillingUsages,
  CompanySettingsBillingInvoices: BillingInvoices,
  CompanySettingsOrderHistory: SettingsOrderHistory,
  PaymentMethodUpdate: PaymentMethodUpdate,
  CandidateScreeningTestIdDetail: CandidateScreeningTestIdDetail,
  PlanList: PlanList,
  CompanyProfileSettings: CompanyProfileSettings,
  SecuritySettings: SecuritySettings,
  IntegrationSonarSettings: IntegrationSonarSettings,
  SlackNotificationSettings: SlackNotificationSettings,
  EmployeeSettings: EmployeeSettings,
  TalentSettings: TalentSettings,
  EmployeeGroupSettings: EmployeeGroupSettings,
  EvaluationsMetricsGroups: EvaluationsMetricsGroups,
  EvaluationsMetrics: EvaluationsMetrics,
  EmployeeInvitationsId: EmployeeInvitationsId,
  SignIn: SignIn,
  SignUp: SignUp,
  Auth: Auth,
  ResetPassword: ResetPassword,
  QuestionList: QuestionList,
  ScreeningCreate: ScreeningCreate,
  ScreeningIdUpdate: ScreeningIdUpdate,
  ScreeningList: ScreeningList,
  ScreeningIdDetail: ScreeningIdDetail,
  RemoteInterviewList: RemoteInterviewList,
  RemoteInterviewDetail: RemoteInterviewDetail,
  Home: Home,
  RemoteInterviewCreate: RemoteInterviewCreate,
  RemoteInterviewIdUpdate: RemoteInterviewIdUpdate,
  TemplateRemoteCreate: TemplateRemoteCreate,
  TemplateRemoteIdUpdate: TemplateRemoteIdUpdate,
  MonacoEditorPlayground: MonacoEditorPlaygroundContainer,
  EvaluationResultPlaygroundContainer: EvaluationResultPlaygroundContainer,
};
