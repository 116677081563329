import * as Graphql from "@hireroo/graphql/client/urql";
import { useTranslation } from "@hireroo/i18n";

export const useJobChangeInterestMap = (): Record<Graphql.JobChangeInterest, string> => {
  const { t } = useTranslation();

  return {
    [Graphql.JobChangeInterest.High]: t("高い"),
    [Graphql.JobChangeInterest.Medium]: t("普通"),
    [Graphql.JobChangeInterest.Low]: t("低い"),
    [Graphql.JobChangeInterest.None]: t("なし"),
    [Graphql.JobChangeInterest.Unknown]: t("未設定"),
  };
};

export const useJobChangeInterestDetailMap = (): Record<Graphql.JobChangeInterest, string> => {
  const { t } = useTranslation();

  return {
    [Graphql.JobChangeInterest.High]: t("転職意欲高い"),
    [Graphql.JobChangeInterest.Medium]: t("転職意欲普通"),
    [Graphql.JobChangeInterest.Low]: t("転職意欲低い"),
    [Graphql.JobChangeInterest.None]: t("転職意欲なし"),
    [Graphql.JobChangeInterest.Unknown]: t("未設定"),
  };
};

export const useAverageRankEvaluationMap = () => {
  return {
    [Graphql.RankEvaluation.S]: "S",
    [Graphql.RankEvaluation.A]: "A",
    [Graphql.RankEvaluation.B]: "B",
    [Graphql.RankEvaluation.C]: "C",
    [Graphql.RankEvaluation.D]: "D",
    [Graphql.RankEvaluation.Unknown]: "UNKNOWN",
  } as const satisfies Record<Graphql.RankEvaluation, string>;
};
