import * as ErrorHandlingHelper from "@hireroo/app-helper/error-handling";
import { useTitle } from "@hireroo/app-helper/react-use";
import { EmployeeSideBarContent } from "@hireroo/app-store/widget/e/EmployeeSideBarContent";
import { Snackbar } from "@hireroo/app-store/widget/shared/Snackbar";
import { getGraphqlClient } from "@hireroo/graphql/client/request";
import * as Graphql from "@hireroo/graphql/client/urql";
import { useTranslation } from "@hireroo/i18n";
import type { Pages } from "@hireroo/presentation";
import { generatePath } from "@hireroo/router/api";
import { useTransitionNavigate } from "@hireroo/router/hooks";
import * as Sentry from "@sentry/react";
import * as React from "react";
import { useParams } from "react-router-dom";

import EmployeeHeaderRightContentContainer from "../../../../widget/v2/e/EmployeeHeaderRightContent/Container";
import EmployeeSideBarContentContainer from "../../../../widget/v2/e/EmployeeSideBarContent/Container";
import FooterContainer from "../../../../widget/v2/e/Footer/Container";
import { useNotificationBanner } from "../../../../widget/v2/e/NotificationBanner/Container";
import RemoteInterviewResourceEditorFetchContainer from "../../../../widget/v2/e/RemoteInterviewResourceEditor/FetchContainer";
import { RemoteInterviewResourceEditorMiddleContainerProps } from "../../../../widget/v2/e/RemoteInterviewResourceEditor/MiddleContainer";
import SnackbarContainer from "../../../../widget/v2/shared/Snackbar/Container";

export type GenerateRemoteInterviewResourceEditorPropsArgs = {};

export const useGenerateProps = (_args: GenerateRemoteInterviewResourceEditorPropsArgs): Pages.RemoteInterviewResourceEditorProps => {
  const { t } = useTranslation();
  const navigate = useTransitionNavigate();
  const NotificationBannerContainer = useNotificationBanner();
  const [status, setStatus] = React.useState<"READY" | "PENDING">("READY");
  const { id } = useParams<{
    id: string;
  }>();
  const client = getGraphqlClient();
  useTitle(t("インタビュー編集"));
  if (!id) {
    throw new Error("Not found");
  }

  const remoteInterviewResourceEditorProps: RemoteInterviewResourceEditorMiddleContainerProps = {
    mode: "EDIT",
    loading: status === "PENDING",
    onSubmit: fields => {
      setStatus("PENDING");
      client
        .UpdateRemoteForRemoteInterviewsUpdate({
          updateRemoteInput: {
            remoteId: id,
            isPublic: fields.remoteReportSetup.isPublic,
            name: fields.remoteQuestionSetup.name,
            candidateName: fields.remoteQuestionSetup.candidateName,
            memo: fields.remoteReportSetup.memo,
            tagNames: fields.remoteReportSetup.tags.map(t => t.value.name),
            metricIds: fields.remoteReportSetup.evaluationMetricIds,
            entitySources: fields.remoteQuestionSetup.entityTracks.reduce<Graphql.RemoteEntitySourceInput[]>((all, entityTrack) => {
              /** Currently, Remote Interview does not support anything other than FIXED */
              if (entityTrack.type !== "FIXED") {
                return all;
              }
              const entitySource = entityTrack.entitySource;
              switch (entitySource.type) {
                case "REMOTE_ALGORITHM":
                  return all.concat({
                    entityType: "LIVE_CODING",
                    algorithmQuestion: {
                      questionId: entitySource.questionId,
                      questionVersion: entitySource.questionVersion,
                    },
                    variant: "ALGORITHM",
                  });
                case "REMOTE_SYSTEM_DESIGN":
                  return all.concat({
                    entityType: "LIVE_CODING",
                    systemDesignQuestion: {
                      questionId: entitySource.questionId,
                    },
                    variant: "SYSTEM_DESIGN",
                  });
              }
              return all;
            }, []),
            viewerIds: fields.remoteReportSetup.viewers.reduce<Graphql.RemoteViewerIdInput[]>((viewerIds, reviewer) => {
              if (reviewer.value === null) {
                return viewerIds;
              }
              switch (reviewer.value.type) {
                case "EMPLOYEE":
                  return viewerIds.concat({
                    type: "EMPLOYEE",
                    employeeId: reviewer.value.employeeId,
                  });
                case "EMPLOYEE_GROUP":
                  return viewerIds.concat({
                    type: "GROUP",
                    groupId: reviewer.value.groupId,
                  });
                default:
                  break;
              }
              return viewerIds;
            }, []),
          },
        })
        .then(res => {
          Snackbar.notify({
            severity: "success",
            message: t("インタビューの編集が完了しました。"),
          });
          navigate("/e/remotes/:id/detail", { pathParams: { id: res.updateRemote.id } });
        })
        .catch(error => {
          Sentry.captureException(error);
          const errorNotification = ErrorHandlingHelper.generateErrorNotification(
            error,
            t("インタビューの編集に失敗しました。しばらくしてから再度お試し頂くか、ヘルプボタンよりお問い合わせください。"),
          );
          Snackbar.notify({
            severity: "error",
            message: errorNotification.message,
          });
        })
        .finally(() => {
          setStatus("READY");
        });
    },
  };

  return {
    layout: {
      loading: status === "PENDING",
      openSidebar: EmployeeSideBarContent.useOpenSidebar(),
      onChangeOpenSidebar: open => {
        EmployeeSideBarContent.setOpenSidebar(open);
      },
      NotificationBanner: NotificationBannerContainer,
      HeaderRightContent: <EmployeeHeaderRightContentContainer />,
      SideBarContent: <EmployeeSideBarContentContainer />,
      Footer: <FooterContainer />,
      Snackbar: <SnackbarContainer />,
      sideBarHeader: {
        logo: {
          href: generatePath("/e/home"),
          onClick: () => {
            navigate("/e/home");
          },
        },
      },
      navigationBreadcrumbs: {
        parentLinks: [
          {
            href: generatePath("/e/home"),
            children: t("ホーム"),
            onClick: () => {
              navigate("/e/home");
            },
          },
          {
            href: generatePath("/e/remotes"),
            children: t("インタビュー一覧"),
            onClick: () => {
              navigate("/e/remotes");
            },
          },
          {
            href: generatePath("/e/remotes/:id/detail", { pathParams: { id: id } }),
            children: t("インタビュー詳細"),
            onClick: () => {
              navigate("/e/remotes/:id/detail", { pathParams: { id: id } });
            },
          },
        ],
        current: t("インタビュー編集"),
      },
    },
    children: <RemoteInterviewResourceEditorFetchContainer {...remoteInterviewResourceEditorProps} remoteId={id} />,
  };
};
