import { useTranslation } from "@hireroo/i18n";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";

import EmployeeLayout, { EmployeeLayoutProps } from "../../layouts/EmployeeLayout/EmployeeLayout";
import ButtonWithTooltip, { ButtonWithTooltipProps } from "../../primitive/Button/ButtonWithTooltip/ButtonWithTooltip";

const StyledButton = styled(ButtonWithTooltip)(() => ({
  height: 36,
  whiteSpace: "nowrap",
  wordBreak: "keep-all",
}));

const StyledHeaderStack = styled(Stack)(() => ({
  height: 36,
  alignItems: "center",
  justifyContent: "space-between",
}));

export type RemoteInterviewListProps = {
  layout: EmployeeLayoutProps;
  SearchArea: React.ReactNode;
  List: React.ReactNode;
  createButton: Pick<ButtonWithTooltipProps, "onClick" | "disabled" | "title">;
  Dialog: React.ReactNode;
};

const RemoteInterviewList: React.FC<RemoteInterviewListProps> = props => {
  const { t } = useTranslation();
  const layoutProps: EmployeeLayoutProps = {
    ...props.layout,
  };
  const createButtonProps: ButtonWithTooltipProps = {
    ...props.createButton,
    startIcon: "ADD",
    color: "primary",
    variant: "contained",
  };

  return (
    <EmployeeLayout {...layoutProps}>
      <Box p={3}>
        <StyledHeaderStack direction="row" mb={2}>
          <Typography variant="h5" fontWeight={700} noWrap>
            {t("インタビュー一覧")}
          </Typography>
          <StyledButton {...createButtonProps}>{t("インタビュー作成")}</StyledButton>
        </StyledHeaderStack>

        <Box>
          <Stack spacing={2}>
            {props.SearchArea}
            {props.List}
            {props.Dialog}
          </Stack>
        </Box>
      </Box>
    </EmployeeLayout>
  );
};

RemoteInterviewList.displayName = "RemoteInterviewList";

export default RemoteInterviewList;
