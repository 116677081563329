import { useTranslation } from "@hireroo/i18n";
import { Edit } from "@mui/icons-material";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Button, { ButtonProps } from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import { styled, useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";

const HeaderContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  padding: theme.spacing(3),
  backgroundColor: theme.palette["Background/Paper"].p1,
}));

const ProfileSection = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(4),
}));

const StatsSection = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(1),
}));

const StatsItem = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(0.5),
}));

const ScoreText = styled(Typography)({
  fontSize: 14,
  fontWeight: 800,
});

const RankText = styled(Typography)({
  fontSize: 14,
  fontWeight: 800,
});

const EditButton = styled(Button)({
  height: 30,
  whiteSpace: "nowrap",
  wordBreak: "keep-all",
});

const RankColorMap = {
  S: "#FA3A36",
  A: "#EE9321",
  B: "#EEBA35",
  C: "#77A4E9",
  D: "#3DCF43",
  UNKNOWN: "text.secondary",
} as const;

export type AverageRank = keyof typeof RankColorMap;

export type CandidateDetailHeaderProps = {
  imgUrl?: string;
  name: string;
  averageScore?: number;
  averageRank: AverageRank;
  editButton?: Pick<ButtonProps, "onClick">;
};

const CandidateDetailHeader: React.FC<CandidateDetailHeaderProps> = props => {
  const theme = useTheme();
  const { t } = useTranslation();

  const editButtonProps: ButtonProps = {
    children: t("プロフィール編集"),
    variant: "outlined",
    size: "small",
    color: "outline-only",
    sx: {
      borderColor: theme.palette.Other.Divider,
    },
    startIcon: <Edit color="inherit" />,
    onClick: props.editButton?.onClick,
  };

  return (
    <HeaderContainer>
      <ProfileSection>
        <Avatar src={props.imgUrl} alt={props.name} sx={{ width: 64, height: 64 }} />
        <Box>
          <Typography variant="h6" fontWeight={700} fontSize={20} mb={1}>
            {props.name}
          </Typography>
          <StatsSection>
            <StatsItem>
              <Typography variant="caption" color="text.secondary" fontSize={12}>
                {t("平均スコア")}
              </Typography>
              {props.averageScore === undefined ? (
                <ScoreText color="text.secondary">-</ScoreText>
              ) : (
                <ScoreText color="#EF9A9A">{props.averageScore}</ScoreText>
              )}
            </StatsItem>
            <Divider orientation="vertical" sx={{ my: 0.5 }} flexItem />
            <StatsItem>
              <Typography variant="caption" color="text.secondary" fontSize={12}>
                {t("平均ランク")}
              </Typography>
              <RankText color={RankColorMap[props.averageRank]}>{props.averageRank === "UNKNOWN" ? "-" : props.averageRank}</RankText>
            </StatsItem>
          </StatsSection>
        </Box>
      </ProfileSection>
      {props.editButton && (
        <Box>
          <EditButton {...editButtonProps} />
        </Box>
      )}
    </HeaderContainer>
  );
};

CandidateDetailHeader.displayName = "CandidateDetailHeader";

export default CandidateDetailHeader;
