import { useSnapshot } from "valtio";

import { state } from "./State";

const useSnapshotState = () => {
  return useSnapshot(state);
};

export const useInitialized = (): boolean => {
  const snapshot = useSnapshotState();
  return !!snapshot.competition;
};

export const useCompetition = () => {
  const snapshot = useSnapshotState();
  if (!snapshot.competition) {
    throw new Error("Competition is not initialized");
  }
  return snapshot.competition;
};

export const useInitializedApplicantsRes = (): boolean => {
  const snapshot = useSnapshotState();
  return !!snapshot.applicantsRes;
};

export const useApplicantsRes = () => {
  const snapshot = useSnapshotState();
  if (!snapshot.applicantsRes) {
    throw new Error("ApplicantsRes is not initialized");
  }
  return snapshot.applicantsRes;
};

export const useApplicantFilter = () => {
  const snapshot = useSnapshotState();
  return snapshot.applicantFilter;
};

export const useApplicantPager = () => {
  const snapshot = useSnapshotState();
  return snapshot.applicantPager;
};

export const useInitializedApplicantFilter = () => {
  const snapshot = useSnapshotState();
  return snapshot.initializedApplicantFilter;
};
