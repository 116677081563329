import { useTranslation } from "@hireroo/i18n";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";

import SortField, { SortFieldProps } from "../../../../modules/SortField/SortField";

const Wrapper = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  flexDirection: "row",
  justifyContent: "space-between",
}));

const StyledTypography = styled(Typography)(() => ({
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  wordBreak: "keep-all",
  overflow: "hidden",
}));

const FieldValues = {
  CREATED_AT_DESCENDING: "CREATED_AT_DESCENDING",
  CREATED_AT_ASCENDING: "CREATED_AT_ASCENDING",
  STARTS_AT_DESCENDING: "STARTS_AT_DESCENDING",
  STARTS_AT_ASCENDING: "STARTS_AT_ASCENDING",
  MIN_SALARY_DESCENDING: "MIN_SALARY_DESCENDING",
  MIN_SALARY_ASCENDING: "MIN_SALARY_ASCENDING",
} as const;
type FieldValue = (typeof FieldValues)[keyof typeof FieldValues];

export type SearchResultBarProps = {
  defaultValue: FieldValue;
  resultText: string;
  onChangeSortMethod: (value: FieldValue) => void;
};

const SearchResultBar: React.FC<SearchResultBarProps> = props => {
  const { t } = useTranslation();

  const sortFieldProps: SortFieldProps = {
    defaultValue: props.defaultValue,
    options: [
      {
        displayName: t("作成日時が古い順"),
        value: FieldValues.CREATED_AT_ASCENDING,
      },
      {
        displayName: t("作成日時が新しい順"),
        /**
         * @example 2024/02/01 -> 2024/01/31 -> 2024/01/30
         */
        value: FieldValues.CREATED_AT_DESCENDING,
      },
      {
        displayName: t("開催日時が古い順"),
        value: FieldValues.STARTS_AT_ASCENDING,
      },
      {
        displayName: t("開催日時が新しい順"),
        value: FieldValues.STARTS_AT_DESCENDING,
      },
      {
        displayName: t("最低提示年収の低い順"),
        value: FieldValues.MIN_SALARY_ASCENDING,
      },
      {
        displayName: t("最低提示年収の高い順"),
        value: FieldValues.MIN_SALARY_DESCENDING,
      },
    ],
    onChange: value => {
      if (Object.values(FieldValues).includes(value as FieldValue)) {
        props.onChangeSortMethod(value as FieldValue);
      }
    },
  };

  return (
    <Wrapper px={2} py={1}>
      <Stack direction="row" alignItems="center" justifyContent="center" spacing={2}>
        <StyledTypography variant="body2" fontSize={12} color="textSecondary">
          {props.resultText}
        </StyledTypography>
      </Stack>
      <SortField {...sortFieldProps} />
    </Wrapper>
  );
};

SearchResultBar.displayName = "SearchResultBar";

export default SearchResultBar;
