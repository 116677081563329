import { useTitle } from "@hireroo/app-helper/react-use";
import { App } from "@hireroo/app-store/essential/employee";
import { Candidates } from "@hireroo/app-store/page/e/candidates";
import { EmployeeSideBarContent } from "@hireroo/app-store/widget/e/EmployeeSideBarContent";
import { useTranslation } from "@hireroo/i18n";
import type { Pages } from "@hireroo/presentation";
import { generatePath } from "@hireroo/router/api";
import { useTransitionNavigate } from "@hireroo/router/hooks";
import React from "react";
import { useSearchParams } from "react-router-dom";

import EmployeeHeaderRightContentContainer from "../../../../widget/v2/e/EmployeeHeaderRightContent/Container";
import EmployeeSideBarContentContainer from "../../../../widget/v2/e/EmployeeSideBarContent/Container";
import FooterContainer from "../../../../widget/v2/e/Footer/Container";
import { useNotificationBanner } from "../../../../widget/v2/e/NotificationBanner/Container";
import SnackbarContainer from "../../../../widget/v2/shared/Snackbar/Container";
import * as PrivateHelper from "./privateHelper";
import CandidateListFetchContainer from "./widgets/CandidateList/FetchContainer";
import CandidateSearchAreaContainer from "./widgets/CandidateSearchArea/Container";

export type GenerateCandidateListPropsArgs = {};

export const useGenerateProps = (_args: GenerateCandidateListPropsArgs): Pages.CandidateListProps => {
  const { t } = useTranslation();
  const navigate = useTransitionNavigate();
  const NotificationBannerContainer = useNotificationBanner();
  const appStatus = App.useStatus();
  const initializedSearchFilter = Candidates.useInitializedSearchFilter();
  const [searchParams] = useSearchParams();

  useTitle(t("候補者一覧"));

  React.useEffect(() => {
    Candidates.initializeFilters(
      {
        textFilter: searchParams.get(Candidates.QueryKeys.DISPLAY_NAME) ?? "",
        expectedSalary: {
          max: PrivateHelper.convertStringToExpectedSalaryMax(searchParams.get(Candidates.QueryKeys.EXPECTED_SALARY_MAX)),
          min: PrivateHelper.convertStringToExpectedSalaryMin(searchParams.get(Candidates.QueryKeys.EXPECTED_SALARY_MIN)),
        },
        jobChangeStatuses: PrivateHelper.convertStringToJobChangeStatuses(searchParams.getAll(Candidates.QueryKeys.JOB_CHANGE_STATUS)),
        averageScore: {
          max: PrivateHelper.convertStringToAverageScoreMax(searchParams.get(Candidates.QueryKeys.AVERAGE_SCORE_MAX)),
          min: PrivateHelper.convertStringToAverageScoreMin(searchParams.get(Candidates.QueryKeys.AVERAGE_SCORE_MIN)),
        },
        averageRank: PrivateHelper.convertStringToAverageRank(searchParams.getAll(Candidates.QueryKeys.AVERAGE_RANK)),
      },
      {
        page: PrivateHelper.convertStringsToPage(searchParams.get(Candidates.QueryKeys.PAGE)),
        size: PrivateHelper.convertStringToSize(searchParams.get(Candidates.QueryKeys.SIZE)),
        sortParams: PrivateHelper.convertStringToSortParams(searchParams.get(Candidates.QueryKeys.SORT)),
      },
    );
  }, [searchParams]);

  return {
    layout: {
      openSidebar: EmployeeSideBarContent.useOpenSidebar(),
      onChangeOpenSidebar: open => {
        EmployeeSideBarContent.setOpenSidebar(open);
      },
      loading: appStatus !== "INITIALIZED",
      NotificationBanner: NotificationBannerContainer,
      HeaderRightContent: <EmployeeHeaderRightContentContainer />,
      SideBarContent: <EmployeeSideBarContentContainer />,
      Footer: <FooterContainer />,
      Snackbar: <SnackbarContainer />,
      sideBarHeader: {
        logo: {
          href: generatePath("/e/home"),
          onClick: () => {
            navigate("/e/home");
          },
        },
      },
      navigationBreadcrumbs: {
        parentLinks: [
          {
            href: generatePath("/e/home"),
            children: t("ホーム"),
            onClick: () => {
              navigate("/e/home");
            },
          },
        ],
        current: t("候補者一覧"),
      },
    },
    SearchArea: initializedSearchFilter && <CandidateSearchAreaContainer />,
    CandidateList: initializedSearchFilter && <CandidateListFetchContainer />,
  };
};
