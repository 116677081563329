import { signInWithGitHub, signInWithGoogle } from "@hireroo/firebase";
import { useLanguageCode } from "@hireroo/i18n";
import type { Widget } from "@hireroo/presentation";
import { generateHelpCenterUrl } from "@hireroo/router/api";
import { useHelpCenterNavigate, useTransitionNavigate } from "@hireroo/router/hooks";

import SignInMailAddressContainer from "../SignInMailAddress/Container";

export type GenerateSignInPropsArgs = {};

export const useGenerateProps = (_args: GenerateSignInPropsArgs): Widget.CandidateSignInProps => {
  const navigate = useTransitionNavigate();
  const lang = useLanguageCode();
  const helpCenterNavigate = useHelpCenterNavigate(lang);

  return {
    googleSignInButton: {
      onClick: () => {
        signInWithGoogle();
      },
    },
    githubSignInButton: {
      onClick: () => {
        signInWithGitHub();
      },
    },
    SignInComponent: <SignInMailAddressContainer />,
    footer: {
      helpCenterLink: {
        href: generateHelpCenterUrl(lang, "CANDIDATE_HELP_PAGE"),
        onClick: () => {
          helpCenterNavigate("CANDIDATE_HELP_PAGE", { _blank: true });
        },
      },
      termsOfUseLink: {
        href: generateHelpCenterUrl(lang, "TOS_FOR_CANDIDATE"),
        onClick: () => {
          helpCenterNavigate("TOS_FOR_CANDIDATE", { _blank: true });
        },
      },
      privacyPolicyLink: {
        href: generateHelpCenterUrl(lang, "PRIVACY_POLICY"),
        onClick: () => {
          helpCenterNavigate("PRIVACY_POLICY", { _blank: true });
        },
      },
    },
    signUpButton: {
      onClick: () => {
        navigate("/c/signup");
      },
    },
  };
};
