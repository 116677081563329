import type * as Types from "./types";

export const MIN_SALARY = {
  MAX: 1000,
  MIN: 600,
  STEP: 100,
} as const;

export const DEFAULT_SEARCH_FILTER: Types.SearchFilter = {
  statuses: [],
  selfHostedOnly: false,
  textFilter: "",
  minSalary: {
    max: MIN_SALARY.MAX,
    min: MIN_SALARY.MIN,
  },
};

export const DEFAULT_SORT_PARAMS: Types.SortParams = {
  sortMethod: "CREATED_AT",
  isDescending: true,
};

export const QueryKeys = {
  STATUS: "status",
  NAME: "name",
  MIN_SALARY_MAX: "maxSalary",
  MIN_SALARY_MIN: "minSalary",
  SORT: "sorting",
} as const;
