import { RemoteInterviewOverview } from "@hireroo/app-store/widget/e/RemoteInterviewOverview";
import type { Widget } from "@hireroo/presentation";
import * as React from "react";

type Item = Exclude<Widget.RemoteInterviewOverviewProps["metricItemTable"], undefined>["items"][0];

export const useGenerateMetricItemTableProps = (): Widget.RemoteInterviewOverviewProps["metricItemTable"] => {
  const interview = RemoteInterviewOverview.useInterview();

  const items = React.useMemo((): Item[] => {
    return interview.metrics.map(
      (metric): Item => ({
        id: metric.id,
        title: metric.title,
        description: metric.description,
      }),
    );
  }, [interview]);

  return {
    items: items,
  };
};
