import { RemoteInterviewResourceEditorStore } from "@hireroo/app-store/view-domain/RemoteInterviewResourceEditor";
import { EvaluationMetricListForRemote } from "@hireroo/app-store/widget/e/EvaluationMetricListForRemote";

export const subscribeEvaluationMetricMap = () => {
  return EvaluationMetricListForRemote.subscribeMetricMap(metricMap => {
    const metrics = Array.from(metricMap.values());
    if (metrics.length > 0) {
      RemoteInterviewResourceEditorStore.setEvaluationMetrics(metrics);
    }
  });
};
