import { useAnswerLabelMap } from "@hireroo/challenge/definition";
import { useTranslation } from "@hireroo/i18n";
import { useMethods, withSplitProvider } from "@hireroo/react-split";
import { AlgorithmAnswerForm } from "@hireroo/validator";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import * as React from "react";

import ActivityBar, { ActivityBarProps } from "../../modules/ActivityBar/ActivityBar";
import { QuestionAndSolutionLayoutProps } from "../../ms-components/Challenge";
import AnswerSection, { AnswerSectionProps } from "../../ms-components/Challenge/AnswerSection/AnswerSection";
import { EditorAndTestCasesSection, type EditorAndTestCasesSectionProps } from "../../ms-components/Challenge/ChallengeCodingEditorParts";
import QuestionDetailLayout, { QuestionDetailLayoutProps } from "../../ms-components/Challenge/QuestionDetailLayout/QuestionDetailLayout";
import { SplitParams, SplitParamsInEditorAndTestCasesSection, useSplitEvent } from "./privateHelper";

export {
  ChallengeCodingEditorWithDetailProvider,
  type ChallengeCodingEditorWithDetailProviderProps,
  useChallengeCodingEditorWithDetailContext,
} from "./Context";

type ExtendedAnswerSectionProps = AnswerSectionProps & { label?: string };

type QuestionDetailSidebar = Omit<QuestionDetailLayoutProps["sidebar"], "answerSections"> & {
  answers: ExtendedAnswerSectionProps[];
};

const RightSideWrapper = styled(Box)`
  width: 100%;
  height: 100%;
  display: flex;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
`;

export type ChallengeCodingEditorWithDetailProps = {
  content: Omit<QuestionDetailLayoutProps, "items" | "sidebar"> & { sidebar: QuestionDetailSidebar };
  editorAndTestCases: EditorAndTestCasesSectionProps;
};

const ChallengeCodingEditorWithDetail: React.FC<ChallengeCodingEditorWithDetailProps> = props => {
  const { t } = useTranslation();
  const methods = useMethods();
  const answerLabelMap = useAnswerLabelMap();
  const {
    sidebarVisibleStatus,
    setSidebarVisibleStatus,
    terminalVisibleStatus,
    setTerminalVisibleStatus,
    detailInfoVisibleStatus,
    setDetailInfoVisibleStatus,
  } = useSplitEvent();

  const items: QuestionAndSolutionLayoutProps["items"] = [
    {
      id: "right-side-bar",
      Content: (
        <RightSideWrapper>
          <EditorAndTestCasesSection {...props.editorAndTestCases} />
        </RightSideWrapper>
      ),
      size: {
        value: 100,
        unit: "%",
      },
    },
  ];

  const activityBarProps: ActivityBarProps = {
    items: [
      {
        icon: "DESCRIPTION",
        tooltip: t("問題"),
        onClick: () => {
          if (sidebarVisibleStatus === "MINIMIZED") {
            methods.expand(SplitParams.splitId, SplitParams.Contents.LeftSideBar.id);
          } else {
            methods.minimize(SplitParams.splitId, SplitParams.Contents.LeftSideBar.id);
          }
          setSidebarVisibleStatus(prev => (prev === "MINIMIZED" ? "OPEN" : "MINIMIZED"));
        },
        active: sidebarVisibleStatus === "OPEN",
      },
      {
        icon: "TERMINAL",
        tooltip: t("テストケース"),
        onClick: () => {
          if (terminalVisibleStatus === "MINIMIZED") {
            methods.expand(SplitParamsInEditorAndTestCasesSection.splitId, SplitParamsInEditorAndTestCasesSection.Contents.Terminal.id);
          } else {
            methods.minimize(SplitParamsInEditorAndTestCasesSection.splitId, SplitParamsInEditorAndTestCasesSection.Contents.Terminal.id);
          }
          setTerminalVisibleStatus(prev => (prev === "MINIMIZED" ? "OPEN" : "MINIMIZED"));
        },
        active: terminalVisibleStatus === "OPEN",
      },
      {
        icon: "INFO",
        tooltip: t("この問題について"),
        onClick: () => {
          if (detailInfoVisibleStatus === "MINIMIZED") {
            methods.expand(SplitParams.splitId, SplitParams.Contents.RightSideBar.id);
          } else {
            methods.minimize(SplitParams.splitId, SplitParams.Contents.RightSideBar.id);
          }
          setDetailInfoVisibleStatus(prev => (prev === "MINIMIZED" ? "OPEN" : "MINIMIZED"));
        },
        active: detailInfoVisibleStatus === "OPEN",
      },
    ],
  };

  return (
    <Box maxWidth="100vw" width="100%" height="100%" display="flex" flexDirection="row">
      <ActivityBar {...activityBarProps} />

      <QuestionDetailLayout
        {...props.content}
        items={items}
        sidebar={{
          ...props.content.sidebar,
          answerSections: props.content.sidebar.answers.map(({ label, ...answer }, index) => {
            const parsedLabel = AlgorithmAnswerForm.AlgorithmAnswerLabel.safeParse(label);
            const answerLabel = parsedLabel.success ? `- ${answerLabelMap[parsedLabel.data]}` : "";
            return {
              name: [t("解答"), index + 1, answerLabel].join(" "),
              Component: <AnswerSection {...answer} />,
            };
          }),
        }}
      />
    </Box>
  );
};

ChallengeCodingEditorWithDetail.displayName = "ChallengeCodingEditorWithDetail";

export default withSplitProvider(ChallengeCodingEditorWithDetail);
