import { Auth } from "@hireroo/app-store/essential/candidate";
import { CompetitionsIdDetail } from "@hireroo/app-store/page/c/competitions_id_detail";
import { useTranslation } from "@hireroo/i18n";
import type { Widget } from "@hireroo/presentation";
import { generateCurrentOriginUrl } from "@hireroo/router/api";

import CompetitionApplicantLeaderboardFetchContainer from "../CompetitionApplicantLeaderboard/FetchContainer";
import CompetitionOverviewContainer from "../CompetitionOverview/Container";
import { useGenerateHeaderProps } from "./useGenerateHeaderProps";

export type GenerateCompetitionIdDetailForCandidatePropsArgs = {
  refresh: () => void;
};

export const useGenerateProps = (args: GenerateCompetitionIdDetailForCandidatePropsArgs): Widget.CompetitionIdDetailForCandidateProps => {
  const headerProps = useGenerateHeaderProps(args);
  const candidate = Auth.useCurrentCandidate();
  const { t } = useTranslation();
  const competition = CompetitionsIdDetail.useCompetition();
  return {
    profileHref: !candidate.displayName ? generateCurrentOriginUrl("/c/settings/user") : undefined,
    header: headerProps,
    items: [
      {
        id: "OVERVIEW",
        label: {
          name: t("概要"),
        },
        Component: <CompetitionOverviewContainer />,
      },
      {
        id: "LEADERBOARD",
        label: {
          name: t("参加者一覧"),
          disabled: competition.status === "CREATED",
          disabledTooltip: t("コンペ開催前のため、参加者一覧は表示できません。"),
        },
        Component: <CompetitionApplicantLeaderboardFetchContainer competitionId={competition.competitionId} />,
      },
    ],
  };
};
