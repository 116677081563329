import { App, Auth } from "@hireroo/app-store/essential/candidate";
import { CompetitionsIdDetail } from "@hireroo/app-store/page/c/competitions_id_detail";
import * as Graphql from "@hireroo/graphql/client/urql";
import { Widget } from "@hireroo/presentation";
import { withErrorBoundary } from "@sentry/react";
import React from "react";
import { useParams } from "react-router-dom";

import CompetitionIdDetailForCandidateContainer, { CompetitionIdDetailForCandidateContainerProps } from "./Container";

export type CompetitionIdDetailForCandidateFetchContainerProps = {};

const CompetitionIdDetailForCandidateFetchContainer: React.FC<CompetitionIdDetailForCandidateFetchContainerProps> = () => {
  const appStatus = App.useStatus();
  const { id } = useParams<{ id: string }>();
  const competitionId = `${id}`;
  const initialized = CompetitionsIdDetail.useInitialized();
  const candidate = Auth.useCurrentCandidate();

  const [result, refresh] = Graphql.useGetCompetitionForCandidateCompetitionsIdDetailQuery({
    variables: {
      id: competitionId,
      candidateId: candidate.candidateId,
    },
    pause: appStatus !== "INITIALIZED",
    requestPolicy: "cache-and-network",
  });

  React.useEffect(() => {
    if (result.data) {
      CompetitionsIdDetail.initialize(result.data.competition, result.data.competition.applicantByCandidateId);
    }
  }, [result.data]);

  React.useEffect(() => {
    return () => {
      CompetitionsIdDetail.clear();
    };
  }, []);

  if (result.error) {
    return <Widget.RefreshPanel refresh={refresh} />;
  }
  if (result.fetching || appStatus !== "INITIALIZED" || !initialized) {
    return <Widget.Loading kind="CENTER_%" />;
  }

  const containerProps: CompetitionIdDetailForCandidateContainerProps = {
    refresh: refresh,
  };

  return <CompetitionIdDetailForCandidateContainer {...containerProps} />;
};

CompetitionIdDetailForCandidateFetchContainer.displayName = "CompetitionIdDetailForCandidateFetchContainer";

export default withErrorBoundary(CompetitionIdDetailForCandidateFetchContainer, {});
