import { Payment } from "@hireroo/app-store/essential/employee";
import * as Graphql from "@hireroo/graphql/client/urql";
import { useTranslation } from "@hireroo/i18n";
import type { Widget } from "@hireroo/presentation";

type RowProps = Widget.PlanUpdateFormProps["planSelectionField"]["rows"][0];

const createRowItemsFactory = (generation: number, planTypes: Graphql.PlanType[]) => {
  const keys = planTypes.map((planType): Payment.PaymentV2ItemType => {
    return `${planType}.${generation}`;
  });
  return (featureName: Payment.AvailableFeatureName): RowProps["items"] => {
    const list = Payment.FeaturePlanMap[featureName] as Graphql.PaymentItemType[];
    if (!list) {
      return [];
    }
    return keys.map((key): RowProps["items"][0] => {
      const paymentItemType = Payment.paymentV2ItemTypeToPaymentItemType[key];
      const included = list.includes(paymentItemType);
      if (included) {
        return {
          kind: "CHECK",
        };
      }
      return {
        kind: "TEXT",
        text: "-",
      };
    });
  };
};

type GenerateOption = { kind: "ALL_CHECK" } | { kind: "TEXT"; text: string };

const generateConstantRowItems = (planTypes: Graphql.PlanType[], args: GenerateOption): RowProps["items"] => {
  return planTypes.map(() => {
    if (args.kind === "ALL_CHECK") {
      return {
        kind: "CHECK",
      };
    }
    return {
      kind: "TEXT",
      text: args.text,
    };
  });
};

/**
 * @see https://www.notion.so/Pricing-525bc6172256436080e44e968dd2ea73#9b7cafef71fb450f929968afcbe85b5c
 * TODO: @himenon Use an abstract hook name
 */
export const useGenerateRowPropsList = (planTypes: Graphql.PlanType[]): RowProps[] => {
  const customer = Payment.useCustomerV2();
  const { generation } = customer.subscription.plan;
  const generateRowItems = createRowItemsFactory(generation, planTypes);
  const { t } = useTranslation();
  return [
    {
      rowName: t("実践形式"),
      items: generateRowItems("question.project.create"),
    },
    {
      rowName: t("システムデザイン形式"),
      items: generateRowItems("question.systemDesign.read"),
    },
    {
      rowName: t("ライブコーディング"),
      items: generateRowItems("interview.create"),
    },
    {
      rowName: t("レポート自動生成"),
      items: generateConstantRowItems(planTypes, { kind: "ALL_CHECK" }),
    },
    {
      rowName: t("レポート共有リンク"),
      items: generateConstantRowItems(planTypes, { kind: "ALL_CHECK" }),
    },
    {
      rowName: t("レポート詳細PDF出力"),
      items: generateConstantRowItems(planTypes, { kind: "ALL_CHECK" }),
    },
    {
      rowName: t("レポート一覧CSV出力"),
      items: generateRowItems("test.csv-export.read"),
    },
    {
      rowName: t("プレイバック機能"),
      items: generateRowItems("test.playback.read"),
    },
    {
      rowName: t("行動検知（コピペ・離脱）"),
      items: generateRowItems("test.copy-and-paste.read"),
    },
    {
      rowName: t("相対評価機能"),
      items: generateRowItems("test.statics.read"),
    },
    {
      rowName: t("技術レビュー機能"),
      items: generateRowItems("test.technical-comment.create"),
    },
    {
      rowName: t("権限管理機能"),
      items: generateConstantRowItems(planTypes, { kind: "ALL_CHECK" }),
    },
    {
      rowName: t("ユーザー追加人数"),
      items: generateConstantRowItems(planTypes, { kind: "TEXT", text: t("無制限") }),
    },
    {
      rowName: t("メール通知"),
      items: generateConstantRowItems(planTypes, { kind: "ALL_CHECK" }),
    },
    {
      rowName: t("Slack通知"),
      items: generateRowItems("test.notifications.slack.create"),
    },
    {
      rowName: "SSO",
      items: generateRowItems("signin.sso.create"),
    },
    {
      rowName: t("ATS連携"),
      items: generateRowItems("integrations.ats.create"),
    },
    {
      rowName: t("ヘルプページ"),
      items: generateConstantRowItems(planTypes, { kind: "ALL_CHECK" }),
    },
    {
      rowName: t("候補者サポート"),
      items: generateConstantRowItems(planTypes, { kind: "ALL_CHECK" }),
    },
    {
      rowName: t("多言語対応（英語）"),
      items: generateConstantRowItems(planTypes, { kind: "ALL_CHECK" }),
    },
  ];
};
