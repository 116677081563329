import { useTranslation } from "@hireroo/i18n";
import { Box } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import * as React from "react";

export type PhotoURLFieldProps = {
  photoUrl: string;
  disabled: boolean;
  onClickFileUpload: (file: File) => void;
};

const PhotoURLField: React.FC<PhotoURLFieldProps> = props => {
  const { t } = useTranslation();
  const inputRef = React.useRef<HTMLInputElement | null>(null);

  const onClickFileUploadButton = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.item(0);
    if (!file) return;

    props.onClickFileUpload(file);
    e.target.value = "";
  };

  const handleFileUploadClick = () => {
    inputRef.current?.click();
  };

  return (
    <Stack spacing={1.5}>
      <Typography fontSize={14} fontWeight={700}>
        {t("アイコン")}
      </Typography>

      <Stack spacing={2} direction="row" alignItems="center">
        <Box pl={2}>
          <Avatar
            alt="userImage"
            src={props.photoUrl}
            style={{
              height: 64,
              width: 64,
            }}
          />
        </Box>
        <Button component="label" color="inherit" onClick={handleFileUploadClick} disabled={props.disabled}>
          {t("新しい写真をアップロード")}
        </Button>
        <input type="file" ref={inputRef} hidden onChange={onClickFileUploadButton} accept="image/jpeg, image/png" />
      </Stack>
    </Stack>
  );
};

PhotoURLField.displayName = "PhotoURLField";

export default PhotoURLField;
