import { App } from "@hireroo/app-store/essential/employee";
import { Candidates } from "@hireroo/app-store/page/e/candidates";
import * as Graphql from "@hireroo/graphql/client/urql";
import { Widget } from "@hireroo/presentation";
import { withErrorBoundary } from "@sentry/react";
import React from "react";

import CandidateListContainer, { CandidateListContainerProps } from "./Container";

export type CandidateListFetchContainerProps = {};

const CandidateListFetchContainer: React.FC<CandidateListFetchContainerProps> = () => {
  const appStatus = App.useStatus();

  const initialized = Candidates.useInitialized();
  const currentSearchFilter = Candidates.useCurrentSearchFilter();
  const pager = Candidates.usePager();

  const [result, refresh] = Graphql.useListCandidatesForCandidatesQuery({
    variables: {
      filters: {
        displayName: currentSearchFilter.textFilter.length > 0 ? currentSearchFilter.textFilter : undefined,
        maxExpectedSalaryYen:
          currentSearchFilter.expectedSalary.max !== Candidates.EXPECTED_SALARY.MAX ? currentSearchFilter.expectedSalary.max : undefined,
        minExpectedSalaryYen:
          currentSearchFilter.expectedSalary.min !== Candidates.EXPECTED_SALARY.MIN ? currentSearchFilter.expectedSalary.min : undefined,
        jobChangeInterests: currentSearchFilter.jobChangeStatuses.slice(),
        maxAverageScore:
          currentSearchFilter.averageScore.max !== Candidates.AVERAGE_SCORE.MAX ? currentSearchFilter.averageScore.max : undefined,
        minAverageScore:
          currentSearchFilter.averageScore.min !== Candidates.AVERAGE_SCORE.MIN ? currentSearchFilter.averageScore.min : undefined,
        averageRankEvaluations: currentSearchFilter.averageRank.slice(),
      },
      isDescending: pager.sortParams.isDescending,
      offset: pager.offset,
      size: pager.size,
      sortMethod: pager.sortParams.sortMethod,
    },
    pause: appStatus !== "INITIALIZED",
    requestPolicy: "cache-and-network",
  });

  React.useEffect(() => {
    if (result.data) {
      Candidates.setResponse(result.data.candidates);
    }
  }, [result.data]);

  if (result.error) {
    return <Widget.RefreshPanel refresh={refresh} />;
  }
  if (result.fetching || appStatus !== "INITIALIZED" || !initialized) {
    return <Widget.Loading kind="CENTER_%" />;
  }

  const containerProps: CandidateListContainerProps = {};

  return <CandidateListContainer {...containerProps} />;
};

CandidateListFetchContainer.displayName = "CandidateListFetchContainer";

export default withErrorBoundary(CandidateListFetchContainer, {});
