import { Candidates } from "@hireroo/app-store/page/e/candidates";
import { Pages } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { startSubscribeListParams } from "./Subscriber";
import { useGenerateProps } from "./useGenerateProps";

export type CandidateListContainerProps = {};

const CandidateListContainer: React.FC<CandidateListContainerProps> = () => {
  const candidateListProps = useGenerateProps({});
  React.useEffect(() => {
    const stop = startSubscribeListParams();

    return () => {
      stop();
      Candidates.clear();
    };
  }, []);
  return (
    <ErrorBoundary>
      <Pages.CandidateList {...candidateListProps} />
    </ErrorBoundary>
  );
};

CandidateListContainer.displayName = "CandidateListContainer";

export default withErrorBoundary(CandidateListContainer, {});
