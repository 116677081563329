import firebase from "firebase/compat/app";

export type User = firebase.User;

export async function listenForRedirect() {
  return await firebase.auth().getRedirectResult();
}

export async function signInAnonymously(): Promise<firebase.auth.UserCredential> {
  return await firebase.auth().signInAnonymously();
}

// Please check at: https://firebase.google.com/docs/reference/js/auth.googleauthprovider?hl=ja
// Scope: https://developers.google.com/identity/protocols/oauth2/scopes#google-sign-in
export async function signInWithGoogle() {
  const provider = new firebase.auth.GoogleAuthProvider();
  provider.addScope("profile");
  provider.addScope("email");
  return await firebase.auth().signInWithRedirect(provider);
}

// Please check at: https://firebase.google.com/docs/reference/js/auth.githubauthprovider?hl=ja
// Scope: https://docs.github.com/ja/apps/oauth-apps/building-oauth-apps/scopes-for-oauth-apps
export async function signInWithGitHub() {
  const provider = new firebase.auth.GithubAuthProvider();
  provider.addScope("read:user");
  provider.addScope("user:email");
  return await firebase.auth().signInWithRedirect(provider);
}

export async function signInWithPassword(email: string, password: string) {
  return await firebase.auth().signInWithEmailAndPassword(email, password);
}

export async function signInWithSAML(providerId: string) {
  const provider = new firebase.auth.SAMLAuthProvider(providerId);
  return await firebase.auth().signInWithRedirect(provider);
}

export async function signUpWithPassword(email: string, password: string) {
  return await firebase.auth().createUserWithEmailAndPassword(email, password);
}

export async function sendEmailVerification(user: firebase.User, lang: string): Promise<void> {
  firebase.auth().languageCode = lang;
  return await user.sendEmailVerification();
}

export async function sendEmailVerificationForCurrentUser(lang: string): Promise<void> {
  const auth = firebase.auth();
  auth.languageCode = lang;

  return await auth.currentUser?.sendEmailVerification();
}

export async function confirmPasswordReset(actionCode: string, password: string): Promise<void> {
  return await firebase.auth().confirmPasswordReset(actionCode, password);
}
