import { Fields } from "@hireroo/validator";

import { QuestionDetailEditableListItemProps } from "./parts/QuestionDetailEditableListItem/QuestionDetailEditableListItem";

type UniqueKey = Fields.EntitySource.EntitySource["uniqueKey"];

type EntitySource = {
  title: string;
  name: string;
  variant?: string;
  options?: QuestionDetailEditableListItemProps["options"];
  itemLabel?: QuestionDetailEditableListItemProps["itemLabel"];
};

type GenerateQuestionDetailEditableListItemPropsArgs = {
  name: string;
  index: number;
  /**
   * @example "Q1", "Q1 - 1"
   */
  titlePrefix: string;
  uniqueKey: string;
  disabledAll: boolean | undefined;
  entitySources: Record<UniqueKey, EntitySource>;
};

export const generateQuestionDetailEditableListItemProps = (
  args: GenerateQuestionDetailEditableListItemPropsArgs,
): QuestionDetailEditableListItemProps | null => {
  const { index, titlePrefix, uniqueKey, disabledAll, name, entitySources } = args;
  const entitySource = entitySources[uniqueKey];
  if (!entitySource) {
    return null;
  }
  return {
    index,
    uniqueKey,
    name: name,
    title: `${titlePrefix} ${entitySource.title}`,
    itemLabel: entitySource.itemLabel,
    variantLabel: entitySource.variant,
    options: entitySource.options,
    disabledAll: disabledAll,
  };
};
