import { useTranslation } from "@hireroo/i18n";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import * as React from "react";

import { InputControlTextFieldProps } from "../../../../legacy/primitive/InputControl/InputControlTextField";
import InputControlTextField from "../../../../primitive/InputControl/InputControlTextField/InputControlTextField";

export type DisplayNameFieldProps = Pick<InputControlTextFieldProps, "sx">;

const DisplayNameField: React.FC<DisplayNameFieldProps> = props => {
  const { t } = useTranslation();
  return (
    <Stack direction="column" spacing={1.5}>
      <Typography fontSize={14} fontWeight={700}>
        {t("あなたの氏名")}
      </Typography>
      <InputControlTextField name="displayName" {...props} />
    </Stack>
  );
};

DisplayNameField.displayName = "DisplayNameField";

export default DisplayNameField;
