import { App, Company } from "@hireroo/app-store/essential/employee";
import { RemoteCreateDialog } from "@hireroo/app-store/widget/e/RemoteCreateDialog";
import * as Graphql from "@hireroo/graphql/client/urql";
import { Widget } from "@hireroo/presentation";
import { withErrorBoundary } from "@sentry/react";
import React from "react";

import ScreeningTestListSingleChoiceContainer, { ScreeningTestListSingleChoiceContainerProps } from "./Container";

export type ScreeningTestListSingleChoiceFetchContainerProps = {};

const ScreeningTestListSingleChoiceFetchContainer: React.FC<ScreeningTestListSingleChoiceFetchContainerProps> = () => {
  const appStatus = App.useStatus();
  const activeCompanyId = Company.useStrictActiveCompanyId();
  const size = RemoteCreateDialog.useSize();
  const listParams = RemoteCreateDialog.useListParams();

  const [result, refresh] = Graphql.useListSpotsByCompanyIdForRemoteCreateDialogQuery({
    variables: {
      input: {
        companyId: activeCompanyId,
        isDescending: listParams.isDescending,
        offset: listParams.page * size,
        size: size,
        sortMethod: listParams.sortMethod,
        status: Graphql.SpotStatus.Unknown,
        filters: {
          name: listParams.filters.name,
          candidateEmail: listParams.filters.candidateEmail,
          candidateName: listParams.filters.candidateName,
          tagNames: listParams.filters.tagNames.slice(),
          screeningIds: listParams.filters.screeningIds.slice(),
          /** fixed statues */
          statuses: ["EVALUATED", "FINALIZED"],
          minTotalScore: null,
          maxTotalScore: null,
          minCustomScore: null,
          maxCustomScore: null,
          createdByList: [],
          rankEvaluations: [],
          suspiciousDegrees: [],
        },
        withAnswers: false,
        withCount: true,
        withDeleted: false,
      },
    },
    pause: appStatus !== "INITIALIZED",
  });

  React.useEffect(() => {
    if (result.data?.spotsByCompanyId) {
      RemoteCreateDialog.setRes(result.data.spotsByCompanyId);
    }
  }, [result.data?.spotsByCompanyId]);

  if (result.error) {
    return <Widget.RefreshPanel refresh={refresh} />;
  }
  if (result.fetching || appStatus !== "INITIALIZED") {
    return <Widget.Loading kind="CENTER_%" />;
  }

  const containerProps: ScreeningTestListSingleChoiceContainerProps = {};

  return <ScreeningTestListSingleChoiceContainer {...containerProps} />;
};

ScreeningTestListSingleChoiceFetchContainer.displayName = "ScreeningTestListSingleChoiceFetchContainer";

export default withErrorBoundary(ScreeningTestListSingleChoiceFetchContainer, {});
