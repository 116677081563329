import { RemoteCreateDialog } from "@hireroo/app-store/widget/e/RemoteCreateDialog";
import { useTranslationWithVariable } from "@hireroo/i18n";
import type { Widget } from "@hireroo/presentation";
import { generateCurrentOriginUrl, navigate as nativeNavigate } from "@hireroo/router/api";
import * as React from "react";

import { generateSortMethod, sortMethodMap } from "./privateHelper";

type Item = Widget.ScreeningTestListSingleChoiceProps["screeningTestListTable"]["items"][0];

export type GenerateScreeningTestListSingleChoicePropsArgs = {};

export const useGenerateProps = (_args: GenerateScreeningTestListSingleChoicePropsArgs): Widget.ScreeningTestListSingleChoiceProps => {
  const { t: t2 } = useTranslationWithVariable();
  const selectedSpotId = RemoteCreateDialog.useSelectedSpotId();
  const res = RemoteCreateDialog.useRes();
  const size = RemoteCreateDialog.useSize();
  const page = RemoteCreateDialog.usePage();
  const listParams = RemoteCreateDialog.useListParams();
  const refreshKey = RemoteCreateDialog.useRefreshKey();

  return {
    searchResultBar: {
      defaultValue: generateSortMethod({ sortMethod: listParams.sortMethod, isDescending: listParams.isDescending }),
      resultText: t2("SearchResultCount", { count: res === null ? "-" : res.count }),
      onChangeSortMethod: value => {
        const { sortMethod, isDescending } = sortMethodMap[value];
        RemoteCreateDialog.updateListParams({
          isDescending: isDescending,
          sortMethod: sortMethod,
        });
      },
    },
    refreshKey: `${refreshKey}${res?.spots.length}`,
    screeningTestListTable: {
      items: React.useMemo(() => {
        return (res?.spots ?? []).map((spot): Item => {
          const url = generateCurrentOriginUrl("/e/screenings/tests/:id/detail", {
            pathParams: { id: spot.spotId },
            queryParams: { tab: "report" },
          });
          return {
            id: spot.spotId,
            candidateName: spot.candidateName,
            candidateEmail: spot.candidateEmail,
            selected: selectedSpotId === spot.spotId,
            screeningName: spot.screening?.name ?? undefined,
            onClick: () => {
              RemoteCreateDialog.updateSelectedSpotId(spot.spotId);
            },
            detailButton: {
              onClick: () => {
                nativeNavigate(url, { _blank: true });
              },
            },
            href: url,
          };
        });
      }, [res?.spots, selectedSpotId]),
    },
    tablePagination: {
      count: res?.count ?? 0,
      rowsPerPage: size,
      onRowsPerPageChange: event => {
        const newSize = parseInt(event.target.value, 10);
        RemoteCreateDialog.updateSize(newSize);
      },
      page: page,
      onPageChange: (_, newPage) => {
        RemoteCreateDialog.updatePage(newPage);
      },
    },
  };
};
