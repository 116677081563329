import { useTranslation } from "@hireroo/i18n";
import { ResetPasswordForm } from "@hireroo/validator";
import { zodResolver } from "@hookform/resolvers/zod";
import Stack from "@mui/material/Stack";
import * as React from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";

import { MailAddressFieldProps } from "../../../../modules/MailAddressField/MailAddressField";
import FieldLabel from "../../../../ms-components/Auth/FieldLabel/FieldLabel";
import FormSubmitButton, { FormSubmitButtonProps } from "../../../../primitive/Button/FormSubmitButton/FormSubmitButton";
import InputControlTextField from "../../../../primitive/InputControl/InputControlTextField/InputControlTextField";

export type RequestResetPasswordFormProps = {
  onSubmit: SubmitHandler<ResetPasswordForm.RequestResetPasswordFormSchema>;
};

const RequestResetPasswordForm: React.FC<RequestResetPasswordFormProps> = props => {
  const { t } = useTranslation();
  const validateSchema = ResetPasswordForm.useRequestResetPasswordFormSchema();
  const method = useForm<ResetPasswordForm.RequestResetPasswordFormSchema>({
    resolver: zodResolver(validateSchema),
    defaultValues: { email: "" },
  });

  const mailAddressFieldProps: MailAddressFieldProps = {
    fullWidth: true,
    color: "primary",
    placeholder: "contact@hireroo.io",
  };
  const button: FormSubmitButtonProps = {
    children: t("パスワード再設定リンクを送信"),
    onClick: method.handleSubmit(props.onSubmit),
    sx: {
      height: "40px",
      textTransform: "none",
    },
  };
  return (
    <Stack spacing={2}>
      <FormProvider {...method}>
        <FieldLabel label={t("メールアドレス")}>
          <InputControlTextField {...mailAddressFieldProps} name="email" />
        </FieldLabel>
        <FormSubmitButton {...button} />
      </FormProvider>
    </Stack>
  );
};

RequestResetPasswordForm.displayName = "RequestResetPasswordForm";

export default RequestResetPasswordForm;
