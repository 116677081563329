import { Mixpanel } from "@hireroo/app-monitoring";
import { Auth, Company, Payment, Role, Security } from "@hireroo/app-store/essential/employee";
import { App, Credential } from "@hireroo/app-store/essential/shared";
import { FirebaseUser, getExpireDate, getIdToken, parseCustomClaims } from "@hireroo/firebase";
import { initialize as initializeGraphqlClient } from "@hireroo/graphql/client/request";
import type * as Graphql from "@hireroo/graphql/client/urql";
import { redirect, safeRedirect } from "@hireroo/router/api";

import { initializeGraphqlClientSdkAndRestApiClient } from "./helper";

const startSubscribeActiveCompanyIdZero = () => {
  return Company.subscribeActiveCompanyIdZero(async () => {
    Company.updateErrorStatus("DO_NOT_BELONG_TO_ANY_COMPANY");
    Role.initialize();
    Payment.initialize();
  });
};

const startSubscribeForSwitchActiveCompany = () => {
  /**
   * Executed after the server-side state ActiveCompanyId is changed.
   */
  return Company.subscribeActiveCompanyId(async (activeCompanyId, previousActiveCompanyId) => {
    App.setStatus("INITIALIZING");
    if (activeCompanyId !== previousActiveCompanyId) {
      await redirect("/e/home");
    }
    App.setStatus("INITIALIZED");
  });
};

type EmployeeInitializeArgs = {
  currentUser: FirebaseUser;
  authToken: string;
  res: Graphql.InitializeParamsForEmployeeFragment;
};

const signedInProcess = async (args: EmployeeInitializeArgs): Promise<void> => {
  const { currentUser, authToken, res } = args;
  if (res.shouldAction === "GO_EMPLOYEE_HOME") {
    await safeRedirect("/e/home");
  }
  Credential.setCurrentUserType("EMPLOYEE");
  initializeGraphqlClient(import.meta.env.VITE_GRAPHQL_SERVER_ADDRESS, {
    authToken,
    getOrRefreshAuthTokenExpire: Credential.getOrRefreshAuthTokenExpire,
    appVersion: APP_VERSION,
  });
  Auth.setUser(res.currentUser);
  App.setEnableTalentScore(res.activeCompany.enableTalentScore);
  App.setEnableProject(res.activeCompany.enableProject);
  Payment.setCustomerV2(res.customer);
  Payment.initialize();
  if (res.activeCompany.security) {
    Security.initialize(res.activeCompany.security);
  }
  Company.addActiveCompany(res.activeCompany);
  Company.initializeBelongsCompanies({
    activeCompanyId: res.activeCompany.companyId,
    companies: res.belongsCompanies.slice(),
    pager: undefined,
  });
  Role.updateCurrentRole(res.employeeRole);
  Role.initialize();
  const idToken = await currentUser.getIdTokenResult(true);
  const customClaims = parseCustomClaims(idToken.token);
  Credential.setProviderId(idToken.signInProvider);
  Credential.setUid(currentUser.uid);
  Credential.setMailAddress(currentUser.email);
  Credential.setProviderId(idToken.signInProvider);
  Credential.setIsSystemAdmin(customClaims?.system_admin ?? false);
  const expireDate = await getExpireDate();
  Credential.setAuthToken({
    authToken: authToken || null,
    expireDate: expireDate || null,
    getRefreshAuthToken: async () => {
      const authToken = await getIdToken(true);
      const expireDate = await getExpireDate();
      if (authToken) {
        initializeGraphqlClientSdkAndRestApiClient(authToken);
      }
      return {
        authToken,
        expireDate,
      };
    },
  });
  Mixpanel.identifyCurrentUser({ uid: currentUser.uid, companyId: res.activeCompany.companyId });

  startSubscribeActiveCompanyIdZero();
  startSubscribeForSwitchActiveCompany();
};

const initialize = async (args: EmployeeInitializeArgs): Promise<void> => {
  App.setStatus("INITIALIZING");
  await signedInProcess(args);
  App.setStatus("INITIALIZED");
};

export const Employee = {
  initialize,
};
