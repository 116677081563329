import { QuestionSelectFieldForResourceEditor } from "@hireroo/app-store/widget/e/QuestionSelectFieldForResourceEditor";
import * as Graphql from "@hireroo/graphql/client/urql";
import { Fields } from "@hireroo/validator";

type EntityTrack = Fields.EntityTrack.EntityTrack;
type Sessions = Exclude<Exclude<Graphql.RemoteForRemoteInterviewResourceEditorFragment["entity"], null>["liveCoding"], null>["sessions"];

export const convertEntityTracksValidatorFromGraphql = (sessions: Sessions): EntityTrack[] => {
  return sessions.reduce<EntityTrack[]>((all, session) => {
    if (!session) {
      return all;
    }
    if (session.algorithmQuestion) {
      const entityTrack: EntityTrack = {
        type: "FIXED",
        questionScoreWeight: 1,
        entitySource: {
          type: "REMOTE_ALGORITHM",
          uniqueKey: session.algorithmQuestion.key,
          questionId: session.algorithmQuestion.questionId,
          questionVersion: session.algorithmQuestion.version,
          isSelectable: true,
        },
      };
      return all.concat(entityTrack);
    } else if (session.systemDesignQuestion) {
      const entityTrack: EntityTrack = {
        type: "FIXED",
        questionScoreWeight: 1,
        entitySource: {
          type: "REMOTE_SYSTEM_DESIGN",
          uniqueKey: session.systemDesignQuestion.key,
          questionId: session.systemDesignQuestion.questionId,
          isSelectable: true,
        },
      };
      return all.concat(entityTrack);
    }
    return all;
  }, []);
};

type IssuedEntity = Graphql.SpotForRemoteInterviewResourceEditorFragment["issuedEntities"][number];

export const convertEntityTracksValidatorFromGraphqlIssuedEntities = (issuedEntities: IssuedEntity[]): EntityTrack[] => {
  return issuedEntities.reduce<EntityTrack[]>((all, issuedEntity) => {
    if (!issuedEntity) {
      return all;
    }
    switch (issuedEntity.__typename) {
      case "SpotIssuedChallengeEntity": {
        if (issuedEntity.entity.challengeQuestion) {
          const entityTrack: EntityTrack = {
            type: "FIXED",
            questionScoreWeight: 1,
            entitySource: {
              type: "REMOTE_ALGORITHM",
              uniqueKey: issuedEntity.entity.challengeQuestion.key,
              questionId: issuedEntity.entity.challengeQuestion.questionId,
              questionVersion: issuedEntity.entity.challengeQuestion.version,
              isSelectable: true,
            },
          };
          return all.concat(entityTrack);
        }
        break;
      }
      case "SpotIssuedSystemDesignEntity": {
        if (issuedEntity.entity.question) {
          const entityTrack: EntityTrack = {
            type: "FIXED",
            questionScoreWeight: 1,
            entitySource: {
              type: "REMOTE_SYSTEM_DESIGN",
              uniqueKey: issuedEntity.entity.question.key,
              questionId: issuedEntity.entity.question.questionId,
              isSelectable: true,
            },
          };
          return all.concat(entityTrack);
        }
        break;
      }
    }
    return all;
  }, []);
};

export const convertSessionsToEntitySourceQuestions = (sessions: Sessions): QuestionSelectFieldForResourceEditor.EntitySourceQuestion[] => {
  return sessions.reduce<QuestionSelectFieldForResourceEditor.EntitySourceQuestion[]>((all, session) => {
    if (!session) {
      return all;
    }
    if (session.algorithmQuestion) {
      return all.concat(session.algorithmQuestion);
    } else if (session.systemDesignQuestion) {
      return all.concat(session.systemDesignQuestion);
    }
    return all;
  }, []);
};
