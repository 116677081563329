import { currentWindowMatchPath } from "./matchPath";

const key = "appcontext.lastvisited";

export const LastVisitedRoute = {
  get: () => {
    return localStorage.getItem(key);
  },
  getAndClear: () => {
    const path = localStorage.getItem(key);
    localStorage.removeItem(key);
    return path;
  },
  save: () => {
    const pathname = window.location.pathname;
    const ignoreList: boolean[] = [
      pathname.startsWith("/__/auth"),
      !!currentWindowMatchPath("/"),
      !!currentWindowMatchPath("/auth"),
      !!currentWindowMatchPath("/signin"),
      !!currentWindowMatchPath("/signup"),
      !!currentWindowMatchPath("/c/signin"),
      !!currentWindowMatchPath("/c/signup"),
      !!currentWindowMatchPath("/c/verify_email"),
      !!currentWindowMatchPath("/reset/password"),
    ];
    if (ignoreList.some(value => value)) {
      return;
    }
    localStorage.setItem(key, window.location.pathname);
  },
  clear: () => {
    localStorage.removeItem(key);
  },
};
