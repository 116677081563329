import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { useGenerateProps } from "./useGenerateProps";

export type CompetitionIdDetailContainerProps = {};

const CompetitionIdDetailContainer: React.FC<CompetitionIdDetailContainerProps> = () => {
  const competitionIdDetailProps = useGenerateProps({});
  return (
    <ErrorBoundary>
      <Widget.CompetitionIdDetail {...competitionIdDetailProps} />
    </ErrorBoundary>
  );
};

CompetitionIdDetailContainer.displayName = "CompetitionIdDetailContainer";

export default withErrorBoundary(CompetitionIdDetailContainer, {});
