import { CompetitionsIdDetail } from "@hireroo/app-store/page/e/competitions_id_detail";
import * as Graphql from "@hireroo/graphql/client/urql";

export const SortMethodReverseMap: Record<
  string,
  Exclude<Graphql.ApplicantSortMethod, typeof Graphql.ApplicantSortMethod.CompletedAt | typeof Graphql.ApplicantSortMethod.AppliedAt>
> = {
  [Graphql.ApplicantSortMethod.TotalScore]: Graphql.ApplicantSortMethod.TotalScore,
  [Graphql.ApplicantSortMethod.Rank]: Graphql.ApplicantSortMethod.Rank,
  [Graphql.ApplicantSortMethod.ElapsedTime]: Graphql.ApplicantSortMethod.ElapsedTime,
};

export const convertStringToSortFieldValue = (value: string | null): CompetitionsIdDetail.ApplicantSortParams => {
  if (value === null) return CompetitionsIdDetail.DEFAULT_SORT_PARAMS;
  try {
    // "sortField+isDesc" (e.g.) "CREATED_AT-true"
    const [field, isDesc] = value.split("-");
    const sortField = SortMethodReverseMap[field] ?? CompetitionsIdDetail.DEFAULT_SORT_PARAMS.sortMethod;
    return {
      sortMethod: sortField,
      isDescending: isDesc === "true",
    };
  } catch (_) {
    return CompetitionsIdDetail.DEFAULT_SORT_PARAMS;
  }
};

export const convertStringToStatuses = (values: string[] | null): CompetitionsIdDetail.ApplicantFilter["statuses"] => {
  if (!values) {
    return [];
  }

  const statusMap: Record<string, CompetitionsIdDetail.ApplicantFilter["statuses"][0]> = {
    CREATED: "CREATED",
    STARTED: "STARTED",
    COMPLETED: "COMPLETED",
    CONFIRMED: "CONFIRMED",
  };
  const newStatuses: CompetitionsIdDetail.ApplicantFilter["statuses"] = [];

  for (const value of values) {
    value.split("%").forEach(v => {
      // Check if the string value can be converted to a valid status.
      if (statusMap[v]) {
        newStatuses.push(statusMap[v]);
      }
    });
  }
  return newStatuses;
};

export const convertStringToTotalScore = (value: string | null): number | undefined => {
  if (!value) return undefined;
  const score = Number(value);

  if (0 <= score && score <= 1) {
    return score;
  }
  return undefined;
};

export const SUSPICIOUS_DEGREE_REVERSE_MAP: Record<string, CompetitionsIdDetail.ApplicantFilter["suspiciousDegrees"][number]> = {
  CLEAN: "CLEAN",
  SUSPICIOUS: "SUSPICIOUS",
};

export const convertStringsToSuspiciousDegrees = (values: string[]): CompetitionsIdDetail.ApplicantFilter["suspiciousDegrees"] => {
  const newSuspiciousDegrees: CompetitionsIdDetail.ApplicantFilter["suspiciousDegrees"] = [];

  for (const value of values) {
    if (!value) continue;
    value.split("%").forEach(v => {
      if (SUSPICIOUS_DEGREE_REVERSE_MAP[v]) {
        newSuspiciousDegrees.push(SUSPICIOUS_DEGREE_REVERSE_MAP[v]);
      }
    });
  }
  return newSuspiciousDegrees;
};

export const convertStringToPage = (value: string | null): number => {
  if (value === null) return 0;
  const page = Number(value);
  // Set it to 0 if it is invalid (NaN or negative). Note that page > 0 is false if page is NaN.
  // Actual (zero-index) page number = (page number from the query param) - 1
  if (!Number.isInteger(page) || page < 1) {
    return 0;
  }
  return page - 1;
};

const DEFAULT_SIZE = CompetitionsIdDetail.DEFAULT_APPLICANT_PAGER.size;
const SIZES = [20, 30, 50];
export const convertStringToSize = (value: string | null) => {
  if (value === null) return DEFAULT_SIZE;
  const size = Number(value);
  // Set it to the default value if it is invalid.
  if (!SIZES.includes(size)) {
    return DEFAULT_SIZE;
  }
  return size;
};
