import ScorePieChartWithRanking, { ScorePieChartWithRankingProps } from "@hireroo/charts/react/v2/ScorePieChartWithRanking";
import { useTranslation } from "@hireroo/i18n";
import WarningIcon from "@mui/icons-material/Warning";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";

import EntityScoreSelector, { EntityScoreSelectorProps } from "../../../modules/EntityScoreSelector/EntityScoreSelector";
import Link, { LinkProps } from "../../../primitive/Link/Link";

const SuspiciousMessageWrapper = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(3),
  padding: `${theme.spacing(1)} ${theme.spacing(1.75)}`,
  alignItems: "center",
  display: "flex",
  color: theme.palette.text.secondary,
  backgroundColor: theme.palette.Action.Hover.p8,
  border: `1px solid ${theme.palette.warning.main}`,
  borderRadius: "4px",
}));

const StyledWarningIcon = styled(WarningIcon)(({ theme }) => ({
  color: theme.palette.warning.main,
  paddingRight: theme.spacing(1.25),
  fontSize: "32px",
}));

export type CompetitionReportDetailSummaryProps = {
  scorePieChart?: ScorePieChartWithRankingProps;
  status: "READY" | "NOT_READY";
  entityScoreSelector: EntityScoreSelectorProps;
  helpLink: Pick<LinkProps, "onClick">;
  showSuspiciousBehaviorText: boolean;
};

const ReportDetailSummary: React.FC<CompetitionReportDetailSummaryProps> = props => {
  const { t } = useTranslation();

  return (
    <Box>
      <Stack direction="row" display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        <Typography id="summary" fontSize={16} sx={{ fontWeight: "bold" }}>
          {t("サマリー")}
        </Typography>
      </Stack>
      {props.showSuspiciousBehaviorText && (
        <SuspiciousMessageWrapper>
          <StyledWarningIcon />
          <Typography>{t("この候補者から不審な行動がみつかりました。")}</Typography>
        </SuspiciousMessageWrapper>
      )}
      <Box>
        <Stack direction="column" spacing={2} width="100%">
          <Stack direction="row" spacing={2} width="100%">
            {props.scorePieChart && (
              <Stack direction="column" alignItems="center" spacing={1.5}>
                <React.Suspense>
                  <ScorePieChartWithRanking {...props.scorePieChart} />
                </React.Suspense>
              </Stack>
            )}
            <EntityScoreSelector {...props.entityScoreSelector} />
          </Stack>
        </Stack>
        <Box textAlign="right" my={1}>
          <Typography variant="caption">
            <Link {...props.helpLink} underline={"hover"} color="secondary">{`${t("レポートの見方はこちら")}`}</Link>
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

ReportDetailSummary.displayName = "ReportDetailSummary";

export default ReportDetailSummary;
