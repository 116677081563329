import { DEFAULT_PAGER } from "./constants";
import { state } from "./State";
import type * as Types from "./types";

export const initializeFilter = (pager: Omit<Types.Pager, "offset">): void => {
  state.pager = {
    ...pager,
    offset: pager.page * pager.size,
  };
  state.initializedFilter = true;
};

export const setResponse = (res: Types.ApplicantsResponse): void => {
  state.res = res;

  state.competitionIds = res.applicants.map(applicant => {
    return applicant.competition.id;
  });

  res.applicants.forEach(applicant => {
    state.applicantMap.set(applicant.competition.id, applicant);
  });
};

export const clear = (): void => {
  state.res = null;
  state.applicantMap.clear();
  state.competitionIds = [];
  state.pager = DEFAULT_PAGER;
  state.initializedFilter = false;
};

export const updatePage = (page: number) => {
  state.pager = {
    ...state.pager,
    page,
    offset: page * state.pager.size,
  };
};

export const updatePageSize = (size: number) => {
  state.pager = {
    ...state.pager,
    size: size,
    // Reset page when page size is updated
    page: 0,
    offset: 0,
  };
};

export const updateSortParams = (sortParams: Types.SortParams) => {
  state.pager = {
    ...state.pager,
    sortParams,
    // Reset page when sortParams is updated
    page: 0,
    offset: 0,
  };
};
