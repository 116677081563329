import InsertInvitationRoundedIcon from "@mui/icons-material/InsertInvitationRounded";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import { useTheme } from "@mui/material";
import Card from "@mui/material/Card";
import CardActionArea, { CardActionAreaProps } from "@mui/material/CardActionArea";
import CardContent from "@mui/material/CardContent";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";

import MoreButton, { MoreButtonProps } from "../../../../primitive/Button/MoreButton/MoreButton";
import CompetitionThumbnail, { CompetitionThumbnailProps } from "../CompetitionThumbnail/CompetitionThumbnail";

const StyledCard = styled(Card)(({ theme }) => ({
  borderRadius: "8px",
  backgroundColor: theme.palette["Background/Paper"].p1,
  minHeight: "300px",
}));

const Title = styled(Typography)(() => ({
  fontSize: 16,
  fontWeight: 700,
  whiteSpace: "pre-line",
}));

const SalaryWrapper = styled(Stack)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  padding: theme.spacing(1.5, 2),
  borderTop: `1px solid ${theme.palette.divider}`,
}));

export type CompetitionCardProps = {
  id: string;
  title: string;
  duration: string;
  candidates: string;
  salary: string;
  thumbnail: CompetitionThumbnailProps;
  onClick?: CardActionAreaProps["onClick"];
  href: string;
  moreButton?: Pick<MoreButtonProps, "options">;
};

const CompetitionCard: React.FC<CompetitionCardProps> = props => {
  const theme = useTheme();
  const cardActionAreaProps: CardActionAreaProps & { href: string } = {
    LinkComponent: "a",
    href: props.href,
    onClick: event => {
      event.preventDefault();
      event.stopPropagation();
      props.onClick?.(event);
    },
  };
  return (
    <StyledCard>
      <CardActionArea {...cardActionAreaProps}>
        <CompetitionThumbnail {...props.thumbnail} mediaHeight={140} />
        <CardContent sx={{ padding: 0 }}>
          <Stack p={2}>
            <Stack direction="row" mb={1.5} alignItems="center">
              <Title fontSize={16} fontWeight={700}>
                {props.title}
              </Title>
              {props.moreButton && <MoreButton {...props.moreButton} variant="vertical" />}
            </Stack>
            <Stack direction="row" alignItems="center" spacing={1} mb={0.25}>
              <InsertInvitationRoundedIcon fontSize="small" htmlColor={theme.palette["Gray/Shades"].p56} />
              <Typography fontSize={14} color="textSecondary">
                {props.duration}
              </Typography>
            </Stack>
            <Stack direction="row" alignItems="center" spacing={1}>
              <SupervisorAccountIcon fontSize="small" htmlColor={theme.palette["Gray/Shades"].p56} />
              <Typography fontSize={14} color="textSecondary">
                {props.candidates}
              </Typography>
            </Stack>
          </Stack>
          <SalaryWrapper>
            <Typography fontWeight={700}>{props.salary}</Typography>
          </SalaryWrapper>
        </CardContent>
      </CardActionArea>
    </StyledCard>
  );
};

CompetitionCard.displayName = "CompetitionCard";

export default CompetitionCard;
