import { useTranslation } from "@hireroo/i18n";
import { CandidateProfileForm } from "@hireroo/validator";
import { zodResolver } from "@hookform/resolvers/zod";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import * as React from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";

import FormSubmitButton, { FormSubmitButtonProps } from "../../primitive/Button/FormSubmitButton/FormSubmitButton";
import DisplayNameField from "./parts/DisplayNameField/DisplayNameField";
import ExpectedSalaryField, { ExpectedSalaryFieldProps } from "./parts/ExpectedSalaryField/ExpectedSalaryField";
import ImageCropDialog, { ImageCropDialogProps } from "./parts/ImageCropDialog/ImageCropDialog";
import JobChangeStatusField, { JobChangeStatusFieldProps } from "./parts/JobChangeStatusField/JobChangeStatusField";
import PhotoURLField, { PhotoURLFieldProps } from "./parts/PhotoURLField/PhotoURLField";
import ProfileTextField from "./parts/ProfileTextField/ProfileTextField";

const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette["Background/Paper"].p1,
  borderRadius: theme.spacing(2),
}));

export type CandidateProfileProps = {
  onSubmit: SubmitHandler<CandidateProfileForm.CandidateProfileFormSchema>;
  photoURLField: PhotoURLFieldProps;
  imageCropDialog: ImageCropDialogProps;
  jobChangeStatusField: Pick<JobChangeStatusFieldProps, "items">;
  expectedSalaryField: Pick<ExpectedSalaryFieldProps, "items">;
  saveButton: Pick<FormSubmitButtonProps, "loading">;
  defaultValues?: CandidateProfileForm.CandidateProfileFormSchema;
};

const CandidateProfile: React.FC<CandidateProfileProps> = props => {
  const { t } = useTranslation();
  const validateSchema = CandidateProfileForm.useCandidateProfileForm();
  const { onSubmit } = props;

  const method = useForm({
    resolver: zodResolver(validateSchema),
    mode: "onChange",
    defaultValues: props.defaultValues,
  });

  const handleSubmit = React.useCallback(() => {
    method.handleSubmit(fields => {
      onSubmit(fields);
      method.reset(fields);
    })();
  }, [method, onSubmit]);

  const jobChangeStatusProps: JobChangeStatusFieldProps = {
    ...props.jobChangeStatusField,
    onChange: event => {
      const result = validateSchema.pick({ jobChangeStatus: true }).safeParse({ jobChangeStatus: event.target.value });
      if (result.error) {
        console.warn(result.error);
        return;
      }

      method.setValue("jobChangeStatus", result.data.jobChangeStatus);
    },
  };

  const expectedSalaryFieldProps: ExpectedSalaryFieldProps = {
    ...props.expectedSalaryField,
    onChange: event => {
      const result = validateSchema.pick({ expectedSalary: true }).safeParse({ expectedSalary: event.target.value });
      if (result.error) {
        console.warn(result.error);
        return;
      }

      method.setValue("expectedSalary", result.data.expectedSalary);
    },
  };

  const hasError = method.formState.errors && Object.keys(method.formState.errors).length !== 0;

  const submitButtonProps: FormSubmitButtonProps = {
    ...props.saveButton,
    disabled: hasError || !method.formState.isDirty,
    onClick: handleSubmit,
  };

  return (
    <StyledBox p={3}>
      <FormProvider {...method}>
        <Stack direction="column" spacing={3}>
          <PhotoURLField {...props.photoURLField} />
          <DisplayNameField sx={{ width: "50%" }} />
          <ProfileTextField sx={{ width: "100%" }} />
          <JobChangeStatusField {...jobChangeStatusProps} />
          <ExpectedSalaryField {...expectedSalaryFieldProps} />

          <Box display="flex" justifyContent="left">
            <FormSubmitButton color="secondary" size="medium" {...submitButtonProps}>
              {t("保存")}
            </FormSubmitButton>
          </Box>
        </Stack>

        <ImageCropDialog {...props.imageCropDialog} />
      </FormProvider>
    </StyledBox>
  );
};

CandidateProfile.displayName = "CandidateProfile";

export default CandidateProfile;
