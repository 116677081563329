import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { useGenerateProps } from "./useGenerateProps";

export type CandidateSignUpContainerProps = {};

const CandidateSignUpContainer: React.FC<CandidateSignUpContainerProps> = () => {
  const signUpProps = useGenerateProps({});
  return (
    <ErrorBoundary>
      <Widget.CandidateSignUp {...signUpProps} />
    </ErrorBoundary>
  );
};

CandidateSignUpContainer.displayName = "CandidateSignUpContainer";

export default withErrorBoundary(CandidateSignUpContainer, {});
