import { proxy } from "valtio";

import { defaultFilters } from "./contants";
import type * as Types from "./types";

export const state = proxy<Types.State>({
  open: false,
  step: "CHOOSE_OPTION",
  selectedSpotId: null,
  listParams: {
    isDescending: true,
    page: 0,
    size: 20,
    sortMethod: "CREATED_AT",
    filters: defaultFilters,
  },
  res: null,
  refreshKey: "",
});
