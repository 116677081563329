import * as Types from "./types";

export const DEFAULT_APPLICANT_FILTER: Types.ApplicantFilter = {
  textFilter: "",
  score: {
    min: 0,
    max: 100,
  },
  statuses: [],
  suspiciousDegrees: [],
};

export const DEFAULT_SORT_PARAMS: Types.ApplicantSortParams = {
  sortMethod: "RANK",
  isDescending: false,
};

export const DEFAULT_APPLICANT_PAGER: Types.ApplicantPager = {
  page: 0,
  size: 50,
  offset: 0,
  sortParams: DEFAULT_SORT_PARAMS,
};

export const QueryKeys = {
  STATUS: "status",
  NAME: "name",
  MIN_SCORE: "minScore",
  MAX_SCORE: "maxScore",
  SUSPICIOUS_DEGREES: "suspiciousDegrees",
  SORT: "sorting",
  PAGE: "page",
  SIZE: "size",
} as const;
