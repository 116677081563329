import * as CandidateEssentialStores from "@hireroo/app-store/essential/candidate";
import { VerifyEmail } from "@hireroo/app-store/page/c/verify_email";
import { Snackbar } from "@hireroo/app-store/widget/shared/Snackbar";
import { sendEmailVerificationForCurrentUser, signOut } from "@hireroo/firebase";
import { getLanguage, getTranslation, getTranslationWithVariable } from "@hireroo/i18n";
import { Widget } from "@hireroo/presentation";

export type GenerateCandidateNeedEmailVerificationPropsArgs = {};

export const useGenerateProps = (_args: GenerateCandidateNeedEmailVerificationPropsArgs): Widget.EmailVerificationScreenProps => {
  const isLoading = VerifyEmail.useIsLoading();
  const user = CandidateEssentialStores.Auth.useCurrentUser();
  if (!user) {
    return {
      resendButton: {},
      signOutButton: {},
    };
  }

  const { t } = getTranslation();
  const { t: t2 } = getTranslationWithVariable();
  const languageCode = getLanguage();

  return {
    resendButton: {
      disabled: isLoading,
      onClick: async () => {
        VerifyEmail.setIsLoading(true);
        try {
          await sendEmailVerificationForCurrentUser(languageCode);
          Snackbar.notify({
            severity: "success",
            message: t2("sendEmailVerify", { email: user.email }),
          });
        } catch {
          Snackbar.notify({
            severity: "error",
            // 何度も送信すると Firebase に Bad Request とみなされるので、しばらく待つよう促す
            message: t("メールアドレスの確認メールの送信に失敗しました。しばらく待ってから再度お試しください。"),
          });
        } finally {
          VerifyEmail.setIsLoading(false);
        }
      },
    },
    signOutButton: {
      disabled: isLoading,
      onClick: async () => {
        VerifyEmail.setIsLoading(true);
        try {
          await signOut();
        } catch {
          Snackbar.notify({
            severity: "error",
            message: t("サインアウトに失敗しました。しばらくしてから再度お試しください。"),
          });
        } finally {
          VerifyEmail.setIsLoading(false);
        }
      },
    },
  };
};
