import { useTranslation } from "@hireroo/i18n";
import { CompetitionHistorySearchForm } from "@hireroo/validator";

import type { SearchFormProps } from "../../../../modules/SearchForm/SearchForm";

type FieldKeyName = keyof CompetitionHistorySearchForm.CompetitionHistorySearchQuerySchema;

const FieldName = {
  TEXT_FILTER: "textFilter",
} satisfies Record<string, FieldKeyName>;

export const useTextFilterField = (): SearchFormProps["textFilter"] => {
  const { t } = useTranslation();
  return {
    name: FieldName.TEXT_FILTER,
    placeholder: t("コンペ検索"),
  };
};
