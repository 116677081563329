import { proxy } from "valtio";

import type { State } from "./types";

export const state = proxy<State>({
  uid: null,
  authToken: "",
  expireDate: null,
  getRefreshAuthToken: async () => {
    return {
      authToken: null,
      expireDate: null,
    };
  },
  providerId: null,
  signedIn: false,
  userType: null,
  customClaim: null,
  mailAddress: null,
  isSystemAdmin: false,
});
