import { App } from "@hireroo/app-store/essential/employee";
import { CompetitionsIdDetail } from "@hireroo/app-store/page/e/competitions_id_detail";
import * as Graphql from "@hireroo/graphql/client/urql";
import { Widget } from "@hireroo/presentation";
import { withErrorBoundary } from "@sentry/react";
import React from "react";

import CompetitionApplicantListContainer, { CompetitionApplicantListContainerProps } from "./Container";

export type CompetitionApplicantListFetchContainerProps = {};

const CompetitionApplicantListFetchContainer: React.FC<CompetitionApplicantListFetchContainerProps> = () => {
  const appStatus = App.useStatus();
  const competition = CompetitionsIdDetail.useCompetition();
  const initialized = CompetitionsIdDetail.useInitializedApplicantsRes();
  const filter = CompetitionsIdDetail.useApplicantFilter();
  const pager = CompetitionsIdDetail.useApplicantPager();

  const [result, refresh] = Graphql.useListApplicantsForEmployeeCompetitionsIdDetailQuery({
    variables: {
      filters: {
        competitionId: competition.competitionId,
        name: filter.textFilter,
        maxTotalScore: filter.score.max !== CompetitionsIdDetail.DEFAULT_APPLICANT_FILTER.score.max ? filter.score.max : undefined,
        minTotalScore: filter.score.min !== CompetitionsIdDetail.DEFAULT_APPLICANT_FILTER.score.min ? filter.score.min : undefined,
        statuses: filter.statuses.slice(),
        suspiciousDegrees: filter.suspiciousDegrees.slice(),
      },
      isDescending: pager.sortParams.isDescending,
      offset: pager.offset,
      size: pager.size,
      sortMethod: pager.sortParams.sortMethod,
    },
    pause: appStatus !== "INITIALIZED",
    requestPolicy: "cache-and-network",
  });

  React.useEffect(() => {
    if (result.data) {
      CompetitionsIdDetail.initializeApplicantsRes(result.data.applicants);
    }
  }, [result.data]);

  React.useEffect(() => {
    return () => {
      CompetitionsIdDetail.clearApplicantFilter();
    };
  }, []);

  if (result.error) {
    return <Widget.RefreshPanel refresh={refresh} />;
  }
  if (result.fetching || appStatus !== "INITIALIZED" || !initialized) {
    return <Widget.Loading kind="CENTER_%" />;
  }

  const containerProps: CompetitionApplicantListContainerProps = {};

  return <CompetitionApplicantListContainer {...containerProps} />;
};

CompetitionApplicantListFetchContainer.displayName = "CompetitionApplicantListFetchContainer";

export default withErrorBoundary(CompetitionApplicantListFetchContainer, {});
