import { Company, Payment } from "@hireroo/app-store/essential/employee";
import { App } from "@hireroo/app-store/essential/shared";
import { EmployeeSideBarContent } from "@hireroo/app-store/widget/e/EmployeeSideBarContent";
import { useTranslation } from "@hireroo/i18n";
import type { Widget } from "@hireroo/presentation";
import { generatePath } from "@hireroo/router/api";
import { useTransitionNavigate } from "@hireroo/router/hooks";
import * as React from "react";
import { useLocation } from "react-router";

import CompanySelectContainer from "./parts/CompanySelect/Container";

export type GenerateEmployeeSideBarContentPropsArgs = {};

type MenuItem = Exclude<Widget.LayoutSideBarContentProps["menuItems"], undefined>[0];
type MenuItemSection = Exclude<Widget.LayoutSideBarContentProps["menuItemSections"], undefined>[0];

export const useGenerateProps = (_args: GenerateEmployeeSideBarContentPropsArgs): Widget.LayoutSideBarContentProps => {
  const { t } = useTranslation();
  const mode = EmployeeSideBarContent.useMode();

  const navigate = useTransitionNavigate();

  const isAvailableFeature = Payment.useIsAvailableFeature();
  const location = useLocation();
  const company = Company.useActiveCompany();
  const enableTalentScore = App.useEnableTalentScore();

  /**
   * v1 design @deprecated remove after enabledSkillHiringPhase1 is released
   */
  const menuItems = React.useMemo((): Widget.LayoutSideBarContentProps["menuItems"] => {
    const baseMenuItems: MenuItem[] = [
      {
        id: "HOME",
        children: t("ホーム"),
        startIcon: "HOME",
        href: generatePath("/e/home"),
        onClick: () => {
          navigate("/e/home");
        },
      },
    ];
    const screeningItem: MenuItem[] = [
      {
        id: "SCREENINGS",
        href: generatePath("/e/screenings"),
        onClick: () => {
          navigate("/e/screenings");
        },
        title: t("コーディングテスト"),
        startIcon: "MONITOR",
        children: [
          {
            id: "SCREENINGS",
            children: t("コーディングテスト一覧"),
            href: generatePath("/e/screenings"),
            onClick: () => {
              navigate("/e/screenings");
            },
          },
          {
            id: "SCREENINGS_TESTS",
            children: t("候補者一覧"),
            href: generatePath("/e/screenings/tests"),
            onClick: () => {
              navigate("/e/screenings/tests");
            },
          },
          {
            id: "QUESTIONS",
            children: t("問題一覧"),
            href: generatePath("/e/questions"),
            onClick: () => {
              navigate("/e/questions");
            },
          },
        ],
      },
    ];
    baseMenuItems.push(...screeningItem);

    if (isAvailableFeature("interview.read")) {
      baseMenuItems.push({
        id: "REMOTES",
        title: t("リモートインタビュー"),
        href: generatePath("/e/remotes"),
        onClick: () => {
          navigate("/e/remotes");
        },
        startIcon: "VIDEOCAM",
        children: [
          {
            id: "INTERVIEWS",
            children: t("インタビュー一覧"),
            href: generatePath("/e/remotes"),
            onClick: () => {
              navigate("/e/remotes");
            },
          },
        ],
      });
    }

    if (isAvailableFeature("test.technical-comment.create")) {
      baseMenuItems.push({
        id: "EVALUATIONS_GROUP",
        href: generatePath("/e/evaluations/groups"),
        onClick: () => {
          navigate("/e/evaluations/groups");
        },
        title: t("評価指標管理"),
        startIcon: "REGISTRATION",
        children: [
          {
            id: "EVALUATIONS_GROUPS",
            children: t("評価指標一覧"),
            href: generatePath("/e/evaluations/groups"),
            onClick: () => {
              navigate("/e/evaluations/groups");
            },
          },
          {
            id: "EVALUATIONS_METRICS",
            children: t("評価項目一覧"),
            href: generatePath("/e/evaluations/metrics"),
            onClick: () => {
              navigate("/e/evaluations/metrics");
            },
          },
        ],
      });
    }

    if (enableTalentScore && isAvailableFeature("assessment.read")) {
      baseMenuItems.push({
        id: "ASSESSMENTS",
        children: t("タレントスコア"),
        startIcon: "ASSIGNMENT",
        href: generatePath("/e/assessments"),
        onClick: () => {
          navigate("/e/assessments");
        },
      });
    }

    return baseMenuItems;
  }, [t, isAvailableFeature, enableTalentScore, navigate]);

  /**
   * v2 design
   */
  const menuItemSections = React.useMemo((): Widget.LayoutSideBarContentProps["menuItemSections"] => {
    const baseMenuItemSections: MenuItemSection[] = [
      {
        items: [
          {
            id: "HOME",
            children: t("ホーム"),
            startIcon: "HOME",
            href: generatePath("/e/home"),
            onClick: () => {
              navigate("/e/home");
            },
          },
        ],
      },
    ];
    const skillInterviewItems: MenuItem[] = [
      {
        id: "SCREENINGS",
        href: generatePath("/e/screenings"),
        onClick: () => {
          navigate("/e/screenings");
        },
        title: t("コーディングテスト"),
        startIcon: "MONITOR",
        children: [
          {
            id: "SCREENINGS",
            children: t("コーディングテスト一覧"),
            href: generatePath("/e/screenings"),
            onClick: () => {
              navigate("/e/screenings");
            },
          },
          {
            id: "SCREENINGS_TESTS",
            children: t("候補者一覧"),
            href: generatePath("/e/screenings/tests"),
            onClick: () => {
              navigate("/e/screenings/tests");
            },
          },
          {
            id: "QUESTIONS",
            children: t("問題一覧"),
            href: generatePath("/e/questions"),
            onClick: () => {
              navigate("/e/questions");
            },
          },
        ],
      },
    ];

    if (isAvailableFeature("interview.read")) {
      skillInterviewItems.push({
        id: "REMOTES",
        title: t("リモートインタビュー"),
        href: generatePath("/e/remotes"),
        onClick: () => {
          navigate("/e/remotes");
        },
        startIcon: "VIDEOCAM",
        children: [
          {
            id: "INTERVIEWS",
            children: t("インタビュー一覧"),
            href: generatePath("/e/remotes"),
            onClick: () => {
              navigate("/e/remotes");
            },
          },
        ],
      });
    }

    if (isAvailableFeature("test.technical-comment.create")) {
      skillInterviewItems.push({
        id: "EVALUATIONS_GROUP",
        href: generatePath("/e/evaluations/groups"),
        onClick: () => {
          navigate("/e/evaluations/groups");
        },
        title: t("評価指標管理"),
        startIcon: "REGISTRATION",
        children: [
          {
            id: "EVALUATIONS_GROUPS",
            children: t("評価指標一覧"),
            href: generatePath("/e/evaluations/groups"),
            onClick: () => {
              navigate("/e/evaluations/groups");
            },
          },
          {
            id: "EVALUATIONS_METRICS",
            children: t("評価項目一覧"),
            href: generatePath("/e/evaluations/metrics"),
            onClick: () => {
              navigate("/e/evaluations/metrics");
            },
          },
        ],
      });
    }

    const skillInterviewSection: MenuItemSection = {
      title: "Skill Interview",
      items: [...skillInterviewItems],
    };

    baseMenuItemSections.push(skillInterviewSection);

    const skillHiringItems: MenuItem[] = [
      {
        id: "COMPETITIONS",
        children: t("コンペ一覧"),
        startIcon: "EVENT",
        href: generatePath("/e/competitions"),
        onClick: () => {
          navigate("/e/competitions");
        },
      },
      {
        id: "CANDIDATES",
        children: t("候補者一覧"),
        startIcon: "PERSON",
        href: generatePath("/e/candidates"),
        onClick: () => {
          navigate("/e/candidates");
        },
      },
    ];

    const skillHiringSection: MenuItemSection = {
      title: "Skill Hiring",
      items: skillHiringItems,
    };

    if (company?.enabledCompetition) {
      baseMenuItemSections.push(skillHiringSection);
    }

    if (enableTalentScore && isAvailableFeature("assessment.read")) {
      baseMenuItemSections.push({
        title: "Talent Score",
        items: [
          {
            id: "ASSESSMENTS",
            children: t("タレントスコア"),
            startIcon: "ASSIGNMENT",
            href: generatePath("/e/assessments"),
            onClick: () => {
              navigate("/e/assessments");
            },
          },
        ],
      });
    }

    return baseMenuItemSections;
  }, [company?.enabledCompetition, enableTalentScore, isAvailableFeature, navigate, t]);

  return {
    mode: mode,
    CompanySelect: <CompanySelectContainer />,
    menuItems: menuItems,
    createButton: {
      onClick: () => {
        if (location.pathname.includes("interviews")) {
          navigate("/e/interviews/create");
        } else if (location.pathname.includes("assessments")) {
          navigate("/e/assessments/create");
        } else if (location.pathname.includes("remotes")) {
          navigate("/e/remotes/create");
        } else {
          // create interview by default
          navigate("/e/interviews/create");
        }
      },
    },
    menuItemSections: menuItemSections,
  };
};
