import { useTranslation } from "@hireroo/i18n";
import GroupIcon from "@mui/icons-material/Group";
import PersonIcon from "@mui/icons-material/Person";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";

import ReadonlySelectedQuestionField, {
  ReadonlySelectedQuestionFieldProps,
} from "../../../../modules/ReadonlySelectedQuestionField/ReadonlySelectedQuestionField";
import MetricItemTable, { MetricItemTableProps } from "../../../../ms-components/Evaluation/MetricItemTable/MetricItemTable";
import { useRemoteInterviewResourceEditorContext } from "../../Context";
import ConfirmField from "./parts/ConfirmField/ConfirmField";
import Subsection, { SubsectionProps } from "./parts/Subsection/Subsection";

const OutlinedChip = styled(Chip)(({ theme }) => ({
  height: 30,
  ".MuiChip-deleteIcon": {
    color: theme.palette.action.disabled,
  },
  color: theme.palette.text.primary,
  borderColor: theme.palette.action.disabled,
}));

const MemoWrapper = styled(Box)(() => ({
  wordBreak: "break-all",
  whiteSpace: "pre-wrap",
  fontSize: "14px",
}));

export type ConfirmSectionProps = {
  name: string;
  selectedQuestions: ReadonlySelectedQuestionFieldProps[];
  candidateName: string;

  // Report Setup
  memo?: string;
  viewers: { iconSrc?: string; name: string; kind: "GROUP" | "USER" }[];
  tags: string[];
  enableEvaluationMetrics?: boolean;
  metricItemTable?: Omit<MetricItemTableProps, "enableActionButton" | "enableCheckbox">;
};

const ConfirmSection: React.FC<ConfirmSectionProps> = props => {
  const { t } = useTranslation();
  const { stepName, updateStep } = useRemoteInterviewResourceEditorContext();

  const questionSetupSubsection: SubsectionProps = {
    title: t("インタビュー設定"),
    changeButton: {
      onClick: () => {
        updateStep("REMOTE_QUESTION_SETUP");
      },
    },
    children: (
      <Stack spacing={2}>
        <ConfirmField label={t("タイトル")}>{props.name}</ConfirmField>
        <ConfirmField label={t("候補者")}>{props.candidateName}</ConfirmField>
        <ConfirmField label={t("出題問題")}>
          <Stack spacing={1}>
            {props.selectedQuestions.length === 0 && <Typography fontSize={14}>{t("指定なし")}</Typography>}
            {props.selectedQuestions.map((selectedQuestion, index) => {
              return <ReadonlySelectedQuestionField key={`selected-${index}`} {...selectedQuestion} />;
            })}
          </Stack>
        </ConfirmField>
      </Stack>
    ),
  };

  const reportSetupSubsection: SubsectionProps = {
    title: t("レポート設定"),
    changeButton: {
      onClick: () => {
        updateStep("REPORT_SETUP");
      },
    },
    children: (
      <Stack spacing={2}>
        <ConfirmField label={t("社内用メモ")}>
          <MemoWrapper>{props.memo}</MemoWrapper>
        </ConfirmField>
        <ConfirmField label={t("タグ設定")}>
          {props.tags.length > 0 ? (
            <Stack direction="row" sx={{ flexWrap: "wrap", gap: "8px" }}>
              {props.tags.map((tag, index) => {
                return <OutlinedChip key={`${tag}-${index}`} variant="outlined" label={tag} />;
              })}
            </Stack>
          ) : undefined}
        </ConfirmField>
        <ConfirmField label={t("閲覧権限")}>
          {props.viewers.length > 0 ? (
            <Stack direction="row" sx={{ flexWrap: "wrap", gap: "8px" }}>
              {props.viewers.map((viewer, index) => {
                const avatar = (
                  <Avatar src={viewer.kind === "USER" ? viewer.iconSrc : undefined}>
                    {viewer.kind === "USER" && !viewer.iconSrc && <PersonIcon />}
                    {viewer.kind === "GROUP" && <GroupIcon />}
                  </Avatar>
                );
                return <OutlinedChip key={`${viewer.name}-${index}`} variant="outlined" label={viewer.name} avatar={avatar} />;
              })}
            </Stack>
          ) : (
            t("リンクを知っているメンバーが閲覧可能")
          )}
        </ConfirmField>
        {props.enableEvaluationMetrics && (
          <ConfirmField label={t("評価項目")}>
            {props.metricItemTable && (
              <MetricItemTable {...props.metricItemTable} enableActionButton={false} enableCheckbox={false} enableTopBorder={true} />
            )}
          </ConfirmField>
        )}
      </Stack>
    ),
  };

  const subsections: SubsectionProps[] = [questionSetupSubsection, reportSetupSubsection];

  if (stepName !== "CONFIRM") {
    return null;
  }

  return (
    <Stack spacing={2} py={2} direction="column" sx={{ width: 800, margin: "0 auto" }}>
      {subsections.map((subsection, index) => {
        return <Subsection key={`subsection-${index}`} {...subsection} />;
      })}
    </Stack>
  );
};

ConfirmSection.displayName = "ConfirmSection";

export default ConfirmSection;
