import PriceJPRounded from "@hireroo/ui-assets/images/Icon/PriceJpRounded.svg";
import BusinessCenterRoundedIcon from "@mui/icons-material/BusinessCenterRounded";
import { useTheme } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Popover, { PopoverProps } from "@mui/material/Popover";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import * as React from "react";

export type ApplicantPreviewProps = {
  popover: Pick<PopoverProps, "open" | "anchorEl" | "id" | "onClose" | "PaperProps">;
  name: string;
  imageUrl?: string;
  status: string;
  salary: string;
  onClick: () => void;
};

const ApplicantPreview: React.FC<ApplicantPreviewProps> = props => {
  const theme = useTheme();
  const popoverProps: PopoverProps = {
    ...props.popover,
    PaperProps: {
      ...props.popover.PaperProps,
      sx: {
        pointerEvents: "auto",
      },
    },
    sx: { pointerEvents: "none" },
    disableRestoreFocus: true,
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "left",
    },
    transformOrigin: {
      vertical: "top",
      horizontal: "left",
    },
  };
  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    event.preventDefault();
    props.onClick?.();
  };
  return (
    <Popover {...popoverProps}>
      <Stack direction="row" spacing={2} p={2}>
        <Avatar src={props.imageUrl} sx={{ width: "56px", height: "56px", cursor: "pointer" }} onClick={handleClick} />
        <Stack spacing={1.5}>
          <Typography fontWeight={700}>{props.name}</Typography>
          <Stack spacing={1}>
            <Stack direction="row" alignItems="center" spacing={1}>
              <BusinessCenterRoundedIcon sx={{ width: "16px", height: "16px" }} htmlColor={theme.palette["Gray/Shades"].p56} />
              <Typography fontSize={14} color="textSecondary">
                {props.status}
              </Typography>
            </Stack>
            <Stack direction="row" alignItems="center" spacing={1}>
              <img src={PriceJPRounded} alt="price icon jp" />
              <Typography fontSize={14} color="textSecondary">
                {props.salary}
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Popover>
  );
};

ApplicantPreview.displayName = "ApplicantPreview";

export default ApplicantPreview;
