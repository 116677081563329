import { proxy } from "valtio";

import { DEFAULT_APPLICANT_FILTER, DEFAULT_APPLICANT_PAGER } from "./constants";
import type * as Types from "./types";

export const state = proxy<Types.State>({
  competition: null,
  applicantsRes: null,
  applicantFilter: DEFAULT_APPLICANT_FILTER,
  applicantPager: DEFAULT_APPLICANT_PAGER,
  initializedApplicantFilter: false,
});
