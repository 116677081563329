import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import * as React from "react";

import RestrictableTab, { RestrictableTabProps } from "../../modules/RestrictableTab/RestrictableTab";
import CompetitionDetailHeader, {
  CompetitionDetailHeaderProps,
} from "../../ms-components/Competition/CompetitionDetailHeader/CompetitionDetailHeader";

export type CompetitionIdDetailProps = {
  header: CompetitionDetailHeaderProps;
  items: RestrictableTabProps["items"];
  defaultTabId?: string;
};
const CompetitionIdDetail: React.FC<CompetitionIdDetailProps> = props => {
  const theme = useTheme();
  const restrictableTab: RestrictableTabProps = {
    defaultTab: props.defaultTabId,
    items: props.items,
    tabs: {
      "aria-label": "competition-detail-tabs",
    },
    tabBox: {
      sx: {
        paddingLeft: "24px",
        paddingRight: "24px",
        background: theme.palette["Background/Paper"].p1,
      },
    },
    tabPanelBox: {
      pt: 0,
      sx: {
        height: "100%",
      },
    },
    variant: "OUTLINED",
  };
  return (
    <Box>
      <CompetitionDetailHeader {...props.header} />
      <RestrictableTab {...restrictableTab} />
    </Box>
  );
};

CompetitionIdDetail.displayName = "CompetitionIdDetail";

export default CompetitionIdDetail;
