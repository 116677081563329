import { useAverageRankEvaluationMap, useJobChangeInterestDetailMap } from "@hireroo/app-definition/auth";
import { translateSalary } from "@hireroo/app-helper/competition";
import { CandidatesIdDetail } from "@hireroo/app-store/page/e/candidates_id_detail";
import { formatScore } from "@hireroo/formatter/score";
import { useLanguageCode, useTranslation, useTranslationWithVariable } from "@hireroo/i18n";
import type { Widget } from "@hireroo/presentation";

import CompetitionHistoryInitialContainer from "../CompetitionHistory/InitialContainer";

export type GenerateCandidateDetailPropsArgs = {};

export const useGenerateProps = (_args: GenerateCandidateDetailPropsArgs): Widget.CandidateDetailProps => {
  const { t } = useTranslation();
  const { t: t2 } = useTranslationWithVariable();
  const candidate = CandidatesIdDetail.useCandidate();
  const jobChangeInterestDetailMap = useJobChangeInterestDetailMap();
  const averageRankEvaluationMap = useAverageRankEvaluationMap();
  const lang = useLanguageCode();
  return {
    header: {
      imgUrl: candidate.photoUrl || undefined,
      name: candidate.displayName || t("名無し"),
      averageScore: candidate.averageScore !== null ? formatScore(candidate.averageScore) : undefined,
      averageRank: averageRankEvaluationMap[candidate.averageRankEvaluation],
    },
    sideSection: {
      profileText: candidate.profileText,
      emptyProfileText: "",
      jobChangeStatus: jobChangeInterestDetailMap[candidate.jobChangeInterest],
      expectedSalary:
        candidate.expectedSalaryYen !== null && candidate.expectedSalaryYen > 0
          ? t2("MoreThanDesired", { salary: translateSalary({ salaryInTenK: candidate.expectedSalaryYen / 10000, lang, withCurrency: true }) })
          : "-",
    },
    CompetitionHistory: <CompetitionHistoryInitialContainer candidateId={candidate.id} />,
  };
};
