import * as React from "react";

import CandidateLayout, { CandidateLayoutProps } from "../../layouts/CandidateLayout/CandidateLayout";

export type CandidateHomeProps = {
  layout: CandidateLayoutProps;
  children: React.ReactNode;
};

const CandidateHome: React.FC<CandidateHomeProps> = props => {
  return <CandidateLayout {...props.layout}>{props.children}</CandidateLayout>;
};

CandidateHome.displayName = "CandidateHome";

export default CandidateHome;
