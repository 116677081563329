import { EvaluationMetricListForRemote } from "@hireroo/app-store/widget/e/EvaluationMetricListForRemote";
import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { GenerateRemoteCreateDialogPropsArgs, useGenerateProps } from "./useGenerateProps";

export type RemoteCreateDialogFromEvaluationMetricsContainerProps = GenerateRemoteCreateDialogPropsArgs;

const RemoteCreateDialogFromEvaluationMetricsContainer: React.FC<RemoteCreateDialogFromEvaluationMetricsContainerProps> = props => {
  const remoteCreateDialogProps = useGenerateProps(props);
  React.useEffect(() => {
    return () => {
      EvaluationMetricListForRemote.clear();
    };
  }, []);
  return (
    <ErrorBoundary>
      <Widget.RemoteCreateDialog {...remoteCreateDialogProps} />
    </ErrorBoundary>
  );
};

RemoteCreateDialogFromEvaluationMetricsContainer.displayName = "RemoteCreateDialogFromEvaluationMetricsContainer";

export default withErrorBoundary(RemoteCreateDialogFromEvaluationMetricsContainer, {});
