import { SignInOrSignUp } from "@hireroo/app-store/essential/shared";
import type { Pages } from "@hireroo/presentation";

import SnackbarContainer from "../../../../widget/v2/shared/Snackbar/Container";
import SignUpContainer from "./widgets/CandidateSignUp/Container";

export type GenerateSignUpPropsArgs = {};

export const useGenerateProps = (_args: GenerateSignUpPropsArgs): Pages.SignInSignUpProps => {
  const signInStatus = SignInOrSignUp.useSignInStatus();

  return {
    layout: {
      loading: signInStatus === "VERIFYING",
      Bottom: <SnackbarContainer />,
    },
    children: <SignUpContainer />,
  };
};
