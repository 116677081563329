import DefaultThumbnail from "@hireroo/ui-assets/images/CompetitionThumbnail/DefaultThumbnail.svg";
import BusinessIcon from "@mui/icons-material/Business";
import Avatar, { AvatarProps } from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import CardMedia from "@mui/material/CardMedia";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";

const Wrapper = styled(Box)(() => ({
  position: "relative",
  overflow: "hidden",
}));

const LabelWrapper = styled(Box)(() => ({
  position: "absolute",
  left: "16px",
  top: "16px",
  borderRadius: "20px",
  backgroundColor: "white",
  padding: "1px 6px",
}));

const TitleWrapper = styled(Stack)(({ theme }) => ({
  position: "absolute",
  bottom: 0,
  padding: theme.spacing(1, 2),
  background: "linear-gradient(0deg, rgba(0, 0, 0, 0.80) 0%, rgba(0, 0, 0, 0.00) 100%)",
  width: "100%",
}));

type Header = {
  name: string;
  src?: AvatarProps["src"];
};

export type CompetitionThumbnailProps = {
  header: Header;
  image: {
    src?: string;
    alt: string;
  };
  label?: string;
  mediaHeight?: number;
};

const CompetitionThumbnail: React.FC<CompetitionThumbnailProps> = props => {
  return (
    <Wrapper>
      <CardMedia component="img" image={props.image.src ?? DefaultThumbnail} alt={props.image.alt} height={props.mediaHeight} />
      {props.label && (
        <LabelWrapper>
          <Typography fontSize={10} color="black">
            {props.label}
          </Typography>
        </LabelWrapper>
      )}
      <TitleWrapper direction="row" alignItems="center" spacing={1}>
        <Avatar sx={{ width: 24, height: 24 }} alt={props.header.name} src={props.header.src}>
          <BusinessIcon fontSize="small" />
        </Avatar>
        <Typography fontSize={14} fontWeight={700}>
          {props.header.name}
        </Typography>
      </TitleWrapper>
    </Wrapper>
  );
};

CompetitionThumbnail.displayName = "CompetitionThumbnail";

export default CompetitionThumbnail;
