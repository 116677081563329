import * as z from "zod";

const useScoreField = () => {
  return z.object({
    min: z.number(),
    max: z.number(),
  });
};

const useStatus = () => {
  return z.array(z.union([z.literal("CREATED"), z.literal("STARTED"), z.literal("COMPLETED"), z.literal("CONFIRMED")]));
};

const useSuspiciousDegrees = () => {
  return z.array(z.enum(["CLEAN", "SUSPICIOUS"]));
};

export const useApplicantSearchQuery = () => {
  const score = useScoreField();
  const statuses = useStatus();
  const suspiciousDegrees = useSuspiciousDegrees();

  return z.object({
    textFilter: z.string(),
    score,
    statuses,
    suspiciousDegrees,
  });
};

export type ApplicantSearchQuerySchema = z.infer<ReturnType<typeof useApplicantSearchQuery>>;
