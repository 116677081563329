import { MetricItemListForm } from "@hireroo/validator";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import Checkbox, { CheckboxProps } from "@mui/material/Checkbox";
import { styled } from "@mui/material/styles";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { useController, useFormContext } from "react-hook-form";

import IconButtonWithTooltip, { IconButtonWithTooltipProps } from "../../../../../primitive/Button/IconButtonWithTooltip/IconButtonWithTooltip";

const StyledDescription = styled(Typography)(() => ({
  display: "flex",
  whiteSpace: "pre-line",
  wordBreak: "break-all",
}));

export type MetricItemTableRowProps = {
  id: string;
  name: string;
  title: string;
  description: string;
  isSelectable?: boolean;
  deleteButton?: Pick<IconButtonWithTooltipProps, "onClick">;
};

const MetricItemTableRow: React.FC<MetricItemTableRowProps> = props => {
  const { control } = useFormContext();
  const { field } = useController<Record<string, MetricItemListForm.MetricItem>>({
    name: props.name,
    control: control,
  });

  const deleteButton: IconButtonWithTooltipProps | undefined = props.deleteButton
    ? {
        ...props.deleteButton,
        size: "small",
        children: <DeleteOutlineIcon fontSize="small" />,
      }
    : undefined;

  const checkbox: CheckboxProps | undefined = props.isSelectable
    ? {
        checked: field?.value?.selected ?? false,
        size: "small",
        color: "secondary",
        onClick: event => {
          event.stopPropagation();
        },
        onChange: (_event, checked) => {
          const newFieldValue: MetricItemListForm.MetricItem = {
            ...field.value,
            selected: checked,
          };
          field.onChange(newFieldValue);
        },
      }
    : undefined;
  const handleClick = React.useCallback(
    (event: React.MouseEvent) => {
      event.stopPropagation();
      event.preventDefault();
      if (props.isSelectable && field.value) {
        const newFieldValue: MetricItemListForm.MetricItem = {
          ...field.value,
          selected: !field.value.selected,
        };
        field.onChange(newFieldValue);
      }
    },
    [field, props.isSelectable],
  );

  return (
    <TableRow
      hover={props.isSelectable}
      onClick={handleClick}
      sx={{
        cursor: props.isSelectable ? "pointer" : "default",
        width: "100%",
      }}
    >
      {checkbox && (
        <TableCell>
          <Checkbox {...checkbox} />
        </TableCell>
      )}
      <TableCell>
        <Typography fontSize={14} fontWeight={700}>
          {props.title}
        </Typography>
      </TableCell>
      <TableCell>
        <StyledDescription color="textSecondary" fontSize={14}>
          {props.description}
        </StyledDescription>
      </TableCell>
      {deleteButton && (
        <TableCell>
          <IconButtonWithTooltip {...deleteButton} />
        </TableCell>
      )}
    </TableRow>
  );
};

MetricItemTableRow.displayName = "MetricItemTableRow";

export default MetricItemTableRow;
